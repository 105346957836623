import Component from '../components/HealthPassportIndex'
import { Api } from 'typescript-fetch-api'
import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'
import { RootStoreState } from 'modules/root'
import * as _ from 'lodash'
import { downloadDocument } from 'modules/documents/actions'

export interface OwnProps {
	
}

/**
 * Interface for properties that the container passes to the component.
 */
interface Props {
	clientRef?: string
	document?: Api.ClientDocumentSummary
	loading: boolean
	failed: boolean
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
interface Actions {
	onDownloadDocument: (documentRef: string) => void
}

export type ComponentProps = Props & Actions

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	/* Get loaded documents */
	const documents = state.documents.documents ? _.values(state.documents.documents.documents).filter(d => d.documentType === 'health-passport') : undefined
	/* Get the summary of the document */
	const document = documents !== undefined ? (documents.length > 0 ? documents[0] : undefined) : undefined
	
	return {
		clientRef: state.account.currentClientRef,
		document,
		loading: state.documents.loadingDocuments || false,
		failed: state.documents.documentsError !== undefined,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: OwnProps): Actions => ({
	onDownloadDocument: (documentRef) => {
		dispatch(downloadDocument({
			documentRef,
		}))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
