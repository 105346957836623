import { initApiConfiguration } from 'modules/api'
import { createStore, compose, applyMiddleware, StoreEnhancer, Middleware } from 'redux'
import { devToolsEnhancer } from 'redux-devtools-extension/logOnlyInProduction'
import createSagaMiddleware from 'redux-saga'
import { offline } from '@redux-offline/redux-offline'
import defaultOfflineConfig from '@redux-offline/redux-offline/lib/defaults'
import { Config } from '@redux-offline/redux-offline/lib/types'
import { createTransform } from 'redux-persist'

import rootSaga from './sagas'
import { setAuthConfig } from 'modules/auth'
import platform from 'modules/platform'

import { readyAction } from './actions'
import { StoreState as _RootStoreState, reducer } from './reducer'
import { StoreState as AuthStoreState } from 'modules/auth/reducer'
import { StoreState as AccountStoreState } from 'modules/account/reducer'

/* API handling */
import { handleDiscard, handleEffect } from 'modules/api/offline'
import { login } from 'modules/auth/actions'

export type RootStoreState = _RootStoreState

/**
 * Create the redux-saga middleware.
 */
const sagaMiddleware = createSagaMiddleware()

/** A redux-persist transformer so we don't persist our auth state if the user hasn't chosen rememberMe */
const rememberMeTransformer = createTransform(
	(inState, key) => {
		if (key === 'auth') {
			const authState = inState as AuthStoreState
			if (!authState.rememberMe) {
				return {}
			}
		}

		return inState
	},
	(outState, key) => outState,
	{
		whitelist: ['auth'],
	},
)

/** Only preserve the currentClientRef from the account store */
const accountTransformer = createTransform(
	(inState: AccountStoreState, key) => {
		const result: AccountStoreState = {
			currentClientRef: inState.currentClientRef,
			clients: {},
			supportNetworkUsers: {},
			loading: false,
			saving: false,
			savingChangePassword: false,
			ready: false,
		}
		return result
	},
	(outState, key) => outState,
	{
		whitelist: ['account'],
	},
)

/**
 * Create the redux-offline configuration, based on the default configuration.
 */
const offlineConfig: Config = {
	...defaultOfflineConfig,

	/**
	 * This callback occurs after redux-persist has rehydrated our Redux state.
	 */
	persistCallback: () => {
		/* Access token login hack for printing */
		if (window.location.search && window.location.search.indexOf('at=') !== -1) {
			const i = window.location.search.indexOf('at=')
			let at = window.location.search.substring(i + 3)
			const j = at.indexOf('&')
			if (j !== -1) {
				at = at.substring(0, j)
			}

			getStore().dispatch(login.done({
				params: {
					username: '',
					password: '',
					rememberMe: false,
				},
				result: {
					access_token: at,
					expires_in: 60,
					refreshAt: Date.now() + 60000,
					refresh_token: 'X',
					token_type: 'Bearer',
				},
			}))
		}
		
		/* Let our app know that the application state has been rehydrated and is ready to be used. */
		getStore().dispatch(readyAction())
	},

	/** Don't persist */
	persistOptions: {
		whitelist: ['account', 'auth', 'booking', 'documents'],
		blacklist: ['ready'],
		transforms: [rememberMeTransformer, accountTransformer],
	},

	/**
	 * This function is used to handle actions tagged for redux-offline to handle.
	 */
	effect: handleEffect,

	/**
	 * This function determines whether to discard a request, or to retry in, in the event
	 * of an error.
	 */
	discard: handleDiscard,

	/* The commented out function below hard-codes the app to function as if it is offline. */
	// detectNetwork: function(callback: (online: boolean) => void) {
	// 	callback(false)
	// },
}

let middlewares: Middleware[] = [sagaMiddleware]
middlewares = platform.customiseReduxMiddleware(middlewares)

/**
 * Enhancers for the store.
 */
const enhancers = compose(
	/* Add the redux-offline store enhancer */
	offline(offlineConfig),
	/* Add the middlewares */
	applyMiddleware(...middlewares),
	/* Include the devtools. Comment this out if you don't want to use the dev tools. */
	devToolsEnhancer({}),
) as StoreEnhancer<RootStoreState>

/**
 * Create the store. We do not include an initial state, as each of the module / duck
 * reducers includes its own initial state.
 */
export const store = createStore(reducer, enhancers)

function getStore() {
	return store
}

/* Init the API */
initApiConfiguration(platform.createApiConfigurationParams())

/* Create the authentication config */
setAuthConfig(platform.createAuthConfiguration())

/* Run the root saga */
sagaMiddleware.run(rootSaga)
