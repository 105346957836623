import * as React from 'react'
import { Api } from 'typescript-fetch-api'
import { OwnProps, Props, Actions } from '../containers/EditBooking'
import * as f from '../functions'
import Datetime from 'react-datetime'
import moment from 'moment'
import Moment from 'react-moment'
import { RouteComponentProps } from 'react-router'
import { LocalDate, LocalTime } from '@js-joda/core'
import * as features from 'modules/root/features'

export default class EditBooking extends React.Component<OwnProps & Props & Actions & RouteComponentProps<undefined>> {
	StartTimeOptionEnum = Api.NewAppointment.StartTimeOptionEnum

	handleDateChange = (newDate: moment.Moment | string) => {
		if (typeof newDate === 'string') {
			/* The user entered something that was not parseable as a date */
			return
		}
		const booking = { ...this.props.bookingToEdit, job: { ...this.props.bookingToEdit.job } }
		booking.job.date = LocalDate.parse(newDate.format('YYYY-MM-DD'))
		this.props.onUpdateEditBookingDetails(booking)
	}

	handleStartTimeChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
		const booking = { ...this.props.bookingToEdit, job: { ...this.props.bookingToEdit.job } }
		const value = evt.target.value

		if (this.StartTimeOptionEnum[value] === this.StartTimeOptionEnum.Flexible || 
			this.StartTimeOptionEnum[value] === this.StartTimeOptionEnum.Morning ||
			this.StartTimeOptionEnum[value] === this.StartTimeOptionEnum.Afternoon) {
			booking.job.startTime = undefined
			booking.job.startTimeOption = this.StartTimeOptionEnum[value]
		} else {
			booking.job.startTime = LocalTime.parse(value)
			booking.job.startTimeOption = this.StartTimeOptionEnum.Specified
		}
		this.props.onUpdateEditBookingDetails(booking)
	}

	handleDurationChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
		const value = evt.target.value
		const booking = { ...this.props.bookingToEdit, job: { ...this.props.bookingToEdit.job } }
		
		if (Api.AppointmentScheduleChange.DurationOptionEnum[value] === Api.AppointmentScheduleChange.DurationOptionEnum.NotSpecified) {
			booking.job.durationOption = Api.AppointmentScheduleChange.DurationOptionEnum.NotSpecified
			booking.job.durationMins = undefined
		} else if (Api.AppointmentScheduleChange.DurationOptionEnum[value] === Api.AppointmentScheduleChange.DurationOptionEnum.Overnight) {
			booking.job.durationOption = Api.AppointmentScheduleChange.DurationOptionEnum.Overnight
			booking.job.durationMins = undefined
			booking.job.startTime = LocalTime.parse('23:00')
			booking.job.startTimeOption = this.StartTimeOptionEnum.Specified
		} else {
			booking.job.durationOption = Api.AppointmentScheduleChange.DurationOptionEnum.Specified
			booking.job.durationMins = Number(value)
			if (this.props.bookingToEdit.job.durationOption === Api.AppointmentScheduleChange.DurationOptionEnum.Overnight) {
				booking.job.startTime = undefined
				booking.job.startTimeOption = this.StartTimeOptionEnum.Flexible
			}
		}
		
		this.props.onUpdateEditBookingDetails(booking)
	}

	handleRescheduleNotesChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
		const booking = { ...this.props.bookingToEdit, job: { ...this.props.bookingToEdit.job } }
		booking.notes = evt.target.value
		this.props.onUpdateEditBookingDetails(booking)
	}

	onContinue = () => {
		const currentUrlWithoutTrailingSlash = this.props.match.url.replace(/\/$/, '')

		if (this.props.editBookingFormState.editSupportWorkers && this.props.editBookingFormState.chooseOwnSupportWorker) {
			this.props.history.push(`${currentUrlWithoutTrailingSlash}/support-workers`)
		} else {
			this.props.history.push(`${currentUrlWithoutTrailingSlash}/summary`)
		}
	}

	cancelEditBooking = () => {
		const booking = this.props.bookingToEdit
		
		if (this.props.timesheetJobEdit) {
			this.props.history.replace(`/timesheet/${booking.job.timesheetRef}`)
		} else {
			this.props.history.replace('/calendar/date/' + booking.calDate + '/appt/' + booking.job.date + '/' + booking.job.ref)
		}
		this.props.onCancelEditBooking()
	}

	handleUpdateEditFormState = (evt: React.ChangeEvent<HTMLInputElement>) => {
		if (evt.target.name === 'reschedule') {
			this.props.onUpdateEditBookingFormState({
				...this.props.editBookingFormState,
				editTime: !this.props.editBookingFormState.editTime,
			})
		}
		if (evt.target.name === 'update-worker') {
			this.props.onUpdateEditBookingFormState({
				...this.props.editBookingFormState,
				editSupportWorkers: !this.props.editBookingFormState.editSupportWorkers,
			})
		}
		if (evt.target.name === 'repeat') {
			this.props.onUpdateEditBookingFormState({
				...this.props.editBookingFormState,
				editRepeating: !this.props.editBookingFormState.editRepeating,
			})
		}
		if (evt.target.name === 'choose-worker') {
			this.props.onUpdateEditBookingFormState({
				...this.props.editBookingFormState,
				chooseOwnSupportWorker: !this.props.editBookingFormState.chooseOwnSupportWorker,
			})
		}
	}
	
	public render() {
		const booking = this.props.bookingToEdit
		const editForm = this.props.editBookingFormState
		const { timesheetJobEdit, findSupportWorkers } = this.props

		const startTimes = (this.canSpecifyAnyTime() ? f.bookingTimes24hrs() : f.bookingTimes(booking.job.startTime ? booking.job.startTime.toString() : undefined))
		const durations = this.canSpecifyAnyTime() ? f.durations(30, 60 * 12, booking.job.durationMins) : f.durations(30, 480, booking.job.durationMins)

		return (
			<article className="site-body">
				<div className="width-limit -site">
					<form className="content-layout booking-form" action="#">
						<div className="body">
							<div className="form-layout">
								<div className="row">
									<h1 className="headline-text">Edit booking</h1>
								</div>
								{
									booking.job.repeatOptionDisplayName && features.repeatBooking &&
										<div className="row">
											<div className="body-text">
												<p><strong>
													This booking repeats {booking.job.repeatOptionDisplayName}
													{booking.job.repeatEndDate && (
														<>{' '}until <Moment date={booking.job.repeatEndDate.toString()} format="dddd D MMMM YYYY" /></>
													)}.</strong><br/>
													Do you want to modify this booking for <Moment date={booking.effectiveDate.toString()} format="dddd D MMMM YYYY" /> only, or also for all upcoming dates?
												</p>
											</div>
											<div className="form-field">
												<div className="form-input -options">
													<ul className="option-inputs -inline">
														<li className="option -radio">
															<label className="label">
																<input type="radio" className="radio" name="repeat" checked={!editForm.editRepeating} onChange={this.handleUpdateEditFormState} /><span className="substitute" />
																For this date only
															</label>
														</li>
														<li className="option -radio">
															<label className="label">
																<input type="radio" className="radio" name="repeat" checked={editForm.editRepeating} onChange={this.handleUpdateEditFormState}/><span className="substitute" />
																For this date and all upcoming dates
															</label>
														</li>
													</ul>
												</div>
											</div>
										</div>
								}
								<div className="row">
									<div className="form-field">
										<div className="form-input -options">
											<ul className="option-inputs -single">
												<li className="option -checkbox">
													<label className="label">
														<input type="checkbox" name="reschedule" className="checkbox" checked={editForm.editTime} onChange={this.handleUpdateEditFormState} />
														<span className="substitute" />
														I would like to reschedule this booking for another date/time
													</label>
												</li>
											</ul>
										</div>
									</div>
								</div>
						
								{		
									editForm.editTime &&
									<div className="row -split -auto">
										<div className="form-field">
											<label className="label">Day</label>
											<div className="form-input -date">
												<Datetime
													className="field"
													dateFormat="D MMM YYYY"
													timeFormat={false}
													inputProps={{ readOnly: true }}
													onChange={this.handleDateChange}
													isValidDate={f.isSelectableBookingStartDate}
													value={moment(booking.job.date.toString(), ['YYYY-MM-DDTHH:mm']).format('D MMM YYYY')}
												/>

											</div>
										</div>
										<div className="form-field">
											<label className="label">Start time</label>
											<div className="form-input -select">
												<select 
													className="select" 
													onChange={this.handleStartTimeChange} 
													value={booking.job.startTimeOption === this.StartTimeOptionEnum.Specified ? (booking.job.startTime ? booking.job.startTime.toString() : undefined) : booking.job.startTimeOption}
													disabled={booking.job.durationOption === Api.AppointmentScheduleChange.DurationOptionEnum.Overnight}
												>
													{
														!this.canSpecifyAnyTime() && booking.job.startTime && booking.job.startTime.toString() === '23:00' &&
														<option value="23:00">11:00pm</option>
													}
													{!this.onlySpecifyExactTimes() && (
														<>
															<option value={this.StartTimeOptionEnum.Flexible}>Flexible</option>
															<option value={this.StartTimeOptionEnum.Morning}>Morning</option>
															<option value={this.StartTimeOptionEnum.Afternoon}>Afternoon</option>
														</>
													)}
													{startTimes.map((bookingTime, key) => {
														return (
															<option key={key} value={bookingTime.toString()}>{moment(bookingTime.toString(), ['HH:mm']).format('h:mma')}</option>
														)
													})
													}
												</select>
											</div>
										</div>
										<div className="form-field">
											<label className="label">Duration</label>
											<div className="form-input -select">
												<select 
													className="select" 
													onChange={this.handleDurationChange} 
													value={booking.job.durationOption === Api.AppointmentScheduleChange.DurationOptionEnum.Specified ? booking.job.durationMins : booking.job.durationOption}
												>
													{!this.onlySpecifyExactTimes() && (
														<option value={Api.AppointmentScheduleChange.DurationOptionEnum.NotSpecified}>Unsure</option>
													)}
													{
														durations.map((duration, key) => {
															return (
																<option key={key} value={duration.minutes}>{duration.toText}</option>
															)
														})
													}
													{!this.onlySpecifyExactTimes() && (
														<option value={Api.AppointmentScheduleChange.DurationOptionEnum.Overnight}>Overnight</option>
													)}
												</select>
											</div>
										</div>
									</div>
								}

								<div className="row">
									<div className="form-field">
										<div className="form-input -options">
											<ul className="option-inputs -single">
												<li className="option -checkbox">
													<label className="label">
														<input type="checkbox" className="checkbox" name="update-worker" checked={editForm.editSupportWorkers} onChange={this.handleUpdateEditFormState} />
														<span className="substitute" />
													I would like to change my support worker
													</label>
												</li>
											</ul>
										</div>
									</div>
								</div>
								{
									editForm.editSupportWorkers && findSupportWorkers &&
								<div className="row">
									<div className="body-text">
										<p>Would you like to choose your support worker(s) or let our coordinators find the best match based on your requirements?</p>
									</div>
									<div className="form-field">
										<div className="form-input -options">
											<ul className="option-inputs -inline">
												<li className="option -radio">
													<label className="label">
														<input type="radio" className="radio" name="choose-worker" checked={editForm.chooseOwnSupportWorker} onChange={this.handleUpdateEditFormState} /><span className="substitute" />
														I'd like to choose my own support worker(s)
													</label>
												</li>
												<li className="option -radio">
													<label className="label">
														<input type="radio" className="radio" name="choose-worker" checked={!editForm.chooseOwnSupportWorker} onChange={this.handleUpdateEditFormState}/><span className="substitute" />
														Please find the best match for me
													</label>
												</li>
											</ul>
										</div>
									</div>
								</div>
								}
								{ !timesheetJobEdit &&
								<div className="row">
									<div className="form-field">
										<label className="label">Details</label>
										<div className="form-input -textarea">
											<textarea
												className="field"
												placeholder="Please provide details about any changes or notes you would like added for this booking."
												value={booking.notes}
												onChange={this.handleRescheduleNotesChange}
											/>
										</div>
									</div>
								</div>
								}
						
								<div className="row">
									<div className="button-group">
										<div className="button">
											<div onClick={this.cancelEditBooking} className="button-link -text">Cancel</div>
										</div>
										<div className="button -constrained">
											<div onClick={this.onContinue} className={'button-link -action' + (editForm.editSupportWorkers || editForm.editTime ? '' : ' -disabled')}>Continue</div>
										</div>
									</div>
								</div>
					
							</div>
						</div>
					
						<aside className="sidebar">
							<h1 className="page-title">Edit booking</h1>
							<nav className="progress-nav">
								<ul className="listitems">
									<li className="item -active"><div className="link">Booking details</div></li>
									{ editForm.editSupportWorkers && editForm.chooseOwnSupportWorker &&
									<li className="item"><span className="link">Support workers</span></li>
									}
									<li className="item"><span className="link">Summary</span></li>
								</ul>
							</nav>
						</aside>
					</form>
				</div>
			</article>
		)
	}

	private canSpecifyAnyTime = () => {
		return this.props.bookingToEdit.job.timesheetRef !== undefined
	}

	private onlySpecifyExactTimes = () => {
		return this.props.bookingToEdit.job.timesheetRef !== undefined
	}
}
