import * as React from 'react'
import Header from 'modules/common/containers/Header'
import Login from '../containers/Login'
import ForgotPassword from '../containers/ForgotPassword'
import Footer from 'modules/common/components/Footer'
import { RouteComponentProps, Switch, Route } from 'react-router'

export default class LoginScreen extends React.Component<RouteComponentProps<undefined>> {
	public render() {
		return (
			<div className="site-content signin-screen">
				<Header section="sign-in" />
				<Switch>
					<Route path="/sign-in/forgot-password" component={ForgotPassword} />
					<Route 
						path="/sign-in" 
						render={() => (
							<Login {...this.props} />
						)}
					/>
				</Switch>
				<Footer />
			</div>
		)
	}
}
