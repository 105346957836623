import { reducerWithInitialState } from 'typescript-fsa-reducers'
import * as a from './actions'

/**
 * Export the StoreState interface for this module. We always name this interface
 * `StoreState` so it is consistent across each of our modules.
 * We export a readonly version of the interface, to try to prevent modifications,
 * and retain a private (not exported) MutableStoreState for ourselves to use very
 * carefully in the reducers, if we need to.
 */
export type StoreState = DeepReadonly<MutableStoreState>

export interface MutableStoreState {
	submitting: boolean
}

/**
 * The initial store state for this module.
 */
const INITIAL_STATE: MutableStoreState = {
	submitting: false,
}

/**
 * Reducer function for this module.
 */
export const reducer = reducerWithInitialState(INITIAL_STATE)
reducer.case(a.cancelNewOrder, (state): MutableStoreState => INITIAL_STATE)
reducer.case(a.newOrder, (state): MutableStoreState => INITIAL_STATE)

reducer.case(a.submitNewOrderAsync.started, (state): MutableStoreState => ({
	...state,
	submitting: true,
}))

reducer.case(a.submitNewOrderAsync.done, (state): MutableStoreState => ({
	...state,
	submitting: false,
}))

reducer.case(a.submitNewOrderAsync.failed, (state, { error: { message } }): MutableStoreState => ({
	...state,
	submitting: false,
}))