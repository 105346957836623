import * as React from 'react'
import TextBox from './TextBox'
import { forComponentProps, wrapComponent } from 'changeling'
import Radio from './Radio'
import { HealthPassportFormProps } from './HealthPassportScreen'
import Contact from './Contact'
import ThreeQuestion from './ThreeQuestion'

import importantPeopleHeader from 'modules/frontend/img/health-passport/33-important-people.jpg'
import Image23 from 'modules/frontend/img/health-passport/23-moving-around.jpg'
import Image24 from 'modules/frontend/img/health-passport/24-moving-methods.jpg'
import Image25 from 'modules/frontend/img/health-passport/25-moving-info.jpg'
import Image26 from 'modules/frontend/img/health-passport/26-daily-activities.jpg'
import Image27 from 'modules/frontend/img/health-passport/27-using-toilet.jpg'
import Image28 from 'modules/frontend/img/health-passport/28-using-shower.jpg'
import Image29 from 'modules/frontend/img/health-passport/29-personal-shower.jpg'
import Image30 from 'modules/frontend/img/health-passport/30-dressing.jpg'
import Image31 from 'modules/frontend/img/health-passport/31-eating-drinking.jpg'
import Image32 from 'modules/frontend/img/health-passport/32-sleeping.jpg'
import Image35 from 'modules/frontend/img/health-passport/35-next-of-kin.jpg'
import Image36 from 'modules/frontend/img/health-passport/36-support-person.jpg'
import Image37 from 'modules/frontend/img/health-passport/37-general-practitioner.jpg'
import Image38 from 'modules/frontend/img/health-passport/38-contact-other.jpg'

type MyComponentProps = HealthPassportFormProps

class HealthPassportSectionB extends React.Component<MyComponentProps> {

	private changeling = forComponentProps(this)

	public render() {
		const { editingMode, value } = this.props

		return (
			<>
				<h2 className="section-subheader"><span className="header-number">6.</span>Moving around</h2>

				<Radio 
					defaultOption="I don't need support with moving around"
					secondaryOption="I may need support with moving around"
					imageLocation={Image23}
					controller={this.changeling}
					prop="movingAround"
					editingMode={editingMode}
				/>
				{
					value.movingAround &&
					<>
						<TextBox 
							subOrder="a)"
							title="I move around using"
							subText="(e.g., I can walk with the support of a wall, I can see only up to a certain distance, I use a hoist for transfers, I have a guide dog, etc):"
							controller={this.changeling}
							imageLocation={Image24}
							prop="movingMethods"
							editingMode={editingMode}
						/>
						<TextBox 
							subOrder="b)"
							title="Things you need to know when supporting me to move around"
							subText="(e.g., roll me on one side when helping me to move in bed, let me hold your left arm when you are guiding me, please put my power wheelchair on charge at night, etc):"
							controller={this.changeling}
							imageLocation={Image25}
							prop="movingInfo"
							editingMode={editingMode}
						/>
					</>
				}

				<h2 className="section-subheader"><span className="header-number">7.</span>Daily activities</h2>
				<Radio 
					defaultOption="I don't need support with daily activities"
					secondaryOption="I may need support with daily activities"
					imageLocation={Image26}
					controller={this.changeling}
					prop="dailyActivities"
					editingMode={editingMode}
				/>
				{
					value.dailyActivities &&
					<>
						<ThreeQuestion
							subOrder="a)"
							title="Using the toilet"
							questionOne="I can"
							questionTwo="You can support me with"
							questionThree="Things to be aware of"
							imageLocation={Image27}
							controller={this.changeling}
							prop="usingToilet"
							editingMode={editingMode}
						/>
						<ThreeQuestion
							subOrder="b)"
							title="Washing/Taking shower"
							questionOne="I can"
							questionTwo="You can support me with"
							questionThree="Things to be aware of"
							imageLocation={Image28}
							controller={this.changeling}
							prop="usingShower"
							editingMode={editingMode}
						/>
						<ThreeQuestion
							subOrder="c)"
							title="Grooming &amp; personal hygiene"
							questionOne="I can"
							questionTwo="You can support me with"
							questionThree="Things to be aware of"
							imageLocation={Image29}
							controller={this.changeling}
							prop="personalHygiene"
							editingMode={editingMode}
						/>
						<ThreeQuestion
							subOrder="d)"
							title="Dressing"
							questionOne="I can"
							questionTwo="You can support me with"
							questionThree="Things to be aware of"
							imageLocation={Image30}
							controller={this.changeling}
							prop="dressing"
							editingMode={editingMode}
						/>
						<ThreeQuestion
							subOrder="e)"
							title="Eating &amp; drinking"
							questionOne="I can"
							questionTwo="You can support me with"
							questionThree="Things to be aware of"
							imageLocation={Image31}
							controller={this.changeling}
							prop="eatingDrinking"
							editingMode={editingMode}
						/>
						<ThreeQuestion
							subOrder="f)"
							title="Sleeping"
							questionOne="I can"
							questionTwo="You can support me with"
							questionThree="Things to be aware of"
							imageLocation={Image32}
							controller={this.changeling}
							prop="sleeping"
							editingMode={editingMode}
						/>
					</>
				}
				<div className="header-wrapper">
					<h2 className="section-subheader"><span className="header-number">8.</span>Important people in my life</h2>
					<p className="image-contain"><img src={importantPeopleHeader} /></p>
				</div>
				<Contact 
					title="Next of kin"
					subOrder="a)"
					subText="(e.g., my spouse, family member, or friend):"
					imageLocation={Image35}
					relationshipToMe={true}
					controller={this.changeling}
					prop="nextOfKin"
					editingMode={editingMode}
				/>
				<Contact 
					title="Support person"
					subOrder="b)"
					subText="(e.g., my key support worker in the house where I live):"
					imageLocation={Image36}
					relationshipToMe={true}
					nameOfAgency={true}
					controller={this.changeling}
					prop="supportPerson"
					editingMode={editingMode}
				/>
				<Contact 
					title="General practitioner"
					subOrder="c)"
					imageLocation={Image37}
					address={true}
					controller={this.changeling}
					prop="generalPractitioner"
					editingMode={editingMode}
				/>
				<TextBox 
					subOrder="d)"
					title="Any other person or agency and their contact details"
					controller={this.changeling}
					imageLocation={Image38}
					prop="contactOther"
					editingMode={editingMode}
				/>
			</>
		)
	}
}

export default wrapComponent(HealthPassportSectionB)
