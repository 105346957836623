import * as React from 'react'

interface OwnProps {

}

export default class StartRegistration extends React.Component<OwnProps> {
	
	public render() {
		return (
			<article className="site-body">
				<div className="width-limit -site">
					<div className="single-form">
			
						<form className="form">
							<div className="form-layout">
								<div className="introduction">
									<h1 className="headline-text">Coming soon!</h1>
									<div className="body-text">
										<p>My Homecare will be available soon.</p>
									</div>
								</div>

							</div>
						</form>
						
					</div>
				</div>
			</article>
		)
	}
}
