import * as React from 'react'
import { Props, OwnProps } from '../containers/TransactionHistory'
import { Helmet } from 'react-helmet'
import Transaction from './Transaction'
import numeral from 'numeral'

export default class TransactionHistory extends React.Component<Props & OwnProps> {
	public render() {
		const { individualisedFunding, transactions } = this.props

		let balance = individualisedFunding.openingBalanceNZD
		return (
			<div className="individualised-funding-screen body body-text">
				<Helmet>
					<title>Transaction history</title>
				</Helmet>
				<h1 className="headline-text">Transaction history</h1>
				<div className="form-layout">
					<div className="row">
						<table className="table table-default">
							<thead>
								<tr>
									<th scope="col">Date</th>
									<th scope="col">Description</th>
									<th scope="col">Amount</th>
									<th scope="col">Balance</th>
								</tr>
							</thead>
							<tbody>
								{
									transactions.map((transaction, key) => {
										balance -= transaction.amountNZD
										return (
											<Transaction 
												key={key}
												transactionId={transaction.ref}
												date={transaction.transactionDate}
												description={transaction.serviceType}
												amount={numeral(transaction.amountNZD).format('$0,0.00')}
												balance={numeral(balance).format('$0,0.00')}
											/>
										)
									})
								}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		)
	}
}
