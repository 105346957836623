import * as React from 'react'
import { Helmet } from 'react-helmet'
import { Api } from 'typescript-fetch-api'
import { Link } from 'react-router-dom'

export interface OwnProps {
	individualisedFunding: DeepReadonly<Api.IndividualisedFunding>
}

/**
 * Interface for private internal component state.
 */
interface State {

}

/**
 * The initial state for our internal component state.
 */
const INITIAL_STATE: State = {
	// myValue: 'Example',
}

export default class Guidelines extends React.Component<OwnProps, State> {
// export default class Overview extends React.Component<OwnProps & Props & Actions, State> {

	public state = INITIAL_STATE

	public render() {
		return (
			<div className="individualised-funding-screen body body-text">
				<Helmet>
					<title>Resources</title>
				</Helmet>   
				<h1 className="headline-text">Resources</h1>
				<div className="form-layout">
					<div className="row">
						<h2>About individualised funding</h2>
						<p>About person directed disability support.</p>
						<div className="registration-list">
							<ul>
								<li className="icon -document-download"><a href={require('../resources/Enabling_Good_Lives_-_Booklet.pdf')}>About iThrive</a></li>
							</ul>
						</div>
						<p>These are resources about the individualised funding process.</p>
						<div className="registration-list">
							<ul>
								<li className="icon -document-download"><a href={require('../resources/About_Individualised_Funding_and_How_the_process_works.docx')}>About Individual Funding and how the process works</a></li>
								<li className="icon -document-download"><a href={require('../resources/5a_._Guidance_notes_on_the_use_of_your_IF_Personal_Budget.docx')}>Guidance notes on the use of your personal budget</a></li>
								<li className="icon -document-download"><a href={require('../resources/7._Ministry_Guideline_for_Verification_of_Supports_within_Hosted_Funding_Schemes_V1.docx')}>Ministry guidelines for verification of supports</a></li>
								<li className="icon -external-link"><a target="_blank" rel="noopener noreferrer" href="https://www.hdc.org.nz/your-rights/about-the-code/code-of-health-and-disability-services-consumers-rights/">Code of Health and Disability Services Consumers’ Rights</a></li>
								<li className="icon -document-download"><a href={require('../resources/Individualised_Funding_Host_Services.pdf')}>Individualised funding services</a></li>
							</ul>
						</div>
					</div>
					<br/>
					<div className="row">
						<h2>Employment</h2>
						<p>To employ your own support workers you need to know what’s involved in being an employer.</p>
						<div className="registration-list">
							<ul>
								<li className="icon -external-link"><a target="_blank" rel="noopener noreferrer" href="https://www.employment.govt.nz/starting-employment/who-is-an-employee/types-of-employee/">Types of employees</a></li>
								<li className="icon -document-download"><a href={require('../resources/Sample_letter_of_employment_offer-no-collective-agreement.docx')}>Sample letter of employment offer</a></li>
								<li className="icon -external-link"><a target="_blank" rel="noopener noreferrer" href="https://www.employment.govt.nz/starting-employment/rights-and-responsibilities/minimum-rights-of-employees/">Minimum rights of employees</a></li>
								<li className="icon -document-download"><a href={require('../resources/INDIVIDUALISED_FUNDING_Employee_Application_Form_Template.docx')}>Employee application form template</a></li>
								<li className="icon -document-download"><a href={require('../resources/LETTER_OR_EMAIL_SAMPLE_TEMPLATE___TO_D_ECLINE_A_SUPPORT_WORKER_APPLICANT.docx')}>Template to decline a Support Worker applicant</a></li>
								<li className="icon -external-link"><a target="_blank" rel="noopener noreferrer" href="https://www.employment.govt.nz/starting-employment/rights-and-responsibilities/employment-responsibilities/">Employment responsibilities</a></li>
								<li className="icon -document-download"><a href={require('../resources/Employment-agreements_overview_information.pdf')}>Employment agreements overview</a></li>
								<li className="icon -document-download"><a href={require('../resources/A_Guide_on_how-to-hire_staff.pdf')}>A guide on how to hire staff</a></li>
								<li className="icon -external-link"><a target="_blank" rel="noopener noreferrer" href="https://www.acc.co.nz/for-business/">ACC for Employers</a></li>
								<li className="icon -document-download"><a href={require('../resources/8._Employee_Support_Worker_Privacy_Notice.docx')}>Employee Support Worker privacy notice</a></li>
								<li className="icon -document-download"><a href={require('../resources/Individual_Employment_agreement_Sample_template_(permanent).docx')}>Permanent Employment Agreement</a></li>
								<li className="icon -document-download"><a href={require('../resources/IF_Casual_IEA_template.doc')}>Casual Employment Agreement</a></li>
							</ul>
							<br/>
							<p>Individualised Funding set up forms:</p>
							<ul>
								<li className="icon -document-download"><a target="_blank" rel="noopener noreferrer" href="https://business-survival-guide.ird.govt.nz/pdf/ir334.pdf">Registering as an Employer – IR334</a></li>
								<li className="icon -document-download"><a target="_blank" rel="noopener noreferrer" href="https://thankyoupayroll.co.nz/docs/ir920.pdf">Employer link to Thank you Payroll Services</a></li>
								<li className="icon -document-download"><a target="_blank" rel="noopener noreferrer" href="https://www.nzqa.govt.nz/assets/About-us/Working-for-NZQA/ECM/KS2-Deduction-Form.pdf">Employee Kiwi Saver opt in form</a></li>
								<li className="icon -document-download"><a target="_blank" rel="noopener noreferrer" href="https://www.ird.govt.nz/resources/b/8/b8a84d4e-84f4-4e22-b5f5-b5e15bd10f55/ks10.pdf">Kiwi Saver opt out form</a></li>
								<li className="icon -document-download"><a href={require('../resources/9._Support_Worker_Privacy_Declaration_template.docx')}>Support Worker Privacy Declaration</a></li>
								<li className="icon -document-download"><a href={require('../resources/3._IF_Geneva_Healthcare_Service_Fees_and_Client_Informed_Consent_and_Client_Details.docx')}>Geneva IF Consent and Service Agreement</a></li>
								<li className="icon -document-download"><a href={require('../resources/10a.Verification_Details_for_all_Employees.docx')}>Information required by Geneva Healthcare to Verify IF Employees</a></li>
							</ul>
							<br/>
							<p>Resources to help you find Support Workers:</p>
							<ul>
								<li className="icon -external-link"><Link to="/support-workers">Find a My Homecare Support Worker</Link></li>
								<li className="icon -document-download"><a href={require('../resources/Individualised_Funding_Childcare_CV_Guidelines_for_Applicants.docx')}>CV Guidelines for Childcare</a></li>
								<li className="icon -document-download"><a href={require('../resources/IF_Support_Worker_Sample_Job_Advertisement.docx')}>Sample job advertisement</a></li>
								<li className="icon -document-download"><a href={require('../resources/IF_Homecare_Support_Worker_Position_Description_Feb_2016.docx')}>Support Worker position description</a></li>
							</ul>
							<br/>
							<p>Resources to help you check applicants’ references:</p>
							<ul>
								<li className="icon -document-download"><a href={require('../resources/Reference_Checking_Template_-_Individualised_Funding.docx')}>Reference checking template</a></li>
								<li className="icon -document-download"><a href={require('../resources/Police_Vetting_Form_Template_Dec_2016_(ID_263277).docx')}>Police vetting form</a></li>
								<li className="icon -document-download"><a href={require('../resources/User-guide-to-pvs-vetting-request-consent-form.docx')}>User guide to Police vetting request consent form</a></li>
								<li className="icon -document-download"><a href={require('../resources/Criminal-record-checks_explained.pdf')}>Criminal record checks explained</a></li>
								<li className="icon -document-download"><a href={require('../resources/Applicant_Phone_Screen_and_Interview_Questionnaire_ExampleTemplate.docx')}>Phone screen and interview questionnaire</a></li>
							</ul>
							<br/>
							<p>Resources to help you pay Support Workers:</p>
							<ul>
								<li className="icon -document-download"><a href={require('../resources/IF_Claim_Schedule_2018-2019.pdf')}>Fortnightly claim form schedule 2018-2019</a></li>
								<li className="icon -document-download"><a href={require('../resources/IF_client_Mock_Budget_template_using_Geneva_Payroll.xlsx')}>IF client budget template using Geneva Payroll</a></li>
								<li className="icon -document-download"><a href={require('../resources/IF_client_Mock_Budget_template_self_managing.xlsx')}>IF client budget for self managing</a></li>
								<li className="icon -document-download"><a href={require('../resources/11._Pay_Equity_and_Salary_Setting_information_-_Copy.docx')}>Pay Equity and Salary Setting</a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
