/* Import the component from the component path */
import Component from '../components/BookingSupportWorkers'
import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'
import { push } from 'connected-react-router'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'

/* Import module actions */
import * as actions from '../actions'

/** Interface for properties that are passed to the container/ */
export interface OwnProps {
	newBooking: boolean
}

/** Interface for properties that the container passes to the component. */
export interface Props {

}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
	onCancel: () => void
	onContinue: () => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	return {

	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: OwnProps): Actions => ({
	onCancel: () => {
		dispatch(actions.cancelBooking())
	},
	onContinue: () => {
		if (ownProps.newBooking) {
			dispatch(push('/calendar/new/summary'))
		} else {
			dispatch(push('/calendar/edit/summary'))
		}
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)