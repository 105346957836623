import { SagaIterator } from 'redux-saga'
import { takeEvery, put, call, select } from 'redux-saga/effects'

import * as a from './actions'
import * as f from './functions'
import * as s from './selectors'
import * as accountSelectors from 'modules/account/selectors'
import * as aa from 'modules/account/actions'

import { callApiWithActions } from 'modules/api/functions'
import api from 'modules/api'

function* handleSearch(action: a.SearchAction): SagaIterator {
	const userRef = (yield select(accountSelectors.currentUserRefSelector))

	yield call(callApiWithActions, action, a.searching, (options: RequestInit) => {
		return api().userApi.searchUserOrganisationClients(userRef, action.payload.organisationRef, action.payload.searchTerm, options)
	})
}

function* chooseFunderClient(action: a.ChooseFunderClientAction): SagaIterator {
	const userRef = yield select(accountSelectors.currentUserRefSelector)
	const clientAccess = yield select(s.organisationClientAccessSelector, action.payload)
	if (userRef && clientAccess) {
		const membership = f.createSupportNetworkMembership(clientAccess, userRef)
		const client = (yield select(s.createClientFromOrganisation, action.payload)) as ReturnType<typeof s.createClientFromOrganisation>
	
		if (membership && client) {
			yield put(a.addClientToUserClients({ membership: membership, client: client }))
			yield put(aa.chooseClient({ clientRef: client.ref, interactive: true }))
		}
	}
}

export default function* (): SagaIterator {
	yield takeEvery(a.search, handleSearch)
	yield takeEvery(a.chooseFunderClient, chooseFunderClient)
}
