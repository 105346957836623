import * as React from 'react'
import { Snapshot, forComponentProps, Input, wrapComponent } from 'changeling'

export interface OwnProps extends Snapshot<boolean | undefined> {
	subOrder?: string
	secondaryQuestion?: boolean // TRUE if this question has been displayed due to the previous question
	title: string
	imageLocation?: string
	editingNote?: string
	valueNote?: string
	question: string
	editingMode?: boolean
}

class CheckBox extends React.Component<OwnProps> {
	private changeling = forComponentProps(this)
	
	public render() {
		return (
			<div className={'form-item -checkbox -background' + (this.props.secondaryQuestion ? ' -secondary' : '') + (this.props.editingMode ? '' : ' -valuemode')}>
				<div className="form-wrapper">
					<div className="content-wrapper">
						<p className="title">
							{ this.props.subOrder && 
								<span className="title-order">{this.props.subOrder}</span>
							}
							{this.props.title}
						</p>
						{
							this.props.editingMode && this.props.editingNote && 
							<div className="note">
								<p className="sub-text"><span className="title">Note:</span> {this.props.editingNote}</p>
							</div>
						}
						{
							!this.props.editingMode && this.props.valueNote &&
							<div className="note">
								<p className="sub-text"><span className="title">Note:</span> {this.props.valueNote}</p>
							</div>
						}
						{
							this.props.editingMode &&
							<div className="form-field">
								<div className="form-input -options">
									<div className="option-inputs -stacked">
										<div className="option -checkbox">
											<label className="label">
												<Input.Checkable
													type="checkbox"
													className="checkbox" 
													controller={this.changeling} 
													prop="this"
													checkedValue={true}
												/>
												<span className="substitute" />
												{this.props.question}
											</label>
										</div>
									</div>
								</div>
							</div>
						}
					</div>
					<div className="image-wrapper">
						{
							this.props.imageLocation &&
								<img src={this.props.imageLocation} />
						}
					</div>
				</div>
			</div>
		)
	}
}

export default wrapComponent(CheckBox)