import Component from '../components/HeaderNav'
import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'
import { push } from 'connected-react-router'
import * as a from '../actions'
import * as as from 'modules/account/selectors'
import * as authActions from 'modules/auth/actions'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'

/* Import module actions */
// import * as actions from '../actions'

/** Interface for properties that are passed to the container/ */
export interface OwnProps {

}

export interface Props {
	viewingDifferentAccount: boolean
	currentClientName?: string
}

export interface Actions {
	onRegisterForCare: () => void
	onSignOut: () => void
	onHome: () => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	const client = as.currentClientSelector(state)

	return {
		viewingDifferentAccount: client && !as.clientIsYou(state, client.ref) ? true : false,
		currentClientName: client ? client.givenName : undefined,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	onRegisterForCare: () => {
		dispatch(push('/setup/register-new/care'))
	},
	onSignOut: () => {
		dispatch(authActions.logoutRequest())
	},
	onHome: () => {
		dispatch(a.goHome())
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
