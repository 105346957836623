import * as React from 'react'
import { Snapshot, forComponentProps, Input, wrapComponent } from 'changeling'
import { LegalFormValue } from 'modules/health-passport/types'

export interface OwnProps extends Snapshot<LegalFormValue | undefined> {
	subOrder?: string
	title?: string
	secondaryQuestion?: boolean // TRUE if this question has been displayed due to the previous question
	editingMode?: boolean
}

class LegalForm extends React.Component<OwnProps> {
	private changeling = forComponentProps(this)

	public render() {
		return (
			<div className={'form-item -legalform' + (this.props.secondaryQuestion ? ' -secondary' : '') + (this.props.editingMode ? ' -no-gutters' : ' -valuemode')}>
				<p className="title">
					{ this.props.subOrder && 
						<span className="title-order">{this.props.subOrder}</span>
					}
					{this.props.title}
				</p>
				{
					this.props.editingMode ? (
						<div className="form-layout">
							<div className="row -split -half">
								<div className="form-field">
									<label className="label">Name</label>
									<div className="form-input -text">
										<Input.String type="text" className="field" controller={this.changeling} prop="name"/>
									</div>
								</div>
								<div className="form-field">
									<label className="label">Legal Relationship <span className="note">(power of attorney, etc)</span></label>
									<div className="form-input -text">
										<Input.String type="text" className="field" controller={this.changeling} prop="legalRelationship"/>
									</div>
								</div>
							</div>

							<div className="row -split -thirds">
								<div className="form-field ">
									<label className="label">Phone</label>
									<div className="form-input -text">
										<Input.String type="text" className="field" controller={this.changeling} prop="phone"/>
									</div>
								</div>
								<div className="form-field">
									<label className="label">Mobile phone</label>
									<div className="form-input -text">
										<Input.String type="text" className="field" controller={this.changeling} prop="mobile"/>
									</div>
								</div>
								<div className="form-field">
									<label className="label">Fax</label>
									<div className="form-input -text">
										<Input.String type="text" className="field" controller={this.changeling} prop="fax"/>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="form-field">
									<label className="label">Email</label>
									<div className="form-input -text">
										<Input.String type="text" className="field" controller={this.changeling} prop="email"/>
									</div>
								</div>
							</div>
						</div>
					) : (
						<div className="value-wrapper">
							<p className="sub-text"><span className="title">Name: </span> {this.props.value && this.props.value.name}</p>
							<p className="sub-text"><span className="title">Legal relationship: </span> {this.props.value && this.props.value.legalRelationship}</p>
							<p className="sub-text"><span className="title">Phone: </span> {this.props.value && this.props.value.phone}</p>
							<p className="sub-text"><span className="title">Mobile: </span> {this.props.value && this.props.value.mobile}</p>
							<p className="sub-text"><span className="title">Fax: </span> {this.props.value && this.props.value.fax}</p>
							<p className="sub-text"><span className="title">Email: </span> {this.props.value && this.props.value.email}</p>
						</div>
					)
				}

			</div>
		)
	}
}

export default wrapComponent(LegalForm)
