import * as React from 'react'
import { Props, OwnProps } from '../containers/IFFundingSummary'
import { Link } from 'react-router-dom'
import LineChart from 'modules/individualised-funding/components/LineChart'
import Moment from 'react-moment'
import * as f from 'modules/individualised-funding/functions'
import numeral from 'numeral'
import { Api } from 'typescript-fetch-api'

export default class IFFundingSummary extends React.Component<Props & OwnProps> {
	public render() {
		const { individualisedFunding, funder, transactions } = this.props

		return (
			<div className="funding-summary">
				<div className="col -details">
					<h1 className="title">{funder.name}</h1>
					<h1 className="headline-text">{numeral(individualisedFunding.closingBalanceNZD).format('$0,0.00')}</h1>
					<div className="details">
						<label className="label">Remaining from {numeral(individualisedFunding.openingBalanceNZD).format('$0,0.00')} total</label>
						<p>to cover {f.fundingRemainingText(individualisedFunding)}</p>
					</div>
					<div className="details">
						<label className="label">Funding period</label>
						<p>
							<Moment date={individualisedFunding.startDate} format="D MMM YYYY" /> &mdash; 
							{individualisedFunding.endDate && (
								<Moment date={individualisedFunding.endDate} format="D MMM YYYY" />
							)}
						</p>
					</div>
					<Link className="button-link -action -micro" to={'/account/individualised-funding/' + individualisedFunding.ref}>View more information</Link>
				</div>
				<div className="col -graph">
					<LineChart data={f.prepareDataForGraph(individualisedFunding, transactions)} className="individualised-funding-chart" />
				</div>
				{
					individualisedFunding.needsLevel === Api.IndividualisedFunding.NeedsLevelEnum.Level2 &&
					<div className="col -timesheet">
						<h1 className="title">Timesheets</h1>
						<p>If you haven’t already, please submit any timesheets you have for the period</p>
						<p><strong>{f.calculateCurrentTimesheetPeriod()}</strong></p>
						<Link className="button-link -action -micro" to={'/account/individualised-funding/' + individualisedFunding.ref + '/timesheets-expenses'}>Go to timesheets</Link>
					</div>
				}
			</div>
		)
	}
}
