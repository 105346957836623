import * as React from 'react'
import { Api } from 'typescript-fetch-api'
import * as t from '../types'
import * as a from '../actions'
import { avatarPlaceholder } from 'modules/root/theme'

interface OwnProps {
	network: t.SupportNetworkMembership
	handleUpdateSupportNetworkCapabilities: (request: a.UpdateUserCapabilitiesPayload) => void
	removeUserFromSupportNetwork: (userRef: string) => void
	userRef: string
	capabilities?: DeepReadonly<Api.SupportNetworkCapabilities>
	memberIsClient: boolean
	clientIsYou: boolean
	leaveSupportNetwork: () => void
	supportsTimesheets: boolean
}

interface State {
	editingCapabilities: boolean
	managerCapability?: boolean
	viewClientInfoCapability?: boolean
	viewCalendarCapability?: boolean	
	manageCalendarCapability?: boolean
	manageClientInfoCapability?: boolean
	viewTimesheetsCapability?: boolean
	manageTimesheetsCapability?: boolean
}

const INITIAL_STATE: State = {
	editingCapabilities: false,
}

export default class SupportNetworkMembership extends React.Component<OwnProps, State> {
	
	public state = INITIAL_STATE

	handleUpdateSupportNetworkCapabilities = () => {
		const request: a.UpdateUserCapabilitiesPayload = {
			userRef: this.props.network.userRef,
			request: { capabilities: {
				manager: this.state.managerCapability !== undefined ? this.state.managerCapability : this.props.network.capabilities.manager,
				manageRoster: this.state.manageCalendarCapability !== undefined ? this.state.manageCalendarCapability : this.props.network.capabilities.manageRoster,
				manageClientDetails: this.state.manageClientInfoCapability !== undefined ? this.state.manageClientInfoCapability : this.props.network.capabilities.manageClientDetails,
				viewRoster: this.state.viewCalendarCapability !== undefined ? this.state.viewCalendarCapability : this.props.network.capabilities.viewRoster,
				viewClientDetails: this.state.viewClientInfoCapability !== undefined ? this.state.viewClientInfoCapability : this.props.network.capabilities.viewClientDetails,
				viewTimesheets: this.state.viewTimesheetsCapability !== undefined ? this.state.viewTimesheetsCapability : this.props.network.capabilities.viewTimesheets,
				manageTimesheets: this.state.manageTimesheetsCapability !== undefined ? this.state.manageTimesheetsCapability : this.props.network.capabilities.manageTimesheets,
			} },
		}
		this.props.handleUpdateSupportNetworkCapabilities(request)
	}

	removeUserFromSupportNetwork = () => {
		this.props.removeUserFromSupportNetwork(this.props.network.userRef)
	}

	toggleEditCapabilities = () => {
		this.setState({ editingCapabilities: !this.state.editingCapabilities })
	}

	checked = (capability: string): boolean => {
		if (capability === 'roster') {
			if ((this.state.managerCapability || this.state.manageCalendarCapability || this.state.viewCalendarCapability) ||
					(this.state.managerCapability === undefined && this.props.network.capabilities.manager === true) ||
					(this.state.manageCalendarCapability === undefined && this.props.network.capabilities.manageRoster === true) ||
					(this.state.viewCalendarCapability === undefined && this.props.network.capabilities.viewRoster === true)) {
				return true
			} else {
				return false
			}
		} else if (capability === 'clientDetails') {
			if ((this.state.managerCapability || this.state.manageClientInfoCapability || this.state.viewClientInfoCapability) ||
					(this.state.managerCapability === undefined && this.props.network.capabilities.manager === true) ||
					(this.state.manageClientInfoCapability === undefined && this.props.network.capabilities.manageClientDetails === true) ||
					(this.state.viewClientInfoCapability === undefined && this.props.network.capabilities.viewClientDetails === true)) {
				return true
			} else {
				return false
			}
		} else if (capability === 'timesheets') {
			if ((this.state.managerCapability || this.state.manageTimesheetsCapability || this.state.viewTimesheetsCapability) ||
					(this.state.managerCapability === undefined && this.props.network.capabilities.manager === true) ||
					(this.state.manageTimesheetsCapability === undefined && this.props.network.capabilities.manageTimesheets === true) ||
					(this.state.viewTimesheetsCapability === undefined && this.props.network.capabilities.viewTimesheets === true)) {
				return true
			} else {
				return false
			}
		} else {
			throw new Error(`Unsupported capability ${capability}`)
		}
	}

	showCapabilityOptions = (capability: string): boolean => {
		if (this.state.managerCapability || (this.state.managerCapability === undefined && this.props.network.capabilities.manager)) {
			return false
		} 
		if (capability === 'roster') {
			if (this.state.manageCalendarCapability || this.state.viewCalendarCapability) {
				return true
			} else if (this.state.manageCalendarCapability === false || this.state.viewCalendarCapability === false) {
				return false
			} else if ((this.state.manageCalendarCapability === undefined && this.state.viewCalendarCapability === undefined) && 
				(this.props.network.capabilities.manageRoster || this.props.network.capabilities.viewRoster)) {
				return true
			} else {
				return false
			}
		} else if (capability === 'clientDetails') {
			if (this.state.manageClientInfoCapability || this.state.viewClientInfoCapability) {
				return true
			} else if (this.state.manageClientInfoCapability === false || this.state.viewClientInfoCapability === false) {
				return false
			} else if ((this.state.manageClientInfoCapability === undefined && this.state.viewClientInfoCapability === undefined) && 
				(this.props.network.capabilities.manageClientDetails || this.props.network.capabilities.viewClientDetails)) {
				return true
			} else {
				return false
			}
		} else if (capability === 'timesheets') {
			if (this.state.manageTimesheetsCapability || this.state.viewTimesheetsCapability) {
				return true
			} else if (this.state.manageTimesheetsCapability === false || this.state.viewTimesheetsCapability === false) {
				return false
			} else if ((this.state.manageTimesheetsCapability === undefined && this.state.viewTimesheetsCapability === undefined) && 
				(this.props.network.capabilities.manageTimesheets || this.props.network.capabilities.viewTimesheets)) {
				return true
			} else {
				return false
			}
		} else {
			throw new Error(`Unsupported capability ${capability}`)
		}
	}

	toggleManagerCapability = () => {
		if (this.state.managerCapability === true || (this.state.managerCapability === undefined && this.props.network.capabilities.manager === true)) {
			this.setState({ managerCapability: false })
		} else {
			this.setState({
				managerCapability: true,
				manageCalendarCapability: true,
				manageClientInfoCapability: true,
				viewCalendarCapability: true,
				viewClientInfoCapability: true,
				viewTimesheetsCapability: true,
				manageTimesheetsCapability: true,
			})
		}
	}

	toggleCalendarCapability = () => {
		if (this.state.manageCalendarCapability === undefined && this.state.viewCalendarCapability === undefined) {
			if (this.props.network.capabilities.manageRoster || this.props.network.capabilities.viewRoster) {
				this.setState({ manageCalendarCapability: false, viewCalendarCapability: false })
			} else {
				this.setState({ manageCalendarCapability: true })
			}
		} else if (this.state.manageCalendarCapability || this.state.viewCalendarCapability) {
			this.setState({ manageCalendarCapability: false, viewCalendarCapability: false })
		} else if (!this.state.manageCalendarCapability && !this.state.viewCalendarCapability) {
			this.setState({ manageCalendarCapability: true })
		}
	}

	toggleClientDetailsCapability = () => {
		if (this.state.manageClientInfoCapability === undefined && this.state.viewClientInfoCapability === undefined) {
			if (this.props.network.capabilities.manageClientDetails || this.props.network.capabilities.viewClientDetails) {
				this.setState({ manageClientInfoCapability: false, viewClientInfoCapability: false })
			} else {
				this.setState({ manageClientInfoCapability: true })
			}
		} else if (this.state.manageClientInfoCapability || this.state.viewClientInfoCapability) {
			this.setState({ manageClientInfoCapability: false, viewClientInfoCapability: false })
		} else if (!this.state.manageClientInfoCapability && !this.state.viewClientInfoCapability) {
			this.setState({ manageClientInfoCapability: true })
		}
	}

	toggleTimesheetsCapability = () => {
		if (this.state.manageTimesheetsCapability === undefined && this.state.viewTimesheetsCapability === undefined) {
			if (this.props.network.capabilities.manageTimesheets || this.props.network.capabilities.viewTimesheets) {
				this.setState({ manageTimesheetsCapability: false, viewTimesheetsCapability: false })
			} else {
				this.setState({ manageTimesheetsCapability: true })
			}
		} else if (this.state.manageTimesheetsCapability || this.state.viewClientInfoCapability) {
			this.setState({ manageTimesheetsCapability: false, viewTimesheetsCapability: false })
		} else if (!this.state.manageTimesheetsCapability && !this.state.viewTimesheetsCapability) {
			this.setState({ manageTimesheetsCapability: true })
		}
	}

	updateCalendarCapability = (evt: React.ChangeEvent<HTMLSelectElement>) => {
		const manage = evt.target.value === 'manage'
		this.setState({ manageCalendarCapability: manage, viewCalendarCapability: !manage })
	}

	updateClientDetialsCapability = (evt: React.ChangeEvent<HTMLSelectElement>) => {
		const manage = evt.target.value === 'manage'
		this.setState({ manageClientInfoCapability: manage, viewClientInfoCapability: !manage })
	}

	updateTimesheetsCapability = (evt: React.ChangeEvent<HTMLSelectElement>) => {
		const manage = evt.target.value === 'manage'
		this.setState({ manageTimesheetsCapability: manage, viewTimesheetsCapability: !manage })
	}

	public render() {
		const { network, memberIsClient, userRef } = this.props

		return (
			<tr>
				<td>
					<div className="person">
						<figure className="avatar-image -medium">
							<div className="image">
								<img src={network.image || avatarPlaceholder()} alt={network.name} />
							</div>
						</figure>
						<span className="name">{network.name} 
							{ (memberIsClient && network.userRef !== userRef) ? (
								<span className="role"><span className="_sr-only">(</span>client<span className="_sr-only">)</span></span>
							) : (
								network.userRef === userRef &&
								<span className="role"><span className="_sr-only">(</span>you<span className="_sr-only">)</span></span>
							)}
						</span>
					</div>
				</td>
				{
					this.state.editingCapabilities ? (
						<td>
							<div className="option-inputs -stacked">
								<div className="option -checkbox">
									<label className="label">
										<input 
											type="checkbox" 
											name="manager" 
											className="checkbox"
											checked={this.state.managerCapability !== undefined ? this.state.managerCapability : network.capabilities.manager}
											onChange={this.toggleManagerCapability}
										/>
										<span className="substitute" />
										Full Access
									</label>
								</div>
								<div className="option -checkbox">
									<label className="label">
										<input 
											type="checkbox" 
											name="roster" 
											className="checkbox"
											checked={this.checked('roster')}
											onChange={this.toggleCalendarCapability}
											disabled={this.state.managerCapability !== undefined ? this.state.managerCapability : network.capabilities.manager}
										/>
										<span className="substitute" />
										Calendar
									</label>
									{
										this.showCapabilityOptions('roster') &&
										<div className="form-input -select">
											<select 
												className="select -micro"
												onChange={this.updateCalendarCapability}
												value={this.state.manageCalendarCapability ? 'manage' : (this.state.viewCalendarCapability ? 'view' : (
													network.capabilities.manageRoster ? 'manage' : (network.capabilities.viewRoster ? 'view' : undefined)))}
											>
												<option value="manage">Manage</option>
												<option value="view">View</option>
											</select>
										</div>
									}
								</div>
								<div className="option -checkbox">
									<label className="label">
										<input 
											type="checkbox" 
											name="clientDetails" 
											className="checkbox"
											checked={this.checked('clientDetails')}
											onChange={this.toggleClientDetailsCapability}
											disabled={this.state.managerCapability !== undefined ? this.state.managerCapability : network.capabilities.manager}
										/>
										<span className="substitute" />
										Client information
									</label>
									{
										this.showCapabilityOptions('clientDetails') &&
										<div className="form-input -select">
											<select 
												className="select -micro" 
												onChange={this.updateClientDetialsCapability}
												value={this.state.manageClientInfoCapability ? 'manage' : (this.state.viewClientInfoCapability ? 'view' : (
													network.capabilities.manageClientDetails ? 'manage' : (network.capabilities.viewClientDetails ? 'view' : undefined)))}
											>
												<option value="manage">Manage</option>
												<option value="view">View</option>
											</select>
										</div>
									}
								</div>
								{this.props.supportsTimesheets && (
									<div className="option -checkbox">
										<label className="label">
											<input 
												type="checkbox" 
												name="timesheets" 
												className="checkbox"
												checked={this.checked('timesheets')}
												onChange={this.toggleTimesheetsCapability}
												disabled={this.state.managerCapability !== undefined ? this.state.managerCapability : network.capabilities.manager}
											/>
											<span className="substitute" />
											Timesheets
										</label>
										{
											this.showCapabilityOptions('timesheets') &&
											<div className="form-input -select">
												<select 
													className="select -micro" 
													onChange={this.updateTimesheetsCapability}
													value={this.state.manageTimesheetsCapability ? 'manage' : (this.state.viewTimesheetsCapability ? 'view' : (
														network.capabilities.manageTimesheets ? 'manage' : (network.capabilities.viewTimesheets ? 'view' : undefined)))}
												>
													<option value="manage">Manage</option>
													<option value="view">View</option>
												</select>
											</div>
										}
									</div>
								)}
							</div>
						</td>
					) : (
						<td>
							{network.capabilities.manager && 'Manager'}
							{!network.capabilities.manager && network.capabilities.manageRoster && <>Roster (manage)<br/></>}
							{!network.capabilities.manager && !network.capabilities.manageRoster && network.capabilities.viewRoster && <>Roster (view)<br/></>}
							{!network.capabilities.manager && network.capabilities.manageClientDetails && <>Client information (manage)<br/></>}
							{!network.capabilities.manager && !network.capabilities.manageClientDetails && network.capabilities.viewClientDetails && <>Client information (view)<br/></>}
							{!network.capabilities.manager && network.capabilities.manageTimesheets && <>Timesheets (manage)<br/></>}
							{!network.capabilities.manager && !network.capabilities.manageTimesheets && network.capabilities.viewTimesheets && <>Timesheets (view)<br/></>}
						</td>
					)
				}
				{
					((network.userRef !== this.props.userRef) && (this.props.capabilities && this.props.capabilities.manager)) ? (
						<>
							<td colSpan={this.state.editingCapabilities ? 2 : 0} className="buttons">
								<div className="button-link -action -micro" onClick={this.state.editingCapabilities ? this.handleUpdateSupportNetworkCapabilities : this.toggleEditCapabilities}>{this.state.editingCapabilities ? 'Save' : 'Edit'}</div>
								{this.state.editingCapabilities && 
									<div className="button-link -secondary -micro" onClick={this.toggleEditCapabilities}>Cancel</div>
								}
							</td>
							{!this.state.editingCapabilities && (
								<td><div className="remove" onClick={this.removeUserFromSupportNetwork}>Remove</div></td>
							)}
						</>
					) : ((network.userRef === this.props.userRef) && !this.props.clientIsYou ? (
						<>
							<td colSpan={2}>
								<div className="button-link -action -micro" onClick={this.props.leaveSupportNetwork}>Leave</div>
							</td>
							<td/>
						</>
					) : (<><td/><td/></>)
					)
				}
			</tr>
		)
	}
}