import * as React from 'react'
import { OwnProps, Props, Actions } from '../containers/ClientPersonalDetailsViewOnly'

export default class ClientPersonalDetailsViewOnly extends React.Component<OwnProps & Props & Actions> {

	public render() {
		
		const { client } = this.props
		if (!client) {
			return null
		}

		return (
			<div className="body">
				<div className="personal-info">
					<div className="header">
						{client.image && (
							<figure className="avatar-image -upload -bordered">
								<div className="image">
									<img src={client.image} alt={client.fullName} />
								</div>
							</figure>
						)}
						<h1 className="headline-text">{client.givenName} {client.familyName}</h1>
					</div>

					<div className="details">
						{client.knownAs && (
							<div className="detail">
								<h3 className="title">Known as</h3>
								<p className="content">{client.knownAs}</p>
							</div>
						)}
						{client.dateOfBirth && (
							<div className="detail">
								<h3 className="title">Date of birth</h3>
								<p className="content">{client.dateOfBirth}</p>
							</div>
						)}
						{client.genderRef && (
							<div className="detail">
								<h3 className="title">Gender</h3>
								<p className="content">{client.genderRef}</p>
							</div>
						)}
						{client.email && (
							<div className="detail">
								<h3 className="title">Email address</h3>
								<p className="content">{client.email}</p>
							</div>
						)}
						{client.deliveryAddress && (
							<div className="detail">
								<h3 className="title">Address</h3>
								<p className="content">{client.deliveryAddress}</p>
							</div>
						)}
						{client.postalAddress && (
							<div className="detail">
								<h3 className="title">Postal address</h3>
								<p className="content">{client.postalAddress}</p>
							</div>
						)}
					</div>
				</div>
			</div>
		)
	}
}
