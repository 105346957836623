import Component from '../components/TransactionHistory'
import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'
import { Api } from 'typescript-fetch-api'
import * as f from '../functions'
import { RootStoreState } from 'modules/root'

export interface OwnProps {
	individualisedFunding: DeepReadonly<Api.IndividualisedFunding>
}

/**
 * Interface for properties that the container passes to the component.
 */
export interface Props {
	transactions: DeepReadonlyArray<Api.IndividualisedFundingTransaction>
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	return {
		transactions: f.orderTransactions(ownProps.individualisedFunding, state.calendar.refs.transactions),
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
