import Component from '../components/SupportNetworkInviteModal'
import { Api } from 'typescript-fetch-api'
import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'

/* Import module actions */
import * as actions from '../actions'
import { currentClientSelector } from 'modules/account/selectors'

/** Interface for properties that are passed to the container/ */
export interface OwnProps {
	onClose: () => void
}

/** Interface for properties that the container passes to the component. */
export interface Props {
	supportsTimesheets: boolean
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
	onSendInvitation: (request: Api.ClientSupportNetworkInvitationRequest) => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	const client = currentClientSelector(state)
	return {
		supportsTimesheets: client ? client.timesheets : false,
	}
}

/** Populate the Actions with the callbacks for the component. */
export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: OwnProps): Actions => ({
	onSendInvitation: (request) => {
		dispatch(actions.sendSupportNetworkInvitation(request))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
