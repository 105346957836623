import Component from '../components/JobDetailModalWrapper'
import { Api } from 'typescript-fetch-api'
import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'

import * as t from '../types'
import * as bookingTypes from 'modules/booking/types'
import * as bookingActions from 'modules/booking/actions'
import { jobDetailView } from '../functions'
import { push } from 'connected-react-router'
import * as actions from '../actions'
import * as supportWorkerActions from 'modules/support-workers/actions'
import * as dashboardActions from 'modules/dashboard/actions'
import { LocalDate } from '@js-joda/core'
import { dontScrollToTopState } from 'modules/routing'
import { featuresSelector } from 'modules/common/selectors'

/** Interface for properties that are passed to the container/ */
export interface OwnProps {
	jobRef: string
	calendar: t.CalendarView
	date: string
	capabilities: Api.SupportNetworkCapabilities
}

/** Interface for properties that the container passes to the component. */
export interface Props {
	job?: t.JobDetailView
	calDate: LocalDate
	jobTypes?: DeepReadonlyArray<Api.JobType>
	findSupportWorkers: boolean
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
	onClose: () => void
	onRosterChange: (request: Api.AppointmentChangeRequest) => void
	onViewDetail: (supportWorkerRef: DeepReadonly<string>) => void
	addAppointmentFeedback: (appointmentRef: string, feedback: string) => void
	onEditBooking: (editBooking: bookingTypes.EditBooking) => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	const features = featuresSelector(state)

	return {
		job: jobDetailView(ownProps.jobRef, LocalDate.parse(ownProps.date), state),
		calDate: ownProps.calendar.originalDate,
		findSupportWorkers: features.findSupportWorkers,
	}
}

/** Populate the Actions with the callbacks for the component. */
export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: OwnProps): Actions => ({
	onClose: () => {
		dispatch(push(`/calendar/date/${ownProps.calendar.originalDate}`, dontScrollToTopState()))
	},
	onRosterChange: (request) => {
		const payload: actions.RosterChangePayload = {
			jobRef: ownProps.jobRef,
			payload: request,
		}
		dispatch(actions.rosterChange(payload))
	},
	onViewDetail: (supportWorkerRef) => {
		dispatch(supportWorkerActions.viewDetail(supportWorkerRef))
	},
	addAppointmentFeedback: (appointmentRef: string, feedback: string) => {
		dispatch(dashboardActions.addAppointmentFeedback({ appointment: appointmentRef, request: { feedback: feedback } }))
	},
	onEditBooking: (editBooking: bookingTypes.EditBooking) => {
		dispatch(bookingActions.editBooking(editBooking))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
