import * as React from 'react'
import logo from 'modules/frontend/img/health-passport/hdc-logo.svg'

interface OwnProps {
	section: string
	printMode?: boolean
}

export default class TitleLogoRow extends React.Component<OwnProps> {

	public sectionToTitle = () => {
		switch (this.props.section) {
			case 'personal-details':
				return 'Personal Details'
			case 'a':
				return 'Section A: Things you MUST know about me'
			case 'b':
				return 'Section B: Things that are important to me'
			case 'c':
				return 'Section C: Other useful information'
		}
	}

	public render() {
		return (
			<>
				{
					this.props.printMode ?
						<>
							<div className="logo-row">
								<img src={logo} />
							</div> 
							<h2 className="section-header">{this.sectionToTitle()}</h2>
						</> :
						<div className="logo-row">
							<h2 className="section-header -page-break">{this.sectionToTitle()}</h2>
							<img src={logo} />
						</div>
				}
			</>
		)
	}
}
