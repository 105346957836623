import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { Api } from 'typescript-fetch-api'
import * as a from './actions'
import * as t from './types'
import { loggedOut, login } from 'modules/auth/actions'

export type StoreState = DeepReadonly<MutableStoreState>

export interface MutableStoreState {
	requestingCareFor: t.RequestCareForEnum
	clientDetails: Api.RegisterClientRequest
	createClientRequestProcessing: boolean
}

const INITIAL_STATE: StoreState = {
	requestingCareFor: t.RequestCareForEnum.Self,
	clientDetails: t.INITIAL_CLIENT_DETAILS,
	createClientRequestProcessing: false,
}

export const reducer = reducerWithInitialState(INITIAL_STATE)

reducer.case(a.updateClientDetails, (state, payload): StoreState => {
	return {
		...state,
		clientDetails: payload,
	}
})

reducer.case(a.startRegisterClient, (state, payload): StoreState => ({
	...state,
	requestingCareFor: payload,
	clientDetails: {
		...t.INITIAL_CLIENT_DETAILS,
		role: payload === t.RequestCareForEnum.Self ? Api.RegisterClientRequest.RoleEnum.Client : Api.RegisterClientRequest.RoleEnum.Supporter,
	},
}))

reducer.case(a.registerNewClientAsync.started, (state, payload): StoreState => {
	return {
		...state,
		createClientRequestProcessing: true,
	}
})

reducer.case(a.registerNewClientAsync.done, (state, payload): StoreState => {
	return {
		...state,
		createClientRequestProcessing: false,
	}
})

reducer.case(a.registerNewClientAsync.failed, (state, payload): StoreState => {
	return {
		...state,
		createClientRequestProcessing: false,
	}
})

reducer.cases([login.started, loggedOut], (): StoreState => INITIAL_STATE)
