import * as React from 'react'
import { Snapshot, forComponentProps, wrapComponent, Input } from 'changeling'

export interface OwnProps extends Snapshot<string | undefined> {
	subOrder?: string
	title: string
	subText?: string
	placeholder?: string
	imageLocation?: string
	secondaryQuestion?: boolean // TRUE if this question has been displayed due to the previous question
	editingMode?: boolean
	hideOnEmpty?: boolean
}

class TextBox extends React.Component<OwnProps> {
	private changeling = forComponentProps(this)

	public render() {
		const { editingMode, value, hideOnEmpty } = this.props

		return (
			<>
				{ (editingMode || (!editingMode && value) || (!editingMode && !value && !hideOnEmpty)) && 
				<div className={'form-item -textbox' + (this.props.secondaryQuestion ? ' -secondary' : '') + (editingMode ? '' : ' -valuemode')}>
					<p className="title">
						{ this.props.subOrder && 
						<span className="title-order">{this.props.subOrder}</span>
						}
						{this.props.title}
					</p>
					{
						this.props.subText && editingMode &&
						<p className={'sub-text' + (this.props.imageLocation ? ' -three-quarters' : '')}>{this.props.subText}</p>
					}
					{
						this.props.imageLocation ? (
							<div className="input-wrapper">
								{
									editingMode ? (
										<div className="form-input -textarea">
											<Input.TextArea className="field" placeholder={this.props.placeholder && this.props.placeholder} controller={this.changeling} prop="this"/>
										</div>
									) : (
										<div className="value-wrapper -with-image">
											<p className="sub-text">{this.props.value}</p>
										</div>
									)
								}
								<div className="image-wrapper">
									<img src={this.props.imageLocation} />
								</div>
							</div>
						) : (
							editingMode ? (
								<div className="form-input -textarea">
									<Input.TextArea className="field" placeholder={this.props.placeholder && this.props.placeholder} controller={this.changeling} prop="this"/>
								</div>
							) : (
								<div className={'value-wrapper' + (value && value !== '' ? '' : ' -empty')}>
									<p className="sub-text">{value}</p>
								</div>
							)
						)
					}
				</div>
				}
			</>
		)
	}
}

export default wrapComponent(TextBox)
