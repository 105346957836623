import { RootStoreState } from 'modules/root'
import * as _ from 'lodash'
import { Api } from 'typescript-fetch-api'

/** Select the current user or undefined. */
export const currentUserSelector = (state: RootStoreState) => state.account.user

export const clientSupportWorkerRelationship = (state: RootStoreState, clientSupportWorkerRelationshipRef: string) => state.supportWorkers.refs.relationships[clientSupportWorkerRelationshipRef]

export const supportWorkerForRef = (state: RootStoreState, ref: string): DeepReadonly<Api.SupportWorker> | undefined => state.supportWorkers.refs.supportWorkers[ref]

export const appointmentByRef = (state: RootStoreState, appointmentRef: string) => state.supportWorkers.refs.appointments[appointmentRef]

export const skillByRef = (state: RootStoreState, skillRef: string) => state.common.settings && state.common.settings.skills.find((skill) => {
	return skill.ref === skillRef 
}) 

export const languageByRef = (state: RootStoreState, languageRef: string) => state.common.settings && state.common.settings.languages.find((language) => {
	return language.ref === languageRef 
})

export const supportWorkerForClientSupportWorkerRelationshipRef = (state: RootStoreState, clientSupportWorkerRelationshipRef: string) => {
	const relationship = clientSupportWorkerRelationship(state, clientSupportWorkerRelationshipRef)
	if (!relationship) {
		return undefined
	}
	
	return supportWorkerForRef(state, relationship.supportWorkerRef)
}

export const clientSupportWorkerRelationshipForSupportWorkerRef = (state: RootStoreState, supportWorkerRef: string) => {
	const clientSupportWorkerRelationships = _.values(state.supportWorkers.refs.relationships)
	for (const clientSupportWorkerRelationship of clientSupportWorkerRelationships) {
		if (clientSupportWorkerRelationship.supportWorkerRef === supportWorkerRef) {
			return clientSupportWorkerRelationship
		}
	}
	return undefined
}

export const currentSearchRequest = (state: RootStoreState) => state.supportWorkers.searchRequest

export const currentSearchFormState = (state: RootStoreState) => state.supportWorkers.searchForm

export const preferredSupportWorkerRefs = (state: RootStoreState, filterBlocked: boolean): DeepReadonly<string>[] => {

	const preferenceRefs = state.booking.supportWorkerPreference

	if (preferenceRefs) {
		return _.keys(preferenceRefs).filter((ref) => {
			return filterBlocked ? (preferenceRefs[ref] !== undefined && preferenceRefs[ref] < 5) : preferenceRefs[ref] !== undefined
		}).sort((ref1, ref2) => {
			if (preferenceRefs[ref1] > preferenceRefs[ref2]) {
				return 1
			} else if (preferenceRefs[ref1] < preferenceRefs[ref2]) {
				return -1
			} else {
				return 0
			}
		})
	} else {
		return []
	}

}

export const clientSupportWorkerRelationshipRefsToSupportWorkerRefs = (state: RootStoreState, clientSupportWorkerRelationshipRefs?: DeepReadonlyArray<string>) => {
	if (!clientSupportWorkerRelationshipRefs) {
		return undefined
	}
	
	const result: string[] = []
	for (const clientSupportWorkerRelationshipRef of clientSupportWorkerRelationshipRefs) {
		const relationship = state.supportWorkers.refs.relationships[clientSupportWorkerRelationshipRef]
		if (relationship) {
			result.push(relationship.supportWorkerRef)
		}
	}
	return result
}
