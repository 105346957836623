import { SagaIterator } from 'redux-saga'
import { takeEvery, put, call, select } from 'redux-saga/effects'
import * as a from './actions'
import { push } from 'connected-react-router'
import { callApiWithActions, handleApiFailedAction, handleApiSuccessAction } from 'modules/api/functions'
import api from 'modules/api'
import { currentClientRefSelector } from 'modules/account/selectors'
import { editTimesheetJobLocation } from './functions'

function* handleEditBooking(action: a.EditBookingAction) {
	if (action.payload.job.timesheetRef) {
		yield put(push(editTimesheetJobLocation(action.payload.job, action.payload.job.timesheetRef)))
	} else {
		yield put(push(editTimesheetJobLocation(action.payload.job)))
	}
}

function* handleCompleteTimesheet(action: a.CompleteTimesheetActionType): SagaIterator {
	const timesheetRef = action.payload

	const currentClientRef = (yield select(currentClientRefSelector)) as string | undefined
	if (!currentClientRef) {
		return
	}

	yield call(callApiWithActions, timesheetRef, a.completingTimesheetAction, (options: RequestInit) => {
		return api().clientApi.completeTimesheet(currentClientRef, timesheetRef, options)
	})
}

function* handleRecallTimesheet(action: a.RecallTimesheetActionType): SagaIterator {
	const timesheetRef = action.payload

	const currentClientRef = (yield select(currentClientRefSelector)) as string | undefined
	if (!currentClientRef) {
		return
	}

	yield call(callApiWithActions, timesheetRef, a.recallingTimesheetAction, (options: RequestInit) => {
		return api().clientApi.recallTimesheet(currentClientRef, timesheetRef, options)
	})
}

function* handleStartTimesheetBooking(action: a.StartTimesheetBookingFromDateType): SagaIterator {
	if (action.payload.timesheetRef) {
		yield put(push('/timesheet/ref/' + action.payload.timesheetRef + '/new'))
	} else {
		yield put(push('timesheet/new'))
	}
}

export default function* (): SagaIterator {
	yield takeEvery(a.editBooking, handleEditBooking)
	yield takeEvery(a.completeTimesheetAction, handleCompleteTimesheet)
	yield takeEvery(a.completingTimesheetAction.done, handleApiSuccessAction.bind(null, 'Your timesheet has been submitted.'))
	yield takeEvery(a.recallTimesheetAction, handleRecallTimesheet)
	yield takeEvery(a.recallingTimesheetAction.done, handleApiSuccessAction.bind(null, 'Your timesheet has been recalled.'))
	yield takeEvery(a.recallingTimesheetAction.failed, handleApiFailedAction.bind(null, 'Oops! We were unable to recall this timesheet.'))
	yield takeEvery(a.startTimesheetBookingFromDate, handleStartTimesheetBooking)
}
