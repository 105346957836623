/**
 * Component template.
 * 
 * Note that this file has a `.tsx` suffix, as it contains React elements.
 */

import * as React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import MyNavLink from 'modules/routing/components/MyNavLink'

interface OwnProps {
	// myValue: string
}

export default class Example extends React.Component<OwnProps & RouteComponentProps<undefined>> {

	public render() {
		// const { exampleProperty } = this.props

		return (
			<header className="site-subheader">
				<div className="width-limit -site">
					<div className="supportworkers-header">
					
						<h1 className="headline-text">Find my support worker</h1>
				
						<nav className="supportworkers-nav">
							<ul className="navitems">
								<MyNavLink to={`${this.props.match.url}`} exact={true} className="navitem -all" activeClassName="-active" linkClassName="link">
									<span className="label">All<span className="_hide-mobile"> workers</span></span>
								</MyNavLink>
								<MyNavLink to={`${this.props.match.url}/favourites`} className="navitem -faves" activeClassName="-active" linkClassName="link">
									<span className="label">Favourites</span>
								</MyNavLink>
								<MyNavLink to={`${this.props.match.url}/hidden`} className="navitem -hidden" activeClassName="-active" linkClassName="link">
									<span className="label">Hidden</span>
								</MyNavLink>
							</ul>
						</nav>
					</div>
				</div>
			</header>
		)
	}
}
