export interface PowerOfAttorneyValue {
	givenName?: string
	familyName?: string
	relationship?: string
	address?: string
	homePhone?: string
	daytimePhone?: string
	mobilePhone?: string
}

export interface ImportantPerson {
	givenName?: string
	familyName?: string
	homePhone?: string
	relationship?: string
}

export type CheckBoxOptionValue = SelectedOption[]

export interface SelectedOption {
	optionValue: string
	additionalInfo?: string
}

export interface CheckBoxOption {
	optionValue: string
	optionLabel: string
	withAdditionalInfo?: boolean
	placeholder?: string
}

export type MultiTextBoxValue = PreferenceOption[]

export interface PreferenceOption {
	circumstance?: string
	preference?: string
}

export interface FormValue {
	/// step one
	powerOfAttorney?: PowerOfAttorneyValue
	importantPeople?: ImportantPerson[]
	hasWill?: boolean
	willHolder?: string
	/// step two
	importantThings?: string
	meaningfulThings?: string
	thingsToRemember?: string
	dyingImportantItems?: CheckBoxOptionValue
	dyingImportantItemsOther?: string
	dyingPlaceImportant?: boolean
	dyingPlaceImportantOptions?: CheckBoxOptionValue
	dyingPlaceImportantOther?: string
	burialPreferred?: boolean
	funeralDetails?: string
	organDonation?: boolean
	organDonationComments?: string
	/// step three
	carePreferences?: MultiTextBoxValue
}

export const DEFAULT_FORM: FormValue = {
	importantPeople: [{}, {}],
	hasWill: true,
	dyingImportantItems: [],
	dyingPlaceImportant: true,
	dyingPlaceImportantOptions: [],
	burialPreferred: true,
	organDonation: true,
	carePreferences: [{}, {}],

	// dummy default form
	
	// powerOfAttorney: {
	// 	givenName: 'katto',
	// 	familyName: 'anderson',
	// 	relationship: 'lawyer',
	// 	address: '113 symonds st',
	// 	homePhone: '0277791290',
	// 	daytimePhone: '5483500',
	// 	mobilePhone: '027 779 1290'
	// },
	// importantPeople: [{
	// 	givenName: 'test data :)',
	// 	familyName: 'test data :)',
	// 	homePhone: 'test data :)',
	// 	relationship: 'test data :)',
	// }, {
	// 	givenName: 'more test data :)',
	// 	familyName: 'more test data :)',
	// 	homePhone: 'more test data :)',
	// 	relationship: 'more test data :)',
	// }],
	// hasWill: true,
	// willHolder: 'Carol Anderson',
	// // /// step two
	// importantThings: 'Duis euismod, nulla sit amet euismod porta, libero neque condimentum tellus, at sodales ante neque ultricies ligula. Nulla in arcu at nisl cursus iaculis a a tellus. Proin aliquet nibh enim, eu iaculis enim pharetra non. Quisque ultrices congue sem. Fusce quis est faucibus, sodales enim vitae, efficitur est. Praesent sem metus, semper id sapien eget, accumsan dapibus tellus. Ut lectus elit, euismod a lectus id, faucibus dignissim mi. Sed sit amet ullamcorper eros, eget sodales turpis. Quisque lacus dui, posuere sit amet nibh in, efficitur malesuada ante. Phasellus malesuada iaculis sapien, vitae condimentum lacus luctus eget. Pellentesque vel leo imperdiet, consequat libero eu, malesuada mi.',
	// meaningfulThings: 'Proin sed iaculis tellus. Suspendisse sit amet magna tempor, imperdiet risus non, vulputate nunc. Fusce nibh purus, laoreet et bibendum sollicitudin, tristique et turpis. Aenean hendrerit, urna in consectetur tempor, mauris nulla venenatis tortor, nec ornare est ex eu ex. Integer tempor mauris nec arcu iaculis vestibulum. Proin ultricies dolor sit amet leo egestas dictum vitae in mauris. Quisque nisl dolor, suscipit in cursus sit amet, aliquet et augue. Duis faucibus tincidunt ornare. Quisque porttitor tincidunt posuere. Aliquam sagittis ultrices dolor.',
	// thingsToRemember: 'Curabitur egestas nisl sit amet neque interdum, a molestie lacus lobortis. Aenean ligula ipsum, ultricies nec dapibus in, volutpat ac purus. Praesent sollicitudin ligula at fermentum accumsan. Cras posuere faucibus nunc, ut accumsan purus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ac venenatis dolor. Vivamus orci augue, venenatis ut augue et, iaculis tempor nisi. Ut sed elit sit amet justo interdum aliquam. Integer dictum, ex at efficitur posuere, urna orci volutpat dui, vel cursus neque tellus at sapien. Aenean leo lectus, euismod vestibulum feugiat a',
	// dyingImportantItems: [{
	// 	optionValue: 'comfort',
	// } , {
	// 	optionValue: 'friends-family'
	// } , {
	// 	optionValue: 'spiritual-needs'
	// }],
	// dyingImportantItemsOther: 'Mauris ut mattis lacus. Quisque et dui et libero iaculis accumsan. Donec dignissim risus eget viverra luctus. Mauris aliquam massa quis finibus placerat. Cras nec facilisis purus. Phasellus finibus accumsan vulputate. Quisque turpis ipsum, dignissim eget felis non, feugiat congue lacus.',
	// dyingPlaceImportant: true,
	// dyingPlaceImportantOptions: [{
	// 	optionValue: 'home',
	// 	additionalInfo: 'my house'
	// } , {
	// 	optionValue: 'hospital'
	// }],
	// dyingPlaceImportantOther: 'Mauris ut mattis lacus. Quisque et dui et libero iaculis accumsan. Donec dignissim risus eget viverra luctus. Mauris aliquam massa quis finibus placerat. Cras nec facilisis purus. Phasellus finibus accumsan vulputate. Quisque turpis ipsum, dignissim eget felis non, feugiat congue lacus.',
	// burialPreferred: true,
	// funeralDetails: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In arcu ligula, porttitor sit amet leo id, egestas ullamcorper magna. Curabitur nulla enim, imperdiet sit amet velit quis, dictum auctor purus. Nam lorem nulla, cursus vitae ipsum id, rutrum elementum massa. Sed laoreet sem eu neque interdum, eu placerat dolor ultricies. Pellentesque pharetra maximus sem, sed cursus elit faucibus vitae. Sed hendrerit mi nisl, ac pellentesque lorem luctus non. Nam vel dolor nec sem eleifend dapibus vitae sit amet dui. Donec eget nunc ipsum. Cras porta magna enim, nec porta sapien ultrices sed. Nullam vel molestie mauris. Suspendisse aliquet, ex vitae tincidunt faucibus, mauris elit mollis lacus, eget iaculis elit nisl vitae nisi.',
	// organDonation: true,
	// organDonationComments: 'Duis euismod, nulla sit amet euismod porta, libero neque condimentum tellus, at sodales ante neque ultricies ligula. Nulla in arcu at nisl cursus iaculis a a tellus. Proin aliquet nibh enim, eu iaculis enim pharetra non. Quisque ultrices congue sem. Fusce quis est faucibus, sodales enim vitae, efficitur est. Praesent sem metus, semper id sapien eget, accumsan dapibus tellus. Ut lectus elit, euismod a lectus id, faucibus dignissim mi. Sed sit amet ullamcorper eros, eget sodales turpis. Quisque lacus dui, posuere sit amet nibh in, efficitur malesuada ante. Phasellus malesuada iaculis sapien, vitae condimentum lacus luctus eget. Pellentesque vel leo imperdiet, consequat libero eu, malesuada mi.',
	// // /// step three
	// carePreferences: [{
	// 	circumstance: 'Suspendisse id tempor risus.',
	// 	preference: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In arcu ligula, porttitor sit amet leo id, egestas ullamcorper magna. Curabitur nulla enim, imperdiet sit amet velit quis, dictum auctor purus. Nam lorem nulla, cursus vitae ipsum id, rutrum elementum massa. Sed laoreet sem eu neque interdum, eu placerat dolor ultricies. Pellentesque pharetra maximus sem, sed cursus elit faucibus vitae. Sed hendrerit mi nisl, ac pellentesque lorem luctus non. Nam vel dolor nec sem eleifend dapibus vitae sit amet dui.'
	// } , {
	// 	circumstance: 'Praesent sem metus, semper id sapien eget',
	// 	preference: 'Proin ultricies dolor sit amet leo egestas dictum vitae in mauris. Quisque nisl dolor, suscipit in cursus sit amet, aliquet et augue. Duis faucibus tincidunt ornare. Quisque porttitor tincidunt posuere. Aliquam sagittis ultrices dolor.',
	// }, {
	// 	circumstance: 'Cras ac venenatis dolor.',
	// 	preference: 'Sed sit amet ullamcorper eros, eget sodales!urpis. Quisque lacus dui, posuere sit amet nibh in, efficitur malesuada ante. Phasellus malesuada iaculis sapien, vitae condimentum lacus luctus eget. Pellentesque vel leo imperdiet, consequat libero eu, malesuada mi.'
	// }],
}

export const DOCUMENT_TYPE = 'advance-care-plan'
export const DEFAULT_NAME = 'Advanced Care Plan'
