import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { Props } from '../containers/DashboardScreen'

import Header from 'modules/common/containers/Header'
import Footer from 'modules/common/components/Footer'
import Dashboard from '../containers/Dashboard'
import Debug from 'modules/common/components/Debug'
import { Helmet } from 'react-helmet'

export default class DashboardScreen extends React.Component<Props & RouteComponentProps<undefined>> {

	public render() {
		return (
			<div className="site-content dashboard-screen">
				<Header section="dashboard" />
				<Helmet>
					<title>Dashboard — My Homecare</title>
				</Helmet>
				<Dashboard />
				<Footer />
				<Debug params={this.props.location.search}/>
			</div>
		)
	}
}
