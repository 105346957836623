import * as React from 'react'
import { forComponentProps, wrapComponent, Input, Snapshot } from 'changeling'
import { ContactValue } from 'modules/health-passport/types'

export interface OwnProps extends Snapshot<ContactValue | undefined> {
	subOrder?: string
	imageLocation?: string
	editingMode?: boolean
}

class PhoneNumbers extends React.Component<OwnProps> {
	private changeling = forComponentProps(this)

	public render() {
		return (
			<div className={'form-item -phone-numbers' + (this.props.editingMode ? ' -no-gutters' : ' -valuemode')}>
				{
					!this.props.editingMode &&
					<div className="input-wrapper">
						<div className="content-wrapper">
							<div className="value-wrapper">
								<p className="sub-text"><span className="title">Phone: </span>{this.props.value && this.props.value.phone ? this.props.value.phone : ''}</p>
								<p className="sub-text"><span className="title">Mobile: </span>{this.props.value && this.props.value.mobile ? this.props.value.mobile : ''}</p>
								<p className="sub-text"><span className="title">Fax: </span>{this.props.value && this.props.value.fax && this.props.value.fax}</p>
							</div>
						</div>
						<div className="image-wrapper">
							{
								this.props.imageLocation &&
								<img src={this.props.imageLocation} />
							}
						</div>
					</div>
				}
				{
					this.props.editingMode &&
					<div className="form-wrapper">
						<div className="fields">
							<div className="content-wrapper">
								<p className="title">Phone</p>
								<div className="form-input -text">
									<Input.String type="text" className="field" controller={this.changeling} prop="phone" />
								</div>
							</div>
							<div className="content-wrapper">
								<p className="title">Mobile phone</p>
								<div className="form-input -text">
									<Input.String type="text" className="field" controller={this.changeling} prop="mobile" />
								</div>
							</div>
							<div className="content-wrapper">
								<p className="title">Fax</p>
								<div className="form-input -text">
									<Input.String type="text" className="field" controller={this.changeling} prop="fax" />
								</div>
							</div>
						</div>
						<div className="image-wrapper">
							{
								this.props.imageLocation &&
								<img src={this.props.imageLocation} />
							}
						</div>
					</div>
				}
			</div>
		)
	}
}

export default wrapComponent(PhoneNumbers)