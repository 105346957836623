import * as React from 'react'
import { OwnProps, Props, Actions } from '../containers/RecentVisits'
import Visit from '../containers/Visit'
import AppointmentFeedbackModalWrapper from './AppointmentFeedbackModalWrapper'
import { RecentDay } from '../types'

interface State {
	showAppointmentFeedbackModal: boolean
	currentAppointmentRef?: string
	currentAppointmentFeedback?: string
	currentSupportWorkerName?: string
}

const INITIAL_STATE: State = {
	showAppointmentFeedbackModal: false,
}

export default class RecentVisits extends React.Component<OwnProps & Props & Actions, State> {

	public state = INITIAL_STATE

	toggleLeaveAppointmentFeedbackModal = () => {
		this.setState({ showAppointmentFeedbackModal: !this.state.showAppointmentFeedbackModal })
	}

	handleSubmitFeedback = (feedback: string) => {
		if (this.state.currentAppointmentRef) {
			this.props.addAppointmentFeedback(this.state.currentAppointmentRef, feedback)
		}
	}

	showModal = (job: RecentDay, appointmentRef: string, feedback?: string) => {
		this.setState({
			currentAppointmentRef: appointmentRef,
			currentAppointmentFeedback: feedback ? feedback : undefined,
			showAppointmentFeedbackModal: true,
			currentSupportWorkerName: job.supportWorker ? job.supportWorker.name : 'the support worker',
		})
	}

	public render() {
		const { recentJobs } = this.props

		return (
			<section className="dashboard-section">
				<header className="dashboard-header">
					{
						this.props.you ? (
							<h1 className="headline-text">My recent visits</h1>
						) : (
							<h1 className="headline-text">{this.props.displayName || ''}’s recent visits</h1>
						)
					}
					{/* <div className="button-group">
						<div className="button">
							<a href="#"  className="button-link -secondary -small"><span className="_hide-mobile">Full </span>history</a>
						</div>
					</div> */}
				</header>
				<div className="recent-visits">
					<table className="table table-default">
						<thead>
							<tr>
								<th scope="col">Date</th>
								<th scope="col">Time</th>
								<th scope="col">With</th>
								<th scope="col">Type of care</th>
								{this.props.featureJobFeedback &&
								<th scope="col">Feedback</th>
								}
							</tr>
						</thead>
						<tbody>
							{
								recentJobs && recentJobs.map((job, key) => {
									return (
										<Visit key={key} job={job} showModal={this.showModal.bind(this, job)}/>
									)
								})
							}
						</tbody>
					</table>
				</div>
				{
					this.state.showAppointmentFeedbackModal &&
					<AppointmentFeedbackModalWrapper supportWorkerName={this.state.currentSupportWorkerName || 'the support worker'} onClose={this.toggleLeaveAppointmentFeedbackModal} submitFeedback={this.handleSubmitFeedback}/>
				}
			</section>
		)
	}
}