/**
 * Component template.
 * 
 * Note that this file has a `.tsx` suffix, as it contains React elements.
 */

import * as React from 'react'
import Moment from 'react-moment'
import { LocalDate } from '@js-joda/core'

import * as t from '../types'
import Job from './Job'

interface OwnProps {
	view: t.DayView
	calendar: t.CalendarView
}

interface Actions {
	onSelectDay: (date: LocalDate) => void
}

export default class Day extends React.Component<OwnProps & Actions> {

	handleClick = () => {
		this.props.onSelectDay(this.props.view.date)
	}
	
	public render() {
		const { view, calendar } = this.props

		return (
			<div className={'day' + (view.otherMonth ? ' -secondary' : '') + (view.today ? ' -current' : '')} onDoubleClick={this.handleClick}>
				<div className="date"><Moment date={view.date.toString()} format="D" /></div>
				<div className="bookings">
					{
						view.jobs.map((job, index) => (
							<Job view={job} calendar={calendar} key={index} />
						))
					}
				</div>
			</div>
		)
	}
}
