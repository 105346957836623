import * as React from 'react'
import { Switch, Route, Redirect } from 'react-router'
import Header from 'modules/common/containers/Header'
import Footer from 'modules/common/components/Footer'
import MyNavLink from 'modules/routing/components/MyNavLink'
import Resources from 'modules/individualised-funding/components/Resources'
import GeneralFAQs from './GeneralFAQs'
import IndividualisedFundingFAQs from './IndividualisedFundingFAQs'

export default class GenevaHelpScreen extends React.Component {

	public render() {
		return (
			<div className="site-content account-screen">
				<Header section='' />
				
				<article className="site-body">
					<div className="width-limit -site">
						<div className="content-layout">
							{

							}
							<Switch>
								<Route path="/help/resources" component={Resources} />
								<Route path="/help/faq/individualised-funding" exact={true} component={IndividualisedFundingFAQs} />
								<Route path="/help/faq" exact={true} component={GeneralFAQs} />
								<Route path="/help">
									<Redirect to="/help/faq" />
								</Route>
							</Switch>
							<aside className="sidebar">
								<Switch>
									<Route
										path="/help"
										render={() => {
											return (
												<nav className="section-nav">
													<ul className="listitems">
														<MyNavLink to="/help/faq" className="item" activeClassName="-active -current" linkClassName="link">FAQs</MyNavLink>
														<Switch>
															<Route 
																path={'/help/faq/'}
																render={() => {
																	return (
																		<div className="subnav">
																			<MyNavLink to="/help/faq" exact={true} className="item" activeClassName="-active -current" linkClassName="link">General</MyNavLink>
																			<MyNavLink to="/help/faq/individualised-funding" exact={true} className="item" activeClassName="-active -current" linkClassName="link">Individualised Funding</MyNavLink>
																		</div>
																	)
																}}
															/>
														</Switch>
														<MyNavLink to={'/help/resources'} className="item" activeClassName="-active -current" linkClassName="link">Resources</MyNavLink>
													</ul>
												</nav>
											)
										}}
									/>
								</Switch>
							</aside>
						</div>
					</div>
				</article>
				<Footer />
			</div>
		)
	}
}
