import * as React from 'react'
import { Api } from 'typescript-fetch-api'
import { OwnProps, Props, Actions } from '../containers/SupportNetwork'
import SupportNetworkInvite from '../containers/SupportNetworkInvite'
import LeaveSupportNetwork from './LeaveSupportNetworkModal'
import SupportNetworkMembership from './SupportNetworkMembership'
import * as a from '../actions'
import { Helmet } from 'react-helmet'
import { avatarPlaceholder } from 'modules/root/theme'

interface State {
	showSupportNetworkInviteModal: boolean
	showLeaveSupportNetworkModal: boolean
}

/**
 * The initial state for our internal component state.
 */
const INITIAL_STATE: State = {
	showSupportNetworkInviteModal: false,
	showLeaveSupportNetworkModal: false,
}

export default class SupportNetwork extends React.Component<OwnProps & Props & Actions, State> {

	public state = INITIAL_STATE

	handleUpdateSupportNetworkCapabilities = (request: a.UpdateUserCapabilitiesPayload) => {
		this.props.handleUpdateSupportNetworkCapabilities(request)
	}

	handleLeaveSupportNetwork = () => {
		this.props.handleLeaveSupportNetwork()
	}

	removeUserFromSupportNetwork = (userRef: string) => {
		this.props.removeUserFromSupportNetwork(userRef)
	}

	revokeInvite = (clientRef: string, inviteRef: string) => {
		this.props.revokeInvite({ clientRef, inviteRef })
	}

	resendInvite = (invite: Api.ClientSupportNetworkInvitation) => {
		this.props.onResendInvitation({
			email: invite.email,
			role: invite.role,
			capabilities: {
				manager: invite.capabilities.manager,
				manageRoster: invite.capabilities.manageRoster,
				viewRoster: invite.capabilities.viewRoster,
				manageClientDetails: invite.capabilities.manageClientDetails,
				viewClientDetails: invite.capabilities.viewClientDetails,
				viewTimesheets: invite.capabilities.viewTimesheets,
				manageTimesheets: invite.capabilities.manageTimesheets,
			},
		})
	}

	toggleInviteModal = () => {
		this.setState({ showSupportNetworkInviteModal: !this.state.showSupportNetworkInviteModal })
	}

	toggleLeaveModal = () => {
		this.setState({ showLeaveSupportNetworkModal: !this.state.showLeaveSupportNetworkModal })
	}

	public render() {
		const { capabilitiesForCurrentUser, clientIsYou, client, user } = this.props
		if (!client) {
			return null
		}

		return (
			<div className="body">
				<Helmet>
					<title>Support Network — My Homecare</title>
				</Helmet>
				{clientIsYou ? (
					<h1 className="headline-text">My support network</h1>
				) : (
					<>
						<h1 className="headline-text">{client.givenName || client.fullName}’s support network</h1>
						<div className="supportnetwork-status">
							<div className="body-text">
								<p>You are a member of {client.givenName || client.fullName}’s support network.</p>
								<div onClick={this.toggleLeaveModal} className="button-link -small -action">Leave</div>
							</div>
						</div>
					</>
				)}
				
				{
					this.props.supportNetworks && this.props.supportNetworks.length > 0 &&
						<div className="support-network">
							<table className="table table-default">
								<thead>
									<tr>
										<th scope="col">Name</th>
										<th scope="col">Role / access</th>
										<th scope="col" colSpan={2} />
									</tr>
								</thead>
								<tbody>
									{
										this.props.supportNetworks.map((network, key) => {
											return (
												<SupportNetworkMembership 
													key={key} 
													network={network} 
													handleUpdateSupportNetworkCapabilities={this.handleUpdateSupportNetworkCapabilities}
													removeUserFromSupportNetwork={this.removeUserFromSupportNetwork}
													userRef={user ? user : ''}
													capabilities={capabilitiesForCurrentUser ? capabilitiesForCurrentUser : undefined}
													memberIsClient={network.role === Api.SupportNetworkMembership.RoleEnum.Client ? true : false}
													clientIsYou={clientIsYou}
													leaveSupportNetwork={this.toggleLeaveModal}
													supportsTimesheets={this.props.supportsTimesheets}
												/>
											)
										})
									}
								</tbody>
							</table>
						</div>
				}
				<div className="button-group">
					{ capabilitiesForCurrentUser && capabilitiesForCurrentUser.manager &&
						<div className="button">
							<div onClick={this.toggleInviteModal} className="button-link -action -small">Invite someone to join</div>
						</div>
					}
				</div>
				{
					this.props.pendingInvitations && this.props.pendingInvitations.length > 0 && ((capabilitiesForCurrentUser && (capabilitiesForCurrentUser.manager || capabilitiesForCurrentUser.manageClientDetails))) &&
					<>
						<div className="body-text">
							<h2>Pending invitations</h2>
						</div>
						<div className="support-network">
							<table className="table table-default">
								<thead>
									<tr>
										<th scope="col">Name</th>
										<th scope="col">Role / access</th>
										<th scope="col" colSpan={2} />
									</tr>
								</thead>
								<tbody>
									{
										this.props.pendingInvitations.map((invite, key) => {
											return (
												<tr key={key}>
													<td>
														<div className="person">
															<figure className="avatar-image -medium">
																<div className="image">
																	<img src={avatarPlaceholder()} alt="Johnny" />
																</div>
															</figure>
															<span className="name">{invite.email}</span>
														</div>
													</td>
													<td>
														{invite.capabilities.manager && 'Manager'}
														{!invite.capabilities.manager && invite.capabilities.manageRoster && <>Calendar (manage)<br/></>}
														{!invite.capabilities.manager && !invite.capabilities.manageRoster && invite.capabilities.viewRoster && <>Calendar (view)<br/></>}
														{!invite.capabilities.manager && invite.capabilities.manageClientDetails && <>Client information (manage)<br/></>}
														{!invite.capabilities.manager && !invite.capabilities.manageClientDetails && invite.capabilities.viewClientDetails && <>Client information (view)<br/></>}
													</td>
												
													{ capabilitiesForCurrentUser && capabilitiesForCurrentUser.manager ? (
														<>
															<td className="resend">
																<a 
																	href="#" 
																	className="button-link -secondary -micro" 
																	onClick={(evt: React.MouseEvent) => {
																		evt.preventDefault()
																		this.resendInvite(invite)
																	}}
																>
															Re-send
																</a>
															</td>
															<td className="delete"><div onClick={() => this.revokeInvite(invite.clientRef, invite.ref)} className="remove">Cancel</div></td>
														</>) : (<td/>)
													}
												</tr>
											)
										})
									}
								</tbody>
							</table>
						</div>
					</>
				}
				{
					this.state.showSupportNetworkInviteModal &&
					<SupportNetworkInvite onClose={this.toggleInviteModal}/>
				}
				{
					this.state.showLeaveSupportNetworkModal &&
					<LeaveSupportNetwork onClose={this.toggleLeaveModal} leaveNetwork={this.handleLeaveSupportNetwork} supportNetworkName={client.givenName || client.fullName}/>
				}
			</div>
		)
	}
}
