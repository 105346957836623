import * as React from 'react'
import { Snapshot, forComponentProps, wrapComponent } from 'changeling'
import { CheckBoxOption, CheckBoxOptionValue, SelectedOption } from '../types'

export interface OwnProps extends Snapshot<CheckBoxOptionValue | undefined> {
	options: CheckBoxOption[]
	secondaryQuestion?: boolean // TRUE if this question has been displayed due to the previous question
	editingMode?: boolean
	title?: string
}

class CheckBoxOptions extends React.Component<OwnProps> {
	private changeling = forComponentProps(this)

	public render() {
		const { editingMode, value } = this.props
		return (
			<div className={'form-item -checkboxoptions' + (this.props.secondaryQuestion ? ' -secondary' : '') + (editingMode ? '' : ' -valuemode')}>
				{
					editingMode ? (
						<div className="form-wrapper">
							<div className="form-field">
								<div className="form-input -options">
									<ul className="option-inputs">
										{
											this.props.options.map((option, key) => (
												<li className="option -checkbox" key={key}>
													<label className={'label' + (option.withAdditionalInfo ? ' -withtextbox' : '')}>
														<input type="checkbox" className="checkbox" value={option.optionValue} onChange={this.checkBoxToggle} checked={this.amIChecked(option.optionValue)}/>
														<span className={'substitute' + (option.withAdditionalInfo ? ' -withtextbox' : '')}/>
														{option.optionLabel}
														{
															option.withAdditionalInfo &&
															<div className="form-input -text">
																<input type="text" className="field" placeholder={option.placeholder} name={option.optionValue} value={this.textValue(option.optionValue)} onChange={this.textChange} disabled={!this.amIChecked(option.optionValue)}/>
															</div>
														}
													</label>
												</li>
											))
										}
									</ul>
								</div>
							</div>
						</div>
					) : (
						<>
							{this.props.title && <p className="title">{this.props.title}</p>}
							<div className="value-wrapper">
								{value && value.map((option, key) => (
									<p className="sub-text" key={key}>{this.valueToLabel(option.optionValue)} {option.additionalInfo ? option.additionalInfo : ''}</p>
								))}
							</div>
						</>
					)
				}
				
			</div>
		)
	}

	private checkBoxToggle = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const optionValue = evt.target.value 
		const checked = evt.target.checked
		let newValue: SelectedOption[] = []
		
		if (this.props.value) {
			newValue = [...this.props.value]
		}
		
		if (checked) {
			newValue.push({
				optionValue: optionValue,
			})
		} else {
			const index = newValue.findIndex(x => x.optionValue === optionValue)
			newValue.splice(index, 1)
		}
		
		this.changeling.snapshot().onChange(newValue)
	}

	private textChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const optionValue = evt.target.name
		let newValue: SelectedOption[] = []
		let valueIndex = -1
		
		if (this.props.value) {
			newValue = [...this.props.value]
			valueIndex = newValue.findIndex(x => x.optionValue === optionValue)
			
			if (valueIndex > -1) {
				newValue.splice(valueIndex, 1, {
					optionValue: optionValue,
					additionalInfo: evt.target.value,
				})
				this.changeling.snapshot().onChange(newValue)
			}
		}
	}

	private textValue = (optionValue: string): string => {
		const value = this.props.value
		let valueIndex = -1
		
		if (value) {
			valueIndex = value.findIndex(x => x.optionValue === optionValue)

			if (valueIndex > -1 && value[valueIndex].additionalInfo) {
				return value[valueIndex].additionalInfo!
			}
		}

		return ''
	}

	private amIChecked = (optionValue: string): boolean => {
		const value = this.props.value

		if (value && (value.findIndex(x => x.optionValue === optionValue) < 0)) {
			return false
		} else {
			return true
		}
	}

	private valueToLabel = (optionValue: string): string => {
		let label = ''
		if (this.props.options) {
			const index = this.props.options.findIndex(x => x.optionValue === optionValue)
			if (index > -1) {
				label = this.props.options[index].optionLabel
			}
		}
		return label
	}
}

export default wrapComponent(CheckBoxOptions)