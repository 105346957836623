import { createBrowserHistory } from 'history'

import { routerMiddleware } from 'connected-react-router'
import { History, LocationState } from 'history'
import ReactGA from 'react-ga'

export const history = createBrowserHistory({
	basename: '/app',
})
export const middleware = routerMiddleware(history)

/* Scroll to the top of the page when changing to a new page. */
history.listen((location, action) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	if (!location.state || !(location.state as any).dontScrollToTop) {
		window.scrollTo(0, 0)
	}

	/* Record Google Analytics after timeout so we have changed the page title correctly using Helmet */
	setTimeout(
		function() {
			ReactGA.pageview(location.pathname + location.search)
		},
		0,
	)
})

export function dontScrollToTopState(): LocationState {
	return {
		dontScrollToTop: true,
	}
}

/** Returns a location descriptor suitable for the <Link> element that will result in the browser
 * scrolling to the top.
 */
export function dontScrollToTopLocation(pathname: string): History.LocationDescriptor {
	return {
		pathname,
		state: dontScrollToTopState(),
	}
}
