import 'core-js'
import 'url-polyfill'
import 'modules/frontend/vendor/focus-visible/focus-visible.js'
import 'modules/frontend/vendor/lazysizes/lazysizes.js'
import * as React from 'react'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { Redirect, Route, Switch } from 'react-router'
import { ToastContainer, Slide } from 'react-toastify'
import { IntercomProvider } from 'react-use-intercom'

import { store } from 'modules/root'
import { history } from 'modules/routing'

import ReadyGate from 'modules/offline/containers/ReadyGate'

import DashboardScreen from 'modules/dashboard/containers/DashboardScreen'
import HelpScreen from 'modules/help/components/HelpScreen'
import CalendarScreen from 'modules/calendar/components/CalendarScreen'
import HealthPassportScreen from 'modules/health-passport/containers/HealthPassportScreen'
import AdvanceCarePlanScreen from 'modules/advance-care-plan/containers/AdvanceCarePlanScreen'
import SupportWorkersScreen from 'modules/support-workers/components/SupportWorkersScreen'
import ClientSearch from 'modules/funder-dashboard/containers/ClientSearch'
import NewBookingScreen from 'modules/booking/containers/NewBookingScreen'
import NewOrderScreen from 'modules/order/components/NewOrderScreen'
import AccountScreen from 'modules/account/containers/AccountScreen'
import TimesheetScreen from 'modules/timesheet/containers/TimesheetScreen'
import SetupScreen from 'modules/setup/containers/SetupScreen'
import CommunityScreen from 'modules/community/containers/CommunityScreen'
import PrivateRoute from 'modules/routing/containers/PrivateRoute'
import LoginScreen from 'modules/auth/components/LoginScreen'
import RegisterScreen from 'modules/register/components/RegisterScreen'
import UpdateEmailScreen from 'modules/account/containers/UpdateEmailScreen'
import { Helmet } from 'react-helmet'
import Waiting from 'modules/common/components/Waiting'
import PrintScreen from 'modules/documents/components/PrintScreen'

import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import platform, { Brand } from 'modules/platform'

/* Main styles */
if (process.env.REACT_APP_BRANDING) {
	require(`modules/frontend/css/main-${process.env.REACT_APP_BRANDING}.less`)
} else {
	require('modules/frontend/css/main.less')
}

/* To test alt-brand styles, comment out the main styles above and uncomment a brand styles below.
   DON'T commit this change in.
   For production the styles are changed out in the deploy script by replacing the main.less file
   with the file for the right brand.
*/
// require('modules/frontend/css/main-suemann.less')

declare global {
	interface Window {
		PUBLIC_URL: string
	}
}

class App extends React.Component {
	public render() {
		return (
			<Provider store={store}>
				<ReadyGate waitComponent={Waiting}><IntercomProvider appId="w007n7y7">
					<ConnectedRouter history={history}>
						<MuiPickersUtilsProvider utils={MomentUtils}>
							<ToastContainer
								position="top-center"
								hideProgressBar={true}
								closeOnClick={true}
								draggable={false}
								pauseOnHover={true}
								className="toast-container"
								transition={Slide}
								newestOnTop={true}
							/>
							{/* Default head tags */}
							<Helmet>
								<title>My Homecare</title>
								{platform.brand() === Brand.Suemann ? (
									<link rel="stylesheet" href="https://use.typekit.net/dtd3fwj.css" /> 
								) : (
									<link rel="stylesheet" href="https://use.typekit.net/tef6fvv.css" />
								)}
								{platform.brand() === Brand.Suemann ? (
									<link rel="shortcut icon" href={`${window.PUBLIC_URL}/favicon/theme/suemann/favicon.ico`} />
								) : platform.brand() === Brand.Hcnz ? (
									<link rel="shortcut icon" href={`${window.PUBLIC_URL}/favicon/theme/hcnz/favicon.ico`} />
								) : (
									<link rel="shortcut icon" href={`${window.PUBLIC_URL}/favicon/favicon.ico`} />
								)}
								{platform.brand() === Brand.Suemann ? (
									<link rel="apple-touch-icon-precomposed" href={`${window.PUBLIC_URL}/favicon/theme/suemann/favicon-152.png`} />
								) : platform.brand() === Brand.Hcnz ? (
									<link rel="apple-touch-icon-precomposed" href={`${window.PUBLIC_URL}/favicon/theme/hcnz/favicon-152.png`} />
								) : (
									<link rel="apple-touch-icon-precomposed" href={`${window.PUBLIC_URL}/favicon/favicon-152.png`} />
								)}
								{platform.brand() === Brand.Suemann ? (
									<link rel="mask-icon" href={`${window.PUBLIC_URL}/favicon/theme/suemann/safari-pinned-tab.svg" color="#264653`} />
								) : platform.brand() === Brand.Hcnz ? (
									<link rel="mask-icon" href={`${window.PUBLIC_URL}/favicon/theme/hcnz/safari-pinned-tab.svg" color="#264653`} />
								) : (
									<link rel="mask-icon" href={`${window.PUBLIC_URL}/favicon/safari-pinned-tab.svg" color="#264653`} />
								)}
							</Helmet>
							<Switch>
								<PrivateRoute exact={true} path="/" component={DashboardScreen} />
								<PrivateRoute path="/account/email" component={UpdateEmailScreen} />
								<PrivateRoute path="/account" component={AccountScreen} />
								<PrivateRoute path="/calendar/new" component={NewBookingScreen} />
								<PrivateRoute path="/calendar/new-order" component={NewOrderScreen} />
								<PrivateRoute path="/calendar" component={CalendarScreen} />
								<PrivateRoute path="/client-search" component={ClientSearch} />
								<PrivateRoute path="/health-passport" component={HealthPassportScreen} />
								<PrivateRoute path="/help" component={HelpScreen} />
								<PrivateRoute path="/advance-care-plan" component={AdvanceCarePlanScreen} />
								<PrivateRoute path="/timesheet" component={TimesheetScreen} />
								<PrivateRoute path="/support-workers" component={SupportWorkersScreen} />
								<PrivateRoute path="/setup" component={SetupScreen} />
								<PrivateRoute path="/my-community" component={CommunityScreen} />
								<PrivateRoute path="/print" component={PrintScreen} />
								<Route path="/sign-in" component={LoginScreen} />
								<Route path="/register" component={RegisterScreen} />
								<Redirect from="*" to="/" />
							</Switch>
						</MuiPickersUtilsProvider>
					</ConnectedRouter>
				</IntercomProvider>
				</ReadyGate>
			</Provider>
		)
	}
}

export default App
