import * as React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { ComponentProps } from '../containers/AdvanceCarePlanIndex'
import Waiting from 'modules/common/components/Waiting'
import Moment from 'react-moment'

export default class AdvanceCarePlanIndex extends React.Component<ComponentProps> {

	public render() {

		return (
			<div className="body document-index document-types">
				<Helmet>
					<title>Advance Care Plan — My Homecare</title>
				</Helmet>
				<h1 className="headline-text">Advance Care Plan</h1>
				<h2 className="section-header">What is an Advance Care Plan?</h2>
				<p className="body-text">If you have had a chance to think about the care you want towards the end of your life, you may want to write your thoughts down. Use this plan to write down what you want health professionals, friends and family/whanau to know if you could no longer tell them yourself.</p>
				<p className="body-text">There is a section on medical treatments which is important to discuss with your doctor if possible, before you complete it.</p>
				<p className="body-text">This plan is for you and about you. You should not feel like you have to complete every section, complete only as much as you want to. You can show it to anyone involved in your healthcare. You can add to it as often as you like and change your decisions at any time. </p>
				<p className="body-text">It can be forwarded through your doctor to others who may need it, with your consent.</p>
				
				<h2 className="section-header">Your Advance Care Plan</h2>
				{this.props.loading ? (
					<Waiting />
				) : this.props.failed ? (
					<p className="body-text">Your documents could not be loaded.</p>
				) : (
					<div className="button-group -leftaligned">
						<div className="button">
							{this.props.document ? (
								<>
									<Link to="/advance-care-plan/step-one" className="button-link -action">Update plan</Link>
									<div className="last-updated"><p>Updated <Moment date={this.props.document.whenUpdated} fromNow={true} /></p></div>
								</>
							) : (
								<Link to="/advance-care-plan/step-one" className="button-link -action">Start Plan</Link>
							)}
						</div>
						{this.props.document && this.props.clientRef && (
							<div className="button">
								<p className="download"><a href={`/app/print/client/${this.props.clientRef}/document/${this.props.document.ref}`} onClick={this.onDownload}>Download</a></p>
							</div>
						)}
					</div>
				)}
			</div>
		)
	}

	private onDownload = (evt: React.MouseEvent) => {
		evt.preventDefault()

		if (this.props.document) {
			this.props.onDownloadDocument(this.props.document.ref)
		}
	}
}
