/* Import the component from the component path */
import Component from '../components/ForgotPassword'

import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'
import { Api } from 'typescript-fetch-api'

/* Import module files */
import * as actions from '../actions'
import * as s from '../selectors'
import * as account from 'modules/account/selectors'

export interface OwnProps {
}

/**
 * Interface for properties that the container passes to the component.
 */
export interface Props {
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
	onForgotPassword: (request: string) => void
	onResetPassword: (request: Api.ResetPasswordRequest) => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState): Props => {
	return {
		processing: state.auth.loggingIn,
		loadingAccount: state.account.loading,
		loadedAccount: account.loaded(state),
		loggedIn: s.loggedInSelector(state),
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	onForgotPassword: (request) => {
		dispatch(actions.forgotPassword({ email: request }))
	},
	onResetPassword: (request) => {
		dispatch(actions.resetPassword(request))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
