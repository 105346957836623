import * as React from 'react'
import { Link } from 'react-router-dom'
import TitleLogoRow from './TitleLogoRow'

interface OwnProps {
	section: string
}

export default class HealthPassportSectionWrapper extends React.Component<OwnProps> {
	public render() {
		const { children, section } = this.props

		return (
			<article className="site-body">
				<div className="width-limit -site">
					<form className="content-layout" action="#">
						<div className="body">
							<TitleLogoRow section={section}/>
							{children}

							<div className="row progress-buttons">
								<div className="button-group">
									<div className="button">
										{section === 'personal-details' && <Link to="/account/my-documents/health-passport" className="button-link -text">Cancel</Link>}
										{section === 'a' && <Link to="personal-details" className="button-link -text">Back</Link>}
										{section === 'b' && <Link to="section-a" className="button-link -text">Back</Link>}
										{section === 'c' && <Link to="section-b" className="button-link -text">Back</Link>}
									</div>
									<div className="button -constrained">
										{section === 'personal-details' && <Link to="section-a" className="button-link -action">Continue</Link>}
										{section === 'a' && <Link to="section-b" className="button-link -action">Continue</Link>}
										{section === 'b' && <Link to="section-c" className="button-link -action">Continue</Link>}
										{section === 'c' && <Link to="/account/my-documents/health-passport" className="button-link -action">Complete plan</Link>}
									</div>
								</div>
							</div>
						</div>

						<aside className="sidebar">
							<Link to="/account/my-documents/health-passport" className="back-nav">My Documents</Link>
							<h1 className="page-title">e-Health Passport</h1>
							<nav className="progress-nav">
								<ul className="listitems">
									<li className={`item${section === 'personal-details' ? ' -active' : ''}`}><Link to="personal-details" className="link">Personal Details</Link></li>
									<li className={`item${section === 'a' ? ' -active' : ''}`}><Link to="section-a" className="link">Section A</Link></li>
									<li className={`item${section === 'b' ? ' -active' : ''}`}><Link to="section-b" className="link">Section B</Link></li>
									<li className={`item${section === 'c' ? ' -active' : ''}`}><Link to="section-c" className="link">Section C</Link></li>
								</ul>
							</nav>
						</aside>
					</form>
				</div>
			</article>
		)
	}
}
