import * as React from 'react'

import * as t from '../types'
import { avatarPlaceholder } from 'modules/root/theme'

interface OwnProps {
	client: t.ClientView
	overrideName?: string
	selected: boolean
	onChooseClient: () => void
}

export default class AccountMenuClient extends React.Component<OwnProps> {
	public render() {
		const { client, overrideName, selected } = this.props

		return (
			<li className={'menuitem' + (selected ? ' -selected' : '')}>
				<a onClick={this.props.onChooseClient} className="link person">
					<figure className="avatar-image -medium -bordered">
						<div className="image -placeholder">
							<img src={client.image || avatarPlaceholder()} alt={client.name} />
						</div>
					</figure>
					<span className="name">{overrideName || client.name}</span>
				</a>
			</li>
		)
	}
}
