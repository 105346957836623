import * as React from 'react'
import { RouteComponentProps, Switch, Route } from 'react-router'
import PrintDocument from '../containers/PrintDocument'

export default class PrintScreen extends React.Component<RouteComponentProps<undefined>> {

	public render() {
		return (
			<div className="site-content print-screen document-form document-types">
				<Switch>
					<Route
						path="/print/client/:client/document/:document"
						render={(props) => (
							<PrintDocument clientRef={props.match.params.client} documentRef={props.match.params.document} />
						)}
					/>
					<Route 
						render={() => (
							<div>Invalid print request</div>
						)}
					/>
				</Switch>
			</div>
		)
	}

}
