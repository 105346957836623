import * as React from 'react'
import { Props, Actions, OwnProps } from '../containers/HeaderNav'
import { Link } from 'react-router-dom'  
import { registerForCare } from 'modules/root/features'

interface State {
}

const INITIAL_STATE: State = {
}

export default class HeaderNav extends React.Component<Props & Actions & OwnProps, State> {

	public state = INITIAL_STATE
		
	onRegisterForCare = () => {
		this.props.onRegisterForCare()
	}

	onSignOut = () => {
		this.props.onSignOut()
	}

	onHome = () => {
		this.props.onHome()
	}

	public render() {

		const { viewingDifferentAccount, currentClientName } = this.props

		return (
			<div className={'header-nav _no-print' + (viewingDifferentAccount ? ' -otheruser' : '')}>
				<div className="header-padding">
					<div className="nav-wrapper width-limit -site">
						<div className="left-content">
							{viewingDifferentAccount && <p className="nav-link -thicc"><span className="extended">You are </span>viewing {currentClientName}'s account</p>}
							{viewingDifferentAccount && <p className="nav-link -back"><a onClick={this.onHome} className="link">Home</a></p>}
						</div>
						<div className="right-content">
							{
								registerForCare &&
								<p className="nav-link"><a onClick={this.onRegisterForCare} className="link">Register for care</a></p>
							}
							<p className="nav-link"><Link to="/help/faq" className="link">Help</Link></p>
							<p className="nav-link"><a onClick={this.onSignOut} className="link">Sign out</a></p>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
