import Component from '../components/EditBooking'
import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'
import { withRouter } from 'react-router'
import { RootStoreState } from 'modules/root'

import * as a from '../actions'
import * as t from '../types'
import { timesheetableSupportWorkerRefsSelector } from 'modules/booking/selectors'
import { featuresSelector } from 'modules/common/selectors'

/** Interface for properties that are passed to the container/ */
export interface OwnProps {
	bookingToEdit: DeepReadonly<t.EditBooking>
	timesheetEdit?: boolean
}

/** Interface for properties that the container passes to the component. */
export interface Props {
	editBookingFormState: t.EditBookingFormState
	availableSupportWorkers?: DeepReadonlyArray<string>
	timesheetJobEdit: boolean
	findSupportWorkers: boolean
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
	onCancelEditBooking: () => void
	onUpdateEditBookingFormState: (editBookingFormState: t.EditBookingFormState) => void
	onUpdateEditBookingDetails: (editBookingDetails: DeepReadonly<t.EditBooking>) => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	const bookingTimesheetRef = state.booking.editBooking ? state.booking.editBooking.job.timesheetRef : undefined
	const features = featuresSelector(state)

	return {
		editBookingFormState: state.booking.editBookingFormState,
		availableSupportWorkers: timesheetableSupportWorkerRefsSelector(state),
		timesheetJobEdit: ownProps.timesheetEdit ? true : bookingTimesheetRef ? true : false,
		findSupportWorkers: features.findSupportWorkers,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	onCancelEditBooking: () => {
		dispatch(a.clearEditBooking())
		dispatch(a.cancelEditBooking())
	},
	onUpdateEditBookingFormState: (editBookingFormState: t.EditBookingFormState) => {
		dispatch(a.updateEditBookingFormState(editBookingFormState))
	},
	onUpdateEditBookingDetails: (editBookingDetails: t.EditBooking) => {
		dispatch(a.updateEditBookingDetails(editBookingDetails))
	},
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Component))
