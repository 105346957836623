import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { Props } from '../containers/GeneralLinks'

import { Helmet } from 'react-helmet'

export default class GeneralLinks extends React.Component<Props & RouteComponentProps<undefined>> {

	public render() {
		return (
			<section className="body">
				<Helmet>
					<title>General Community and Informational Links — My Homecare</title>
				</Helmet>
								
				<h1 className="headline-text">General Community and Informational Links</h1>
				
				<div className="body-text">
					<h2><a href="https://www.whitecoat.co.nz" target="_blank" rel="noopener noreferrer">Whitecoat</a></h2>
					<p>With Whitecoat, finding, comparing and reviewing healthcare providers is easier than ever before. They have thousands of practitioners across multiple specialities. Search their New Zealand directory to find a practitioner close to you. Browse reviews, including star ratings and comments, to make informed choices on which practitioners are best suited to your needs. Share your recent healthcare experience through Whitecoat so that others, just like you, can be more informed when choosing a healthcare provider. All reviews will remain anonymous and are moderated.</p>
					
					<h2><a href="https://drivingmissdaisy.co.nz" target="_blank" rel="noopener noreferrer">Driving Miss Daisy</a></h2>
					<p>Live life to the full with our safe, friendly, and reliable companion driving service. They provide transportation and companion driving services for the elderly, children, disabled and for anyone who is unable to drive from A to B. They offer you independence and peace of mind, enabling you to get out, have fun, and add some zing into your everyday living. Their special service also provides assistance in and out of the car, going to appointments, shopping and company on outings. Special needs are catered for including assistance with a walker or wheelchair.</p>

					<h2><a href="https://www.neighbourly.co.nz/community/organisation" target="_blank" rel="noopener noreferrer">Neighbourly</a></h2>
					<p>Neighbourly allows you to stay connected with your neighbours and community using a private neighbourhood website. It's a free service that is designed to make your neighbourhood a safer and better place to live. Members of Neighbourly use the website to interact on topics such as local events, after school activities, crime and safety, council issues, local services or even lost pets. Neighbourly also offers a crime prevention service for members within its communities. Members are kept informed of any suspicious activity and can also inform each other instantly of any urgent crime or safety updates via SMS text message.</p>

					<h2><a href="https://www.newzealandnow.govt.nz/regions-nz" target="_blank" rel="noopener noreferrer">New Zealand Now</a></h2>
					<p>This is a website supported by the NZ Immigration Service that provides links to a wide range of regional community groups.</p>

					<h2><a href="https://www.youthline.co.nz/community-organisations.html" target="_blank" rel="noopener noreferrer">Younger Persons</a></h2>
					<p>Information and a collection of links to useful resources aimed at Younger Persons provided by Youthline.</p>

					<h2><a href="https://teara.govt.nz/en/community-organisations" target="_blank" rel="noopener noreferrer">Te Ara</a></h2>
					<p>“Te ara” in Māori means “the pathway”. Te Ara – The Encyclopedia of New Zealand offers many pathways to understanding New Zealand. It is a comprehensive guide to the country‘s peoples, natural environment, history, culture, economy, institutions and society.</p>

					<h2><a href="https://www.msd.govt.nz/what-we-can-do/community/index.html" target="_blank" rel="noopener noreferrer">Ministry of Social Development</a></h2>
					<p>A guide to community groups provided by the Ministry of Social Development</p>

					<h2><a href="https://www.mentalhealth.org.nz/get-help/in-crisis/community-directories/" target="_blank" rel="noopener noreferrer">Mental Health Foundation of NZ</a></h2>
					<p>A guide to community groups provided by the Mental Health Foundation of NZ</p>

					<h2><a href="https://www.lifeline.org.nz" target="_blank" rel="noopener noreferrer">Lifeline</a></h2>
					<p>Lifeline Aotearoa has been helping New Zealanders in crisis for over 50 years. Their free community helplines (Lifeline 24/7, Suicide Crisis Helpline, Kidsline) as well as their text support service HELP (4357) are answered by qualified counsellors and trained volunteers from call centres in Auckland and Christchurch. They also provide specialist counselling and corporate and volunteer training.</p>

					<h2><a href="https://www.supportcrew.co" target="_blank" rel="noopener noreferrer">Support Crew</a></h2>
					<p>Support Crew is a free online support platform that makes it easy to co-ordinate meals and support for family and friends in need. Create a support page for yourself or someone else.</p>
					
					<h2><a href="http://thereadingrevolution.org/books-uplifting-hearts-and-minds/" target="_blank" rel="noopener noreferrer">Reading Revolution</a></h2>
					<p>The Reading Revolution provides access to great literature. We run shared reading groups where participants listen to short stories and poems read aloud. The group then discuss ideas, thoughts and feelings about the readings. Together we mull over different parts of the story or poem that strike us as interesting, listening carefully to each other and agreeing or disagreeing in a respectful and supportive fashion.</p>
				</div>
				
			</section>
		)
	}
}
