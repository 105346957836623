import Component from '../components/TimesheetEditJob'
import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'
import * as s from '../selectors'
import * as bt from 'modules/booking/types'
import * as ba from 'modules/booking/actions'
import * as ca from 'modules/calendar/actions'
import * as ct from 'modules/calendar/types'
import { Api } from 'typescript-fetch-api'
import { push } from 'connected-react-router'
import { dontScrollToTopState } from 'modules/routing'
import { timesheetableSupportWorkerRefsSelector } from 'modules/booking/selectors'

/** Interface for properties that are passed to the container/ */
export interface OwnProps {
	timesheetRef?: string
	bookingToEdit: DeepReadonly<bt.EditBooking>
}

/** Interface for properties that the container passes to the component. */
export interface Props {
	availableSupportWorkers?: DeepReadonlyArray<string>
	editBookingFormState: bt.EditBookingFormState
	processing: boolean
	timesheet?: DeepReadonlyObject<Api.Timesheet>
	currentlySelectedSupportWorkerRef?: string
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
	onClose: () => void
	onUpdateEditBookingFormState: (editBookingFormState: bt.EditBookingFormState) => void
	onUpdateEditBookingDetails: (editBookingDetails: DeepReadonly<bt.EditBooking>) => void
	onSubmitEditBooking: (jobRef: string, request: Api.AppointmentChangeRequest) => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	return {
		availableSupportWorkers: timesheetableSupportWorkerRefsSelector(state),
		editBookingFormState: state.booking.editBookingFormState,
		processing: state.booking.submitting,
		timesheet: ownProps.timesheetRef ? s.timesheetSelector(state, ownProps.timesheetRef) : undefined,
		currentlySelectedSupportWorkerRef: s.currentSupportWorkerUpdateSelector(state) ? s.currentSupportWorkerUpdateSelector(state) : undefined,
	}
}

/** Populate the Actions with the callbacks for the component. */
export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: OwnProps): Actions => ({
	onClose: () => {
		dispatch(ba.clearEditBooking())
		if (ownProps.timesheetRef) {
			dispatch(push(`/timesheet/ref/${ownProps.timesheetRef}`, dontScrollToTopState()))
		} else {
			dispatch(push('/timesheet', dontScrollToTopState()))
		}
	},
	onUpdateEditBookingFormState: (editBookingFormState: bt.EditBookingFormState) => {
		dispatch(ba.updateEditBookingFormState(editBookingFormState))
	},
	onUpdateEditBookingDetails: (editBookingDetails: bt.EditBooking) => {
		dispatch(ba.updateEditBookingDetails(editBookingDetails))
	},
	onSubmitEditBooking: (jobRef, request) => {
		const payload: ca.RosterChangePayload = {
			jobRef: jobRef,
			payload: request,
			returnTo: ct.JobChangeReturnLocation.Timesheet,
			timesheetRef: ownProps.timesheetRef,
		}
		dispatch(ca.rosterChange(payload))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
