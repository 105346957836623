import Component from '../components/AccountMenu'

import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'
import { Api } from 'typescript-fetch-api'
import { push } from 'connected-react-router'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'

/* Import module actions */
import * as a from '../actions'
import * as ca from 'modules/common/actions'
import * as t from '../types'
import * as s from '../selectors'

/** Interface for properties that are passed to the container/ */
export interface OwnProps {
	section: string
	closeMobileMenu?: () => void
}

/** Interface for properties that the container passes to the component. */
export interface Props {
	clients: t.ClientsView
	currentClient?: t.ClientView
	currentClientRef?: string
	currentUser?: DeepReadonly<Api.User>
	invitations?: DeepReadonly<Api.ClientSupportNetworkInvitation[]>
	currentUserClient?: DeepReadonlyObject<Api.Client>
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
	onChooseClient: (clientRef: string) => void
	onMyDetails: () => void
	onInvitations: () => void
	onFAQs: () => void
	onHome: () => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	const currentClientRef = s.currentClientRefSelector(state)
	const currentClient = s.currentClientViewSelector(state)
	const currentUserClientRef = s.currentUserClientRefSelector(state)
	const currentUserClient = currentUserClientRef ? s.clientSelector(state, currentUserClientRef) : undefined
	
	return {
		clients: s.clientsViewSelector(state),
		currentClient,
		currentClientRef,
		currentUser: s.currentUserSelector(state),
		invitations: state.account.supportNetworks ? state.account.supportNetworks.invitations : undefined,
		currentUserClient: currentUserClient ? currentUserClient : undefined,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	onChooseClient: (clientRef) => {
		dispatch(a.chooseClient({ clientRef, interactive: true }))
	},
	onMyDetails: () => {
		dispatch(a.showMyDetails())
	},
	onInvitations: () => {
		dispatch(push('/setup/register-new/invites'))
	},
	onFAQs: () => {
		dispatch(push('/account/faq'))
	},
	onHome: () => {
		dispatch(ca.goHome())
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
