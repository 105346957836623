import Component from '../components/Visit'

import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'
/* Import RootStoreState */
import { RootStoreState } from 'modules/root'

/* Import module actions */
import * as supportWorkerActions from 'modules/support-workers/actions'
import { Api } from 'typescript-fetch-api'
import * as t from '../types'
import { featuresSelector } from 'modules/common/selectors'

/** Interface for properties that are passed to the container/ */
export interface OwnProps {
	job: DeepReadonly<t.RecentDay>
	showModal: (appointmentRef: string, feedback?: string) => void
}

/** Interface for properties that the container passes to the component. */
export interface Props {
	jobTypes?: DeepReadonlyArray<Api.JobType>
	findSupportWorkers: boolean
	featureJobFeedback: boolean
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
	onViewDetail: (supportWorkerRef: DeepReadonly<string>) => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	const features = featuresSelector(state)
	return {
		jobTypes: state.common.settings ? state.common.settings.jobTypes : undefined,
		findSupportWorkers: features.findSupportWorkers,
		featureJobFeedback: features.jobFeedback,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	onViewDetail: (supportWorkerRef) => {
		dispatch(supportWorkerActions.viewDetail(supportWorkerRef))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
