import * as Api from 'typescript-fetch-api'

import { fetchTimeout } from './timeout'

/** The API configuration. */
let configuration: Api.Configuration

interface ApiCollection {
	authApi: Api.AuthApi
	clientApi: Api.ClientApi
	cmsApi: Api.CmsApi
	settingsApi: Api.SettingsApi
	userApi: Api.UserApi
}

let apiCollection: ApiCollection

/** Our fetch API wrapper to provide additional functionality */
const myFetchAPI: Api.FetchAPI = function(url: string, init?: RequestInit): Promise<Response> {
	/* Apply a timeout to our requests. Note that the timeout doesn't cancel the request, it merely
	   throws an error so we are not left hanging.
	 */
	return fetchTimeout(url, init, 30000)
}

export function initApiConfiguration(params: Api.ConfigurationParameters) {
	configuration = new Api.Configuration(params)

	apiCollection = {
		authApi: new Api.AuthApi(configuration, undefined, myFetchAPI),
		clientApi: new Api.ClientApi(configuration, undefined, myFetchAPI),
		cmsApi: new Api.CmsApi(configuration, undefined, myFetchAPI),
		settingsApi: new Api.SettingsApi(configuration, undefined, myFetchAPI),
		userApi: new Api.UserApi(configuration, undefined, myFetchAPI),
	}
}

export function getConfiguration(): Api.Configuration {
	return configuration
}

/** A global API client. This API client should be used by the rest of the app to connect to the
 * API. It uses the global configuration, so it can pick up the required access token.
 */
export default function getApi(): ApiCollection {
	return apiCollection
}
