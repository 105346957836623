import actionCreatorFactory from 'typescript-fsa'
import { Api } from 'typescript-fetch-api'
import { Document } from './types'

/**
 * The action creator for this module. Note that it contains the module name.
 */
const actionCreator = actionCreatorFactory('Documents')

/** Download specific document given a document ref */
interface LoadDocumentPayload {
	clientRef?: string
	documentRef: string
}
export const loadDocument = actionCreator<LoadDocumentPayload>('LOAD_DOCUMENT')
export const loadDocumentAsync = actionCreator.async<LoadDocumentPayload, Api.ClientDocumentResponse, Api.ClientDocumentError>('LOAD_DOCUMENT')
export type LoadDocumentAction = ReturnType<typeof loadDocument>

/** Download the clients list of documents */
export const getDocuments = actionCreator('GET_DOCUMENTS')
export const getDocumentsAsync = actionCreator.async<undefined, Api.ClientDocumentsResponse, Api.ClientDocumentsError>('GET_DOCUMENTS')

export interface SaveDocumentPayload {
	documentRef: string
	request: Document<object>
}
export const saveDocument = actionCreator<SaveDocumentPayload>('SAVE_DOCUMENT')
export const saveDocumentAsync = actionCreator.async<Api.ClientDocumentUpsertRequest, Api.ClientDocumentUpsertResponse, Api.ClientDocumentUpsertError>('SAVE_DOCUMENT')
export type SaveDocumentAction = ReturnType<typeof saveDocument>

export const downloadDocument = actionCreator<LoadDocumentPayload>('DOWNLOAD_DOCUMENT')
export const downloadDocumentAsync = actionCreator.async<LoadDocumentPayload, Api.ClientDocumentRequestDownloadResponse, Api.ClientDocumentError>('DOWNLOAD_DOCUMENT')
export type DownloadDocumentAction = ReturnType<typeof downloadDocument>
export type DownloadDocumentDoneAction = ReturnType<typeof downloadDocumentAsync.done>
