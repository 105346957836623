import { Api } from 'typescript-fetch-api'
import Component from '../components/PrintDocument'
import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'
import { RootStoreState } from 'modules/root'
import { loadDocument } from 'modules/documents/actions'
import { Document } from 'modules/documents/types'

export type ComponentProps = Props & Actions & OwnProps

interface OwnProps {
	clientRef: string
	documentRef: string
}

interface Props {
	document?: Document<object>
	languages?: Api.Language[]
	docExtended?: DeepReadonlyObject<Api.ClientDocument>
}

interface Actions {
	onLoadDocument: (documentRef: string) => void
	onSaveDocument: (document: Document<object>) => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	if (!state.documents.currentDocument || state.documents.currentDocument.ref !== ownProps.documentRef || !state.documents.currentDocumentData) {
		return {}
	}

	const languages: Api.Language[] = []
	languages.push({
		ref: '0',
		name: 'English',
	})
	if (state.common.settings) {
		state.common.settings.languages.forEach(language => {
			languages.push({
				ref: language.ref,
				name: language.name,
			})
		})
	}

	const document: Document<object> = {
		ref: state.documents.currentDocument.ref,
		name: state.documents.currentDocument.name,
		documentType: state.documents.currentDocument.documentType,
		// tslint:disable-next-line:no-any
		data: state.documents.currentDocumentData,
		unique: false,
	}

	return {
		document,
		languages,
		docExtended: state.documents.currentDocument,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: OwnProps): Actions => ({
	onLoadDocument: (documentRef) => {
		dispatch(loadDocument({
			clientRef: ownProps.clientRef,
			documentRef,
		}))
	},
	onSaveDocument: () => {
		// noop
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
