import * as React from 'react'
import { RouteComponentProps, Switch, Route } from 'react-router'
import { Props } from '../containers/SetupScreen'

import Header from 'modules/common/containers/Header'
import Footer from 'modules/common/components/Footer'
import Debug from 'modules/common/components/Debug'
import ClientDetails from '../containers/ClientDetails'
import RegisterForCare from '../containers/RegisterForCare'
import JoinSupportNetwork from '../containers/JoinSupportNetwork'

export default class SetupScreen extends React.Component<Props & RouteComponentProps<undefined>> {

	public render() {
		return (
			<div className="site-content register-screen">
				<Header section="register" />
				<article className="site-body">
					<div className="width-limit -site">
						<Switch>
							<Route path="/setup/client-details" component={ClientDetails} />
							<Route
								path={'/setup/register-new/care'}
								render={() => (
									<div className="form register-form -column">
										<RegisterForCare/>
									</div>
								)}
							/>
							<Route 
								path={'/setup/register-new/invites'}
								render={() => (
									<div className="form register-form  -column">
										<JoinSupportNetwork/>
									</div>
								)}
							/>
							<Route 
								path={'/'}
								render={() => (
									<div className={'form register-form' + (this.props.hasInvites ? ' -split' : ' -column')}>
										<RegisterForCare/>
										<JoinSupportNetwork/>
									</div>
								)}
							/>
						</Switch>
					</div>
				</article>
				<Footer />
				<Debug params={this.props.location.search}/>
			</div>
		)
	}
}
