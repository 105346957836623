import * as React from 'react'
import { RouteComponentProps, Switch, Route } from 'react-router'
import { LocalDate } from '@js-joda/core'
import Calendar from '../containers/Calendar'
import Header from 'modules/common/containers/Header'
import Footer from 'modules/common/components/Footer'
import { Helmet } from 'react-helmet'
import EditBookingScreen from 'modules/booking/containers/EditBookingScreen'

export default class CalendarScreen extends React.Component<RouteComponentProps<undefined>> {

	public render() {
		return (
			<div className="site-content calendar-screen">
				<Header section="calendar" />
				<Helmet>
					<title>Calendar — My Homecare</title>
				</Helmet>
				<Switch>
					<Route 
						path={`${this.props.match.url}/edit`}
						render={props => (
							<EditBookingScreen />
						)}
					/>
					<Route
						path={`${this.props.match.url}/date/:date`}
						render={props => (
							<Calendar date={LocalDate.parse(props.match.params.date)} />
						)}
					/>
					<Route 
						path={`${this.props.match.url}`}
						render={props => (
							<Calendar date={LocalDate.now()} />
						)}
					/>
				</Switch>
				<Footer />
			</div>
		)
	}
}
