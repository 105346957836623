/**
 * Container template for a component.
 */

/* Import the component from the component path */
import Component from '../components/ClientNextOfKinDetails'
import { Api } from 'typescript-fetch-api'

import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'
import * as a from '../actions'
import * as s from '../selectors'

/* Import module actions */
// import * as a from '../actions'

/** Interface for properties that are passed to the container/ */
export interface OwnProps {

}

/** Interface for properties that the container passes to the component. */
export interface Props {
	client?: DeepReadonly<Api.Client>
	nextOfKin?: DeepReadonly<Api.NextOfKin>
	settings?: DeepReadonly<Api.Settings>
	saving: boolean
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
	onSave: (nextOfKin: Api.NextOfKin, clientRef: string) => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	const client = s.currentClientSelector(state)
	const nextOfKin = client && client.nextOfKin
	const settings = state.common.settings
	return {
		nextOfKin,
		client,
		settings,
		saving: state.account.saving,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: OwnProps): Actions => ({
	onSave: (nextOfKin: Api.NextOfKinRequest, clientRef: string) => {

		const request: Api.UpdateClientDetailsRequest = {
			nextOfKin,
		}

		const payload: a.UpdateClientDetailsActionPayload = {
			clientRef,
			request,
		}
		dispatch(a.updateClientDetails(payload))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
