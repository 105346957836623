import Component from '../components/RecentVisits'

import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'

/* Import module actions */
import * as actions from '../actions'
import * as t from '../types'
import { currentClientSelector, clientIsYou } from 'modules/account/selectors'
import { featuresSelector } from 'modules/common/selectors'

/** Interface for properties that are passed to the container/ */
export interface OwnProps {
	recentJobs?: DeepReadonly<t.RecentDay[]>
}

/** Interface for properties that the container passes to the component. */
export interface Props {
	displayName?: string
	you: boolean
	featureJobFeedback: boolean
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
	addAppointmentFeedback: (appointmentRef: string, feedback: string) => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	const currentClient = currentClientSelector(state)
	const you = currentClient ? clientIsYou(state, currentClient.ref) : false
	const features = featuresSelector(state)

	return {
		displayName: currentClient ? (currentClient.knownAs ? currentClient.knownAs : currentClient.givenName) : undefined,
		you,
		featureJobFeedback: features.jobFeedback,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	addAppointmentFeedback: (appointmentRef: string, feedback: string) => {
		dispatch(actions.addAppointmentFeedback({ appointment: appointmentRef, request: { feedback: feedback } }))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
