import actionCreatorFactory, { Action, Success } from 'typescript-fsa'
import { Api } from 'typescript-fetch-api'
import * as t from './types'

const actionCreator = actionCreatorFactory('Register')

export const invalidToken = actionCreator('INVALID_TOKEN')

export const updateYourDetailsStore = actionCreator<t.YourDetails>('UPDATE_YOUR_DETAILS')

export const updateEmailStore = actionCreator<string>('UPDATE_YOUR_EMAIL')

export const prepareRegistration = actionCreator<Api.PrepareRegistrationRequest>('PREPARE_REGISTRATION')
export const prepareRegistrationAsync = actionCreator.async<Api.PrepareRegistrationRequest, never, Api.PrepareRegistrationError>('PREPARE_REGISTRATION')
export type PrepareRegistrationAction = ReturnType<typeof prepareRegistration>

export const completeRegistration = actionCreator<Api.CompleteRegistrationRequest>('COMPLETE_REGISTRATION')
export const completeRegistrationAsync = actionCreator.async<Api.CompleteRegistrationRequest, never, Api.CompleteRegistrationError>('COMPLETE_REGISTRATION')
export type CompleteRegistrationAction = ReturnType<typeof completeRegistration>

export const checkRegistrationProcess = actionCreator<Api.CheckRegistrationProgressRequest>('CHECK_REGISTRATION_PROCESS')
export type CheckRegistrationProgressAction = ReturnType<typeof checkRegistrationProcess>
export type CheckRegistrationProgressDoneAction = Action<Success<Api.CheckRegistrationProgressRequest, Api.CheckRegistrationProgressResponse>>
export const checkRegistrationProcessAsync = actionCreator.async<Api.CheckRegistrationProgressRequest, Api.CheckRegistrationProgressResponse, Api.CheckRegistrationProgressError>('CHECK_REGISTRATION_PROCESS')