import * as React from 'react'

export interface Props {
	editingMode?: boolean
}

export default class SignatureForm extends React.Component<Props> {
	public render() {
		const { editingMode } = this.props

		return (
			<div className={'form-item -signature-form -background' + (editingMode ? '' : ' -valuemode')}>
				{
					editingMode ? (
						<h2 className="section-header">Download, print out and sign this form to complete your Advanced Care Plan</h2>
					) : (
						<>
							<h2 className="section-header">For signature</h2>
							<div className="form-layout">
								<div className="signature-acknowledgements">
									<p><span className="order">1.</span>I understand this is a record of my preferences to guide my healthcare team in providing appropriate care for me.</p>
									<p><span className="order">2.</span>I understand that it will only be used when I am unable to make decisions for myself.</p>
									<p><span className="order">3.</span>I understand that medically futile and/or inappropriate treatments will not be administered even if this is my expressed preference.</p>
									<p><span className="order">4.</span>I acknowledge that this record may be held in an electronic form and will be made available to other health care providers for purposes of treating me.</p>
								</div>
								<div className="row -split signature-row">
									<div className="form-field">
										<label className="label">Signed</label>
										<div className="empty-input"/>
									</div>
									<div className="form-field date-field">
										<label className="label">Date</label>
										<div className="empty-input"/>
									</div>
								</div>
								<h2 className="section-header">Witness (health professional)</h2>
								<div className="row -split signature-row">
									<div className="form-field">
										<label className="label">Signed</label>
										<div className="empty-input"/>
									</div>
									<div className="form-field date-field">
										<label className="label">Date</label>
										<div className="empty-input"/>
									</div>
								</div>
								<div className="row -split -auto">
									<div className="form-field">
										<label className="label">Salutation</label>
										<div className="empty-input"/>
									</div>
									<div className="form-field">
										<label className="label">Given name(s)</label>
										<div className="empty-input"/>
									</div>
									<div className="form-field">
										<label className="label">Family name</label>
										<div className="empty-input"/>
									</div>
								</div>
							</div>
						</>
					)
				}
			</div>
		)
	}
}