import Component from '../components/Timesheet'
import { Api } from 'typescript-fetch-api'
import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'
import * as ct from 'modules/calendar/types'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'

/* Import module actions */
// import * as a from '../actions'
import * as s from '../selectors'
import * as t from '../types'
import * as bt from 'modules/booking/types'
import { completeTimesheetAction, recallTimesheetAction } from 'modules/timesheet/actions'

interface OwnProps {
	timesheetRef?: string
}

/**
 * Interface for properties that the container passes to the component.
 */
export interface Props {
	timesheet?: DeepReadonlyObject<Api.Timesheet>
	prevTimesheetRef?: string
	nextTimesheetRef?: string
	timesheetAppointments?: ct.WeekView
	hoursSummary?: t.TimesheetHoursSummary
	submitting: boolean
	recalling: boolean
	bookingToEdit?: DeepReadonly<bt.EditBooking>
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
	onSubmitTimesheet: (timesheetRef: string) => void
	onRecallTimesheet: (timesheetRef: string) => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	let timesheetRef: string | undefined
	if (ownProps.timesheetRef) {
		timesheetRef = ownProps.timesheetRef
	} else if (s.currentWeekTimesheetRefSelector(state)) {
		timesheetRef = s.currentWeekTimesheetRefSelector(state)
	}

	return {
		prevTimesheetRef: timesheetRef ? s.previousTimesheetRefSelector(state, timesheetRef) : undefined,
		nextTimesheetRef: timesheetRef ? s.nextTimesheetRefSelector(state, timesheetRef) : undefined,
		timesheet: timesheetRef ? s.timesheetSelector(state, timesheetRef) : undefined,
		timesheetAppointments: timesheetRef ? s.weeklyTimesheetAppointmentsSelector(state, timesheetRef) : undefined,
		hoursSummary: timesheetRef ? s.timesheetHoursSummary(state, timesheetRef) : undefined,
		submitting: state.timesheet.submitting,
		recalling: state.timesheet.recalling,
		bookingToEdit: state.booking.editBooking,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: OwnProps): Actions => ({
	onSubmitTimesheet: (timesheetRef) => {
		dispatch(completeTimesheetAction(timesheetRef))
	},
	onRecallTimesheet: (timesheetRef) => {
		dispatch(recallTimesheetAction(timesheetRef))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
