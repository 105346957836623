import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { Api } from 'typescript-fetch-api'

/* Import our module's actions */
import * as a from './actions'
import { tweakJobType } from './functions'

export type StoreState = DeepReadonly<MutableStoreState>

export interface MutableStoreState {
	settings?: Api.Settings
}

/**
 * The initial store state for this module.
 */
const INITIAL_STATE: MutableStoreState = {
}

function tweakSettings(settings: Api.Settings): Api.Settings {
	settings.jobTypes = settings.jobTypes.map(tweakJobType)
	settings.privateJobTypes = settings.privateJobTypes.map(tweakJobType)
	return settings
}

/**
 * Reducer function for this module.
 */
export const reducer = reducerWithInitialState(INITIAL_STATE)

reducer.case(a.loadSettingsAsync.done, (state, payload): MutableStoreState => {
	const result = {
		...state,
		settings: tweakSettings(payload.result.payload),
	}
	return result
})