import Component from '../components/Week'
import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'
import { Api } from 'typescript-fetch-api'
// import * as t from '../types'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'
import { calendarView } from 'modules/calendar/functions'
import { LocalDate } from '@js-joda/core'

/* Import module actions */
import * as a from '../actions'
import * as ct from 'modules/calendar/types'

/** Interface for properties that are passed to the container/ */
export interface OwnProps {
	appointments: ct.WeekView
	timesheet?: DeepReadonlyObject<Api.Timesheet>
	prevTimesheetRef?: string
	nextTimesheetRef?: string
}

/** Interface for properties that the container passes to the component. */
export interface Props {
	calendar: ct.CalendarView
	loading: boolean
}

export interface Actions {
	startTimesheetBookingFromDate: () => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	const now = LocalDate.now()
	const calendar = calendarView(now, state)
	const loading = state.dashboard.loadingCalendar
	return {
		calendar: calendar,
		loading,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: OwnProps): Actions => ({
	startTimesheetBookingFromDate: () => {
		dispatch(a.startTimesheetBookingFromDate({
			date: ownProps.timesheet ? LocalDate.parse(ownProps.timesheet.startDate) : LocalDate.now(),
			timesheetRef: ownProps.timesheet ? ownProps.timesheet.ref : undefined,
		}))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
