import actionCreatorFactory from 'typescript-fsa'
import { Api } from 'typescript-fetch-api'
import { LocalDate } from '@js-joda/core'
import * as bt from 'modules/booking/types'

/**
 * The action creator for this module. Note that it contains the module name.
 */
const actionCreator = actionCreatorFactory('Timesheet')

export const editBooking = actionCreator<bt.EditBooking>('EDIT_BOOKING')
export type EditBookingAction = ReturnType<typeof editBooking>

export const completeTimesheetAction = actionCreator<string>('COMPLETE_TIMESHEET')
export const completingTimesheetAction = actionCreator.async<string, Api.CompleteTimesheetResponse, Api.CompleteTimesheetError>('COMPLETING_TIMESHEET')
export type CompleteTimesheetActionType = ReturnType<typeof completeTimesheetAction>

export const recallTimesheetAction = actionCreator<string>('RECALL_TIMESHEET')
export const recallingTimesheetAction = actionCreator.async<string, Api.RecallTimesheetResponse, Api.RecallTimesheetError>('RECALL_TIMESHEET')
export type RecallTimesheetActionType = ReturnType<typeof recallTimesheetAction>

export interface NewTimesheetBookingPayload {
	date: LocalDate
	timesheetRef?: string
}

export const startTimesheetBookingFromDate = actionCreator<NewTimesheetBookingPayload>('START_BOOKING_FROM_DATE')

export type StartTimesheetBookingFromDateType = ReturnType<typeof startTimesheetBookingFromDate>
