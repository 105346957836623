import { SagaIterator } from 'redux-saga'
import { takeEvery, take, put, call, select } from 'redux-saga/effects'
import { Api } from 'typescript-fetch-api'
import { callApiWithActions, handleApiFailedAction } from 'modules/api/functions'
import getApi from 'modules/api'
import * as a from './actions'
import * as authActions from '../auth/actions'
import * as authTypes from '../auth/types'
import * as s from 'modules/common/selectors'
import { push } from 'connected-react-router'
import { loadedAccount } from 'modules/account/actions'
import { loggedInSelector } from 'modules/auth/selectors'
import { readyAction } from 'modules/root/actions'
import { history } from 'modules/routing'

function* invalidToken(): SagaIterator {
	yield put(push('/register/start-registration', { registrationError: 'invalid' }))
}

function* prepareRegistration(action: a.PrepareRegistrationAction): SagaIterator {
	yield call(callApiWithActions, action.payload, a.prepareRegistrationAsync, (options: RequestInit) => {
		return getApi().userApi.postPrepareRegistration(action.payload, options)
	})
}

function* prepareRegistrationDone(): SagaIterator {
	yield put(push('/register/email-submitted'))
}

function* prepareRegistrationFailed(): SagaIterator {
	yield put(push('/register/start-registration', { registrationError: 'failed' }))
}

function* checkRegistrationProcess(action: a.CheckRegistrationProgressAction): SagaIterator {
	const loggedIn = (yield select(loggedInSelector)) as boolean
	if (loggedIn) {
		yield put(authActions.logoutRequest())
	}
	yield call(callApiWithActions, action.payload, a.checkRegistrationProcessAsync, (options: RequestInit) => {
		return getApi().userApi.postCheckRegistrationProgress(action.payload, options)
	})
}

function* checkRegistrationProcessDone(action: a.CheckRegistrationProgressDoneAction): SagaIterator {
	const code = action.payload.result.code
	switch (code) {
		case Api.CheckRegistrationProgressResponse.CodeEnum.RegistrationCompleted:
			yield put(push('/sign-in', { registration: 'completed' }))
			break
		case Api.CheckRegistrationProgressResponse.CodeEnum.RegistrationNotCompleted:
			if (action.payload.result.email) {
				yield put(a.updateEmailStore(action.payload.result.email))
			}
			yield put(push('/register/set-password'))
			break
		default:
			throw new Error('Unsupported Api.CheckRegistrationProgressResponse.CodeEnum: ' + code)
	}
}

function* checkRegistrationProcessFailed(): SagaIterator {
	yield put(push('/register/start-registration', { registrationError: 'invalid' }))
}

function* completeRegistration(action: a.CompleteRegistrationAction): SagaIterator {
	yield call(callApiWithActions, action.payload, a.completeRegistrationAsync, (options: RequestInit) => {
		return getApi().userApi.postCompleteRegistration(action.payload, options)
	})
}

function* completeRegistrationDone(): SagaIterator {
	const pw = (yield select(s.userPassword))
	const email = (yield select(s.userEmail))

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	if ((window as any).PasswordCredential) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const c: any = new (window as any).PasswordCredential({
			id: email,
			password: pw,
			name: 'My Homecare',
		})
		;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(navigator as any).credentials.store(c)
	}

	if (pw && email) {
		const loginRequest: authTypes.LoginRequest = {
			username: email,
			password: pw,
			rememberMe: false,
		}
		yield put(authActions.login.started(loginRequest))
	} else {
		yield put(push('/sign-in', { registration: 'completed' }))
	}
	yield take(loadedAccount)
	yield put(push('/'))
}

function* handleStartRegistration(): SagaIterator {
	if (history.location.pathname === '/register/welcome') {
		const token = new URLSearchParams(window.location.search).get('token')
		if (token) {
			yield put(a.checkRegistrationProcess({ token }))
		}
	}
}

export default function* (): SagaIterator {

	yield takeEvery(readyAction, handleStartRegistration)
	yield takeEvery(a.invalidToken, invalidToken)
	yield takeEvery(a.prepareRegistration, prepareRegistration)
	yield takeEvery(a.prepareRegistrationAsync.done, prepareRegistrationDone)
	yield takeEvery(a.prepareRegistrationAsync.failed, prepareRegistrationFailed)
	yield takeEvery(a.checkRegistrationProcess, checkRegistrationProcess)
	yield takeEvery(a.checkRegistrationProcessAsync.done, checkRegistrationProcessDone)
	yield takeEvery(a.checkRegistrationProcessAsync.failed, checkRegistrationProcessFailed)
	yield takeEvery(a.completeRegistration, completeRegistration)
	yield takeEvery(a.completeRegistrationAsync.done, completeRegistrationDone)
	yield takeEvery([a.completeRegistrationAsync.failed], handleApiFailedAction.bind(null, 'Oops! We were unable to complete your registration.'))
}