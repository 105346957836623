import Component from '../components/SupportWorkerSearch'
import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'
import { RootStoreState } from 'modules/root'

/* Import module actions */
import * as a from '../actions'
import * as f from '../functions'
import * as sws from 'modules/support-workers/selectors'

export enum TileMode {
	Book,
	Find
}

export interface Props {
	supportWorkerRefs?: DeepReadonlyArray<string>
	showMoreButton: boolean
	loading: boolean
	selectedSupportWorkerRefs?: DeepReadonly<string>[]
}

export interface OwnProps {
	searchMode: TileMode
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
	onLoadInitial: () => void
	onLoadMore: () => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState): Props => {
	return {
		showMoreButton: state.supportWorkers.showMoreButton,
		supportWorkerRefs: sws.clientSupportWorkerRelationshipRefsToSupportWorkerRefs(state, state.supportWorkers.searchResults && f.filterBlockedWorkers(state, state.supportWorkers.searchResults)),
		loading: state.supportWorkers.searching,
		selectedSupportWorkerRefs: sws.preferredSupportWorkerRefs(state, true),
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	onLoadInitial: () => {
		dispatch(a.searchInitial())
	},
	onLoadMore: () => {
		dispatch(a.searchNextPage())
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
