import { Middleware, ReducersMapObject, Reducer, Action } from 'redux'
import * as Api from 'typescript-fetch-api'

import { Config } from 'modules/auth/types'
import platformSupportImplementation from './impl'
import { RootStoreState } from 'modules/root'

export enum Environment {
	Live = 'live',
	Test = 'test',
	Development = 'dev',
}

export enum Brand {
	Default = 'default',
	Suemann = 'suemann',
	Hcnz = 'hcnz'
}

export interface PlatformSupport {
	customiseReduxMiddleware: (middlewares: Middleware[]) => Middleware[]

	customiseRootReducer: (reducer: Reducer<RootStoreState>) => Reducer<RootStoreState>

	customiseReducers: <S, A extends Action>(reducers: ReducersMapObject<S, A>) => ReducersMapObject<S, A>

	alert: (message: string) => void

	/** Display a message to the user, asking them to confirm the given message. */
	confirm: (message: string, title: string, confirmAction: string) => Promise<boolean>

	/** Create and return the API configuration parameters */
	createApiConfigurationParams: () => Api.ConfigurationParameters

	/** Create and return the authentication configuration */
	createAuthConfiguration: () => Config

	/** Return which environment the app is running in. */
	environment: () => Environment

	brand: () => Brand

	googleApiKey: () => string

	googlePlacesComponentRestrictions: () => google.maps.places.ComponentRestrictions
}

export default platformSupportImplementation
