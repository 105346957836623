import { RootStoreState } from 'modules/root'
import { LocalDate } from '@js-joda/core'
import * as t from './types'
import { Api } from 'typescript-fetch-api'

/** Returns the ClientRosterDay for the current day. */
export const currentDay = (state: RootStoreState) => {
	const now = LocalDate.now().toString()
	return state.calendar.roster.roster ? state.calendar.roster.roster.days.find(day => day.date === now) : undefined
}

export const fundersSelector = (state: RootStoreState) => state.calendar.refs.funders
export const funderSelector = (state: RootStoreState, funderRef: string) => state.calendar.refs.funders && state.calendar.refs.funders[funderRef]

export const fundingsSelector = (state: RootStoreState) => state.calendar.refs.funding

export const jobTypesSelector = (state: RootStoreState) => state.calendar.refs.jobTypes

export const dayFunding = (state: RootStoreState, day: DeepReadonly<Api.ClientCalendarDay>): t.Funding => {
	if (!day.fundingRefs || day.fundingRefs.length === 0) {
		return { hasFunding: false }
	}

	const jobTypes = jobTypesSelector(state)
	if (!jobTypes) {
		return { hasFunding: false }
	}
	
	let totalMinutes = 0
	const fundingJobTypes: t.FundingJobType[] = []

	day.fundingRefs.forEach(fundingRef => {
		const fundingSource = state.calendar.refs.funding[fundingRef]
		if (fundingSource && fundingSource.fundedTimeMins > 0) {
			totalMinutes += fundingSource.fundedTimeMins
			
			const jobType = jobTypes[fundingSource.jobTypeRef]
			if (!jobType) {
				return
			}

			const funderDetail = funderSelector(state, fundingSource.funderRef)

			fundingJobTypes.push({
				jobType: jobType,
				funderRef: fundingSource.funderRef,
				funderName: funderDetail ? funderDetail.name : undefined,
				minutes: fundingSource.fundedTimeMins,
				fundedTimeAllocatedThisWeekMins: fundingSource.fundedTimeAllocatedThisWeekMins,
			})
		}
	})

	if (fundingJobTypes.length === 0) {
		return { hasFunding: false }
	}

	return {
		hasFunding: true,
		minutes: totalMinutes,
		jobTypes: fundingJobTypes,
	}
}

/** Returns funding information for the current day. */
export const currentFunding = (state: RootStoreState) => {
	const day = currentDay(state)
	
	if (!day) {
		return undefined
	}

	return dayFunding(state, day)
}

export const supportWorkersSelector = (state: RootStoreState) => state.calendar.refs.supportWorkers
