import * as React from 'react'
import { OwnProps, Props, Actions } from '../containers/Welcome'
import Waiting from 'modules/common/components/Waiting'

export default class Welcome extends React.Component<OwnProps & Props & Actions> {

	public render() {
		return (
			<Waiting />
		)
	}
}
