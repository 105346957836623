import 'core-js/es/map'
import 'core-js/es/set'
import 'raf/polyfill'

import * as React from 'react'
import * as ReactDOM from 'react-dom'
import App from './App'
import ReactGA from 'react-ga'
import * as serviceWorker from './registerServiceWorker'

ReactDOM.render(
	<App />,
	document.getElementById('root') as HTMLElement,
)
serviceWorker.unregister()

ReactGA.initialize('UA-427375-16')
ReactGA.pageview(window.location.pathname + window.location.search)
