import * as React from 'react'
import { RouteComponentProps, Switch, Route, Redirect } from 'react-router'
import { Props } from '../containers/TimesheetScreen'
import Timesheet from '../containers/Timesheet'
import Header from 'modules/common/containers/Header'
import Footer from 'modules/common/components/Footer'
import { Helmet } from 'react-helmet'

export default class TimesheetScreen extends React.Component<Props & RouteComponentProps<undefined>> {

	public render() {
		return (
			<div className="site-content timesheet-screen">
				<Header section="timesheet" />
				<Helmet>
					<title>Timesheet — My Homecare</title>
				</Helmet>
				{
					this.props.canViewTimesheets ? (
						<Switch>
							<Route 
								path={`${this.props.match.url}/ref/:timesheetRef`}
								render={props => (
									<Timesheet timesheetRef={props.match.params.timesheetRef} {...props} />
								)}
							/>
							<Route 
								path={`${this.props.match.url}`}
								render={props => (
									<Timesheet {...props} />
								)}
							/>
						</Switch>
					) : (
						<Redirect to="/" />
					)
				}
				<Footer />
			</div>
		)
	}
}
