import * as React from 'react'
import { Props, Actions, OwnProps, TileMode } from '../containers/SupportWorkerSearch'
import SupportWorkerTile from '../containers/SupportWorkerTile'
import AllSupportWorkersSearch from '../containers/AllSupportWorkersSearch'
import Loading from 'modules/common/components/Loading'
import * as _ from 'lodash'

export default class AllSupportWorkers extends React.Component<Props & Actions & OwnProps> {

	componentDidMount() {
		if (!this.props.supportWorkerRefs) {
			this.props.onLoadInitial()
		}
	}

	public render() {
		return (
			<form className="supportworkers-form" action="#">
				<div className="form-layout -wide">
					<input type="checkbox" id="supportworker-filter-menustate" className="supportworker-filter-menustate js-supportworker-filter-menustate" hidden={true} />

					{
						this.props.searchMode === TileMode.Book && (
							<div className="introduction">
								<h1 className="headline-text">Support workers</h1>

								<div className="body-text">
									<p>
											The following support workers match your requirements and may be available in your date range. 
											Use the search filters to narrow the results, and if you have any preferences for specific workers 
											indicate these using the field provided.
									</p>
								</div>
							</div>
						)
					}
					
					<AllSupportWorkersSearch />

					{
						this.props.selectedSupportWorkerRefs && _.keys(this.props.selectedSupportWorkerRefs).length > 0 && this.props.searchMode === TileMode.Book && (
							<>
								<div className="row selected-supportworkers">
									<h2 className="title">Your selected support workers</h2>
									<div className="supportworker-list">
										{this.props.selectedSupportWorkerRefs.map((swRef, index) => (
											<SupportWorkerTile key={index} supportWorkerRef={swRef} showPreference={this.props.searchMode === TileMode.Book} />
										))}
									</div>
								</div>
							</>
						)
					}

					{
						this.props.loading && (
							<Loading/>
						)
					}
					
					{this.props.supportWorkerRefs && !this.props.loading && (
						<>
							<div className="row">
								<div className="results-count"> 
									{
										this.props.supportWorkerRefs.length === 0 ? (
											<>
												{
													this.props.searchMode === TileMode.Book ? (
														<p>We haven't found any support workers matching your search criteria right now. Please go ahead and complete this booking. We will contact you soon with options.</p>
													) : (
														<p>We didn’t find any support workers, but we can still help you so please get in touch.</p>
													)
												}
											</>
										) : (
											<p>We’ve found {this.props.supportWorkerRefs.length} support worker{this.props.supportWorkerRefs.length !== 1 ? 's' : ''} matching your search.</p>
										)
									}
									
								</div>
							</div>
							
							<div className="row">
								<div className="supportworker-list">
									{this.props.supportWorkerRefs.map((swRef, index) => (
										<SupportWorkerTile key={index} supportWorkerRef={swRef} showPreference={this.props.searchMode === TileMode.Book} />
									))}
								</div>
							</div>
							{
								this.props.showMoreButton && (
									<div className="row">
										<div className="button-group -centered">
											<div className="button -constrained">
												<a className="button-link -action" onClick={this.onClick} href="">Show more</a>
											</div>
										</div>
									</div>
								)
							}
							
						</>
					)}
				</div>
			</form>
		)
	}

	private onClick = (evt: React.MouseEvent) => {
		evt.preventDefault()
		this.props.onLoadMore()
	}
}
