import * as React from 'react'
import { AdvanceCarePlanFormProps } from './AdvanceCarePlanScreen'
import { forComponentProps, wrapComponent } from 'changeling'
import TextBox from 'modules/health-passport/components/TextBox'
import Radio from 'modules/health-passport/components/Radio'
import CheckBoxOptions from './CheckBoxOptions'

type MyComponentProps = AdvanceCarePlanFormProps

class AdvanceCarePlanStepTwo extends React.Component<MyComponentProps> {
	private changeling = forComponentProps(this)

	public render() {
		const { editingMode, value } = this.props

		return (
			<>
				<h2 className="section-header">If I can no longer tell you myself I want those who care for me to know:</h2>
				<TextBox 
					title="The following is important to me"
					subText="This can include your hopes and fears, practical matters (e.g. you like the TV on, you like to be outside), family concerns, spiritual care you would like, anything else you can think of"
					placeholder="Things that are important to me"
					editingMode={editingMode}
					controller={this.changeling}
					prop="importantThings"
				/>
				<TextBox 
					title="This is what makes life meaningful to me"
					subText="This may include values, people, pets, ways you would like those caring for you to look after your spiritual and emotional needs, and anything else you want"
					placeholder="What makes life meaningful to me"
					editingMode={editingMode}
					controller={this.changeling}
					prop="meaningfulThings"
				/>
				<TextBox 
					title="I would like my family and friends to know and remember these things"
					placeholder="Things for my family and friends to know and remember"
					editingMode={editingMode}
					controller={this.changeling}
					prop="thingsToRemember"
				/>

				<h2 className="section-header">When I am dying the following are important to me</h2>
				<CheckBoxOptions
					options={[
						{ optionValue: 'comfort', optionLabel: 'Keep me comfortable' },
						{ optionValue: 'remove-tubes', optionLabel: 'Take out tubes and lines that are not adding to my comfort' },
						{ optionValue: 'friends-family', optionLabel: 'Let my family and friends be with me' },
						{ optionValue: 'stop-medication', optionLabel: 'Stop medications that do not add to my comfort' },
						{ optionValue: 'spiritual-needs', optionLabel: 'Attend to my spiritual needs' },
					]}
					editingMode={editingMode}
					controller={this.changeling}
					prop="dyingImportantItems"
				/>
				<TextBox 
					title="Other"
					secondaryQuestion={true}
					editingMode={editingMode}
					controller={this.changeling}
					prop="dyingImportantItemsOther"
					hideOnEmpty={true}
				/>
				<h2 className="section-header">The place I die is important to me</h2>
				<Radio 
					editingMode={editingMode}
					secondaryOption="Yes"
					defaultOption="No"
					inlineOptions={true}
					prop="dyingPlaceImportant"
					controller={this.changeling}
				/>
				{
					value.dyingPlaceImportant &&
					<>
						{ editingMode && <p className="body-text -gutters">When I am dying I would like to be cared for</p> }
						<CheckBoxOptions
							options={[
								{ optionValue: 'home', optionLabel: 'At home, which for me is ', withAdditionalInfo: true, placeholder: 'e.g. My house' },
								{ optionValue: 'hospice', optionLabel: 'In Hospice ' },
								{ optionValue: 'hospital', optionLabel: 'In Hospital' },
							]}
							editingMode={editingMode}
							controller={this.changeling}
							title="When I am dying I would like to be cared for"
							prop="dyingPlaceImportantOptions"
						/>

						<TextBox 
							title="Other"
							secondaryQuestion={true}
							editingMode={editingMode}
							controller={this.changeling}
							prop="dyingPlaceImportantOther"
						/>
					</>
				}
				<h2 className="section-header">Please care for my body by ensuring the following</h2>
				<Radio 
					editingMode={editingMode}
					title="I would like to be "
					secondaryOption="Buried"
					defaultOption="Cremated"
					inlineOptions={true}
					prop="burialPreferred"
					controller={this.changeling}
				/>
				<TextBox 
					title="I would like the following as my end of life ceremony or funeral"
					secondaryQuestion={true}
					editingMode={editingMode}
					controller={this.changeling}
					prop="funeralDetails"
				/>
				<h2 className="section-header">I would like to donate my organs and/or tissues for transplantation</h2>
				<Radio 
					editingMode={editingMode}
					secondaryOption="Yes"
					defaultOption="No"
					inlineOptions={true}
					prop="organDonation"
					controller={this.changeling}
				/>
				<TextBox 
					title="Other comments"
					secondaryQuestion={true}
					editingMode={editingMode}
					controller={this.changeling}
					prop="organDonationComments"
					hideOnEmpty={true}
				/>
			</>
		)
	}
}

export default wrapComponent(AdvanceCarePlanStepTwo)
