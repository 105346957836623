import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { Props } from '../containers/OlderPersonsLinks'

import { Helmet } from 'react-helmet'

export default class OlderPersonsLinks extends React.Component<Props & RouteComponentProps<undefined>> {

	public render() {
		return (
			<section className="body">
				<Helmet>
					<title>Older Persons Links — My Homecare</title>
				</Helmet>
								
				<h1 className="headline-text">Older Persons Links</h1>
				
				<div className="body-text">
					<h2><a href="https://www.ageconcern.org.nz/" target="_blank" rel="noopener noreferrer">Age Concern</a></h2>
					<p>Age Concern is a charitable organisation dedicated solely to people over 65. They promote dignity, wellbeing, equity and respect and provide expert information and support services in response to older people's needs. They are active and vocal on relevant issues and work to ensure older people stay connected with their family, friends and community</p>
					<p>Age Concern has many <a href="https://www.ageconcern.org.nz/ACNZPublic/Local_Age_Concerns/ACNZ_Public/AroundNZ.aspx" target="_blank" rel="noopener noreferrer">links to local organisations</a>.</p>

					<h2><a href="http://www.agewell.org.nz/" target="_blank" rel="noopener noreferrer">Age Well</a></h2>
					<p>Age Concern promotes this website to support health topics of interest to older persons. To age positively means welcoming the opportunities of older age, while acknowledging the realities. It requires the resilience and courage to accept change in oneself and in the world around us.</p>

					<h2><a href="https://www.probussouthpacific.org/" target="_blank" rel="noopener noreferrer">Probus</a></h2>
					<p>Probus is a club that is all about Friendship, Fellowship and Fun in retirement. Probus provides you with the opportunity to join together in clubs and to progress healthy minds and active bodies through social interaction and activities with retirees in your community. Probus opens the door to new experiences and friendships, you can hear wonderful guest speakers, stay active by participating in a wide range of activities and explore your community, your country or the world.</p>

					<h2><a href="https://www.supergold.govt.nz/" target="_blank" rel="noopener noreferrer">Supergold Card</a></h2>
					<p>The SuperGold Card is a discounts and concessions card for seniors and veterans, in recognition of their contribution to New Zealand society. With the SuperGold Card you can get:</p>
					<ul>
						<li>discounts and offers from a range of businesses</li>
						<li>government concessions (including free off-peak public transport)</li>
						<li>discounted services from your local council</li>
					</ul>
					<p>There are over 8,900 SuperGold businesses and more than 13,700 outlets nationwide.</p>

					<h2><a href="https://www.dementia.nz/" target="_blank" rel="noopener noreferrer">Dementia NZ</a></h2>
					<p>Dementia New Zealand recognises dementia as a syndrome with a wide range of disabling symptoms. We believe anyone with dementia symptoms has a right to be assisted, on their own terms, to live well and with as much independence as possible. We believe that by supporting those who support people living at home with dementia, wellbeing can be enhanced for all. We recognise that living well at home is what most people prefer.</p>

					<h2><a href="https://www.dementia.nz/" target="_blank" rel="noopener noreferrer">Elderly Assist</a></h2>
					<p>They understand that moving home can be stressful – but you do not have to go it alone. At Elderly Assist they take the stress out of moving by helping you downsize, declutter, pack and relocate.</p>

					<h2><a href="https://www.eldernet.co.nz/Home" target="_blank" rel="noopener noreferrer">Eldernet</a></h2>
					<p>Eldernet was established in 1997 as a direct result of the founders seeing the need for a comprehensive information service that focussed on issues concerning older people in New Zealand. The internet seemed to be the perfect way to make this information available and so, Eldernet was born. With this impartial information,  older people, their families’ and professionals who work in this sector access to comprehensive information that enables them to make more informed decisions.</p>

					<h2><a href="https://lgbtihealth.org.au/introducing-silver-rainbow/" target="_blank" rel="noopener noreferrer">Silver Rainbow</a></h2>
					<p>Silver Rainbow is the new name given to the National LGBTI Health Alliance Ageing and Aged Care Projects. Silver Rainbow began in New Zealand as an informal collective of professional people with a skill base in ageing and aged care who created the resources concept and brand known as Silver Rainbow.</p>

					<h2><a href="https://www.health.govt.nz/your-health/services-and-support/health-care-services/services-older-people/useful-websites-services-older-people" target="_blank" rel="noopener noreferrer">Ministry of Health</a></h2>
					<p>The following is a collection of useful links to websites provided by the Ministry of Health for older persons.</p>
					<p><a href="http://www.alzheimers.org.nz/" target="_blank" rel="noopener noreferrer"><strong>Alzheimers NZ</strong></a> is a support and advocacy organisation for people with dementia, their carers, family, whānau and community.</p>
					<p><a href="http://www.carersair.net.nz/" target="_blank" rel="noopener noreferrer"><strong>Carers New Zealand</strong></a> is a national information centre for family carers who support frail aged family members or friends.</p>
					<p><a href="http://www.cab.org.nz/Pages/home.aspx" target="_blank" rel="noopener noreferrer"><strong>Citizens Advice Bureau</strong></a> provides information on a wide range of topics as well as specialist consumer advocacy services, and free legal and budget advice.</p>
					<p><a href="http://www.community.net.nz/" target="_blank" rel="noopener noreferrer"><strong>CommunityNet Aotearoa</strong></a> is an information sharing resource for NZ community and voluntary groups, providing guides and news of local clubs, groups, charities, trusts, hapū and iwi.</p>
					<p><a href="http://www.eldernet.co.nz/" target="_blank" rel="noopener noreferrer"><strong>Eldernet New Zealand</strong></a> provide comprehensive, up-to-date information about services available for older people (eg. home help services, respite care, residential care, dementia care and community groups).</p>
					<p><a href="https://greypower.co.nz/" target="_blank" rel="noopener noreferrer"><strong>Greypower NZ</strong></a> is an association for all New Zealanders aged 50 years and over. It acts as a public voice to represent the views and issues of its members.</p>
					<p><a href="http://www.livestronger.org.nz/" target="_blank" rel="noopener noreferrer"><strong>Live stronger for longer</strong></a> provides information for older people and their carers about preventing falls and fractures. (ACC, MoH, Health Quality &amp; Safety Commission)</p>
					<p><a href="https://www.msd.govt.nz/what-we-can-do/seniorcitizens/" target="_blank" rel="noopener noreferrer"><strong>MSD Seniors Website</strong></a> provides older people with information relating to superannuation, entitlements, work and income, financial means assessment and gold cards.</p>
					<p><a href="http://www.nznasca.co.nz/" target="_blank" rel="noopener noreferrer"><strong>Needs Assessment and Service Coordination Association</strong></a> provides contact details if you need to organise a needs assessment.</p>
					<p><a href="http://www.hcha.org.nz/" target="_blank" rel="noopener noreferrer"><strong>New Zealand Home &amp; Community Health Association</strong></a> provides information on available home health care services.</p>
					<p><a href="http://www.adhb.govt.nz/SeniorLine/" target="_blank" rel="noopener noreferrer"><strong>Residential Care Line</strong></a> provides information for older people, their family/whānau, or carers to make decisions about staying at home, retirement villages, homecare, day care and rest homes.</p>
					<p><a href="http://www.sorted.org.nz/" target="_blank" rel="noopener noreferrer"><strong>Sorted</strong></a> provides information on managing your money and moving into a rest home or retirement village.</p>
					<p><a href="https://www.sorted.org.nz/life-events/living-retirement" target="_blank" rel="noopener noreferrer"><strong>Living in retirement</strong></a> provides you with information in your semi-retired/retired years and how best to manage your nest egg.</p>
					<p><a href="http://www.adhb.govt.nz/SeniorLine/" target="_blank" rel="noopener noreferrer"><strong>Seniorline</strong></a> is a national service that aims to help older people who are considering rest home or long stay hospital care, or who are already in care.</p>
					<p><a href="https://www.supergold.govt.nz/" target="_blank" rel="noopener noreferrer"><strong>SuperGold Card</strong></a> is a discounts and concessions card issued free to all eligible seniors and veterans.</p>
				</div>
				
			</section>
		)
	}
}
