/* Import the component from the component path */
import Component from '../components/SupportWorker'

import { Api } from 'typescript-fetch-api'

import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'

/* Import module actions */
import * as a from '../actions'
import * as s from '../selectors'
import * as ba from 'modules/booking/actions'
import { featuresSelector } from 'modules/common/selectors'

export interface OwnProps {
	supportWorkerRef: string
}

/**
 * Interface for properties that the container passes to the component.
 */
export interface Props {
	relationship?: DeepReadonly<Api.ClientSupportWorkerRelationship>
	supportWorker?: DeepReadonly<Api.SupportWorker>
	skills?: DeepReadonly<Api.Skill[]>
	languages?: DeepReadonly<Api.Language[]>
	prevAppointment?: DeepReadonly<Api.AppointmentSummary>
	nextAppointment?: DeepReadonly<Api.AppointmentSummary>
	featureFeedback: boolean
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
	onMakeFavourite: () => void
	onMakeHidden: () => void
	onSendFeedback: (feedback: a.AddFeedbackPayload) => void
	onBookSupportWorker: (supportWorkerRef: string) => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	const supportWorker = s.supportWorkerForRef(state, ownProps.supportWorkerRef)
	const relationship = s.clientSupportWorkerRelationshipForSupportWorkerRef(state, ownProps.supportWorkerRef)
	const skills = supportWorker && supportWorker.skillRefs.map((skill) => {
		return s.skillByRef(state, skill)!
	})
	const languages: Api.Language[] = []
	languages.push({
		ref: '0',
		name: 'English',
	})
	if (supportWorker) {
		supportWorker.languageRefs.forEach((language) => {
			languages.push(s.languageByRef(state, language)!)
		})
	}

	const prevAppointment = relationship && relationship.previousAppointmentRef ? s.appointmentByRef(state, relationship.previousAppointmentRef) : undefined
	const nextAppointment = relationship && relationship.nextAppointmentRef ? s.appointmentByRef(state, relationship.nextAppointmentRef) : undefined
	return {
		relationship,
		supportWorker,
		skills,
		languages,
		prevAppointment,
		nextAppointment,
		featureFeedback: featuresSelector(state).supportWorkerFeedback,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: OwnProps): Actions => ({
	onMakeFavourite: () => {
		dispatch(a.createFavourite(ownProps.supportWorkerRef))
	},
	onMakeHidden: () => {
		dispatch(a.createBlock(ownProps.supportWorkerRef))
	},
	onSendFeedback: (feedback) => {
		dispatch(a.addFeedback(feedback))
	},
	onBookSupportWorker: (supportWorkerRef: string) => {
		dispatch(ba.bookSupportWorker(supportWorkerRef))
	},

})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
