import { SagaIterator } from 'redux-saga'
import { takeEvery, take, call, put } from 'redux-saga/effects'
import { callApiWithActions, handleApiFailedAction, handleApiSuccessAction } from 'modules/api/functions'
import getApi from 'modules/api'
import * as a from './actions'
import * as accountActions from 'modules/account/actions'
import { push } from 'connected-react-router'
import { loadedAccount } from 'modules/account/actions'

let currentUserClientsAsyncVersion = 10

function* registerNewClient(action: a.RegisterNewClientAction): SagaIterator {
	yield call(callApiWithActions, action.payload, a.registerNewClientAsync, (options: RequestInit) => {
		return getApi().clientApi.postClient(action.payload, options)
	})
}

function* registerNewClientDone(action: a.RegisterNewClientAsyncDoneAction): SagaIterator {
	/* The account will load, and then we go to the dashboard */
	yield take(loadedAccount)
	yield put(accountActions.chooseClient({ clientRef: action.payload.result.payload.ref, interactive: false }))
	yield put(push('/'))
}

function* acceptSupportNetworkInvite(action: a.AcceptSupportNetworkInviteAction): SagaIterator {
	yield call(callApiWithActions, action.payload, a.acceptSupportNetworkInviteAsync, (options: RequestInit) => {
		return getApi().clientApi.postToUserClientSupportNetwork(action.payload, 'me', options)
	})
}

function* acceptSupportNetworkInviteDone(action: a.AcceptSupportNetworkInviteDoneAction): SagaIterator {
	yield call(callApiWithActions, currentUserClientsAsyncVersion++, accountActions.currentUserClientsAsync, (options: RequestInit) => {
		return getApi().userApi.getUserSupportNetworkMemberships('me', options)
	})
	yield put(accountActions.chooseClient({ clientRef: action.payload.params, interactive: false }))
	yield put(push('/'))
}

function* handleStartRegisterClient(): SagaIterator {
	yield put(push('/setup/client-details'))
}

export default function* (): SagaIterator {
	yield takeEvery(a.registerNewClient, registerNewClient)
	yield takeEvery(a.registerNewClientAsync.failed, handleApiFailedAction.bind(null, 'Oops, we were unable to save your details.'))
	yield takeEvery(a.registerNewClientAsync.done, registerNewClientDone)
	yield takeEvery(a.acceptSupportNetworkInvite, acceptSupportNetworkInvite)
	yield takeEvery(a.acceptSupportNetworkInviteAsync.done, acceptSupportNetworkInviteDone)
	yield takeEvery([a.acceptSupportNetworkInviteAsync.done], handleApiSuccessAction.bind(null, 'Success! We\'ve updated your support networks.'))
	yield takeEvery([a.acceptSupportNetworkInviteAsync.failed], handleApiFailedAction.bind(null, 'Oops! We were unable to process your support network update.'))
	yield takeEvery(a.startRegisterClient, handleStartRegisterClient)
}
