import * as React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { ComponentProps } from '../containers/HealthPassportIndex'
import Waiting from 'modules/common/components/Waiting'
import Moment from 'react-moment'

import HdcLogo from 'modules/frontend/img/health-passport/hdc-logo.svg'

export default class HealthPassport extends React.Component<ComponentProps> {

	public render() {

		return (
			<div className="body document-index document-types">
				<Helmet>
					<title>e-Health Passport — My Homecare</title>
				</Helmet>
				<div className="logo-wrapper">
					<h1 className="headline-text">e-Health Passport</h1>
					<img src={HdcLogo} />
				</div>
				<h2 className="section-header">What is an e-Health Passport?</h2>
				<p className="body-text">An e-Health Passport is a booklet, sourced from the Health and Disability Commissioner website, that you can carry with you when attending hospitals or other providers of health and disability services. Your Passport contains information that you want people to know about how to support and communicate with you. It helps health professionals make appropriate and safe decisions about your care. The Health and Disability Commissioner (HDC) is working with District Health Boards throughout New Zealand to introduce the Health Passport initiative into our hospitals.</p>
				<p className="body-text"><a href="https://www.hdc.org.nz/disability/my-health-passport/">Read more on the HDCs website&hellip;</a></p>
				<p className="body-text -publication">Published: 01 July 2019</p>
				
				<h2 className="section-header">Your e-Health Passport</h2>
				{this.props.loading ? (
					<Waiting />
				) : this.props.failed ? (
					<p className="body-text">Your documents could not be loaded.</p>
				) : (
					<div className="button-group -leftaligned">
						<div className="button">
							{this.props.document ? (
								<>
									<Link to="/health-passport/personal-details" className="button-link -action">Update passport</Link>
									<div className="last-updated"><p>Updated <Moment date={this.props.document.whenUpdated} fromNow={true} /></p></div>
								</>
							) : (
								<Link to="/health-passport/personal-details" className="button-link -action">Start passport</Link>
							)}
						</div>
						{this.props.document && this.props.clientRef && (
							<div className="button">
								<p className="download"><a href={`/app/print/client/${this.props.clientRef}/document/${this.props.document.ref}`} onClick={this.onDownload}>Download</a></p>
							</div>
						)}
					</div>
				)}
			</div>
		)
	}

	private onDownload = (evt: React.MouseEvent) => {
		evt.preventDefault()

		if (this.props.document) {
			this.props.onDownloadDocument(this.props.document.ref)
		}
	}
}
