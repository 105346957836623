/**
 * Store state selectors for the authentication module.
 */

import { RootStoreState } from 'modules/root'

/**
 * 
 * @param state Select the current auth access token from the root store state.
 */
export const accessTokenSelector = (state: RootStoreState) => state.auth.accessToken

export const refreshFailedAtSelector = (state: RootStoreState) => state.auth.refreshFailedAt

/** Selects a boolean, whether the user is logged in or not. */
export const loggedInSelector = (state: RootStoreState) => state.auth.accessToken !== undefined
