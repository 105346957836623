import React from 'react'
import * as d3 from 'd3'
import ReactResizeDetector from 'react-resize-detector'
import _ from 'lodash'

interface Props<D> {
	data: D
	className?: string
	title?: string
}

export default class AbstractChart<D> extends React.Component<Props<D>> {
	rootNode: HTMLElement

	margin = { top: 10, right: 10, bottom: 40, left: 40 }

	draw = () => {
		// Implement in subclass
	}

	clear = () => {
		d3.select(this.rootNode).select('svg').remove()
	}

	redraw = () => {
		this.clear()
		this.draw()
	}

	componentDidMount() {
		this.draw()
	}

	componentDidUpdate() {
		this.redraw()
	}

	shouldComponentUpdate(nextProps: Props<D>): boolean {
		if (!_.isEqual(this.props.data, nextProps.data)) {
			return true
		}
		if (!_.isEqual(this.props.className, nextProps.className)) {
			return true
		}
		if (!_.isEqual(this.props.title, nextProps.title)) {
			return true
		}
		return false
	}

	onResize = () => {
		const svg = d3.select(this.rootNode).select('svg')
		if (svg.size() === 0) {
			return
		}
		
		const rootNodeHeight = this.rootNode.offsetHeight
		const rootNodeWidth = this.rootNode.offsetWidth
		if (rootNodeHeight === 0 || rootNodeWidth === 0) {
			/* This happens when the view is hidden, such as when a new Page is pushed in Onsen */
			return
		}
		const svgHeight = parseInt(svg.attr('height'), 10)
		const svgWidth = parseInt(svg.attr('width'), 10)

		if (svgHeight !== rootNodeHeight || svgWidth !== rootNodeWidth) {
			this.redraw()
		}
	}

	_setRef = (componentNode: HTMLDivElement) => {
		this.rootNode = componentNode
	}

	public render() {
		return (
			<div className={this.props.className} ref={this._setRef}>
				<ReactResizeDetector handleHeight={true} onResize={this.onResize} handleWidth={true} />
			</div>
		)
	}
}