import Component from '../components/BookingSummary'
import { Api } from 'typescript-fetch-api'
import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'

/* Import module actions */
import * as actions from '../actions'
import * as calActions from 'modules/calendar/actions'
import * as t from '../types'
import * as f from '../functions'
import { featuresSelector } from 'modules/common/selectors'

/** Interface for properties that are passed to the container/ */
export interface OwnProps {
	newBooking: boolean
	timesheetEdit?: boolean
}

/** Interface for properties that the container passes to the component. */
export interface Props {
	bookingSettings?: DeepReadonly<t.BookingSettings>
	bookings?: DeepReadonly<t.BookingLine[]>
	processing: boolean
	jobTypes?: DeepReadonlyArray<Api.JobType>
	selectedSupportWorkers?: DeepReadonly<Api.SupportWorker[]>
	editBookingFormState: t.EditBookingFormState
	bookingToEdit?: DeepReadonly<t.EditBooking>
	timesheetJobEdit: boolean
	findSupportWorkers: boolean
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
	onSubmitNewBooking: () => void
	onSubmitEditBooking: (jobRef: string, request: Api.AppointmentChangeRequest) => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	const bookingTimesheetRef = state.booking.editBooking ? state.booking.editBooking.job.timesheetRef : undefined
	const timesheetJobEdit = ownProps.timesheetEdit ? true : bookingTimesheetRef ? true : false

	const preference: t.BookingSupportWorkersPreference | undefined = ownProps.newBooking ? state.booking.supportWorkerPreference : timesheetJobEdit ? state.booking.supportWorkerPreference : 
		state.booking.editBookingFormState.chooseOwnSupportWorker ? state.booking.supportWorkerPreference : undefined
	
	const features = featuresSelector(state)

	return {
		jobTypes: state.common.settings ? state.common.settings.jobTypes : undefined,
		bookingSettings: ownProps.newBooking ? state.booking.booking.settings : undefined,
		bookings: ownProps.newBooking ? state.booking.booking.bookings : undefined,
		processing: state.booking.submitting,
		selectedSupportWorkers: preference && f.selectedSupportWorkers(state, preference) ? f.selectedSupportWorkers(state, preference) : undefined,
		editBookingFormState: state.booking.editBookingFormState,
		bookingToEdit: !ownProps.newBooking && state.booking.editBooking ? state.booking.editBooking as t.EditBooking : undefined,
		timesheetJobEdit,
		findSupportWorkers: features.findSupportWorkers,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: OwnProps): Actions => ({
	onSubmitNewBooking: () => {
		dispatch(actions.submitNewBooking({}))
	},
	onSubmitEditBooking: (jobRef, request) => {
		const payload: calActions.RosterChangePayload = {
			jobRef: jobRef,
			payload: request,
		}
		dispatch(calActions.rosterChange(payload))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)