import Component from '../components/ClientSearch'
import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'
import { Api } from 'typescript-fetch-api'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'

/* Import module actions */
import * as a from '../actions'
import * as t from '../types'
import * as f from '../functions'

export interface Props {
	clients?: t.ClientRearchResultView[]
	showMoreButton: boolean
	loading: boolean
	searching: boolean
	orgRef?: string
	numberOfResults?: number
	organisations?: DeepReadonlyObject<{ [key: string]: Api.Organisation }>
}

export interface OwnProps {
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
	onSearchFunderClients: (searchPayload: t.ClientSearchPayload) => void
	onChooseClient: (clientRef: string) => void
	onupdateCurrentFunderRef: (funderRef: string) => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState): Props => {
	return {
		clients: state.funderDashboard.searchResultClients && state.funderDashboard.funderSearchResultRefs ? f.createFunderClientViews(state.funderDashboard.searchResultClients, state.funderDashboard.funderSearchResultRefs) : undefined,
		showMoreButton: false,
		loading: state.funderDashboard.searching,
		searching: state.funderDashboard.searching,
		orgRef: state.funderDashboard.currentFunderRef,
		organisations: state.account.refs && state.account.refs.organisations,
		numberOfResults: state.funderDashboard.numberOfResults,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>, props: Props): Actions => ({
	onSearchFunderClients: (searchPayload) => {
		dispatch(a.search({
			organisationRef: searchPayload.organisationRef,
			searchTerm: searchPayload.searchTerm,
		}))
	},
	onChooseClient: (clientRef) => {
		dispatch(a.chooseFunderClient(clientRef))
	},
	onupdateCurrentFunderRef: (funderRef) => {
		dispatch(a.updateCurrentFunderRef(funderRef))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
