import Component from '../components/FunderDashboard'
import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'
import { Api } from 'typescript-fetch-api'
import { push } from 'connected-react-router'
import * as a from '../actions'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'

/* Import module actions */
// import * as actions from '../actions'

/** Interface for properties that are passed to the container/ */
export interface OwnProps {

}

export interface Props {
	funderImage?: string
	organisations?: DeepReadonlyObject<{ [key: string]: Api.Organisation }>
}

export interface Actions {
	onSearchFunder: (funderRef: string) => void
	onViewReports: (funderRef: string) => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	return {
		organisations: state.account.refs && state.account.refs.organisations,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	onSearchFunder: (funderRef) => {
		dispatch(a.updateCurrentFunderRef(funderRef))
		dispatch(push('/client-search'))
	},
	onViewReports: (funderRef) => {
		dispatch(a.updateCurrentFunderRef(funderRef))
		dispatch(push('/reports'))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
