import * as React from 'react'
import { Props, Actions, OwnProps } from '../containers/ClientSearch'
import Header from 'modules/common/containers/Header'
import Footer from 'modules/common/components/Footer'
import { Helmet } from 'react-helmet'
import Loading from 'modules/common/components/Loading'
import { avatarPlaceholder } from 'modules/root/theme'
import * as strings from 'modules/root/strings'

interface State {
	searchTerm: string
	searchedTerm: string
}

const INITIAL_STATE: State = {
	searchTerm: '',
	searchedTerm: '',
}

export default class ClientSearch extends React.Component<Props & Actions & OwnProps, State> {

	public state = INITIAL_STATE

	render() {
		const { organisations } = this.props
		const hasClients = this.props.clients && this.props.clients.length > 0
		const multiFunder = organisations && Object.keys(organisations).length > 1

		return (
			<div className="site-content client-search-screen">
				<Header section="client-search" />
				<Helmet>
					<title>Client Search — My Homecare</title>
				</Helmet>
				<article className="site-body">
					<div className="width-limit -site">
						<div className="search-wrapper">

							<div className="search-header">
								<h1 className="headline-text">Client search</h1>
								<form className={'search-form' + (multiFunder ? ' -with-multifunder' : '')} onSubmit={(evt) => evt.preventDefault()}>
									{
										multiFunder &&
											<div className="form-field -organisation">
												<div className="form-input -select">
													<select 
														className="select" 
														onChange={this.handleOrganisationUpdate} 
														value={this.props.orgRef}
													>
														{
															Object.keys(organisations!).map((key) => {
																const organisation = organisations![key]

																return (
																	<option key={key} value={organisation.ref}>{organisation.name}</option>
																)
															})
														}
													</select>
												</div>
											</div>
									}

									<div className="form-field -client">
										<div className="form-input -text">
											<input 
												type="text" 
												name="text" 
												className="field"
												value={this.state.searchTerm}
												onChange={this.handleSearchTermChange}
												placeholder={strings.clientSearchPlaceholder}
											/>
										</div>
									</div>
									<div className="button-group -search">
										<div className="button">
											<div className={'button-link -nested -action' + (this.props.searching ? ' -processing' : '')}>
												<input type="submit" value="Search" onClick={this.searchFunderClients} />
											</div>
										</div>
									</div>
								</form>
							</div>

							<div className="form-layout">
								{this.props.loading && (
									<Loading/>
								)}
								{!this.props.loading && 
								<div className="row">
									<table className="table table-default result-table">
										<thead>
											<tr>
												<th className="-name" scope="col">Name</th>
												{strings.nationalHealthIdentifierName &&
												<th scope="col">{strings.nationalHealthIdentifierName}</th>
												}
												<th scope="col">Date of birth</th>
												<th className="-location" scope="col">Location</th>
											</tr>
										</thead>
										{
											hasClients &&
												<tbody className="client-results">
													{this.props.clients!.map((client, key) => (
														<tr key={key}>
															<td className="-name">
																<a onClick={() => this.chooseClient(client.ref)} className="link person">
																	<figure className="avatar-image -medium -bordered">
																		<div className="image -placeholder">
																			<img src={client.image || avatarPlaceholder()} alt={client.name} />
																		</div>
																	</figure>
																	<span className="name">{client.name}</span>
																</a>
															</td>
															{strings.nationalHealthIdentifierName &&
															<td>
																<p>{client.nhi}</p>
															</td>
															}
															<td>
																<p>{client.dateOfBirth}</p>
															</td>
															<td className="-location">
																<p>{client.location}</p>
															</td>
														</tr>
													))}
												</tbody>
										}
									</table>
									{
										!hasClients &&
										<div className="empty-table">
											<p className="body-text -small">
												{this.props.numberOfResults === 0 ? ('No results found for "' + this.state.searchedTerm + '"') : 'Enter a search query above'}
											</p>
										</div>
									}
								</div>
								}
							</div>
						</div>
					</div>
				</article>

				<Footer />
			</div>
		)
	}

	private handleOrganisationUpdate = (evt: React.ChangeEvent<HTMLSelectElement>) => {
		this.props.onupdateCurrentFunderRef(evt.target.value)
	}

	private handleSearchTermChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({
			searchTerm: evt.target.value,
		})
	}

	private searchFunderClients = () => {
		if (this.props.orgRef) {
			this.setState({
				searchedTerm: this.state.searchTerm,
			})

			this.props.onSearchFunderClients({
				organisationRef: this.props.orgRef,
				searchTerm: this.state.searchTerm,
			})
		}
	}

	private chooseClient = (clientRef: string) => {
		this.props.onChooseClient(clientRef)
	}

	private onClick = (evt: React.MouseEvent) => {
		evt.preventDefault()
		// this.props.onLoadMore()
	}
}
