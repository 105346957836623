import * as React from 'react'
import Moment from 'react-moment'
import { Link } from 'react-router-dom'
import { Api } from 'typescript-fetch-api'
import moment from 'moment'
import * as t from '../types'
import { jobLocation } from '../functions'
import { avatarOverlayPlaceholder } from 'modules/root/theme'

interface OwnProps {
	view: t.JobView
	calendar: t.CalendarView
}

export default class Job extends React.Component<OwnProps> {

	classNameForJob = () => {
		if (this.props.view.date) {
			const endDateTime = this.props.view.startTime ? moment(this.props.view.date + ' ' + this.props.view.startTime.toString()) : moment(this.props.view.date.toString(), ['YYYY-MM-DD'])

			switch (this.props.view.status) {
				case t.JobViewStatus.Active:
					if (endDateTime.isBefore(new Date())) {
						return 'booking -past'
					} else {
						return 'booking -active'
					}
				case t.JobViewStatus.Pending:
					return 'booking -pending'
				case t.JobViewStatus.NotAllocated:
					return 'booking -active'
				case t.JobViewStatus.Completed:
					return 'booking -completed'
				case t.JobViewStatus.PendingCancellation:
					return 'booking -pending-cancellation'
				case t.JobViewStatus.Cancelled:
					return 'booking -cancelled'
				default:
					return 'booking'
			} 
		} else {
			return 'booking'
		}
	}

	renderStartTime = (view: t.JobView) => (
		<span className="time">
			{view.startTime ? (
				<Moment parse="hh:mm" date={view.startTime.toString()} format="h:mma" />
			) : view.startTimeOption === Api.Appointment.StartTimeOptionEnum.Flexible ? (
				<>Flexible</>
			) : view.startTimeOption === Api.Appointment.StartTimeOptionEnum.Morning ? (
				<>Morning</>
			) : view.startTimeOption === Api.Appointment.StartTimeOptionEnum.Afternoon ? (
				<>Afternoon</>
			) : null}
		</span>
	)

	public render() {
		const { view, calendar } = this.props
		const jobCancelled = view.status === t.JobViewStatus.Cancelled ? true : false

		return (
			<Link to={jobLocation(view, calendar)} className={this.classNameForJob()}>
				{view.supportWorker && !jobCancelled && (
					<figure className="avatar-image -small">
						<div className="image">
							<img src={view.supportWorker.image || avatarOverlayPlaceholder()} alt="{view.supportWorker.name}" />
						</div>
					</figure>
				)}
				<div className="details">
					{view.supportWorker ? (
						<strong className="name">{view.supportWorker.name}</strong>
					) : (
						(view.status === t.JobViewStatus.Pending || view.status === t.JobViewStatus.PendingCancellation) && <strong className="name">Pending...</strong>
					)}
					{this.renderStartTime(view)}
				</div>
			</Link>
		)
	}
}
