/* Import the component from the component path */
import Component from '../components/ClientPersonalDetails'
import { Api } from 'typescript-fetch-api'

import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'
import { currentClientSelector, supportNetworkMembershipSelector } from '../selectors'

/* Import module actions */
import * as a from '../actions'
import { featuresSelector } from 'modules/common/selectors'

/** Interface for properties that are passed to the container/ */
export interface OwnProps {

}

/** Interface for properties that the container passes to the component. */
export interface Props {
	client: DeepReadonly<Api.Client>
	settings?: DeepReadonly<Api.Settings>
	you: boolean
	saving: boolean
	features: DeepReadonly<Api.ClientDetailsFieldsFeatureSet>
}

export interface Actions {
	// onExample: (value: string) => void
	onAvatarUpload: (clientRef: string, image: string) => void
	onAvatarRemove: (clientRef: string) => void
	onSave: (req: a.UpdateClientDetailsActionPayload) => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	const client = currentClientSelector(state)!
	const membership = supportNetworkMembershipSelector(state, client.ref)!

	// supportNetworkMembershipSelector(state, client.ref)
	return {
		client,
		settings: state.common.settings,
		you: membership.role === Api.SupportNetworkMembership.RoleEnum.Client,
		saving: state.account.saving,
		features: featuresSelector(state).clientDetailsFields,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	onAvatarRemove: (clientRef: string) => {
		dispatch(a.updateClientDetails({
			clientRef,
			request: {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				image: null as any,
			},
		}))
	},
	onAvatarUpload: (clientRef: string, image: string) => {
		dispatch(a.updateClientDetails({
			clientRef,
			request: {
				image,
			},
		}))
	},
	onSave: (req: a.UpdateClientDetailsActionPayload) => {
		dispatch(a.updateClientDetails(req))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
