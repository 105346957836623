import * as React from 'react'
import { useSelector } from 'react-redux'
import { ticketsSelector } from '../selectors'
import moment from 'moment'
import { currentClientSelector, clientIsYou } from 'modules/account/selectors'
import { RootStoreState } from 'modules/root'

const Tickets: React.FC = () => {
	const tickets = useSelector(ticketsSelector)
	const client = useSelector(currentClientSelector)
	const you = useSelector((state: RootStoreState) => client ? clientIsYou(state, client.ref) : false)
	const displayName = client && (client.knownAs || client.givenName)
	
	if (!tickets || tickets.length === 0) {
		return null
	}
	
	return (
		<section className="dashboard-section">
			<header className="dashboard-header">
				{
					you ? <h1 className="headline-text">My pending requests</h1> : <h1 className="headline-text">{displayName || ''}’s pending requests</h1>
				}
			</header>
			<div className="recent-visits">
				<table className="table table-default">
					<thead>
						<tr>
							<th scope="col">Date</th>
							<th scope="col">Time</th>
							<th scope="col">Subject</th>
						</tr>
					</thead>
					<tbody>
						{tickets.map((ticket, index) => (
							<tr key={index}>
								<th scope="row" className="date" key={index}>
									{moment(ticket.whenCreated).format('dddd D MMM, YYYY')}
								</th>
								<td className="time">
									{moment(ticket.whenCreated).format('hh:mm a')}
								</td>
								<td className="type">
									{ticket.subject}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</section>
	)
}

export default Tickets
