import * as React from 'react'
import { wrapComponent } from 'changeling'
import { HealthPassportFormProps } from './HealthPassportScreen'
import coverLogo from 'modules/frontend/img/health-passport/hdc-logo-wordmark.svg'

type MyComponentProps = HealthPassportFormProps

class HealthPassportCover extends React.Component<MyComponentProps> {

	public render() {
		return (
			<div className="passport-cover">
				<div className="logo-container">
					<img src={coverLogo} />
				</div>
				<h1 className="cover-title">Health <span>Passport</span></h1>
				<div className="info-table">
					<div className="form-item -textbox -valuemode">
						<p className="title">First name:</p>
						<div className="value-wrapper">
							<p className="sub-text">{this.props.value.firstName}</p>
						</div>
					</div>
					<div className="form-item -textbox -valuemode">
						<p className="title">Last name:</p>
						<div className="value-wrapper">
							<p className="sub-text">{this.props.value.lastName}</p>
						</div>
					</div>
					<div className="form-item -textbox -valuemode">
						<p className="title">I like to be known as:</p>
						<div className="value-wrapper">
							<p className="sub-text">{this.props.value.knownAs}</p>
						</div>
					</div>
				</div>
				<div className="carry-message">
					<h2>Please ensure I take this with me when I leave.</h2>
				</div>
				<h4 className="version-label">Pictorial Version</h4>
			</div>
		)
	}
}

export default wrapComponent(HealthPassportCover)