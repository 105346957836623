/**
 * Authentication sagas.
 * 
 * Responsible for the process of logging in, refreshing tokens and logging out.
 */

import { take, takeEvery, call, put, race, select } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import * as actions from './actions'
import { authenticate, refresh, logout } from './functions'
import { SagaIterator } from 'redux-saga'
import { readyAction } from 'modules/root/actions'
import { history } from 'modules/routing'
import { AccessToken, LoginRequest } from './types'
import api from 'modules/api'
import { accessTokenSelector } from './selectors'
import { offlineOutboxQueueLength } from 'modules/api/selectors'
import platform from 'modules/platform'
import { toast } from 'react-toastify'
import { callApiWithActions, handleApiFailedAction, handleApiSuccessAction } from 'modules/api/functions'

/** Saga handling the state of being logged out. */
function* loggedOutSaga(): SagaIterator {
	/* Wait for a login request, but we also look for a logout request */
	const loginRaceResult = yield race({
		loginRequest: take(actions.login.started),
		logout: take(actions.logoutRequest),
	})
	
	if (loginRaceResult.logout) {
		/* A logout request may come through if we're only partially logged out. That is we don't
		   have an access token, but we do have a username set, and we want the user to re-login
		   without logging out. But they can choose to logout completely, so we handle that here.
		 */
		yield call(handleLogoutRequest)
		return
	}

	const login = loginRaceResult.loginRequest.payload as LoginRequest

	try {
		/* Attempt to login, but also let a logout request interrupt our login request */
		const loggingInRaceResult = yield race({
			loginResult: call(authenticate, login.username, login.password),
			logout: take(actions.logoutRequest),
		})

		if (loggingInRaceResult.loginResult) {
			const accessToken = loggingInRaceResult.loginResult as AccessToken
			yield put(actions.login.done({ params: login, result: accessToken }))
		} else if (loggingInRaceResult.logout) {
			yield call(handleLogoutRequest)
		}
	} catch (error) {
		yield put(actions.login.failed({ params: login, error }))
	}
}

/** Saga handling the state of being logged in. */
function* loggedInSaga(): SagaIterator {
	try {
		const raceResult = yield race({
			logout: take(actions.logoutRequest),
			loggedInError: take(actions.loggedInError),
			refreshTokenFailed: take(actions.refreshTokenFailed),
		})

		if (raceResult.logout) {
			yield call(handleLogoutRequest)
		} else if (raceResult.loggedInError) {
			yield put(actions.loggedOut())
		} else if (raceResult.refreshTokenFailed) {
			/* There's nothing for us to do here, as the routing saga handles this to take us to the login form to reauth. */
		}
	} catch (error) {
		yield put(actions.loggedInError(error))
		yield put(actions.loggedOut())
	}
}

/** When we request to logout we must check if we have an offline queue that will be lost if
 *  we actually logout. So we do a confirm step first whenever there is a logout request and our
 *  offline queue is not empty.
 */
function* handleLogoutRequest(): SagaIterator {
	const queueLength = yield select(offlineOutboxQueueLength)
	if (queueLength > 0) {
		const confirmResult = (yield call(
			platform.confirm, 
			'Are you sure you want to logout? You have unsynced updates that will be lost.',
			'Warning!',
			'Logout')) as boolean
		if (confirmResult) {
			yield call(doLogout)
		}
	} else {
		yield call(doLogout)
	}
}

function* doLogout(): SagaIterator {
	const accessToken = (yield select(accessTokenSelector)) as ReturnType<typeof accessTokenSelector>
	if (accessToken) {
		yield call(logout, accessToken.access_token, accessToken.refresh_token)
		yield put(actions.loggedOut())
	}
}

/** Yields a boolean result, whether there is a user logged in or not. */
export function* loggedIn(): SagaIterator {
	const accessToken = (yield select(accessTokenSelector)) as ReturnType<typeof accessTokenSelector>
	return accessToken !== undefined
}

let refreshingToken = false

/** Attempt to refresh the access token.
 * @throws An error if there is an error refreshing the token
 * @returns true if the refresh succeeds, false if the refresh is cancelled.
 */
export function* refreshTokenNow(): SagaIterator {
	const accessToken = (yield select(accessTokenSelector)) as AccessToken
	if (!accessToken) {
		throw new Error('Not logged in')
	}

	if (!refreshingToken) {
		refreshingToken = true
		try {
			const refreshedAccessToken = (yield call(refresh, accessToken.refresh_token)) as AccessToken
			refreshingToken = false
			yield put(actions.refreshedToken(refreshedAccessToken))
			return true
		} catch (error) {
			refreshingToken = false

			// TODO: this is nasty relying on the error message format
			if (error.message === 'Auth request failed: invalid_grant') {
				yield put(actions.refreshTokenFailed(Date.now()))
			}
			throw error
		}
	} else {
		/* The token is already being refreshed, so wait for the result of that operation, so we don't
		   double-up our refresh requests.
		 */
		const raceResult = yield race({
			refreshedToken: take(actions.refreshedToken),
			loggedInError: take(actions.loggedInError),
			refreshTokenFailed: take(actions.refreshTokenFailed),
		})

		if (raceResult.refreshedToken) {
			return true
		} else {
			return false
		}
	}
}

function humaniseError(error: Error): string {
	switch (error.message) {
		case 'Auth request failed: invalid_grant': 
			return 'Oops! Your email address or password was not valid. Please try again.'
		case 'Auth request failed: standdown':
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			if ((error as any).description) {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				return `Oops! There have been too many login failures for your account. ${(error as any).description}`
			} else {
				return 'Oops! There have been too many login failures for your account. Please wait a minute before trying to login again.'
			}
		default: return error.message
	}
}

function handleLoginFailed(action: actions.LoginFailedAction) {
	const message = humaniseError(action.payload.error)
	
	toast.dismiss()
	toast.error(message, {
		className: 'toast-content -error',
		autoClose: 3000,
		closeButton: false,
	})
}

function* resetPassword(action: actions.ResetPasswordAction): SagaIterator {
	yield put(actions.logoutRequest())

	yield call(callApiWithActions, action.payload, actions.resetPasswordAsync, (options: RequestInit) => {
		return api().authApi.postResetPassword(action.payload, options)
	})
}

function* resetPasswordDone(): SagaIterator {
	yield put(push('/'))
}

function* forgotPassword(action: actions.ForgotPasswordAction): SagaIterator {
	yield call(callApiWithActions, action.payload, actions.forgotPasswordAsync, (options: RequestInit) => {
		return api().userApi.postForgottenPasswordRequest(action.payload, options)
	})
}

function* forgotPasswordDone(): SagaIterator {
	yield put(push('/'))
}

function* handleForgotPasswordNavigation(): SagaIterator {
	if (history.location.pathname === '/sign-in/forgot-password') {
		const token = new URLSearchParams(window.location.search).get('token')
		if (token) {
			yield put(push('/sign-in/forgot-password', { resetPasswordToken: token }))
		}
	}
}

export default function* saga(): SagaIterator {
	/* Wait for the state to be ready, as we read the state in the loggedIn function.
	The state isn't immediately ready, as we use react-persist to load persisted state,
	which happens asynchronously.
	*/
	yield takeEvery(readyAction, handleForgotPasswordNavigation)
	yield takeEvery(actions.forgotPassword, forgotPassword)
	yield takeEvery(actions.forgotPasswordAsync.done, forgotPasswordDone)
	yield takeEvery([actions.forgotPasswordAsync.done], handleApiSuccessAction.bind(null, 'Please check your email for a link to reset your password.'))
	yield takeEvery([actions.forgotPasswordAsync.failed], handleApiFailedAction.bind(null, 'Oops! We were unable to send your password reset email.'))
	
	yield takeEvery(actions.resetPassword, resetPassword)
	yield takeEvery(actions.resetPasswordAsync.done, resetPasswordDone)
	yield takeEvery([actions.resetPasswordAsync.done], handleApiSuccessAction.bind(null, 'Thank you, your password has been reset.'))
	yield takeEvery([actions.resetPasswordAsync.failed], handleApiFailedAction.bind(null, 'Oops! We were unable to reset your password.'))
	
	yield takeEvery(actions.login.failed, handleLoginFailed)
	
	yield take(readyAction)
	while (true) {
		const isLoggedIn = (yield call(loggedIn)) as boolean

		if (isLoggedIn) {
			yield call(loggedInSaga)
		} else {
			yield call(loggedOutSaga)
		}
	}

}
