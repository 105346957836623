import * as React from 'react'
import { RouteComponentProps, Switch, Route } from 'react-router'
import { OwnProps, Props, Actions } from '../containers/EditBookingScreen'
import EditBooking from 'modules/booking/containers/EditBooking'
import BookingSummary from '../containers/BookingSummary'
import BookingSupportWorkers from '../containers/BookingSupportWorkers'
import Dashboard from 'modules/dashboard/containers/Dashboard'

export default class EditBookingScreen extends React.Component<OwnProps & Props & Actions & RouteComponentProps<undefined>> {
	
	public render() {
		return (
			<>
				{
					this.props.bookingToEdit ? 	
						<Switch>
							<Route 
								path={`${this.props.match.url}/summary`}
								render={props => (
									<BookingSummary newBooking={false} {...this.props} timesheetEdit={this.props.timesheetEdit}/>
								)}
							/>
							<Route
								path={`${this.props.match.url}/support-workers`}
								render={props => (
									<BookingSupportWorkers newBooking={false} {...this.props}/>
								)}
							/>
							<Route 
								path={`${this.props.match.url}`}
								render={props => (
									<EditBooking bookingToEdit={this.props.bookingToEdit!} timesheetEdit={this.props.timesheetEdit} />
								)}
							/>
						</Switch>
						: <Dashboard {...this.props}/>
				}
			</>
		)
	}
}
