import * as React from 'react'
import { RouteComponentProps, Switch, Route } from 'react-router'
import { OwnProps, Props, Actions } from '../containers/NewBookingScreen'

import NewBooking from '../containers/NewBooking'
import BookingSummary from '../containers/BookingSummary'
import BookingSupportWorkers from '../containers/BookingSupportWorkers'
import Header from 'modules/common/containers/Header'
import Footer from 'modules/common/components/Footer'
import { Helmet } from 'react-helmet'

export default class NewBookingScreen extends React.Component<OwnProps & Props & Actions & RouteComponentProps<undefined>> {
	
	public render() {
		return (
			<div className="site-content booking-screen">
				<Helmet>
					<title>New Booking — My Homecare</title>
				</Helmet>
				<Header section="calendar" />
				<Switch>
					<Route 
						path={`${this.props.match.url}/summary`}
						render={() => (
							<BookingSummary newBooking={true} {...this.props}/>
						)}
					/>
					<Route
						path={`${this.props.match.url}/support-workers`}
						render={() => (
							<BookingSupportWorkers newBooking={true} {...this.props}/>
						)}
					/>
					<Route 
						path={`${this.props.match.url}`}
						render={(props: RouteComponentProps<{ [x: string]: string | undefined}>) => (
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							<NewBooking initialDate={props.location.state ? (props.location.state as any).date : undefined} />
						)}
					/>

				</Switch>
				<Footer />
			</div>
		)
	}
}
