import actionCreatorFactory from 'typescript-fsa'
import { Api } from 'typescript-fetch-api'
import * as t from './types'

const actionCreator = actionCreatorFactory('FunderDashboard')

export const search = actionCreator<t.ClientSearchPayload>('CLIENT_SEARCH')
export type SearchAction = ReturnType<typeof search>
export const searching = actionCreator.async<t.ClientSearchPayload, Api.SearchOrganisationClientsResponse, Api.SearchOrganisationClientsError>('CLIENT_SEARCH')

export const chooseFunderClient = actionCreator<string>('CHOOSE_FUNDER_CLIENT')
export type ChooseFunderClientAction = ReturnType<typeof chooseFunderClient>

export interface AddClientToUserClientsPayload {
	membership: Api.SupportNetworkMembership
	client: Api.Client
}

export const addClientToUserClients = actionCreator<AddClientToUserClientsPayload>('ADD_CLIENT_TO_USER_CLIENTS')

export const updateCurrentFunderRef = actionCreator<string>('UPDATE_CURRENT_FUNDER_REF')
