import * as React from 'react'
import { withModal } from 'modules/common/modal-hoc'
import AppointmentFeedbackModal from 'modules/dashboard/components/AppointmentFeedbackModal'

interface OwnProps {
	supportWorkerName: string
	onClose: () => void
	submitFeedback: (feedback: string) => void
}

export default withModal(class AppointmentFeedbackModalWrapper extends React.Component<OwnProps> {
	public render() {
		return (
			<AppointmentFeedbackModal supportWorkerName={this.props.supportWorkerName} onClose={this.props.onClose} submitFeedback={this.props.submitFeedback}/>
		)
	}
})
