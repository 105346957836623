import actionCreatorFactory from 'typescript-fsa'
import { Api } from 'typescript-fetch-api'
import { LocalDate } from '@js-joda/core'
import * as t from './types'

/**
 * The action creator for this module. Note that it contains the module name.
 */
const actionCreator = actionCreatorFactory('Calendar')

export interface LoadCalendarPayload {
	start: LocalDate
	end: LocalDate
}

export const loadCalendarAsync = actionCreator.async<LoadCalendarPayload, Api.ClientAppointmentsResponse, Api.ClientAppointmentsError>('LOAD_CALENDAR')

export const loadClientTicketsAsync = actionCreator.async<undefined, Api.ClientTicketsResponse, Api.ClientTicketsError>('LOAD_TICKETS')

export interface RosterChangePayload {
	jobRef: string
	payload: Api.AppointmentChangeRequest
	returnTo?: t.JobChangeReturnLocation
	timesheetRef?: string
}

export const rosterChange = actionCreator<RosterChangePayload>('ROSTER_CHANGE')
export type RosterChangeAction = ReturnType<typeof rosterChange>

export const rosterChangeAsync = actionCreator.async<RosterChangePayload, never, Api.AppointmentChangeError>('RESCHEDULE_JOB')
export type RosterChangeAsyncDoneAction = ReturnType<typeof rosterChangeAsync.done>
export type RosterChangeAsyncFailedAction = ReturnType<typeof rosterChangeAsync.failed>
