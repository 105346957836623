import Component from '../components/RegisterForCare'

import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'

/* Import module actions */
import * as actions from '../actions'
import * as t from '../types'
import * as as from 'modules/account/selectors'

/** Interface for properties that are passed to the container/ */
export interface OwnProps {
}

/** Interface for properties that the container passes to the component. */
export interface Props {
	/** Is the current user already a client? */
	alreadyAClient: boolean
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
	onRegisterForCare: (request: t.RequestCareForEnum) => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	return {
		alreadyAClient: as.currentUserIsAClient(state),
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	onRegisterForCare: (request) => {
		dispatch(actions.startRegisterClient(request))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
