import * as React from 'react'
import { RouteComponentProps, Switch, Route } from 'react-router'
import Header from 'modules/common/containers/Header'
import Footer from 'modules/common/components/Footer'
import { Helmet } from 'react-helmet'
import { ComponentProps } from '../containers/AdvanceCarePlanScreen'
import AdvanceCarePlanStepOne from './AdvanceCarePlanStepOne'
import { FormValue } from '../types'
import { Snapshot, forComponentProps } from 'changeling'
import Waiting from 'modules/common/components/Waiting'
import AdvanceCarePlanStepTwo from './AdvanceCarePlanStepTwo'
import AdvanceCarePlanStepThree from './AdvanceCarePlanStepThree'
import AdvanceCarePlanStepWrapper from './AdvanceCarePlanStepWrapper'

type MyComponentProps = ComponentProps & RouteComponentProps<undefined>

/** The props used by components included by this screen */
export type AdvanceCarePlanFormProps = Snapshot<FormValue> & {
	editingMode?: boolean
}

export default class AdvanceCarePlanScreen extends React.Component<MyComponentProps> {
	
	private controller = forComponentProps(this, 'document', 'onSaveDocument')
	private dataController = this.controller.controller('data')

	public render() {
		const snapshot = this.controller.snapshot()
		if (!snapshot.value) {
			return (
				<Waiting message="Loading your Advance Care Plan…" />
			)
		}
		return (
			<div className="site-content document-form document-types">
				<Helmet>
					<title>Advance Care Plan — My Homecare</title>
				</Helmet>
				<Header section="account" />
				{this.renderDocument()}
				<Footer />
			</div>
		)
	}

	public componentDidMount() {
		if (this.props.documentRef && !this.props.document && !this.props.loading) {
			this.props.onLoadDocument(this.props.documentRef)
		}
	}

	private renderDocument() {
		const snapshot = this.controller.snapshot()
		if (!snapshot.value) {
			if (this.props.loading) {
				return (
					<Waiting message="Loading your Advance Care Plan…" />
				)
			} else {
				return (
					<article className="site-body">
						<div className="width-limit -site">
							<p>Could not load your documents.</p>
						</div>
					</article>
				)
			}
		} else {
			return (
				<Switch>
					<Route
						path={`${this.props.match.url}/step-one`}
						render={() => (
							<AdvanceCarePlanStepWrapper section="1">
								<AdvanceCarePlanStepOne controller={this.dataController} prop="this" {...this.props}/>
							</AdvanceCarePlanStepWrapper> 
						)}
					/>
					<Route
						path={`${this.props.match.url}/step-two`}
						render={() => (
							<AdvanceCarePlanStepWrapper section="2">
								<AdvanceCarePlanStepTwo controller={this.dataController} prop="this" {...this.props}/>
							</AdvanceCarePlanStepWrapper>
						)}
					/>
					<Route
						path={`${this.props.match.url}/step-three`}
						render={() => (
							<AdvanceCarePlanStepWrapper section="3">
								<AdvanceCarePlanStepThree controller={this.dataController} prop="this" {...this.props}/>
							</AdvanceCarePlanStepWrapper>
						)}
					/>
				</Switch>
			)
		}
	}

}
