import platform, { Brand } from 'modules/platform'
import SuemannPlaceholder from 'modules/frontend/img/theme/suemann/avatar-placeholder.svg'
import SuemannOverlayPlaceholder from 'modules/frontend/img/theme/suemann/avatar-overlay-placeholder.svg'
import HcnzPlaceholder from 'modules/frontend/img/theme/hcnz/avatar-placeholder.svg'
import HcnzOverlayPlaceholder from 'modules/frontend/img/theme/hcnz/avatar-overlay-placeholder.svg'
import DefaultPlaceholder from 'modules/frontend/img/avatar-placeholder.svg'
import DefaultOverlayPlaceholder from 'modules/frontend/img/avatar-overlay-placeholder.svg'

export function avatarPlaceholder() {
	const brand = platform.brand()
	
	switch (brand) {
		case Brand.Suemann:
			return SuemannPlaceholder
		case Brand.Hcnz:
			return HcnzPlaceholder
		case Brand.Default:
			return DefaultPlaceholder
	}
}

export function avatarOverlayPlaceholder() {
	const brand = platform.brand()
	switch (brand) {
		case Brand.Suemann:
			return SuemannOverlayPlaceholder
		case Brand.Hcnz:
			return HcnzOverlayPlaceholder
		case Brand.Default:
			return DefaultOverlayPlaceholder
	}
}
