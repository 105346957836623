export interface YourDetails {
	email: string
	givenName: string
	familyName: string
	phone: string
	password: string
	acceptedTerms: boolean
	marketingOptIn: boolean
}

export const INITIAL_YOUR_DETAILS: YourDetails = {
	email: '',
	givenName: '',
	familyName: '',
	phone: '',
	password: '',
	acceptedTerms: false,
	marketingOptIn: false,
}