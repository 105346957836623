import { Api } from 'typescript-fetch-api'
import bs from 'binary-search'
import { LocalDate, LocalTime } from '@js-joda/core'
import * as t from './types'
import * as calTypes from 'modules/calendar/types'
import moment from 'moment'

export function recentJobs(days: Api.ClientCalendarDay[], refs: Api.ClientAppointmentsResponseRefs): t.RecentDay[] {
	const result: t.RecentDay[] = []
	let dayIndex = 0

	if (!days) {
		return result
	}

	const handleAppointmentRef = (ref: string) => {
		const job = refs.appointments[ref]
		if (!job || result.length > 4 || job.status === Api.Appointment.StatusEnum.Cancelled || !job.startTime || !job.durationMins) {
			return
		}
		const sw = job.supportWorkerRefs && job.supportWorkerRefs.length > 0 ? refs.supportWorkers[job.supportWorkerRefs[0]] : undefined
		const relationship = job.clientSupportWorkerRelationshipRefs && job.clientSupportWorkerRelationshipRefs.length > 0 ? refs.relationships[job.clientSupportWorkerRelationshipRefs[0]] : undefined
		
		const startDateTime = LocalDate.parse(job.date).atTime(LocalTime.parse(job.startTime))

		const newJob = {
			appointmentRef: job.ref,
			date: moment().subtract(dayIndex, 'days'),
			time: {
				startTime: moment(startDateTime.toString(), ['YYYY-MM-DDTHH:mm']),
				endTime: moment(startDateTime.toString(), ['YYYY-MM-DDTHH:mm']).add(job.durationMins, 'minutes'),
			},
			services: job.jobTypeRefs,
			supportWorker: sw ? supportWorkerView(sw) : undefined,
			feedback: job.feedback,
			rating: job.rating,
			relationship: relationship,
		}

		let index = result.findIndex(ob => ob.date.isSame(newJob.date, 'date'))
		if (index < 0) {
			result.push(newJob)
		} else {
			while (index < result.length) {
				if (newJob.time.startTime.isAfter(result[index].time.startTime)) {
					result.splice(index, 0, newJob)
					return
				}
				index++
			}
		}
	}
	
	while (result.length < 5 && dayIndex < 30) {
		const currentDay = clientRosterDayForDate(LocalDate.now().minusDays(dayIndex), days)
		if (currentDay) {
			currentDay.appointmentRefs.forEach(handleAppointmentRef)
		}

		dayIndex++
	}

	return result
}

function clientRosterDayForDate(date: LocalDate, days: DeepReadonly<Api.ClientCalendarDay[]>): DeepReadonly<Api.ClientCalendarDay> | undefined {
	const comparator = (day: Api.ClientCalendarDay, targetDate: LocalDate) => {
		return LocalDate.parse(day.date).compareTo(targetDate)
	}

	// /* Find a job in the given day */
	const found = bs(days, date, comparator)
	if (found < 0) {
		return undefined
	}

	return days[found]
}

function supportWorkerView(sw: Api.SupportWorker): calTypes.SupportWorkerView {
	return {
		name: sw.fullName,
		image: undefined,
		ref: sw.ref,
	}
}