/* Import the component from the component path */
import Component from '../components/Login'

import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'

/* Import module files */
import * as actions from '../actions'
import * as s from '../selectors'
import * as account from 'modules/account/selectors'
import { LoginRequest } from '../types'

export interface OwnProps {
}

/**
 * Interface for properties that the container passes to the component.
 */
export interface Props {
	processing: boolean
	loadingAccount: boolean
	loadedAccount: boolean
	loggedIn: boolean
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
	onForgotPassword: (request: string) => void
	onLogin: (request: LoginRequest) => void
	onLogout: () => void
	/** Called to signal that the user has been logged in, while on the sign-in form. This
	 * is so that we can detect when we need to redirect the user to a useful page.
	 */
	onLoggedIn: () => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState): Props => {
	return {
		processing: state.auth.loggingIn,
		loadingAccount: state.account.loading,
		loadedAccount: account.loaded(state),
		loggedIn: s.loggedInSelector(state),
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	onLogin: (request) => {
		dispatch(actions.login.started(request))
	},
	onLogout: () => {
		dispatch(actions.logoutRequest())
	},
	onLoggedIn: () => {
		dispatch(actions.loggedIn())
	},
	onForgotPassword: (request) => {
		dispatch(actions.forgotPassword({ email: request }))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
