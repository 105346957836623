import { Api } from 'typescript-fetch-api'
import * as ct from 'modules/calendar/types'
import moment from 'moment'
import { TimesheetDayHoursSummary } from './types'

export function sumDailyMinutes(jobs: ct.JobView[]): number {
	let total = 0
	jobs.forEach((job) => {
		total += job.totalMinutes
	})
	return total
}

export function thisWeek(timesheet: DeepReadonlyObject<Api.Timesheet>): boolean {
	return inTimesheetPeriod(moment(), timesheet)
}

/** Returns a LocationDescriptor for a Link tag to link to a given JobView */
export function editTimesheetJobLocation(view: ct.JobDetailView, timesheetRef?: string): string {
	if (timesheetRef) {
		return `/timesheet/ref/${timesheetRef}/appt/${view.date}/${view.ref}`
	} else {
		return `/timesheet/appt/${view.date}/${view.ref}`
	}
}

/** Returns true if the given moment is in the timesheet period. */
export function inTimesheetPeriod(aMoment: moment.Moment, timesheet: DeepReadonly<Api.Timesheet>): boolean {
	return !aMoment.isBefore(timesheet.startDate) && aMoment.isBefore(moment(timesheet.endDate).add(1, 'day'))
}

export function summariseSuportWorkers(day: ct.DayView): TimesheetDayHoursSummary {
	const result: TimesheetDayHoursSummary = {
		supportWorkers: [],
	}

	for (const job of day.jobs) {
		if (!job.supportWorker || job.status === ct.JobViewStatus.Cancelled || job.status === ct.JobViewStatus.PendingCancellation) {
			continue
		}

		let supportWorker = result.supportWorkers.find(sw => sw.supportWorker === job.supportWorker)
		if (!supportWorker) {
			supportWorker = {
				supportWorker: job.supportWorker,
				totalMinutes: 0,
			}
			result.supportWorkers.push(supportWorker)
		}

		supportWorker.totalMinutes += job.totalMinutes
	}

	return result
}
