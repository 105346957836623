import * as React from 'react'
import { ComponentProps } from '../containers/PrintDocument'
import Waiting from 'modules/common/components/Waiting'
import { DOCUMENT_TYPE as HEALTH_PASSPORT_DOCUMENT_TYPE } from 'modules/health-passport/types'
import { DOCUMENT_TYPE as ADVANCE_CARE_PLAN_DOCUMENT_TYPE } from 'modules/advance-care-plan/types'
import HealthPassportPersonalDetails from 'modules/health-passport/components/HealthPassportPersonalDetails'
import { forComponentProps } from 'changeling'
import TitleLogoRow from 'modules/health-passport/components/TitleLogoRow'
import HealthPassportCover from 'modules/health-passport/components/HealthPassportCover'
import HealthPassportBackCover from 'modules/health-passport/components/HealthPassportBackCover'
import HealthPassportSectionA from 'modules/health-passport/components/HealthPassportSectionA'
import HealthPassportSectionB from 'modules/health-passport/components/HealthPassportSectionB'
import HealthPassportSectionC from 'modules/health-passport/components/HealthPassportSectionC'
import AdvanceCarePlanStepOne from 'modules/advance-care-plan/components/AdvanceCarePlanStepOne'
import AdvanceCarePlanStepTwo from 'modules/advance-care-plan/components/AdvanceCarePlanStepTwo'
import AdvanceCarePlanStepThree from 'modules/advance-care-plan/components/AdvanceCarePlanStepThree'
import Helmet from 'react-helmet'

export default class PrintDocument extends React.Component<ComponentProps> {

	private controller = forComponentProps(this, 'document', 'onSaveDocument')
	private dataController = this.controller.controller('data')

	public render() {
		const { document } = this.props
		if (!document) {
			return <Waiting />
		}

		/* This signals that we're ready to be printed */
		console.log('Ready to print')

		switch (document.documentType) {
			case HEALTH_PASSPORT_DOCUMENT_TYPE:
				return (
					<div className="body">
						<Helmet>
							<title>e-Health Passport — My Homecare</title>
						</Helmet>
						<HealthPassportCover controller={this.dataController} prop="this" {...this.props}/>
						<TitleLogoRow section="personal-details" printMode={true}/>
						<HealthPassportPersonalDetails controller={this.dataController} prop="this" {...this.props}/>
						<TitleLogoRow section="a" printMode={true}/>
						<HealthPassportSectionA controller={this.dataController} prop="this" {...this.props}/>
						<TitleLogoRow section="b" printMode={true}/>
						<HealthPassportSectionB controller={this.dataController} prop="this" {...this.props}/>
						<TitleLogoRow section="c" printMode={true}/>
						<HealthPassportSectionC controller={this.dataController} prop="this" {...this.props}/>
						<HealthPassportBackCover/>
					</div>
				)
			case ADVANCE_CARE_PLAN_DOCUMENT_TYPE:
				return (
					<div className="body advance-care-plan">
						<Helmet>
							<title>Advance Care Plan — My Homecare</title>
						</Helmet>
						<h1 className="headline-text">Advance Care Plan</h1>
						<AdvanceCarePlanStepOne controller={this.dataController} prop="this" {...this.props}/>
						<AdvanceCarePlanStepTwo controller={this.dataController} prop="this" {...this.props}/>
						<AdvanceCarePlanStepThree controller={this.dataController} prop="this" {...this.props}/>
					</div>
				)
			default:
				return (
					<div>Unsupported document type</div>
				)
		}
	}

	componentDidMount() {
		this.props.onLoadDocument(this.props.documentRef)
	}
}
