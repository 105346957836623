import { Api } from 'typescript-fetch-api'
import * as t from './types'

/**
 * Returns an array of Ethnicity groups
 */
export function ethnicityGroups(ethnicities: DeepReadonly<Api.Ethnicity[]>): t.EthnicityGroup[] {
	const ethnicityGroup: t.EthnicityGroup[] = []

	ethnicities.forEach((ethnicity) => {
		if (!ethnicity.group) {
			const newGroup = {
				name: ethnicity.name,
				ref: ethnicity.ref,
			}
			ethnicityGroup.push(newGroup)
		} else {
			const groupIndex = ethnicityGroup.findIndex(group => group.name === ethnicity.name)
			if (groupIndex >= 0) {
				ethnicityGroup[groupIndex].groups!.push({
					name: ethnicity.group,
					ref: ethnicity.ref,
				})
			} else {
				const newGroup = {
					name: ethnicity.name,
					groups: [{
						name: ethnicity.group,
						ref: ethnicity.ref,
					}],
				}

				ethnicityGroup.push(newGroup)
			}
		}
	})

	return ethnicityGroup
}

export function getJobType(jobTypes: DeepReadonlyArray<Api.JobType>, jobTypeRef: string): Api.JobType | undefined {
	return jobTypes.find(jt => jt.ref === jobTypeRef)
}

export function getJobTypeName(jobTypes: DeepReadonlyArray<Api.JobType>, jobTypeRef: string): string {
	let jobTypeName = ''
	if (jobTypes) {
		const index = jobTypes.findIndex(jobType => jobType.ref === jobTypeRef)
		if (index > -1) {
			jobTypeName = jobTypes[index].name
		}
	}
	return jobTypeName
}

/** Join the given array using the given separator, and a special separator for the last join.
 * This is useful for creating a string with commas and a final 'and'.
 */
export function joinLast(array: string[], separator: string, lastSeparator: string): string {
	if (array.length > 1) {
		return array.slice(0, array.length - 1).join(separator) + lastSeparator + array[array.length - 1]
	} else {
		return array[0]
	}
}

export function filterJobTypes(jobTypes: DeepReadonly<Api.JobType[]> | undefined, selection: string[]): DeepReadonly<Api.JobType[]> | undefined {
	if (!jobTypes) {
		return undefined
	}

	return jobTypes.filter(jt => selection.indexOf(jt.ref) !== -1)
}

const PRETTY_JOB_TYPES = {
	'A-CC': 'Childcare',
	'A-CC PH': 'Childcare',
	'A-MI': 'MI Support level 1',
	'A-MI PH': 'MI Support level 1',
	'A-MI2': 'RTI Support',
	'A-Misu': 'MI Support level 2',
	'A-RTI': 'RTI Support',
	'A-S': 'Sleepover',
	'HH': 'Home help',
	'HH-D': 'Respite',
	'HH-L': 'Home help',
	'HH-MOH': 'Home help',
	'HH-PRIV': 'Home help',
	'HH-PRIV2': 'Home help',
	'HH-SP': 'Home help',
	'HH-ST': 'Home help',
	'HH-W': 'Home help',
	'IF-HH': 'Home help',
	'IF-PC': 'Personal care',
	'MEET': 'Meet & greet',
	'NIGHT-MOH': 'Wakeover',
	'PC': 'Personal care',
	'PC-H': 'Personal care',
	'PC-H1': 'Personal care',
	'PC-L': 'Personal care',
	'PC-L1': 'Personal care',
	'PC-MOH': 'Personal care',
	'PC-N': 'Wakeover',
	'PC-PRIV': 'Personal care',
	'RES-H': 'Respite',
	'SLEEP': 'Sleepover',
	'SLEEP-MOH': 'Sleepover',
	'SLEEP-P': 'Sleepover',
	'RN': 'Nursing services',
}

/** Tidy the names of some of the job types that we care about. */
export function tweakJobType(jobType: Api.JobType): Api.JobType {
	const prettyName = PRETTY_JOB_TYPES[jobType.ref]
	if (prettyName) {
		return {
			...jobType,
			name: prettyName,
		}
	} else {
		return jobType
	}
}
