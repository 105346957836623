import * as React from 'react'
import { Props, OwnProps, Actions } from '../containers/TimesheetEditJob'
import { Api } from 'typescript-fetch-api'
import { withModal, ModalStyle } from 'modules/common/modal-hoc'
import TimesheetSupportWorkerSelect from '../containers/TimesheetSupportWorkerSelect'
import { LocalDate, LocalTime } from '@js-joda/core'
import * as bf from 'modules/booking/functions'
import moment from 'moment'
import Moment from 'react-moment'
import Datetime from 'react-datetime'
import * as ct from 'modules/calendar/types'
import * as commonFunctions from 'modules/common/functions'
import TimeField from 'react-simple-timefield'
import * as t from 'modules/calendar/types'
import { inTimesheetPeriod } from '../functions'

export default withModal(
	class TimesheetEditJob extends React.Component<Props & OwnProps & Actions> {
		StartTimeOptionEnum = Api.NewAppointment.StartTimeOptionEnum
	
		public render() {
			const { availableSupportWorkers } = this.props
			const booking = this.props.bookingToEdit
			const editForm = this.props.editBookingFormState

			return (
				<div className="timesheet-modal">
					<h1 className="page-title">Edit booking</h1>
					{this.jobStatusIndicator()}
					<div className="form-layout"> 
						{this.renderServices(booking.job)}
						<TimesheetSupportWorkerSelect availableSupportWorkers={availableSupportWorkers} updateEditFormState={this.handleUpdateSupportWorkerEditFormState}/>
						<div className="row">
							<div className="form-field">
								<label className="label">Start date</label>
								<div className="form-input -date">
									<Datetime
										className="field"
										dateFormat="ddd D MMM"
										timeFormat={false}
										inputProps={{ readOnly: true, placeholder: 'Select a date…' }}
										onChange={this.handleBookingDate}
										isValidDate={this.isSelectableStartDate}
										value={booking && booking.job.date && moment(booking.job.date!.toString(), ['YYYY-MM-DD']).toDate()}
									/>
								</div>
							</div>
						</div>
						<div className="row -split -half">
							<div className="form-field">
								<label className="label">Start time</label>
								{booking.job.startTimeOption === this.StartTimeOptionEnum.Specified ? (
									<div className="form-input -text">
										<TimeField
											onChange={this.handleStartTimeTextChange}
											value={booking.job.startTime ? booking.job.startTime.toString() : ''}
											input={<input type="text" className="field" />}
										/>
									</div>
								) : (
									<div className="form-input -select">
										<select 
											className="select" 
											onChange={this.handleStartTimeChange} 
											value={booking.job.startTimeOption}
											disabled={booking.job.durationOption === Api.AppointmentScheduleChange.DurationOptionEnum.Overnight}
										>
											{bf.bookingTimes24hrs().map((bookingTime, key) => {
												return (
													<option key={key} value={bookingTime.toString()}>{moment(bookingTime.toString(), ['HH:mm']).format('h:mma')}</option>
												)
											})
											}
										</select>
									</div>
								)}
							
							</div>
							<div className="form-field">
								<label className="label">Duration</label>
								{booking.job.durationOption === Api.AppointmentScheduleChange.DurationOptionEnum.Specified ? (
									<div className="form-input -text">
										<TimeField
											onChange={this.handleDurationTextChange}
											value={booking.job.durationMins ? `${LocalTime.ofSecondOfDay(booking.job.durationMins * 60)}` : ''}
											input={<input type="text" className="field" />}
										/>
									</div>
								) : (
									<div className="form-input -select">
										<select 
											className="select" 
											onChange={this.handleDurationChange} 
											value={booking.job.durationOption}
										>
											{
												bf.durations(30, 60 * 12, booking.job.durationMins).map((duration, key) => {
													return (
														<option key={key} value={duration.minutes}>{duration.toText}</option>
													)
												})
											}
										</select>
									</div>
								)}
							</div>
						</div>
						{
							booking.job.repeatOptionDisplayName && 
							<div className="row">
								<div className="body-text">
									<p><strong>
										This booking repeats {booking.job.repeatOptionDisplayName}
										{booking.job.repeatEndDate && (
											<>{' '}until <Moment date={booking.job.repeatEndDate.toString()} format="dddd D MMMM YYYY" /></>
										)}.</strong><br/>
									</p>
								</div>
								<div className="form-field">
									<div className="form-input -options">
										<ul className="option-inputs -inline">
											<li className="option -checkbox">
												<label className="label">
													<input type="checkbox" className="checkbox" name="repeat" checked={editForm.editRepeating} onChange={this.handleUpdateRepetitionEditFormState} /><span className="substitute" />
													Apply changes to all future jobs
												</label>
											</li>
										</ul>
									</div>
								</div>
							</div>
						}
						<div className="row">
							<div className="button-group">
								{!this.props.processing &&
								<div className="button">
									<button type="button" onClick={this.onSubmitCancelBooking} className={'button-link -text -destructive' + (this.props.processing ? ' -processing' : '')}>Cancel booking</button>
								</div>
								}
								<div className="button">
									<button type="button" onClick={this.onSubmitEditBooking} className={'button-link -action' + (this.props.processing ? ' -processing' : '')}>Save changes</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		}

		private jobStatusIndicator = () => {
			switch (this.props.bookingToEdit.job.status) {
				case t.JobViewStatus.Active:
					return null
				case t.JobViewStatus.Pending:
					return (
						<div className="status -pending">Pending</div>
					)
				case t.JobViewStatus.PendingCancellation:
					return (
						<div className="status -pending">Pending cancellation</div>
					)
				case t.JobViewStatus.NotAllocated:
					return (
					// <div className="status -active">Not allocated</div>
						null
					)
				case t.JobViewStatus.Completed:
					return (
						<div className="status -completed">Completed</div>
					)
				case t.JobViewStatus.Cancelled:
					return (
						<div className="status -cancelled">Cancelled</div>
					)
				default:
					return null
			}
		}

		private handleStartTimeTextChange = (evt: React.ChangeEvent, stringValue: string) => {
			const booking = { ...this.props.bookingToEdit, job: { ...this.props.bookingToEdit.job } }
			if (stringValue) {
				booking.job.startTime = LocalTime.parse(stringValue)
				booking.job.startTimeOption = this.StartTimeOptionEnum.Specified
			} else {
				booking.job.startTimeOption = this.StartTimeOptionEnum.Flexible
			}
			this.props.onUpdateEditBookingFormState({
				...this.props.editBookingFormState,
				editTime: true,
			})
			this.props.onUpdateEditBookingDetails(booking)
		}

		private handleStartTimeChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
			const booking = { ...this.props.bookingToEdit, job: { ...this.props.bookingToEdit.job } }
			const value = evt.target.value

			if (this.StartTimeOptionEnum[value] === this.StartTimeOptionEnum.Flexible || 
			this.StartTimeOptionEnum[value] === this.StartTimeOptionEnum.Morning ||
			this.StartTimeOptionEnum[value] === this.StartTimeOptionEnum.Afternoon) {
				booking.job.startTime = undefined
				booking.job.startTimeOption = this.StartTimeOptionEnum[value]
			} else {
				booking.job.startTime = LocalTime.parse(value)
				booking.job.startTimeOption = this.StartTimeOptionEnum.Specified
			}
			this.props.onUpdateEditBookingFormState({
				...this.props.editBookingFormState,
				editTime: true,
			})
			this.props.onUpdateEditBookingDetails(booking)
		}

		private handleDurationTextChange = (evt: React.ChangeEvent, stringValue: string) => {
			const booking = { ...this.props.bookingToEdit, job: { ...this.props.bookingToEdit.job } }
			if (stringValue) {
				const value = LocalTime.parse(stringValue).toSecondOfDay() / 60
		
				booking.job.durationOption = Api.AppointmentScheduleChange.DurationOptionEnum.Specified
				booking.job.durationMins = Number(value)
				if (this.props.bookingToEdit.job.durationOption === Api.AppointmentScheduleChange.DurationOptionEnum.Overnight) {
					booking.job.startTime = undefined
					booking.job.startTimeOption = this.StartTimeOptionEnum.Flexible
				}
			} else {
				booking.job.durationOption = Api.AppointmentScheduleChange.DurationOptionEnum.NotSpecified
			}
			this.props.onUpdateEditBookingFormState({
				...this.props.editBookingFormState,
				editTime: true,
			})
			this.props.onUpdateEditBookingDetails(booking)
		}

		private handleDurationChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
			const value = evt.target.value
			const booking = { ...this.props.bookingToEdit, job: { ...this.props.bookingToEdit.job } }
		
			if (Api.AppointmentScheduleChange.DurationOptionEnum[value] === Api.AppointmentScheduleChange.DurationOptionEnum.NotSpecified) {
				booking.job.durationOption = Api.AppointmentScheduleChange.DurationOptionEnum.NotSpecified
				booking.job.durationMins = undefined
			} else if (Api.AppointmentScheduleChange.DurationOptionEnum[value] === Api.AppointmentScheduleChange.DurationOptionEnum.Overnight) {
				booking.job.durationOption = Api.AppointmentScheduleChange.DurationOptionEnum.Overnight
				booking.job.durationMins = undefined
				booking.job.startTime = LocalTime.parse('23:00')
				booking.job.startTimeOption = this.StartTimeOptionEnum.Specified
			} else {
				booking.job.durationOption = Api.AppointmentScheduleChange.DurationOptionEnum.Specified
				booking.job.durationMins = Number(value)
				if (this.props.bookingToEdit.job.durationOption === Api.AppointmentScheduleChange.DurationOptionEnum.Overnight) {
					booking.job.startTime = undefined
					booking.job.startTimeOption = this.StartTimeOptionEnum.Flexible
				}
			}

			this.props.onUpdateEditBookingFormState({
				...this.props.editBookingFormState,
				editTime: true,
			})
			this.props.onUpdateEditBookingDetails(booking)
		}

		private renderServices = (job: DeepReadonlyObject<ct.JobDetailView>) => (
			<>
				{
					job.services && job.services.length > 0 &&
				<p className="item">
					<span className="icon -service" />
					<span className="details">{commonFunctions.joinLast(job.services.map(s => s.name), ', ', ' and ')}</span>
				</p>
				}
			</>
		)

		private isSelectableStartDate = (date: moment.Moment): boolean => {
			if (this.props.timesheet) {
				return inTimesheetPeriod(date, this.props.timesheet)
			} else {
				const week = moment().week()
				const weekOfDate = moment(date).week()
			
				return week === weekOfDate
			}
		}

		private handleBookingDate = (date: moment.Moment | string) => {
			if (typeof date === 'string') {
				/* The user entered something that was not parseable as a date */
				return
			}
			const booking = { ...this.props.bookingToEdit, job: { ...this.props.bookingToEdit.job } }
			booking.job.date = LocalDate.parse(date.format('YYYY-MM-DD'))
			this.props.onUpdateEditBookingDetails(booking)
			this.props.onUpdateEditBookingFormState({
				...this.props.editBookingFormState,
				editTime: true,
			})
		}

		private handleUpdateRepetitionEditFormState = () => {
			this.props.onUpdateEditBookingFormState({
				...this.props.editBookingFormState,
				editRepeating: !this.props.editBookingFormState.editRepeating,
			})
		}

		private handleUpdateSupportWorkerEditFormState = () => {
			this.props.onUpdateEditBookingFormState({
				...this.props.editBookingFormState,
				editSupportWorkers: true,
				chooseOwnSupportWorker: true,
			})
		}

		private onSubmitEditBooking = (evt: React.MouseEvent) => {
			evt.preventDefault()

			if (this.props.bookingToEdit) {
				const booking = { ...this.props.bookingToEdit, job: { ...this.props.bookingToEdit.job } }

				const request: Api.AppointmentChangeRequest = {
					effectiveDate: booking.effectiveDate.toString(),
					repeating: this.props.editBookingFormState.editRepeating,
					notes: booking.notes,
				}

				if (this.props.editBookingFormState.editTime) {
					request.scheduleChange = {
						date: booking.job.date.toString(),
						startTimeOption: Api.AppointmentScheduleChange.StartTimeOptionEnum.Specified,
						startTime: booking.job.startTime ? booking.job.startTime.toString() : undefined,
						durationOption: Api.AppointmentScheduleChange.DurationOptionEnum.Specified,
						durationMins: booking.job.durationMins,
					}
				}

				if (this.props.editBookingFormState.editSupportWorkers) {
					request.supportWorkerChange = {
						allAppointments: false,
						supportWorkerRefs: this.props.currentlySelectedSupportWorkerRef ? [this.props.currentlySelectedSupportWorkerRef] : [],
					}
				}
				this.props.onSubmitEditBooking(booking.job.ref, request)
			}
		}

		private onSubmitCancelBooking = (evt: React.MouseEvent) => {
			evt.preventDefault()

			if (!window.confirm('Are you sure you want to request the cancellation of this booking?')) {
				return
			}

			const cancellationRequest: Api.AppointmentChangeRequest = {
				effectiveDate: this.props.bookingToEdit.effectiveDate.toString(),
				repeating: false,
				cancellation: {
					cancel: true,
				},
			}
	
			if (this.props.editBookingFormState.editRepeating) {
				cancellationRequest.repeating = true
				cancellationRequest.cancellation!.cancelOnDate = this.props.bookingToEdit.effectiveDate.toString()
			}
	
			this.props.onSubmitEditBooking(this.props.bookingToEdit.job.ref, cancellationRequest)
		}
	},
	
	ModalStyle.Medium,
)
