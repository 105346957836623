import * as React from 'react'
import { OwnProps, Props, Actions } from '../containers/PersonalDetails'
import UserPersonalDetails from '../containers/UserPersonalDetails'
import ClientPersonalDetails from '../containers/ClientPersonalDetails'
import ClientPersonalDetailsViewOnly from '../containers/ClientPersonalDetailsViewOnly'

export default class PersonalDetails extends React.Component<OwnProps & Props & Actions> {

	public render() {
		/* Check if we are looking at details for a client or for a user */
		return this.props.membership ? (
			(this.props.membership.capabilities.manager || this.props.membership.capabilities.manageClientDetails) ? (
				<ClientPersonalDetails />
			) : this.props.membership.capabilities.viewClientDetails ? (
				<ClientPersonalDetailsViewOnly />
			) : null
		) : (
			<UserPersonalDetails />
		)
	}
}
