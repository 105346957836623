import * as React from 'react'
import { RouteComponentProps, Switch, Route } from 'react-router'
import Header from 'modules/common/containers/Header'
import Footer from 'modules/common/components/Footer'
import { Helmet } from 'react-helmet'
import { ComponentProps } from '../containers/HealthPassportScreen'
import HealthPassportPersonalDetails from './HealthPassportPersonalDetails'
import HealthPassportSectionA from './HealthPassportSectionA'
import HealthPassportSectionB from './HealthPassportSectionB'
import HealthPassportSectionC from './HealthPassportSectionC'
import { FormValue } from '../types'
import { Snapshot, forComponentProps } from 'changeling'
import { Api } from 'typescript-fetch-api'
import Waiting from 'modules/common/components/Waiting'
import HealthPassportSectionWrapper from './HealthPassportSectionWrapper'

type MyComponentProps = ComponentProps & RouteComponentProps<undefined>

/** The props used by components included by this screen */
export type HealthPassportFormProps = Snapshot<FormValue> & {
	languages?: Api.Language[]
	editingMode?: boolean
	docExtended?: DeepReadonlyObject<Api.ClientDocument>
}

export default class HealthPassportScreen extends React.Component<MyComponentProps> {

	private controller = forComponentProps(this, 'document', 'onSaveDocument')
	private dataController = this.controller.controller('data')

	public render() {
		return (
			<div className="site-content document-form document-types">
				<Helmet>
					<title>e-Health Passport — My Homecare</title>
				</Helmet>
				<Header section="account" />
				{this.renderDocument()}
				<Footer />
			</div>
		)
	}

	public componentDidMount() {
		if (this.props.documentRef && !this.props.document && !this.props.loading) {
			this.props.onLoadDocument(this.props.documentRef)
		}
	}

	private renderDocument() {
		const snapshot = this.controller.snapshot()
		if (!snapshot.value) {
			if (this.props.loading) {
				return (
					<Waiting message="Loading your e-Health Passport…" />
				)
			} else {
				return (
					<article className="site-body">
						<div className="width-limit -site">
							<p>Could not load your documents.</p>
						</div>
					</article>
				)
			}
		} else {
			return (
				<Switch>
					<Route
						path={`${this.props.match.url}/personal-details`}
						render={() => (
							<HealthPassportSectionWrapper section="personal-details">
								<HealthPassportPersonalDetails controller={this.dataController} prop="this" {...this.props}/>
							</HealthPassportSectionWrapper>
						)}
					/>
					<Route
						path={`${this.props.match.url}/section-a`}
						render={() => (
							<HealthPassportSectionWrapper section="a">
								<HealthPassportSectionA controller={this.dataController} prop="this" {...this.props}/>
							</HealthPassportSectionWrapper>
						)}
					/>
					<Route
						path={`${this.props.match.url}/section-b`}
						render={() => (
							<HealthPassportSectionWrapper section="b">
								<HealthPassportSectionB controller={this.dataController} prop="this" {...this.props}/>
							</HealthPassportSectionWrapper>
						)}
					/>
					<Route
						path={`${this.props.match.url}/section-c`}
						render={() => (
							<HealthPassportSectionWrapper section="c">
								<HealthPassportSectionC controller={this.dataController} prop="this" {...this.props}/>
							</HealthPassportSectionWrapper>
						)}
					/>
				</Switch>
			)
		}
	}

}
