import * as React from 'react'
import { Props, Actions, OwnProps } from '../containers/TimesheetSupportWorkerSelect'

export default class TimesheetSupportWorkerSelect extends React.Component<Props & Actions & OwnProps> {

	public render() {
		const { currentBookedSupportWorkerRef, availableSupportWorkerViews, currentlySelectedSupportWorkerRef } = this.props

		return (
			<>
				{ availableSupportWorkerViews &&
				<div className="row">
					<div className="form-field">
						<label className="label">Support worker</label>
						<div className="form-input -select">
							<select 
								className="select" 
								onChange={this.onPreferenceLevelChange} 
								value={currentlySelectedSupportWorkerRef ? currentlySelectedSupportWorkerRef : (currentBookedSupportWorkerRef ? currentBookedSupportWorkerRef : '')}
							>
								<option></option>
								{ availableSupportWorkerViews.map((sw, key) => {
									return (
										<option key={key} value={sw.supportWorkerRef}>{sw.supportWorkerName}</option>
									)
								})
								}
							</select>
						</div>
					</div>
				</div>
				}
			</>
		)
	}

	private onPreferenceLevelChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
		this.props.onPreferenceLevelChange(evt.target.value, true, 1)
		if (this.props.currentBookedSupportWorkerRef !== evt.target.value && this.props.updateEditFormState) {
			this.props.updateEditFormState()
		}
	}
}
