import * as React from 'react'
import * as t from '../types'
import { avatarPlaceholder } from 'modules/root/theme'

interface OwnProps {
	job: t.JobDetailView
	onCancel: () => void
}
/**
 * Interface for private internal component state.
 */
interface State {
	// myValue: string
}

/**
 * The initial state for our internal component state.
 */
const INITIAL_STATE: State = {
	// myValue: 'Example',
}

export default class ChangeSupportWorkerModal extends React.Component<OwnProps, State> {

	public state = INITIAL_STATE

	public render() {
		return (
			<div className="change-supportworker">
				<h1 className="page-title">Change Support Worker</h1>
				<div className="row">
					<div className="body-text">
						<p>Choose one or more of your favourite support workers and we will coordinate with them to find out if they‘re available for this booking.</p>
					</div>
				</div>
				<div className="row">
					<div className="favourites-list -list">
						<div className="person">
							<figure className="avatar-image -large">
								<div className="image">
									<img src={avatarPlaceholder()} alt="Heather" />
								</div>
							</figure>
							<p className="details">
								<a href="" className="name">Heather Richardson</a><br/>
								You‘ve seen Heather 3 times
							</p>
							<div className="option-inputs">
								<div className="option -checkbox">
									<label className="label">
										<input type="checkbox" className="checkbox" /><span className="substitute" />
										Select
									</label>
								</div>
							</div>
						</div>
						<div className="person">
							<figure className="avatar-image -large">
								<div className="image">
									<img src={avatarPlaceholder()} alt="Heather" />
								</div>
							</figure>
							<p className="details">
								<a href="" className="name">Herman Miller</a><br/>
								You‘ve seen Herman twice
							</p>
							<div className="option-inputs">
								<div className="option -checkbox">
									<label className="label">
										<input type="checkbox" className="checkbox" /><span className="substitute" />
										Select
									</label>
								</div>
							</div>
						</div>
						<div className="person">
							<figure className="avatar-image -large">
								<div className="image">
									<img src={avatarPlaceholder()} alt="Heather" />
								</div>
							</figure>
							<p className="details">
								<a href="" className="name">Henrietta Weatherstone</a><br/>
								You haven't met Henrietta yet
							</p>
							<div className="option-inputs">
								<div className="option -checkbox">
									<label className="label">
										<input type="checkbox" className="checkbox" /><span className="substitute" />
										Select
									</label>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="body-text">
						<p>The following people are not in your list of favourites, but you have had bookings with in the past.</p>
					</div>
				</div>
				<div className="row">
					<div className="favourites-list -list">
						<div className="person">
							<figure className="avatar-image -large">
								<div className="image">
									<img src={avatarPlaceholder()} alt="Heather" />
								</div>
							</figure>
							<p className="details">
								<a href="" className="name">Jemima Huddleston</a><br/>
								You‘ve seen Jemima once
							</p>
							<div className="option-inputs">
								<div className="option -checkbox">
									<label className="label">
										<input type="checkbox" className="checkbox" /><span className="substitute" />
										Select
									</label>
								</div>
							</div>
						</div>
						<div className="person">
							<figure className="avatar-image -large">
								<div className="image">
									<img src={avatarPlaceholder()} alt="Heather" />
								</div>
							</figure>
							<p className="details">
								<a href="" className="name">Daisy Wu</a><br/>
								You‘ve seen Daisy twice
							</p>
							<div className="option-inputs">
								<div className="option -checkbox">
									<label className="label">
										<input type="checkbox" className="checkbox" /><span className="substitute" />
										Select
									</label>
								</div>
							</div>
						</div>
						<div className="person">
							<figure className="avatar-image -large">
								<div className="image">
									<img src={avatarPlaceholder()} alt="Heather" />
								</div>
							</figure>
							<p className="details">
								<a href="" className="name">Rodriguez Fernández</a><br/>
								You‘ve seen Rodriguez twice
							</p>
							<div className="option-inputs">
								<div className="option -checkbox">
									<label className="label">
										<input type="checkbox" className="checkbox" /><span className="substitute" />
										Select
									</label>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="body-text">
						<p>If you‘d like to find a new support worker, you can also <a href="#">search for one</a>.</p>
					</div>
				</div>
				<div className="row">
					<div className="form-field">
						<label className="label">Additional notes</label>
						<div className="form-input -textarea">
							<textarea
								className="field"
								placeholder="Please provide any other relevant details about this change"
							/>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="button-group">
						<div className="button">
							<input type="submit" className="button-link -text" value="Cancel" onClick={this.props.onCancel} />
						</div>
						<div className="button -constrained">
							<input type="submit" className="button-link -action" value="Request Change" />
						</div>
					</div>
				</div>
			</div>
		)
	}
}
