import * as React from 'react'
import { OwnProps, Props, Actions } from '../containers/Week'
import { Link } from 'react-router-dom'
import Moment from 'react-moment'
import moment from 'moment'
import TimesheetJob from '../containers/TimesheetJob'
import Loading from 'modules/common/components/Loading'
import * as cf from 'modules/calendar/functions'
import { summariseSuportWorkers } from '../functions'

export default class Week extends React.Component<OwnProps & Props & Actions> {

	hasJobs = (): boolean => {
		let hasJobs = false
		this.props.appointments.days.forEach((day) => {
			if (day.jobs.length > 0) {
				hasJobs = true
			}
		})
		return hasJobs
	}

	formatTimesheetDate = (): string => {
		const startDate = this.props.timesheet ? moment(this.props.timesheet.startDate).format('D MMM') : ''
		const endDate = this.props.timesheet ? moment(this.props.timesheet.endDate).format('D MMM YYYY') : ''
		return startDate + ' — ' + endDate
	}

	public render() {
		const { appointments, calendar, prevTimesheetRef, nextTimesheetRef, timesheet } = this.props

		return (
			<>
				<header className="timesheet-header">
					<h1 className="headline-text">My timesheet</h1>
					<h2 className="date-text">{this.formatTimesheetDate()}</h2>
					<div className="right-group button-group">
						<div className="button">
							<div onClick={this.props.startTimesheetBookingFromDate} className="button-link -action -small">New entry</div>
						</div>
						<div className="date-navigator">
							<div className="navigation">
								{prevTimesheetRef ? (
									<Link to={'/timesheet/ref/' + prevTimesheetRef} className="direction -prev">Previous day</Link>
								) : (<div className="direction -prev -disabled">Previous day</div>)}
								<Link to="/timesheet" className="day">This week</Link>
								{nextTimesheetRef ? (
									<Link to={'/timesheet/ref/' + nextTimesheetRef} className="direction -next">Next day</Link>
								) : (<div className="direction -next -disabled">Next day</div>)}
							</div>
						</div>
					</div>
				</header>

				{
					this.props.loading ? (
						<Loading/>
					) : (
						<>
							<div className="calendar-view -weekly">
								<div className="week">
									{appointments.days.map((d, i) => (
										<div key={i} className="dayname"><Moment date={d.date.toString()} format="ddd D" /></div>
									))}
								</div>
								<div className="week">
									{appointments.days.map((d, i) => (
										<div className="day" key={i}>
											<div className="bookings">
												{d.jobs.map((j, jobIndex) => (
													<TimesheetJob view={j} calendar={calendar} key={jobIndex} date={d.date} timesheetRef={timesheet ? timesheet.ref : undefined} />
												))}
											</div>

											<div className="totals">
												{
													
												}
												{summariseSuportWorkers(d).supportWorkers.map((j, index) => (
													<p key={index}>
														{j.supportWorker ? j.supportWorker.name : ''}&nbsp;&nbsp;&nbsp;
														{cf.formatDuration(j.totalMinutes, true, true)}
													</p>
												))}
												<span className="daily-total">{cf.formatDuration(d.totalMinutes, true, true)}</span>
											</div>
										</div>
									))}
								</div>
							</div>
						</>
					)
				}
			</>
		)
	}
}
