import { Api } from 'typescript-fetch-api'
import { Dispatch, Action } from 'redux'
import { RootStoreState } from 'modules/root'
import { loadDocument, saveDocument } from 'modules/documents/actions'
import { Document } from 'modules/documents/types'
import * as _ from 'lodash'
import { v4 as uuid } from 'uuid'

/**
 * Interface for properties that the container passes to the component.
 */
export interface DocumentScreenProps<T> {
	documentRef?: string
	document?: Document<T>
	languages?: Api.Language[]
	loading: boolean
	editingMode: boolean
	docExtended?: DeepReadonlyObject<Api.ClientDocument>
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface DocumentScreenActions<T> {
	onLoadDocument: (documentRef: string) => void
	onSaveDocument: (document: Document<T>) => void
}

export type DocumentScreenComponentProps<T> = DocumentScreenProps<T> & DocumentScreenActions<T>

/** Populate the Props from the store state. */
export function documentScreenMapStateToProps<T extends object>(state: RootStoreState, documentType: string, defaultName: string, defaultValue: T, unique: boolean): DocumentScreenProps<T> {
	const languages: Api.Language[] = []

	languages.push({
		ref: '0',
		name: 'English',
	})
	if (state.common.settings) {
		state.common.settings.languages.forEach(language => {
			languages.push({
				ref: language.ref,
				name: language.name,
			})
		})
	}

	const documents = state.documents.documents ? _.values(state.documents.documents.documents).filter(d => d.documentType === documentType) : undefined
	if (documents === undefined) {
		if (state.documents.loadingDocuments) {
			/* We must wait for documents to load */
			return {
				loading: true,
				editingMode: false,
			}
		} else {
			return {
				loading: false,
				editingMode: false,
			}
		}
	}

	const documentSummary = documents.length > 0 ? documents[0] : undefined
	if (!documentSummary) {
		/* We are creating a new document */
		const newDocument: Document<T> = {
			ref: uuid(),
			name: defaultName,
			documentType: documentType,
			data: defaultValue,
			unique,
		}
		return {
			documentRef: newDocument.ref,
			document: newDocument,
			editingMode: true,
			languages,
			loading: false,
		}
	}

	if (state.documents.loadingDocument === documentSummary.ref) {
		/* We are currently loading the document we want */
		return {
			loading: true,
			editingMode: true,
		}
	}

	if (!state.documents.currentDocument || state.documents.currentDocument.ref !== documentSummary.ref) {
		/* We need to load the document */
		return {
			loading: false,
			documentRef: documentSummary.ref,
			editingMode: true,
		}
	}

	const document: Document<T> = {
		ref: state.documents.currentDocument.ref,
		name: state.documents.currentDocument.name,
		documentType: state.documents.currentDocument.documentType,
		// tslint:disable-next-line:no-any
		data: state.documents.currentDocumentData as T,
		unique,
	}

	return {
		documentRef: document.ref,
		document,
		languages: languages,
		loading: false,
		editingMode: true,
		docExtended: state.documents.currentDocument,
	}
}

/** Populate the Actions with the callbacks for the component. */
export function documentScreenMapDispatchToProps<T extends object>(dispatch: Dispatch<Action>): DocumentScreenActions<T> {
	return {
		onLoadDocument: (documentRef) => {
			dispatch(loadDocument({
				documentRef,
			}))
		},
		onSaveDocument: (document) => {
			dispatch(saveDocument({
				documentRef: document.ref,
				request: document,
			}))
		},
	}
}
