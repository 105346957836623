/* Import the component from the component path */
import Component from '../components/SupportWorkerTile'
import { Api } from 'typescript-fetch-api'

import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'

/* Import module actions */
import * as a from '../actions'
import * as s from '../selectors'
import * as bs from 'modules/booking/selectors'
import * as ba from 'modules/booking/actions'

/** Interface for properties that are passed to the container/ */
export interface OwnProps {
	supportWorkerRef: string
	showPreference?: boolean
}

/** Interface for properties that the container passes to the component. */
export interface Props {
	supportWorker?: DeepReadonly<Api.SupportWorker>
	relationship?: DeepReadonly<Api.ClientSupportWorkerRelationship>
	prevAppointment?: DeepReadonly<Api.AppointmentSummary>
	nextAppointment?: DeepReadonly<Api.AppointmentSummary>
	preferenceLevel?: number
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
	onMakeFavourite: () => void
	onMakeHidden: () => void
	onViewDetail: () => void
	onPreferenceLevelChange: (level?: number) => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	const supportWorker = s.supportWorkerForRef(state, ownProps.supportWorkerRef)
	const relationship = supportWorker && s.clientSupportWorkerRelationshipForSupportWorkerRef(state, ownProps.supportWorkerRef)
	const prevAppointment = relationship && relationship.previousAppointmentRef ? s.appointmentByRef(state, relationship.previousAppointmentRef) : undefined
	const nextAppointment = relationship && relationship.nextAppointmentRef ? s.appointmentByRef(state, relationship.nextAppointmentRef) : undefined
	const preferenceLevel = relationship && bs.preferenceLevelByRef(state, ownProps.supportWorkerRef)
	return {
		supportWorker,
		relationship,
		prevAppointment,
		nextAppointment,
		preferenceLevel,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: OwnProps): Actions => ({
	onMakeFavourite: () => {
		dispatch(a.createFavourite(ownProps.supportWorkerRef))
	},
	onMakeHidden: () => {
		dispatch(a.createBlock(ownProps.supportWorkerRef))
	},
	onViewDetail: () => {
		dispatch(a.viewDetail(ownProps.supportWorkerRef))
	},
	onPreferenceLevelChange: (level) => {
		dispatch(ba.preferenceLevelChange({
			supportWorkerRef: ownProps.supportWorkerRef,
			level,
		}))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
