import Component from '../components/Header'
import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'
import { push } from 'connected-react-router'
/* Import RootStoreState */
import { RootStoreState } from 'modules/root'
import { accessTokenSelector } from 'modules/auth/selectors'

/* Import module actions */
// import * as actions from '../actions'
import { ClientView } from 'modules/account/types'
import { currentClientViewSelector } from 'modules/account/selectors'
import { featuresSelector } from '../selectors'
import * as authActions from 'modules/auth/actions'

export interface OwnProps {
	section: string
}

/**
 * Interface for properties that the container passes to the component.
 */
export interface Props {
	loggedIn: boolean
	currentClientView?: ClientView
	hasFunderAccess: boolean
	findSupportWorkers: boolean
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
	onRegisterForCare: () => void
	onSignOut: () => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	const user = state.account.user

	return {
		loggedIn: accessTokenSelector(state) !== undefined,
		currentClientView: currentClientViewSelector(state),
		hasFunderAccess: (user && user.organisationRefs && user.organisationRefs.length > 0) ? true : false,
		findSupportWorkers: featuresSelector(state).findSupportWorkers,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	onRegisterForCare: () => {
		dispatch(push('/setup/register-new/care'))
	},
	onSignOut: () => {
		dispatch(authActions.logoutRequest())
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
