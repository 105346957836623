import { Api } from 'typescript-fetch-api'
import { LocalDate, LocalTime } from '@js-joda/core'

///////////////////////////////////////////////////////////////////////////////////////////////

export interface StoreRoster {
	roster?: Api.ClientCalendar
	startDate?: string
	endDate?: string
	lastUpdated?: string
}

///////////////////////////////////////////////////////////////////////////////////////////////

/** The information required to render the calendar for a month. */
export interface CalendarView {
	date: LocalDate
	originalDate: LocalDate
	weeks: WeekView[]
	previousMonth: LocalDate
	nextMonth: LocalDate
	canPreviousMonth: boolean
	canNextMonth: boolean
}

/** A calendar week */
export interface WeekView {
	days: DayView[]
	totalMinutes: number
	thisWeek: boolean
}

/** A calendar day */
export interface DayView {
	date: LocalDate
	/** This day is not from the current month */
	otherMonth?: boolean
	jobs: JobView[]
	totalMinutes: number
	today: boolean
}

/** Represents a job bubble shown on the calendar, so it only contains enough data
 * to be displayed there.
 */
export interface JobView {
	date: LocalDate
	startTime?: LocalTime
	startTimeOption: Api.Appointment.StartTimeOptionEnum
	totalMinutes: number
	supportWorker?: SupportWorkerView
	ref: string
	status: JobViewStatus
}

export interface SupportWorkerView {
	name: string
	image?: string
	ref: string
}

export enum JobDetailViewEnum {
	JobDetails,
	Reschedule,
	ChangeSupportWorker,
	CancelJob,
}

export enum JobChangeReturnLocation {
	Calendar,
	Timesheet,
}

/** A job on a day (may actually be a Job or a Budget in Care~Call parlance) */
export interface JobDetailView {
	actualDurationMins?: number
	actualStartTime?: LocalTime
	date: LocalDate
	startTime?: LocalTime
	startTimeOption: Api.Appointment.StartTimeOptionEnum
	endTime?: LocalTime
	durationMins?: number
	durationOption: Api.Appointment.DurationOptionEnum
	services?: DeepReadonlyArray<Api.JobType>
	supportWorkerRef?: string
	repeatStartDate?: LocalDate
	repeatEndDate?: LocalDate
	repeatOption: Api.Appointment.RepeatOptionEnum
	repeatOptionDisplayName?: string
	repeatFrequencyInWeeks?: number

	supportWorker?: SupportWorkerDetailView

	ref: string

	status: JobViewStatus

	timesheetRef?: string
}

export interface SupportWorkerDetailView {
	name: string
	fullName: string
	image?: string
	ref: string
}

/** The status of a job */
export enum JobViewStatus {
	/** Job has a support worker and is ready to go */
	Active,
	/** Job has been cancelled */
	Cancelled,
	Completed,
	/** Job is in the schedule but doesn't have a support worker yet */
	NotAllocated,
	/** Job request */
	Pending,
	PendingCancellation,
	/** Things went bad */
	Unknown,
}

export interface JobRequestDetailView {
	date: LocalDate
	startTime?: LocalTime
	startTimeOption: Api.Appointment.StartTimeOptionEnum
	endTime?: LocalTime
	durationMins?: number
	durationOption: Api.Appointment.DurationOptionEnum
	services?: DeepReadonlyArray<Api.JobType>
	repeatStartDate?: LocalDate
	repeatEndDate?: LocalDate
	repeatOption: Api.Appointment.RepeatOptionEnum
	repeatOptionDisplayName?: string

	ref: string
}

export interface Funding {
	hasFunding: boolean
	minutes?: number
	jobTypes?: FundingJobType[]
}

export interface FundingJobType {
	jobType: Api.JobType
	funderRef: string
	funderName?: string
	minutes: number
	fundedTimeAllocatedThisWeekMins?: number
}
