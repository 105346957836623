import * as React from 'react'
import Moment from 'react-moment'

interface OwnProps {
	transactionId: string
	date: string
	description: string
	amount: string
	balance: string
}

interface State {

}

const INITIAL_STATE: State = {
}

export default class Transaction extends React.Component<OwnProps, State> {
	
	public state = INITIAL_STATE

	public render() {

		return (
			<tr>
				<td>
					<p><Moment date={this.props.date} format="D MMM YYYY" /></p>
				</td>
				<td>
					<p>{this.props.description}</p>
				</td>
				<td>
					<p>{this.props.amount}</p>
				</td>
				<td>
					<p>{this.props.balance}</p>
				</td>
			</tr>
		)
	}
}