import * as React from 'react'
import * as t from '../types'
import * as cf from 'modules/calendar/functions'
import _ from 'lodash'

interface OwnProps {
	summary: t.TimesheetHoursSummary
}

export default class TotalHoursPerPerson extends React.Component<OwnProps> {

	public render() {
		const { summary } = this.props

		const supportWorkers = _.values(summary.supportWorkers)
		supportWorkers.sort((a, b) => a.supportWorker.name.localeCompare(b.supportWorker.name))

		const fundings = _.values(summary.fundings)
		fundings.sort((a, b) => a.jobTypeRef.localeCompare(b.jobTypeRef))

		return (
			<div className="table-wrapper">
				<div className="title">Hours per person</div>
				<table className="table table-default">
					<thead>
						<tr>
							<th scope="col">Name</th>
							<th scope="col">Type</th>
							<th scope="col">Allocated</th>
						</tr>
					</thead>
					<tbody>
						{supportWorkers.map((sw, index) => Object.keys(sw.jobTypes).map((jobTypeRef, index2) => {
							const jt = fundings.find(f => f.jobTypeRef === jobTypeRef)
							if (!jt) {
								return null
							}
							return (
								<tr key={`${index}-${index2}`}>
									<td>{sw.supportWorker.name}</td>
									<td>{jt.jobTypeName || jt.jobTypeRef}</td>
									<td>{cf.formatDuration(sw.jobTypes[jt.jobTypeRef].totalMinutes, true)}</td>
								</tr>
							)
						}))}
					</tbody>
				</table>
			</div>
		)
	}
}