import * as React from 'react'
import DefaultHelpScreen from './DefaultHelpScreen'
import SuemannHelpScreen from './suemann/SuemannHelpScreen'
import platform, { Brand } from 'modules/platform'

export default class HelpScreen extends React.Component {

	public render() {
		const currentBrand = platform.brand()
		switch (currentBrand) {
			case Brand.Default:
				return <DefaultHelpScreen />
			case Brand.Hcnz:
				return <DefaultHelpScreen />
			case Brand.Suemann:
				return <SuemannHelpScreen />
			default:
				return <DefaultHelpScreen />
		}
	}
}
