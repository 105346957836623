import platform, { Brand } from 'modules/platform'

const currentBrand = platform.brand()

export const community = (currentBrand === Brand.Default)
export const repeatBooking = (currentBrand !== Brand.Suemann)
export const cancelBookingFee = (currentBrand === Brand.Suemann)
export const registerForCare = true
export const createAccount = (currentBrand !== Brand.Suemann)
export const pendingRequests = (currentBrand === Brand.Suemann)
export const orders = (currentBrand === Brand.Suemann)
