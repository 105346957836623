import * as React from 'react'
import { Snapshot, forComponentProps, Input, wrapComponent } from 'changeling'
import { ThreeQuestionValue } from 'modules/health-passport/types'

export interface OwnProps extends Snapshot<ThreeQuestionValue | undefined> {
	subOrder?: string
	title: string
	questionOne: string
	questionTwo?: string
	questionThree?: string
	imageLocation?: string
	secondaryQuestion?: boolean
	editingMode?: boolean
}

class ThreeQuestion extends React.Component<OwnProps> {
	private changeling = forComponentProps(this)

	public render() {
		return (
			<div className={'form-item -three-question' + (this.props.secondaryQuestion ? ' -secondary' : '') + (this.props.editingMode ? '' : ' -valuemode')}>
				<p className="title">
					{ this.props.subOrder && 
						<span className="title-order">{this.props.subOrder}</span>
					}
					{this.props.title}
				</p>
				<div className="input-wrapper">
					<div className="content-wrapper"> 
						{
							this.props.editingMode ? (
								<>
									<div className="question">
										<p className="sub-text">{this.props.questionOne}</p>
										<div className="form-input -text">
											<Input.String type="text" className="field" controller={this.changeling} prop="valueOne" />
										</div>
									</div>
									{ this.props.questionTwo &&
									<div className="question">
										<p className="sub-text">{this.props.questionTwo}</p>
										<div className="form-input -text">
											<Input.String type="text" className="field" controller={this.changeling} prop="valueTwo"/>
										</div>
									</div>
									}
									{ this.props.questionThree &&
									<div className="question">
										<p className="sub-text">{this.props.questionThree}</p>
										<div className="form-input -text">
											<Input.String type="text" className="field" controller={this.changeling} prop="valueThree"/>
										</div>
									</div>
									}
								</>
							) : (
								<div className="value-wrapper">
									{this.props.questionOne && <p className="sub-text"><span className="title">{this.props.questionOne}: </span>{this.props.value && this.props.value.valueOne}</p>}
									{this.props.questionOne && <p className="sub-text"><span className="title">{this.props.questionTwo}: </span>{this.props.value && this.props.value.valueTwo}</p>}
									{this.props.questionOne && <p className="sub-text"><span className="title">{this.props.questionThree}: </span>{this.props.value && this.props.value.valueThree}</p>}
								</div>
							)
						}
					</div>
					<div className="image-wrapper">
						{
							this.props.imageLocation &&
								<img src={this.props.imageLocation} />
						}
					</div>
				</div>
			</div>
		)
	}
}

export default wrapComponent(ThreeQuestion)
