import * as React from 'react'
import AvatarEditor from 'react-avatar-editor'
import DropZone from 'react-dropzone'
import CanvasExifOrientation from 'canvas-exif-orientation'
import * as piexifjs from 'piexifjs'
import { withModal, ModalStyle } from 'modules/common/modal-hoc'
import { avatarPlaceholder } from 'modules/root/theme'

interface OwnProps {
	onDone: (image?: string) => void
	onClose: () => void
}

/**
 * Interface for private internal component state.
 */
interface State {
	image?: string | File
	scale: number
}

/**
 * The initial state for our internal component state.
 */
const INITIAL_STATE: State = {
	scale: 1.0,
}

export default withModal(
	class AvatarModal extends React.Component<OwnProps, State> {

		public state = INITIAL_STATE

		private editorRef: React.RefObject<AvatarEditor> = React.createRef()

		public render() {
			const AVATAR_DIMENSION = 190

			return (
				<div className="avatar-form">
					<div className="editcontrols">
						<div className="preview">
							<DropZone
								onDrop={() => this.handleDrop}
								noClick={true}
							>
								{() => (
									<div style={{ width: `${AVATAR_DIMENSION}px`, height: `${AVATAR_DIMENSION}px` }}>
										<AvatarEditor
											image={this.state.image || avatarPlaceholder()}
											width={AVATAR_DIMENSION} 
											height={AVATAR_DIMENSION}
											border={0}
											borderRadius={AVATAR_DIMENSION / 2.0}
											color={[255, 255, 255, 1]}
											scale={this.state.scale}
											ref={this.editorRef}
										/>
									</div>
								)}
							</DropZone>
						</div>
						
						<div className="controls">
							<div className="row">
								<label className="upload"><div className="button-link -secondary -small">Upload file</div> <input className="input" type="file" onChange={this.handleFileChoice} /></label>
							</div>
							{this.state.image && (
								<div className="row">
									<label className="_sr-only">Zoom:</label>
									<div className="zoom">
										<input className="range-slider" type="range" onChange={this.handleRange} min={1.0} max={5} step={0.01} value={this.state.scale} disabled={!this.state.image} />
									</div>
								</div>
							)}
							<div className="row">
								<p>Use the zoom control to resize, and drag the image to position.</p>
							</div>
						</div>
					</div>
					<div className="button-group">
						<div className="button">
							<a href="#" onClick={this.handleCancel} className="button-link -text">Cancel</a>
						</div>
						<div className="button">
							<button onClick={this.handleDone} disabled={!this.state.image} className="button-link -action">Save</button>
						</div>
					</div>
				</div>
			)
		}

		private handleDrop = (accepted: File[], rejected: File[], event: React.DragEvent<HTMLDivElement>) => {
			if (accepted.length) {
				this.setState({ image: accepted[0] })
			}
		}

		private correctImageOrientation = (imageDataURL: string, callback: (imageDataURL: string) => void) => {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			let exif: any
			try {
				exif = piexifjs.load(imageDataURL)
			} catch (error) {
				callback(imageDataURL)
				return
			}
			const orientation = exif['0th'][piexifjs.ImageIFD.Orientation]

			if (orientation && orientation !== 1) {
				const image = new Image()
				image.src = imageDataURL
				image.onload = () => {
					const transformed = CanvasExifOrientation.drawImage(image, orientation).toDataURL()
					if (transformed) {
						callback(transformed)
					} else {
						callback(imageDataURL)
					}
				}
			} else {
				callback(imageDataURL)
			}
		}

		private handleFileChoice = (evt: React.ChangeEvent<HTMLInputElement>) => {
			if (evt.target.files && evt.target.files[0]) {
				const reader = new FileReader()
				reader.onload = (e) => {
					if (e.target) {
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						this.correctImageOrientation((e.target as any).result, (image) => {
							this.setState({ image })
						})
					}
				}
				reader.readAsDataURL(evt.target.files[0])
			}
		}

		private handleRange = (evt: React.ChangeEvent<HTMLInputElement>) => {
			this.setState({ scale: evt.target.valueAsNumber })
		}

		private handleDone = (evt: React.MouseEvent) => {
			evt.preventDefault()
			document.body.className = ''

			if (!this.state.image) {
				return
			}

			if (this.editorRef.current) {
				const image = this.editorRef.current.getImageScaledToCanvas()
				const imageDataURL = image.toDataURL('image/jpeg', 0.8)
				this.props.onDone(imageDataURL)
			}
		}

		private handleCancel = (evt: React.MouseEvent) => {
			evt.preventDefault()
			document.body.className = ''
			this.props.onDone()
		}
	},
	ModalStyle.Narrow)
