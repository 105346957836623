import * as React from 'react'
import { OwnProps, Props, Actions } from '../containers/Visit'
import moment from 'moment'
import * as commonFunctions from 'modules/common/functions'
import { avatarPlaceholder } from 'modules/root/theme'

export default class Visit extends React.Component<OwnProps & Props & Actions> {
	
	public render() {
		const { job, jobTypes } = this.props
		
		return (
			<tr>
				<th scope="row" className="date">{moment(job.date).format('dddd D MMM, YYYY')}</th>
				<td className="time">{moment(job.time.startTime).format('h:mm')} – {moment(job.time.endTime).format('h:mma')}</td>
				<td className="with">
					{
						this.props.findSupportWorkers ? (
							<a href="#" className="person" onClick={this.onViewDetail}>
								<figure className="avatar-image -medium">
									<div className="image">
										<img src={(job.supportWorker && job.supportWorker.image) || avatarPlaceholder()} alt={job.supportWorker && job.supportWorker.name} />
									</div>
								</figure>
								<span className="name">{job.supportWorker && job.supportWorker.name}</span>
							</a>
						) : (
							<div className="person">
								<figure className="avatar-image -medium">
									<div className="image">
										<img src={(job.supportWorker && job.supportWorker.image) || avatarPlaceholder()} alt={job.supportWorker && job.supportWorker.name} />
									</div>
								</figure>
								<span>{job.supportWorker && job.supportWorker.name}</span>
							</div>
						)
					}
				</td>
				{(job.services && job.services.length > 0 && jobTypes) ? (
					<td className="type">{commonFunctions.getJobTypeName(jobTypes, job.services[0])}</td>
				) : (
					<td className="type">Home help</td>
				)}
				{this.props.featureJobFeedback &&
				<td className="feedback" onClick={() => this.props.showModal(job.appointmentRef, job.feedback)}><a href="#" className="document-link -add"><span className="text">Add<span className="_mobile-only"> feedback</span></span></a></td>
				}
			</tr>
		)
	}

	private onViewDetail = (evt: React.MouseEvent) => {
		evt.preventDefault()
		if (this.props.job.supportWorker) {
			this.props.onViewDetail(this.props.job.supportWorker.ref)
		}
	}
}