import actionCreatorFactory from 'typescript-fsa'
import { Api } from 'typescript-fetch-api'
import * as t from './types'
import * as registerTypes from 'modules/register/types'

const actionCreator = actionCreatorFactory('Setup')

export const startRegisterClient = actionCreator<t.RequestCareForEnum>('START_REGISTER_CLIENT')

export const updateClientDetails = actionCreator<Api.RegisterClientRequest>('UPDATE_CLIENT_DETAILS')

export const registerNewClient = actionCreator<Api.RegisterClientRequest>('REGISTER_CLIENT')
export const registerNewClientAsync = actionCreator.async<Api.RegisterClientRequest, Api.RegisterClientResponse, Error>('REGISTER_CLIENT')
export type RegisterNewClientAction = ReturnType<typeof registerNewClient>
export type RegisterNewClientAsyncDoneAction = ReturnType<typeof registerNewClientAsync.done>

export interface PopulateClientPayload {
	yourDetails: registerTypes.YourDetails
	email: string
}

export const acceptSupportNetworkInvite = actionCreator<string>('ACCEPT_INVITE')
export const acceptSupportNetworkInviteAsync = actionCreator.async<string, never, Api.AddUserToClientSupportNetworkError>('ACCEPT_INVITE')
export type AcceptSupportNetworkInviteAction = ReturnType<typeof acceptSupportNetworkInvite>
export type AcceptSupportNetworkInviteDoneAction = ReturnType<typeof acceptSupportNetworkInviteAsync.done>
