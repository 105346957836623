import { toast } from 'react-toastify'

export function showToastSuccessMessage(message: string) {
	toast.dismiss()
	toast.success(message, {
		className: 'toast-content -success',
		autoClose: 5000,
	})
}

export function showToastFailedMessage(message: string) {
	toast.dismiss()
	toast.error(message, {
		className: 'toast-content -error',
		autoClose: 5000,
	})
}
