/**
 * Component template.
 * 
 * Note that this file has a `.tsx` suffix, as it contains React elements.
 */

import React, { useEffect, useRef } from 'react'
import { useIntercom } from 'react-use-intercom'
import getApi from 'modules/api'
import { Props } from '../containers/Footer'

async function loadFooterHtml() {
	try {
		const text = await getApi().cmsApi.getCMSContentRequest('/cms/footer')
		if (text instanceof Blob) {
			return await text.text()
		} else {
			return text
		}
	} catch (reason) {
		console.error(`Failed to load footer: ${reason}`)
		return undefined
	}
}

export default function Footer(props: Props) {
	const { user } = props
	const containerRef = useRef<HTMLDivElement>(null)
	const { boot, show } = useIntercom()

	useEffect(function() {
		loadFooterHtml().then(function(footerHtml) {
			if (containerRef.current && footerHtml) {
				containerRef.current.innerHTML = footerHtml
	
				/* Intercom link */
				const messageLink = document.getElementById('message-link')
				if (messageLink) {
					messageLink.addEventListener('click', (evt: MouseEvent) => {
						evt.preventDefault()
						show()
					})
				}
			}
		})
	}, [show])

	useEffect(function() {
		if (user) {
			boot({
				hideDefaultLauncher: true,
				name: user.fullName,
			})
		} else {
			boot({
				hideDefaultLauncher: true,
			})
		}
	}, [boot, user])

	return (
		<footer className="site-footer _no-print" ref={containerRef} />
	)

}
