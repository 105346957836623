import * as React from 'react'
import { AdvanceCarePlanFormProps } from './AdvanceCarePlanScreen'
import { forComponentProps, wrapComponent } from 'changeling'
import PowerOfAttorney from './PowerOfAttorney'
import ImportantPeople from './ImportantPeople'
import Radio from 'modules/health-passport/components/Radio'
import InlineTextBox from 'modules/health-passport/components/InlineTextBox'

type MyComponentProps = AdvanceCarePlanFormProps

class AdvanceCarePlanStepOne extends React.Component<MyComponentProps> {
	private changeling = forComponentProps(this)

	public render() {
		const { editingMode, value } = this.props

		return (
			<>
				<h2 className="section-header">My Enduring Power of Attorney <span className="sub-header">(for personal care and welfare)</span></h2>
				<PowerOfAttorney
					editingMode={editingMode}
					prop="powerOfAttorney"
					controller={this.changeling}
				/>

				<h2 className="section-header">Please try to include the following people in decisions about my care</h2>
				<ImportantPeople
					editingMode={editingMode}
					prop="importantPeople"
					controller={this.changeling}
				/>
				<h2 className="section-header">I have made a will</h2>
				<Radio 
					editingMode={editingMode}
					secondaryOption="Yes I have made a will"
					defaultOption="No, I Have not made a will"
					inlineOptions={true}
					prop="hasWill"
					controller={this.changeling}
				/>
				{
					value.hasWill &&
					<InlineTextBox 
						editingMode={editingMode}
						title="My will is held by"
						prop="willHolder"
						controller={this.changeling}
					/>
				}
			</>
		)
	}
}

export default wrapComponent(AdvanceCarePlanStepOne)
