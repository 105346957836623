import { reducerWithInitialState } from 'typescript-fsa-reducers'

/* Import our module's actions */
import * as a from './actions'
import { Api } from 'typescript-fetch-api'
import { chooseClient } from 'modules/account/actions'
import produce from 'immer'

/**
 * Export the StoreState interface for this module. We always name this interface
 * `StoreState` so it is consistent across each of our modules.
 * We export a readonly version of the interface, to try to prevent modifications,
 * and retain a private (not exported) MutableStoreState for ourselves to use very
 * carefully in the reducers, if we need to.
 */
export type StoreState = DeepReadonly<MutableStoreState>

export interface MutableStoreState {
	loadingDocuments?: boolean
	documents?: Api.ClientDocumentsResponseRefs
	documentsError?: Api.ClientDocumentsError

	/** The documentRef of the currently loading document */
	loadingDocument?: string
	currentDocument?: Api.ClientDocument
	currentDocumentData?: object
	currentDocumentError?: Api.ClientDocumentError

	savingDocument?: string
}

/**
 * The initial store state for this module.
 */
const INITIAL_STATE: StoreState = {

}

/**
 * Reducer function for this module.
 */
export const reducer = reducerWithInitialState(INITIAL_STATE)

reducer.case(chooseClient, (): StoreState => INITIAL_STATE)

reducer.case(a.loadDocumentAsync.started, (state, payload): StoreState => produce(state, draft => {
	draft.loadingDocument = payload.documentRef
	draft.currentDocument = undefined
	draft.currentDocumentData = undefined
	draft.currentDocumentError = undefined
}))
reducer.case(a.loadDocumentAsync.done, (state, payload): StoreState => produce(state, draft => {
	if (draft.loadingDocument === payload.params.documentRef) {
		draft.currentDocument = payload.result.payload
		draft.currentDocumentData = JSON.parse(payload.result.payload.data)
		draft.currentDocumentError = undefined
		draft.loadingDocument = undefined
	}
}))
reducer.case(a.loadDocumentAsync.failed, (state, payload): StoreState => produce(state, draft => {
	if (draft.loadingDocument === payload.params.documentRef) {
		draft.currentDocument = undefined
		draft.currentDocumentData = undefined
		draft.currentDocumentError = payload.error
		draft.loadingDocument = undefined
	}
}))

reducer.case(a.getDocumentsAsync.started, (state, payload): StoreState => produce(state, draft => {
	draft.loadingDocuments = true
}))
reducer.case(a.getDocumentsAsync.done, (state, payload): StoreState => produce(state, draft => {
	draft.documents = payload.result.refs
	draft.loadingDocuments = false
}))
reducer.case(a.getDocumentsAsync.failed, (state, payload): StoreState => produce(state, draft => {
	draft.documentsError = payload.error
	draft.loadingDocuments = false
}))

reducer.case(a.saveDocument, (state, payload): StoreState => produce(state, draft => {
	if (!draft.currentDocument || draft.currentDocument.ref !== payload.documentRef) {
		if (!draft.documents) {
			draft.documents = {
				documents: {},
			}
		}
		if (!draft.documents.documents[payload.documentRef]) {
			draft.currentDocument = {
				ref: payload.documentRef,
				data: '',
				documentType: payload.request.documentType || '',
				name: payload.request.name || '',
				whenCreated: new Date().toISOString(),
				whenUpdated: new Date().toISOString(),
			}

			draft.documents.documents[payload.documentRef] = draft.currentDocument
		} else {
			draft.currentDocument = { 
				...draft.documents.documents[payload.documentRef],
				data: '',
			}
		}
	} else {
		draft.currentDocument.whenUpdated = new Date().toISOString()

		if (draft.documents && !draft.documents.documents[payload.documentRef]) {
			draft.documents.documents[payload.documentRef].whenUpdated = draft.currentDocument.whenUpdated
		}
	}
	if (payload.request.data) {
		draft.currentDocumentData = payload.request.data
	}
	if (payload.request.name) {
		draft.currentDocument.name = payload.request.name
	}
	draft.savingDocument = payload.documentRef
}))

reducer.case(a.saveDocumentAsync.done, (state, payload): StoreState => produce(state, draft => {
	draft.savingDocument = undefined
	if (draft.documents && draft.documents.documents[payload.result.payload.ref]) {
		const doc = draft.documents.documents[payload.result.payload.ref]
		doc.whenUpdated = payload.result.payload.whenUpdated
	}
}))

reducer.case(a.saveDocumentAsync.failed, (state, payload): StoreState => produce(state, draft => {
	draft.savingDocument = undefined
}))
