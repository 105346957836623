import * as React from 'react'

export default class MedicalStaffNotes extends React.Component {
	public render() {
		return (
			<div className="form-item -background -infobox">
				<div className="form-wrapper">
					<div className="content-wrapper">
						<div className="form-input -text">
							<h2>Notes for medical and support staff:</h2>
							<ul>
								<li>If you are involved with my care and support, please read this passport.</li>
								<li>This is not my Medical Record. This passport gives information about:</li>
								<ul>
									<li>Things you MUST know about me (Section A)</li>
									<li>Things that are important to me (Section B)</li>
									<li>Other useful information (Section C)</li>
								</ul>
								<li>This passport stays with me in hospital. Please ensure I take it with me when I leave.</li>
							</ul>
						</div>
					</div> 
				</div>
			</div>
		)
	}
}