import Component from '../components/IndividualisedFundingScreen'
import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'
import { withRouter } from 'react-router'
import { Api } from 'typescript-fetch-api'

import { RootStoreState } from 'modules/root'

export interface OwnProps {
	individualisedFundingId: string
}

/**
 * Interface for properties that the container passes to the component.
 */
export interface Props {
	individualisedFunding: DeepReadonlyObject<Api.IndividualisedFunding>
	funder?: DeepReadonly<Api.Funder>
	transactions: DeepReadonlyObject<{ [key: string]: Api.IndividualisedFundingTransaction }>
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	const individualisedFunding = state.calendar.refs.individualisedFunding[ownProps.individualisedFundingId]
	return {
		individualisedFunding,
		funder: individualisedFunding ? state.calendar.refs.funders[individualisedFunding.funderRef] : undefined,
		transactions: state.calendar.refs.transactions,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Component))
