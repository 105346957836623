import * as React from 'react'

export default class EmailSubmitted extends React.Component {
	
	public render() {
		return (
			<article className="site-body">
				<div className="width-limit -site">
					<div className="single-form">
						<form className="form">
							<div className="form-layout">
								<div className="introduction">
									<h1 className="headline-text">Thanks!</h1>
									<div className="body-text">
										<p>We’ve sent you an email to begin the registration process. Please check your email for instructions.</p>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</article>
		)
	}
}
