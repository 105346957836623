import * as React from 'react'
import { Props, Actions } from '../containers/SupportWorkersHidden'

import { RouteComponentProps } from 'react-router'
import SupportWorkerTile from '../containers/SupportWorkerTile'
import { Helmet } from 'react-helmet'

export default class SupportWorkersHidden extends React.Component<Props & Actions & RouteComponentProps<{}>> {

	componentDidMount() {
		if (!this.props.supportWorkerRefs) {
			this.props.onLoadHidden()
		}
	}

	public render() {
		return (
			<form className="supportworkers-form" action="#">
				<Helmet>
					<title>Hidden support workers — My Homecare</title>
				</Helmet>
				<div className="width-limit -site">
					<div className="form-layout -wide">
						{
							this.props.supportWorkerRefs && this.props.supportWorkerRefs.length > 0 ? (
								<div className="row">
									<div className="supportworker-list">
										{this.props.supportWorkerRefs && this.props.supportWorkerRefs.map((swRef, index) => (
											<SupportWorkerTile key={index} supportWorkerRef={swRef} />
										))}
									</div>
								</div>
							) : (
								<div className="row">
									<div className="results-count">
										<p>You can hide support workers that you don't want to use by clicking the 'block/hide' icon.</p>
									</div>
								</div>
							)
						}
					</div>
				</div>
			</form>
		)
	}
}
