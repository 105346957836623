import platform, { Brand } from 'modules/platform'
import * as React from 'react'

const currentBrand = platform.brand()

export const cancelJobSubmitLabel = (currentBrand !== Brand.Suemann) ? 'Cancel Job' : 'Submit Request'
export const requestBookingNote = (currentBrand === Brand.Suemann) ? <><strong>Note: </strong>For booking in the next 48 hours please contact the office directly</> : null

export function bookingSummary(newBooking: boolean): JSX.Element {
	if (currentBrand === Brand.Suemann) {
		return <p><strong>Note:</strong> Once you submit your request, you will receive email notification. Also, please visit the portal for the status of your request.</p>
	} else {
		return <p><strong>Note:</strong> Once you submit this booking {newBooking ? 'request' : 'amendment'} we will call you to confirm availability and any other details.</p>
	}
}

// export const signInScreenNote = (currentBrand === Brand.Suemann) ? <><h2>This is a Beta system</h2> You can access it when you have received an invitation from us.</> : null
export const signInScreenNote = (currentBrand === Brand.Suemann ? <><p>Welcome to the myHomecare client portal.</p><p>To access the portal you first need to register with myHomecare to become one of our valued clients. You can do this by signing up on our website or giving one of our friendly contact team members a call on <a href="tel:1300209020" style={{ whiteSpace: 'nowrap' }}>1300 20 90 20</a>.</p></> : null)
// export const signInScreenNote = null

export const nationalHealthIdentifierName = currentBrand !== Brand.Suemann ? 'NHI number' : null
export const clientSearchPlaceholder = currentBrand !== Brand.Suemann ? 'Enter a name or NHI number' : 'Enter a name'
