/**
 * A component wrapper that pretends to be a route, but if it matches and you're not logged in, it
 * redirects you to the login screen.
 * Based on https://reacttraining.com/react-router/web/example/auth-workflow
 */

import * as React from 'react'
import { Props, Actions } from '../containers/PrivateRoute'
import { Redirect, Route, RouteProps } from 'react-router'
import Waiting from 'modules/common/components/Waiting'
// import ForgottenPassword from '../../auth/containers/ForgottenPassword'

const LOGIN_PATH = '/sign-in'

export default class PrivateRoute extends React.Component<Props & Actions & RouteProps> {
	
	public render() {
		const { loggedIn, ready, component: WhateverComponent, render, children, ...rest } = this.props
		
		return (
			<Route 
				{...rest} 
				render={props => {
					if (loggedIn) {
						if (ready) {
							if (WhateverComponent) {
								return (
									<WhateverComponent {...props} />
								)
							} else if (render) {
								return render(props)
							} else if (children) {
								return children
							} else {
								return null
							}
						} else {
							return <Waiting />
						}
					} else {
						return (
							<Redirect 
								to={{
									pathname: LOGIN_PATH,
									state: { from: props.location },
								}}
							/>
						)
					}
				}}
			/>
		)
	}
}
