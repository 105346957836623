import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { Api } from 'typescript-fetch-api'
import { LocalDate } from '@js-joda/core'

/* Import our module's actions */
import * as a from './actions'
import * as ta from 'modules/timesheet/actions'
import * as ca from 'modules/calendar/actions'
import * as t from './types'
import { produce } from 'immer'

/**
 * Export the StoreState interface for this module. We always name this interface
 * `StoreState` so it is consistent across each of our modules.
 * We export a readonly version of the interface, to try to prevent modifications,
 * and retain a private (not exported) MutableStoreState for ourselves to use very
 * carefully in the reducers, if we need to.
 */
export type StoreState = DeepReadonly<MutableStoreState>

export interface MutableStoreState {
	booking: t.Booking
	submitting: boolean
	supportWorkerPreference: t.BookingSupportWorkersPreference
	editBooking?: t.EditBooking
	editBookingFormState: t.EditBookingFormState
}

/**
 * The initial store state for this module.
 */
const INITIAL_STATE: MutableStoreState = {
	booking: {
		bookings: [t.INITIAL_APPOINTMENT],
		settings: {
			selectSupportWorker: true,
		},
	},
	submitting: false,
	supportWorkerPreference: {},
	editBookingFormState: {
		editTime: false,
		editSupportWorkers: false,
		editRepeating: false,
		chooseOwnSupportWorker: false,
	},
}

/**
 * Reducer function for this module.
 */
export const reducer = reducerWithInitialState(INITIAL_STATE)

reducer.case(a.preferenceLevelChange, (state, payload): MutableStoreState => {
	if (payload.timesheetEdit) {
		return {
			...state, supportWorkerPreference: {
				[payload.supportWorkerRef]: payload.level!,
			},
		} 
	} else {
		return {
			...state, supportWorkerPreference: {
				...state.supportWorkerPreference, 
				[payload.supportWorkerRef]: payload.level!,
			},
		} 
	}
})

reducer.case(a.updateBookingSettings, (state, payload) => ({
	...state, booking: { ...state.booking, settings: payload },
}))

reducer.case(a.updateBookingLine, (state, payload) => produce(state, draft => {
	const index = state.booking.bookings.indexOf(payload.original as t.BookingLine)
	if (index > -1) {
		draft.booking.bookings[index] = payload.updated as t.BookingLine
	}
}))

reducer.case(a.removeBookingLine, (state, payload) => {
	const newBookings = [...state.booking.bookings]
	const index = newBookings.indexOf(payload as t.BookingLine)
	if (index > -1) {
		newBookings.splice(index, 1)
	}

	const result = {
		...state,
		booking: { bookings: newBookings, settings: state.booking.settings },
	}
	
	return result
})

reducer.case(a.addBookingLine, (state) => {
	const newBookings = [...state.booking.bookings]
	const newBookingLine = { ...t.INITIAL_APPOINTMENT }
	newBookings.push(newBookingLine)
	const result = {
		...state,
		booking: { bookings: newBookings, settings: state.booking.settings },
	}
	
	return result
})

reducer.case(a.startBooking, (state): MutableStoreState => INITIAL_STATE)

reducer.case(a.startBookingFromDate, (state, payload) => {
	const newBookings: t.BookingLine[] = []
	const newBookingLine = { ...t.INITIAL_APPOINTMENT }
	newBookingLine.date = payload
	newBookings.push(newBookingLine)
	
	const result: MutableStoreState = INITIAL_STATE
	result.booking.bookings = newBookings
	
	return result
})

reducer.case(ta.startTimesheetBookingFromDate, (state, payload) => {
	const newBookings: t.BookingLine[] = []
	const newBookingLine = { ...t.TIMESHEET_INITIAL_APPOINTMENT }
	newBookingLine.date = payload.date
	newBookings.push(newBookingLine)
	const result = {
		...state,
		booking: { bookings: newBookings, settings: {
			fundingType: Api.NewAppointment.FundingTypeEnum.Funded,
			selectSupportWorker: true,
		} },
		clientSupportWorkerRelationshipPreference: {},
		editBooking: undefined,
	}
	
	return result
})

reducer.case(a.cancelBooking, (state): MutableStoreState => INITIAL_STATE)

reducer.case(a.submitNewBookingAsync.started, (state): MutableStoreState => ({
	...state,
	submitting: true,
}))

reducer.case(a.submitNewBookingAsync.done, (state): MutableStoreState => ({
	...state,
	submitting: false,
	supportWorkerPreference: {},
}))

reducer.case(a.submitNewBookingAsync.failed, (state, { error: { messages } }): MutableStoreState => ({
	...state,
	submitting: false,
}))

reducer.case(a.editBooking, (state, payload) => {
	const result = {
		...state,
		editBooking: {
			calDate: payload.calDate,
			effectiveDate: payload.effectiveDate,
			job: payload.job,
		},
	}

	if (payload.job.timesheetRef && payload.job.supportWorkerRef) {
		result.supportWorkerPreference = {
			[payload.job.supportWorkerRef]: 1,
		}
	}
	
	return result
})

reducer.case(ta.editBooking, (state, payload) => {
	const result = {
		...state,
		editBooking: {
			calDate: payload.calDate,
			effectiveDate: payload.effectiveDate,
			job: payload.job,
		},
	}
	
	if (payload.job.supportWorkerRef) {
		result.supportWorkerPreference = {
			[payload.job.supportWorkerRef]: 1,
		}
	}
	
	return result
})

reducer.case(a.updateEditBookingFormState, (state, payload) => {
	const result = {
		...state,
		editBookingFormState: {
			editRepeating: payload.editRepeating,
			editTime: payload.editTime,
			editSupportWorkers: payload.editSupportWorkers,
			chooseOwnSupportWorker: payload.chooseOwnSupportWorker,
		},
	}
	
	return result
})

reducer.case(a.updateEditBookingDetails, (state, payload) => {
	const result = {
		...state,
		editBooking: {
			effectiveDate: state.editBooking ? state.editBooking.effectiveDate : LocalDate.now(),
			calDate: state.editBooking ? state.editBooking.calDate : LocalDate.now(),
			job: payload.job,
			notes: payload.notes,
		},
	}
	
	return result
})

reducer.case(a.cancelEditBooking, (state): MutableStoreState => ({
	...state,
	editBooking: undefined,
}))

reducer.case(ca.rosterChangeAsync.started, (state): MutableStoreState => ({
	...state,
	submitting: true,
}))

reducer.case(ca.rosterChangeAsync.done, (state): MutableStoreState => ({
	...state,
	submitting: false,
	supportWorkerPreference: {},
	editBookingFormState: {
		editTime: false,
		editSupportWorkers: false,
		editRepeating: false,
		chooseOwnSupportWorker: false,
	},
	editBooking: undefined,
}))

reducer.case(ca.rosterChangeAsync.failed, (state): MutableStoreState => ({
	...state,
	submitting: false,
}))


reducer.case(a.clearEditBooking, (state): MutableStoreState => ({
	...state,
	submitting: false,
	supportWorkerPreference: {},
	editBookingFormState: {
		editTime: false,
		editSupportWorkers: false,
		editRepeating: false,
		chooseOwnSupportWorker: false,
	},
	editBooking: undefined,
}))
