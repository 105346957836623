import * as React from 'react'
import GoogleMapLoader from 'react-google-maps-loader'
import GooglePlacesSuggest from 'react-google-places-suggest'
import { forComponentProps, wrapComponent, Input, Snapshot } from 'changeling'
import { PowerOfAttorneyValue } from '../types'
import platform from 'modules/platform'

interface State {
	focused: boolean
}

const INITIAL_STATE: State = {
	focused: false,
}

export interface OwnProps extends Snapshot<PowerOfAttorneyValue | undefined> {
	editingMode?: boolean
}

class PowerOfAttorney extends React.Component<OwnProps> {
	public state = INITIAL_STATE
	private changeling = forComponentProps(this)

	public render() {
		const { editingMode, value } = this.props

		return (
			<div className={'form-item' + (editingMode ? ' -no-gutters' : ' -valuemode')}>
				{
					editingMode ? (
						<div className="form-layout">
							<div className="row -split -thirds">
								<div className="form-field ">
									<label className="label">Given name(s)</label>
									<div className="form-input -text">
										<Input.String type="text" className="field" controller={this.changeling} prop="givenName" />
									</div>
								</div>
								<div className="form-field">
									<label className="label">Family name</label>
									<div className="form-input -text">
										<Input.String type="text" className="field" controller={this.changeling} prop="familyName" />
									</div>
								</div>
								<div className="form-field">
									<label className="label">Relationship</label>
									<div className="form-input -text">
										<Input.String type="text" className="field" controller={this.changeling} prop="relationship" />
									</div>
								</div>
							</div>
							<div className="row">
								<div className="form-field">
									<label className="label">Address</label>
									<div className="form-input -text">
										<GoogleMapLoader
											params={{
												key: platform.googleApiKey(),
												libraries: 'places,geocode',
											}}
											render={googleMaps =>
												googleMaps && (
													<GooglePlacesSuggest
														googleMaps={googleMaps}
														autocompletionRequest={{
															input: this.state.focused && value && value.address ? value.address : '',
															componentRestrictions: platform.googlePlacesComponentRestrictions(),
														}}
														onSelectSuggest={this.handleAddressSelectSuggest}
														textNoResults="No results"
														customRender={prediction => (
															<>
																{prediction
																	? prediction.description
																	: 'No results'}
															</>
														)}
													>
														<Input.String
															type="text"
															controller={this.changeling}
															className="address-field"
															prop="address"
															placeholder="Search a location"
															onFocus={this.handleOnFocus}
															onBlur={this.handleOnBlur}
														/>
													</GooglePlacesSuggest>
												)
											}
										/>
									</div>
								</div>
							</div>
							<div className="row -split -thirds">
								<div className="form-field ">
									<label className="label">Home phone</label>
									<div className="form-input -text">
										<Input.String type="text" className="field" controller={this.changeling} prop="homePhone" />
									</div>
								</div>
								<div className="form-field">
									<label className="label">Daytime phone</label>
									<div className="form-input -text">
										<Input.String type="text" className="field" controller={this.changeling} prop="daytimePhone" />
									</div>
								</div>
								<div className="form-field">
									<label className="label">Mobile phone</label>
									<div className="form-input -text">
										<Input.String type="text" className="field" controller={this.changeling} prop="mobilePhone" />
									</div>
								</div>
							</div>
						</div>
					) : (value &&
						<div className="value-wrapper">
							{<p className="sub-text"><span className="title">Given name: </span>{value.givenName && value.givenName}</p>}
							{<p className="sub-text"><span className="title">Family name: </span>{value.familyName && value.familyName}</p>}
							{<p className="sub-text"><span className="title">Relationship: </span>{value.relationship && value.relationship}</p>}
							{<p className="sub-text"><span className="title">Address: </span>{value.address && value.address}</p>}
							{<p className="sub-text"><span className="title">Home phone: </span>{value.homePhone && value.homePhone}</p>}
							{<p className="sub-text"><span className="title">Daytime phone: </span>{value.daytimePhone && value.daytimePhone}</p>}
							{<p className="sub-text"><span className="title">Mobile phone: </span>{value.mobilePhone && value.mobilePhone}</p>}
						</div>
					)
				}
			</div>
		)
	}

	private handleOnFocus = () => {
		this.setState({
			focused: true,
		})
	}

	private handleOnBlur = () => {
		this.setState({
			focused: false,
		})
	}

	private handleAddressSelectSuggest = (geocodedPrediction: google.maps.GeocoderResult, originalPrediction: google.maps.places.AutocompletePrediction) => {
		this.changeling.snapshot('address').onChange(geocodedPrediction.formatted_address)
	}
}

export default wrapComponent(PowerOfAttorney)