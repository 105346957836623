import * as React from 'react'

export interface OwnProps {
	editMode?: boolean
}

export default class Acknowledgements extends React.Component<OwnProps> {
	public render() {
		return (
			<div className="form-item -background -infobox">
				<div className="form-wrapper">
					<div className="content-wrapper">
						<div className="form-input -text">
							<h2>Acknowledgements:</h2>
							<p>This passport is based on original work entitled, 'This is my Hospital Passport' by Wandsworth Community Learning Disability Team, UK.</p>
							<p>All pictures are from the CHANGE picture banks: <a href="https://www.changepeople.org/" className={'link' + (this.props.editMode ? '' : ' -print')}>www.changepeople.co.uk</a></p>
							<p>Thanks to everyone who helped in the redesign of this document.</p>
							<h2>Disclaimer:</h2>
							<p>The Health and Disability Commissioner provides this passport template as a guide only and accepts no responsibility for the accuracy of the information completed in the passport.</p>
						</div>
					</div> 
				</div>
			</div>
		)
	}
}