import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { Props } from '../containers/RegionalLinks'

import { Helmet } from 'react-helmet'

export default class RegionalLinks extends React.Component<Props & RouteComponentProps<undefined>> {

	public render() {
		return (
			<section className="body">
				<Helmet>
					<title>Regional Links — My Homecare</title>
				</Helmet>
								
				<h1 className="headline-text">Auckland</h1>
				
				<div className="body-text">
					<h2><a href="http://communities.co.nz/AucklandRegion/AucklandRegion.cfm" target="_blank" rel="noopener noreferrer">Auckland Regional Community Group</a></h2>
					<p>Below is a link to the Auckland Region Community Group directory published by the NZ Community Development Trust</p>

					<h2><a href="https://volunteeringauckland.org.nz/" target="_blank" rel="noopener noreferrer">Volunteering Auckland</a></h2>
					<p>Volunteering Auckland works with over 200 non-profit, non-governmental organisations who are looking for your help.</p>

					<h2><a href="https://www.lifewise.org.nz/about-us/the-lifewise-way/" target="_blank" rel="noopener noreferrer">Lifewise</a></h2>
					<p>Lifewise are an Auckland-based community social development organisation, who develop new ways to solve challenging social issues and work with families, older people, people with disabilities, and people at risk of homelessness to turn people”s lives around. </p>

					<h2><a href="connectthedots.org.nz" target="_blank" rel="noopener noreferrer">Connect the Dots</a></h2>
					<p>Connect the dots run a wonderful programme of connecting elderly of Auckland with the Arts of Auckland.</p>

					<h2><a href="https://www.communicare.org.nz/" target="_blank" rel="noopener noreferrer">Communicare</a></h2>
					<p>Caring friendship centres for the older age group. Communicare are a not for profit multi-cultural association, which has 21 Friendship Centres throughout central, east, west and south Auckland. Our 21 Friendship Centres, which are spread thoughout the area, are friendly and welcoming. Communicare CMA has been helping people since 1959, with the aid of our volunteer helpers.</p>
				</div>
				
				<h1 className="headline-text">Mid Central Community Groups</h1>

				<div className="body-text">
					<h2><a href="https://www.vrcmanawatu.org.nz/" target="_blank" rel="noopener noreferrer">Volunteer Resource Centre</a></h2>
					<p>The Volunteer Resource Centre promotes volunteering activities in the region and is involved in many events and activities. Each year they hold the Volunteer Recognition Event to recognise some of the contributions volunteers make to our region. They run many events, projects and outreaches throughout the year. Read more of the activities they have been involved in detail. Interested in volunteering? Read more about what you can do, why you should volunteer, and how to volunteer and make a difference!</p>

					<h2><a href="https://www.manawatunz.co.nz/" target="_blank" rel="noopener noreferrer">Palmerston North and Manawatu Information</a></h2>
					<p>Palmerston North City and Manawatu is a great place to be and a destination to discover. Open spaces, friendly faces, city sights, rural delights. Come experience our piece of paradise.</p>

					<h2><a href="https://pncsc.org.nz/index.php/members" target="_blank" rel="noopener noreferrer">Palmerston North Community Services</a></h2>
					<p>A directory of local community groups.</p>

					<h2><a href="http://www.manawhaikaha.co.nz/disability-support/providers/" target="_blank" rel="noopener noreferrer">Mana Whaikaha</a></h2>
					<p>Mana Whaikaha provides information about the transformed disability support system. Above is a link to disability support providers, people and organisations in the Mid Central region.</p>
				</div>
				
			</section>
		)
	}
}
