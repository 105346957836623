import { SagaIterator } from 'redux-saga'
import { takeEvery, put, call, select } from 'redux-saga/effects'
import { Api } from 'typescript-fetch-api'
import * as a from './actions'
import * as s from './selectors'
import * as sws from 'modules/support-workers/selectors'
import * as rf from 'modules/root/functions'
import * as f from './functions'
import { currentClientRefSelector } from 'modules/account/selectors'
import { featuresSelector } from 'modules/common/selectors'
import { push } from 'connected-react-router'
import { callApiWithActions, handleApiFailedAction } from 'modules/api/functions'
import api from 'modules/api'
import platform from 'modules/platform'
import { showToastFailedMessage } from 'modules/root/functions'

function* handleCancelBooking(): SagaIterator {
	yield put(push('/calendar'))
}

function* handleSubmitBooking(action: a.SubmitNewBookingAction): SagaIterator {
	const currentClientRef = (yield select(currentClientRefSelector)) as string | undefined
	const features = (yield select(featuresSelector)) as unknown as ReturnType<typeof featuresSelector>
	const booking = (yield select(s.selectBooking)) as unknown as ReturnType<typeof s.selectBooking>
	const preferenceRefs = (yield select(s.preferenceRefs)) as ReturnType<typeof s.preferenceRefs>

	const supportWorkerRefs: string[] = []

	if (preferenceRefs && features.findSupportWorkers) {
		const allSupportWorkerRefs = (yield select(sws.preferredSupportWorkerRefs, false)) as unknown as ReturnType<typeof sws.preferredSupportWorkerRefs>

		for (let i = 0; i < allSupportWorkerRefs.length; i++) {
			const ref = allSupportWorkerRefs[i]

			const supportWorker = (yield select(sws.supportWorkerForRef, ref)) as unknown as ReturnType<typeof sws.supportWorkerForRef>

			if (preferenceRefs[ref] > 5) {
				if (currentClientRef && supportWorker) {
					yield put(a.blockSupportWorkerFromBooking({
						ref: ref,
						currentClientRef: currentClientRef,
						supportWorkerRef: supportWorker.ref,
					}))
				}
			} else {
				const sw = (yield select(sws.supportWorkerForRef, ref)) as unknown as ReturnType<typeof sws.supportWorkerForRef>
				if (sw) {
					supportWorkerRefs.push(sw.ref)
				}
			}
		}
	}

	const req: Api.AddAppointmentsRequest = {
		appointments: booking.bookings.map(b => {
			const result: Api.NewAppointment = {
				jobTypeRefs: [...b.services],
				fundingType: booking.settings.fundingType,
				date: f.startDateForBookingLine(b, booking.settings) || (booking.settings.startDate && booking.settings.startDate.toString()) || 'unknown',
				repeatEndDate: b.repeat !== Api.NewAppointment.RepeatOptionEnum.None && booking.settings.endDate ? booking.settings.endDate.toString() : undefined,
				startTime: b.startTime ? b.startTime.toString() : undefined,
				startTimeOption: b.startTimeOption,
				durationOption: b.durationOption,
				durationMins: b.durationMinutes,
				repeatOption: b.repeat,
				supportWorkerRefs,
				fundingRef: b.fundingRef,
			}
			return result
		}),
		notes: booking.settings.notes,
	}

	if (!currentClientRef) {
		platform.alert('No client is currently selected.')
		return
	}

	if (action.payload.timesheetEdit) {
		if (req.appointments.length !== 1) {
			showToastFailedMessage('Please enter your booking information.')
			return
		}
		const appt = req.appointments[0]
		if (appt.supportWorkerRefs.length !== 1) {
			showToastFailedMessage('Please choose the support worker you are using.')
			return
		}
		if (!appt.fundingRef) {
			showToastFailedMessage('Please choose the funding to use.')
			return
		}
	}

	yield call(callApiWithActions, action.payload, a.submitNewBookingAsync, (options: RequestInit) => {
		return api().clientApi.postAppointments(currentClientRef, req, options)
	})

	if (action.payload.timesheetEdit) {
		if (action.payload.timesheetRef) {
			yield put(push('/timesheet/ref/' + action.payload.timesheetRef))
		} else {
			yield put(push('/timesheet'))
		}
	} else {
		yield put(push('/calendar'))
	}
}

function handleSubmitBookingDone(action: a.SubmitNewBookingAsyncDoneAction) {
	if (action.payload.params.timesheetEdit) {
		rf.showToastSuccessMessage('Thank you, your new booking request has been made.')
	} else {
		rf.showToastSuccessMessage('Thank you! Your new booking request has been made. We’ll confirm with you as soon as possible.')
	}
}

function* handleStartBooking() {
	yield put(push('/calendar/new'))
}

function* handleBookSupportWorker(action: a.BookSupportWorker): SagaIterator {
	yield put(a.startBooking())
	const selectedSupportWorker: a.PreferenceLevelChangePayload = {
		supportWorkerRef: action.payload,
		level: 1,
	}
	yield put(a.preferenceLevelChange(selectedSupportWorker))
}

function* handleEditBooking() {
	yield put(push('/calendar/edit'))
}

function* blockSupportWorkerFromBooking(action: a.BlockSupportWorkerFromBookingAction): SagaIterator {
	yield call(callApiWithActions, action.payload.ref, a.blockingSupportWorkerFromBooking, (options: RequestInit) => {
		return api().clientApi.putClientBlockedSupportWorker(action.payload.currentClientRef, action.payload.supportWorkerRef, options)
	})
}

export default function* (): SagaIterator {
	yield takeEvery(a.cancelBooking, handleCancelBooking)
	yield takeEvery(a.startBooking, handleStartBooking)
	yield takeEvery(a.startBookingFromDate, handleStartBooking)
	yield takeEvery(a.submitNewBooking, handleSubmitBooking)
	yield takeEvery(a.submitNewBookingAsync.done, handleSubmitBookingDone)
	yield takeEvery([a.submitNewBookingAsync.failed], handleApiFailedAction.bind(null, 'Oops! Your booking request could not be saved.'))
	yield takeEvery(a.bookSupportWorker, handleBookSupportWorker)
	yield takeEvery(a.editBooking, handleEditBooking)
	yield takeEvery(a.blockSupportWorkerFromBooking, blockSupportWorkerFromBooking)
}
