import Component from '../components/ClientDoctorDetails'
import { Api } from 'typescript-fetch-api'
import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'
import { RootStoreState } from 'modules/root'
import * as a from '../actions'
import * as s from '../selectors'

/** Interface for properties that are passed to the container/ */
export interface OwnProps {

}

/** Interface for properties that the container passes to the component. */
export interface Props {
	client?: DeepReadonly<Api.Client>
	doctor?: DeepReadonly<Api.Doctor>
	settings?: DeepReadonly<Api.Settings>
	saving: boolean
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
	onSave: (doctor: Api.Doctor, clientRef: string) => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	const client = s.currentClientSelector(state)!
	const doctor = client.doctor
	return {
		doctor,
		client,
		settings: state.common.settings,
		saving: state.account.saving,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: OwnProps): Actions => ({
	onSave: (doctor: Api.Doctor, clientRef: string) => {

		const request: Api.UpdateClientDetailsRequest = {
			doctor,
		}

		const payload: a.UpdateClientDetailsActionPayload = {
			clientRef,
			request,
		}
		dispatch(a.updateClientDetails(payload))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
