import Component from '../components/AdvanceCarePlanScreen'
import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'
import { RootStoreState } from 'modules/root'
import { DOCUMENT_TYPE, DEFAULT_NAME, FormValue, DEFAULT_FORM } from '../types'
import { DocumentScreenProps, DocumentScreenActions, documentScreenMapStateToProps, documentScreenMapDispatchToProps } from 'modules/documents/containers/DocumentScreen'

export interface OwnProps {
	
}

/**
 * Interface for properties that the container passes to the component.
 */
interface Props extends DocumentScreenProps<FormValue> {}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
interface Actions extends DocumentScreenActions<FormValue> {}

export type ComponentProps = Props & Actions

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	return documentScreenMapStateToProps(state, DOCUMENT_TYPE, DEFAULT_NAME, DEFAULT_FORM, true)
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: OwnProps): Actions => {
	return documentScreenMapDispatchToProps(dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)
