import { SagaIterator } from 'redux-saga'
import { takeEvery, take, put, call, select } from 'redux-saga/effects'
import { Api } from 'typescript-fetch-api'

import * as a from './actions'
import * as aa from 'modules/account/actions'
import * as ba from 'modules/booking/actions'
import * as as from 'modules/account/selectors'
import * as s from './selectors'

import { push } from 'connected-react-router'
import { callApiWithActions, handleApiFailedAction, handleApiSuccessAction } from 'modules/api/functions'
import api from 'modules/api'
import { HOW_MANY } from './reducer'
import { history, dontScrollToTopState } from 'modules/routing'
import { showToastFailedMessage } from 'modules/root/functions'
import { isType } from 'typescript-fsa'

function* handleSearch(action: a.SearchAction): SagaIterator {
	const client = (yield select(as.currentClientSelector)) as ReturnType<typeof as.currentClientSelector>
	if (!client) {
		yield take(aa.chooseClient)
		yield call(handleInitial)
		return
	}

	const location = action.payload.location || client.location
	if (!location) {
		showToastFailedMessage('Please provide the address to perform the search')
		return
	}

	const searchRequest: Api.PostSupportWorkersSearchRequest = { location }

	searchRequest.genderRef = action.payload.genders ? action.payload.genders : undefined
	searchRequest.languageRefs = action.payload.languages ? [action.payload.languages] : undefined
	searchRequest.skillRefs = action.payload.skills ? [action.payload.skills] : undefined
	searchRequest.text = action.payload.text ? action.payload.text : undefined
	searchRequest.howMany = HOW_MANY

	yield call(callApiWithActions, searchRequest, a.searching, (options: RequestInit) => {
		return api().clientApi.postSupportWorkersSearch(client.ref, searchRequest as Api.PostSupportWorkersSearchRequest, options)
	})
}

function* handleInitial(): SagaIterator {
	const client = (yield select(as.currentClientSelector)) as ReturnType<typeof as.currentClientSelector>
	if (!client) {
		/* Wait for a client to be chosen */
		yield take(aa.chooseClient)
		yield call(handleInitial)
		return
	}
	
	const location = client.location
	if (!location) {
		// TODO blank out the reducer
		return
	}
	
	const initialRequest: Api.PostSupportWorkersSearchRequest = {
		location,
		howMany: HOW_MANY,
	}

	const membership = (yield select(as.supportNetworkMembershipSelector, client.ref)) as ReturnType<typeof as.supportNetworkMembershipSelector>

	if (membership && (membership.capabilities.manageRoster || membership.capabilities.manager)) {
		yield call(callApiWithActions, initialRequest, a.searching, (options: RequestInit) => {
			return api().clientApi.postSupportWorkersSearch(client.ref, initialRequest, options)
		})
	}
	
	yield call(callApiWithActions, client.ref, a.searchingFavouriteWorkers, (options: RequestInit) => {
		return api().clientApi.getClientFavouriteSupportWorkers(client.ref, options)
	})
	yield call(callApiWithActions, client.ref, a.searchingBlockedWorkers, (options: RequestInit) => {
		return api().clientApi.getClientBlockedSupportWorkers(client.ref, options)
	})
}

function* handleSearchNextPage(): SagaIterator {
	const client = (yield select(as.currentClientSelector)) as ReturnType<typeof as.currentClientSelector>
	if (!client) {
		return
	}

	let searchRequest = (yield select(s.currentSearchRequest)) as ReturnType<typeof s.currentSearchRequest>
	if (!searchRequest) {
		return
	}

	searchRequest = {
		...searchRequest, page: searchRequest.page ? searchRequest.page + 1 : 2, howMany: HOW_MANY,
	}

	yield call(callApiWithActions, searchRequest, a.searching, (options: RequestInit) => {
		return api().clientApi.postSupportWorkersSearch(client.ref, searchRequest as Api.PostSupportWorkersSearchRequest, options)
	})
}

function* handleSearchFavourites(): SagaIterator {
	const client = (yield select(as.currentClientSelector)) as ReturnType<typeof as.currentClientSelector>
	if (!client) {
		yield take(aa.chooseClient)
		yield call(handleInitial)
		return
	}
	yield call(callApiWithActions, client.ref, a.searchingFavouriteWorkers, (options: RequestInit) => {
		return api().clientApi.getClientFavouriteSupportWorkers(client.ref)
	})
}

function* handleSearchBlocked(): SagaIterator {
	const client = (yield select(as.currentClientSelector)) as ReturnType<typeof as.currentClientSelector>
	if (!client) {
		yield take(aa.chooseClient)
		yield call(handleInitial)
		return
	}
	yield call(callApiWithActions, client.ref, a.searchingBlockedWorkers, (options: RequestInit) => {
		return api().clientApi.getClientBlockedSupportWorkers(client.ref)
	})
}

function* handleViewDetail(action: a.ViewDetailAction): SagaIterator {
	if (history.location.pathname.startsWith('/calendar/new')) {
		yield put(push(`/calendar/new/support-workers/worker/${action.payload}`, dontScrollToTopState()))
	} else if (history.location.pathname.startsWith('/calendar/edit')) {
		yield put(push(`/calendar/edit/support-workers/worker/${action.payload}`, dontScrollToTopState()))
	} else {
		yield put(push(`/support-workers/worker/${action.payload}`))
	}
}

function* handleToggleFavourite(action: a.CreateFavouriteAction): SagaIterator {
	const client = (yield select(as.currentClientSelector)) as ReturnType<typeof as.currentClientSelector>
	if (!client) {
		return
	}

	const supportWorker = (yield select(s.supportWorkerForRef, action.payload)) as unknown as ReturnType<typeof s.supportWorkerForRef>
	if (!supportWorker) {
		return
	}
	const relationship = (yield select(s.clientSupportWorkerRelationshipForSupportWorkerRef, supportWorker.ref)) as unknown as ReturnType<typeof s.clientSupportWorkerRelationshipForSupportWorkerRef>

	if (relationship && relationship.favourite) {
		// delete fav
		yield call(callApiWithActions, supportWorker.ref, a.deletingFavourite, (options: RequestInit) => {
			return api().clientApi.deleteClientFavouriteSupportWorker(client.ref, supportWorker.ref, options)
		})
	} else {
		// add fav
		yield call(callApiWithActions, supportWorker.ref, a.creatingFavourite, (options: RequestInit) => {
			return api().clientApi.postClientFavouriteSupportWorker(client.ref, supportWorker.ref, options)
		})
	}
	yield put(a.searchFavouriteWorkers())
	yield put(a.searchBlockedWorkers())
}

function* handleToggleBlock(action: a.CreateBlockAction): SagaIterator {
	const client = (yield select(as.currentClientSelector)) as ReturnType<typeof as.currentClientSelector>
	
	if (!client) {
		return
	}
	const supportWorker = (yield select(s.supportWorkerForRef, action.payload)) as unknown as ReturnType<typeof s.supportWorkerForRef>
	if (!supportWorker) {
		return
	}
	const relationship = (yield select(s.clientSupportWorkerRelationshipForSupportWorkerRef, supportWorker.ref)) as unknown as ReturnType<typeof s.clientSupportWorkerRelationshipForSupportWorkerRef>

	if (relationship && relationship.blocked) {
		// delete block
		yield call(callApiWithActions, supportWorker.ref, a.deletingBlock, (options: RequestInit) => {
			return api().clientApi.deleteClientBlockedSupportWorker(client.ref, supportWorker.ref, options)
		})
	} else {
		// add block
		yield call(callApiWithActions, supportWorker.ref, a.creatingBlock, (options: RequestInit) => {
			return api().clientApi.putClientBlockedSupportWorker(client.ref, supportWorker.ref, options)
		})
	}
	yield put(a.searchBlockedWorkers())
	yield put(a.searchFavouriteWorkers())
}

function* handleSupportWorkerRelationshipAction(action: a.CreatingBlockDoneAction | a.CreatingFavouriteDoneAction | a.DeletingBlockDoneAction | a.DeletingFavouriteDoneAction): SagaIterator {

	const supportWorker = (yield select(s.supportWorkerForRef, action.payload.params)) as unknown as ReturnType<typeof s.supportWorkerForRef>
	if (!supportWorker) {
		return
	}

	const swName = supportWorker.fullName

	if (isType(action, a.creatingBlock.done)) {
		yield call(handleApiSuccessAction, `${swName} has been hidden from your support worker search results.`)
	} else if (isType(action, a.creatingFavourite.done)) {
		yield call(handleApiSuccessAction, `${swName} has been added to your list of preferred support workers.`)
	} else if (isType(action, a.deletingBlock.done)) {
		yield call(handleApiSuccessAction, `${swName} is no longer hidden from your support worker search results.`)
	} else if (isType(action, a.deletingFavourite.done)) {
		yield call(handleApiSuccessAction, `${swName} has been removed from your list of preferred support workers.`)
	}
}

function* addFeedback(action: a.AddFeedbackAction): SagaIterator {
	const currentClientRef = (yield select(as.currentClientRefSelector)) as string | undefined
	if (!currentClientRef) {
		return
	}
	yield call(callApiWithActions, action.payload, a.addFeedbackAsync, (options: RequestInit) => {
		return api().clientApi.postSupportWorkerFeedback(currentClientRef, action.payload.supportWorkerRef, action.payload.request, options)
	})
}

export default function* (): SagaIterator {
	yield takeEvery(a.search, handleSearch)
	yield takeEvery(a.searchInitial, handleInitial)
	yield takeEvery([a.searching.failed], handleApiFailedAction.bind(null, 'Oops! We were unable to perform your search.'))
	yield takeEvery([a.creatingBlock.failed, a.deletingBlock.failed, a.creatingFavourite.failed, a.deletingFavourite.failed], handleApiFailedAction.bind(null, 'Oops! Cannot perform this action.'))
	yield takeEvery([a.creatingBlock.done, a.creatingFavourite.done, a.deletingBlock.done, a.deletingFavourite.done], handleSupportWorkerRelationshipAction)
	yield takeEvery(a.viewDetail, handleViewDetail)
	yield takeEvery([a.createFavourite, a.deleteFavourite], handleToggleFavourite)
	yield takeEvery([a.createBlock, a.deleteBlock], handleToggleBlock)
	yield takeEvery([a.searchFavouriteWorkers, aa.chooseClient], handleSearchFavourites)
	yield takeEvery(a.searchBlockedWorkers, handleSearchBlocked)
	yield takeEvery(ba.blockingSupportWorkerFromBooking.done, handleSearchBlocked)
	yield takeEvery(a.searchNextPage, handleSearchNextPage)
	yield takeEvery(a.addFeedback, addFeedback)
	yield takeEvery([a.addFeedbackAsync.done], handleApiSuccessAction.bind(null, 'Thank you, we have submitted your feedback.'))
	yield takeEvery([a.addFeedbackAsync.failed], handleApiFailedAction.bind(null, 'Oops! We were unable to submit your feedback.'))
}
