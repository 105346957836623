import * as React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import platform, { Brand } from 'modules/platform'

export default class GeneralFAQs extends React.Component {

	public render() {
		const currentBrand = platform.brand()
		return (
			<div className="body">
				<Helmet>
					<title>FAQs — My Homecare</title>
				</Helmet>
				
				<h1 className="headline-text">Frequently asked questions</h1>
				
				<div className="body-text">
					<h2>How do I update my details?</h2>
					<p>
						From your Dashboard, simply select “My Account” from the top right-hand side of the page. Here you will find a
						number of options where you may update your Name, telephone numbers and alternative email address. You can
						also upload and change your profile picture, enter details for your Next of Kin, Doctor and change your password.
					</p>
					<h2>What is a Support Network?</h2>
					<p>
						You can give access to nominated friends and family members to view, edit your cares, or manage them for you.
						These people make up your Support Network, and can be added or removed at any time.
					</p>
					<h2>Who can see my Care Calendar?</h2>
					<p>
						You and the nominated members of your Support Network can view your Calendar, but only if you have granted
						access to them.
					</p>
					<h2>How do I book a new visit? Can I reschedule or cancel my visits?</h2>
					<p>
						It is fast and simple to book a new visit – from your Dashboard, simply click on “Book a New Visit” at the top of the
						page. You will then be prompted to fill out the details of the visit such as date, time and service required. On the
						next page you may choose a Support Worker yourself, or submit your request. After that you will be notified when
						your visit has been confirmed.
					</p>
					<p>
						It’s just as simple if you wish to reschedule a visit – from your Dashboard or Calendar view, click on the shift you wish
						to amend. A new window will open where you can select “Edit Booking”, follow the on screen prompts to
						make changes and then submit your updates. You can follow the same process to cancel any visits.
					</p>
					<p>
						Please note that for both new and rescheduled visits, we do need at least 48 hours’ notice prior to the time of the
						booking.
					</p>
					<h2>Can I use My Homecare from my mobile device?</h2>
					<p>Yes you can. My Homecare is fully integrated for web browsers on both desktop computers and mobile devices.</p>
					<h2>Do you speak my language?</h2>
					<p>
						We understand that for many of our clients &amp; families that English is not your first language. Please let us know and
						we will engage the services of an Interpreter. We can contact a translator over the phone or arrange a face-to-face
						visit as required.
					</p>
					<h2>What is Personal Care?</h2>
					<p>
						If full assistance with toileting, showering, dressing etc is required your Support Worker will be present to ensure you can
						complete your basic hygiene tasks in a safe manner. When intimate contact is required, or contact with broken skin, gloves must
						be used. It is important that these gloves are worn for the safety of both yourself and the Support Worker. If you require
						‘standby assistance only’ for Personal Cares then your Support Worker will stay within hearing distance in case you require
						assistance but will ensure your privacy whilst you are completing your own personal cares.
					</p>
					<h2>How much does it cost?</h2>
					<p>Signing up to My Homecare is free.</p>
					<p>
						If your supports are either paid by a Public Funder or ACC, then there will be no cost to you at all. We also have
						options for Private Care which you can pay for as standalone support, or in addition to your funded support. For
						more information on Private Care please call us {currentBrand === Brand.Default ? 'on 0508 HOMECARE' : currentBrand === Brand.Hcnz ? 'on 0800 002 722' : ''} or talk to us on our Live Chat.
					</p>
					<h2>How can I increase my Publicly Funded support?</h2>
					<p>
						If you need additional support through your Public Funder, you will need to contact your <a href="https://www.health.govt.nz/your-health/services-and-support/disability-services/getting-support-disability/needs-assessment-and-service-coordination-services" target="_blank" rel="noopener noreferrer">Needs Assessor</a> to discuss your situation.
					</p>
					<p>
						<Link to="/my-community" className="button-link">My Communities</Link>
					</p>
					<h2>Can I have both Publicly &amp; Privately Funded Support?</h2>
					<p>
						Yes you can. If you require additional support on an ad-hoc or ongoing basis, we have a range of services which can
						be funded privately.
					</p>
					<p>
						<a href="/about-my-homecare/funded-care/" target="_blank" rel="noopener noreferrer" className="button-link">Funded Care</a> <a href="/about-my-homecare/private-care/" target="_blank" rel="noopener noreferrer" className="button-link">Private Care</a>
					</p>
					<h2>How do I pay?</h2>
					<p>
						If you are paying for Private Care, we have a number of options available. You may pay by credit or debit card, or set-
						up automatic payments from your nominated bank account. You may also apply for an account on credit.
					</p>
					<h2>What do you do with my credit/debit card details?</h2>
					<p>
						Your privacy and security is important to us. {currentBrand === Brand.Hcnz ? 'HealthCare NZ' : 'Geneva Healthcare'} takes reasonable steps to protect personal
						information that is held by us from unauthorised access, use, alteration or destruction.
					</p>
					<h2>What do I do if my Support Worker does not arrive?</h2>
					<p>
						Occasionally a situation may arise where your Support Worker is unable to meet the agreed time. In this instance
						they are instructed to notify {currentBrand === Brand.Hcnz ? 'HealthCare NZ' : 'Geneva'} as soon as possible and we will contact you to advise you of a replacement or
						offer alternative arrangements.
					</p>
					<p>
						If your Support Worker tells you they are not able to attend an appointment in the future please remind them to
						contact the office to advise us and do not make private arrangements with your Support Worker. This is both for
						your safety and also the Support Workers.
					</p>
				</div>

			</div>
		)
	}
}
