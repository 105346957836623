import * as React from 'react'
import { OwnProps, Props, Actions } from '../containers/AccountMenu'

import * as t from '../types'
import AccountMenuClient from './AccountMenuClient'
import { avatarPlaceholder } from 'modules/root/theme'

interface State {
	menuOpen: boolean
}

const INITIAL_STATE: State = {
	menuOpen: false,
}

export default class Example extends React.Component<OwnProps & Props & Actions, State> {
	
	public state = INITIAL_STATE

	chooseClient = (client: t.ClientView) => {
		this.props.onChooseClient(client.ref)
		this.closeMenu()
	}

	openMenu = () => {
		this.setState({ menuOpen: true })
	}

	closeMenu = () => {
		this.setState({ menuOpen: false })
	}
	
	onMyDetails = () => {
		this.props.onMyDetails()
		this.closeMenu()
	}

	onInvitations = () => {
		this.props.onInvitations()
		this.closeMenu()
		if (this.props.closeMobileMenu) {
			this.props.closeMobileMenu()
		}
	}

	onFAQs = () => {
		this.props.onFAQs()
		this.closeMenu()
		if (this.props.closeMobileMenu) {
			this.props.closeMobileMenu()
		}
	}

	onHome = () => {
		this.props.onHome()
	}

	public render() {
		const { section, clients: { manageable, viewable, you }, currentClient, currentClientRef, currentUser, invitations, currentUserClient } = this.props

		return (
			<li className={'navitem -withavatar' + (section === 'account' ? ' -selected' : '')}>
				<div className={'account-menu' + (this.state.menuOpen ? ' open' : '')} onMouseEnter={this.openMenu} onMouseLeave={this.closeMenu}>
					<span className="disclosure">
						{currentClient ? (
							<span className="avatar"><img src={currentClient.image || avatarPlaceholder()} alt={currentClient.name} /></span>
						) : currentUser ? (
							<span className="avatar"><img src={currentUser.image || avatarPlaceholder()} alt={currentUser.fullName} /></span>
						) : (
							<span className="avatar"><img src={avatarPlaceholder()} alt="" /></span>
						)}
						{invitations && invitations.length > 0 && (
							<span className="count">{invitations.length} <span className="_sr-only">invitation{invitations.length !== 1 && 's'} pending</span></span>
						)}
					</span>
					{currentClient ? (
						<span className="label client-name">{currentClient.displayName}</span>
					) : (
						currentUser && <span className="label client-name">{currentUser.fullName}</span>
					)
					}
					<div className="menu _no-print">
						<ul className="menu-items">
							{currentClient && !currentClient.you && (
								<>
									{you && (
										<AccountMenuClient client={you} overrideName="My account" selected={you.ref === currentClientRef} onChooseClient={this.chooseClient.bind(this, you)} />
									)}
								</>
							)}

							<li className={'menuitem' + (((currentUserClient && currentUserClient.ref === currentClientRef) || !currentClient) ? ' -selected' : '')}>
								<a onClick={this.onHome} className="link person">
									<figure className="avatar-image -medium -bordered">
										<div className="image -placeholder">
											{currentUserClient ? (
												<img src={currentUserClient.image || avatarPlaceholder()} alt={currentUserClient.fullName} />
											) : currentUser ? (
												<img src={currentUser.image || avatarPlaceholder()} alt={currentUser.fullName} />
											) : (
												<img src={avatarPlaceholder()} alt="" />
											)}
										</div>
									</figure>
									<span className="name">{currentUserClient ? currentUserClient.fullName : (currentUser ? currentUser.fullName : '')}</span>
								</a>
							</li>

							{invitations && invitations.length > 0 && (
								<li className="menuitem"><a onClick={this.onInvitations} className="link">Invitations<span className="count">{invitations.length}</span></a></li>
							)}
							{manageable.length > 0 && (
								<>
									<li className="menuitem">
										<h2 className="subheading">Accounts you can manage</h2>
									</li>
									{manageable.map((c, i) => (
										<AccountMenuClient client={c} key={i} selected={c.ref === currentClientRef} onChooseClient={this.chooseClient.bind(this, c)} />
									))}
								</>
							)}
							{viewable.length > 0 && (
								<>
									<li className="menuitem">
										<h2 className="subheading">Accounts you can view</h2>
									</li>
									{viewable.map((c, i) => (
										<AccountMenuClient client={c} key={i} selected={c.ref === currentClientRef} onChooseClient={this.chooseClient.bind(this, c)} />
									))}
								</>
							)}
						</ul>
					</div>
				</div>
			</li>
		)
	}
}
