import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as a from 'modules/order/actions'
import { currentClientSelector } from 'modules/account/selectors'
import { RootStoreState } from 'modules/root'

const NewOrder = () => {
	const [notes, handleNotesChange] = React.useState('')
	const client = useSelector(currentClientSelector)
	const processing = useSelector((state: RootStoreState) => state.order.submitting)
	const dispatch = useDispatch()

	return (
		<article className="site-body">
			<div className="width-limit -site">
				<form className="content-layout booking-form" action="#">
					<div className="body">
						<div className="form-layout">
							<div className="row">
								<h1 className="headline-text">New Order</h1>
							</div>
							<div className="row">
								<div className="form-field">
									<label className="label">Order notes</label>
									<div className="form-input -textarea">
										<textarea
											onChange={(e) => handleNotesChange(e.target.value)}
											value={notes}
											className="field"
											placeholder="Enter the details of the items you'd like to order"
										/>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="button-group">
									<div className="button">
										<input 
											type="submit" 
											className="button-link -text" 
											value="Cancel" 
											onClick={() => dispatch(a.cancelNewOrder())} 
										/>
									</div>
									<div className="button -constrained">
										<a 
											href="#" 
											onClick={() => dispatch(a.submitNewOrder({
												notes, 
												subject: `Consumables order for ${client ? client.fullName : 'UNKNOWN'}`,
											}))} 
											className={'button-link -action' + (processing ? ' -processing' : '')}
										>
                      Submit
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<aside className="sidebar">
					</aside>
				</form>
			</div>
		</article>
	)
}

export default NewOrder