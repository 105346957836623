import { SagaIterator } from 'redux-saga'
import { takeEvery, put, call, select } from 'redux-saga/effects'
import { LocalDate } from '@js-joda/core'
import { readyAction } from 'modules/root/actions'
import { login } from 'modules/auth/actions'
import { onlineAction } from 'modules/api/actions'
import * as a from './actions'
import * as ba from 'modules/booking/actions'
import { loggedIn } from 'modules/auth/sagas'
import { callApiWithActions, apiErrorToMessage } from 'modules/api/functions'
import { push } from 'connected-react-router'
import api from 'modules/api'
import { currentClientRefSelector } from 'modules/account/selectors'
import { featuresSelector } from 'modules/common/selectors'
import { chooseClient } from 'modules/account/actions'
import { submitNewBookingAsync } from 'modules/booking/actions'
import { submitNewOrderAsync } from 'modules/order/actions'
import * as f from 'modules/root/functions'
import * as bs from 'modules/booking/selectors'
import * as sws from 'modules/support-workers/selectors'
import * as t from './types'
import * as _ from 'lodash'

function* startLoading(): SagaIterator {
	const now = LocalDate.now()
	const start = now.minusWeeks(6)
	const end = now.plusWeeks(20)

	const payload: a.LoadCalendarPayload = {
		start,
		end,
	}
	yield call(loadCalendar, payload)
	yield call(loadClientTickets)
}

function* loadCalendar(request: a.LoadCalendarPayload): SagaIterator {
	const isLoggedIn = yield call(loggedIn)
	if (!isLoggedIn) {
		return
	}

	const currentClientRef = (yield select(currentClientRefSelector)) as string | undefined
	if (!currentClientRef) {
		return
	}

	yield call(callApiWithActions, request, a.loadCalendarAsync, (options: RequestInit) => {
		return api().clientApi.getClientAppointments(currentClientRef, request.start.toString(), request.end.toString(), options)
	})
}

function* loadClientTickets(): SagaIterator {
	const isLoggedIn = yield call(loggedIn)
	if (!isLoggedIn) {
		return
	}

	const currentClientRef = (yield select(currentClientRefSelector)) as string | undefined
	if (!currentClientRef) {
		return
	}

	yield call(callApiWithActions, undefined, a.loadClientTicketsAsync, (options: RequestInit) => {
		return api().clientApi.getClientTickets(currentClientRef, options)
	})
}

function* rosterChange(action: a.RosterChangeAction): SagaIterator {
	const currentClientRef = (yield select(currentClientRefSelector)) as string | undefined
	if (!currentClientRef) {
		return
	}

	const features = (yield select(featuresSelector)) as unknown as ReturnType<typeof featuresSelector>
	const selectOwnSupportWorkers = (yield select(bs.selectOwnSupportWorkers)) as unknown as ReturnType<typeof bs.selectOwnSupportWorkers>

	const bookingChange = _.cloneDeep(action.payload.payload)

	if (bookingChange.supportWorkerChange) {
		bookingChange.supportWorkerChange = {
			allAppointments: false,
		}

		if (selectOwnSupportWorkers && features.findSupportWorkers) {
			const preferenceRefs = (yield select(bs.preferenceRefs)) as ReturnType<typeof bs.preferenceRefs>
			const supportWorkerRefs: string[] = []
		
			if (preferenceRefs) {
				const allRefs = (yield select(sws.preferredSupportWorkerRefs, false)) as unknown as ReturnType<typeof sws.preferredSupportWorkerRefs>
		
				for (let i = 0; i < allRefs.length; i++) {
					const ref = allRefs[i]
		
					const supportWorker = (yield select(sws.supportWorkerForRef, ref)) as unknown as ReturnType<typeof sws.supportWorkerForRef>
		
					if (preferenceRefs[ref] > 5) {
						if (currentClientRef && supportWorker) {
							yield put(ba.blockSupportWorkerFromBooking({
								ref: ref,
								currentClientRef: currentClientRef,
								supportWorkerRef: supportWorker.ref,
							}))
						}
					} else {
						const sw = (yield select(sws.supportWorkerForRef, ref)) as unknown as ReturnType<typeof sws.supportWorkerForRef>
						if (sw) {
							supportWorkerRefs.push(sw.ref)
						}
					}
				}
		
				bookingChange.supportWorkerChange.supportWorkerRefs = supportWorkerRefs
			}
		}
	}
	yield call(callApiWithActions, action.payload, a.rosterChangeAsync, (options: RequestInit) => {
		return api().clientApi.patchAppointment(currentClientRef, action.payload.jobRef, bookingChange, options)
	})
}

function updateJobMessageSuccess(action: a.RosterChangeAsyncDoneAction) {
	if (action.payload.params.payload.cancellation) {
		f.showToastSuccessMessage('Thank you, your appointment has been cancelled.')
	} else {
		f.showToastSuccessMessage('Thank you, your appointment has been updated.')
	}
}

function updateJobMessageFailed(action: a.RosterChangeAsyncFailedAction) {
	const message = apiErrorToMessage(action.payload.error)
	
	if (action.payload.params.payload.cancellation) {
		f.showToastFailedMessage(`Oops! We were unable to cancel your appointment. ${message}`)
	} else {
		f.showToastFailedMessage(`Oops! We were unable to update your appointment. ${message}`)
	}
}

function* rosterChangeDone(action: a.RosterChangeAsyncDoneAction) {
	if (action.payload.params.returnTo === t.JobChangeReturnLocation.Timesheet) {
		if (action.payload.params.timesheetRef) {
			yield put(push(`/timesheet/ref/${action.payload.params.timesheetRef}`))
		} else {
			yield put(push('/timesheet'))
		}
	} else {
		yield put(push('/calendar'))
	}
}

export default function* (): SagaIterator {
	yield takeEvery(readyAction, startLoading)
	yield takeEvery(login.done, startLoading)
	yield takeEvery(onlineAction, startLoading)
	yield takeEvery(chooseClient, startLoading)
	yield takeEvery(submitNewBookingAsync.done, startLoading)
	yield takeEvery(submitNewOrderAsync.done, loadClientTickets)
	yield takeEvery(a.rosterChange, rosterChange)
	yield takeEvery(a.rosterChangeAsync.done, startLoading)
	yield takeEvery(a.rosterChangeAsync.done, updateJobMessageSuccess)
	yield takeEvery(a.rosterChangeAsync.done, rosterChangeDone)
	yield takeEvery(a.rosterChangeAsync.failed, updateJobMessageFailed)
}
