import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { combineReducers } from 'redux'

import * as actions from './actions'

/* Import reducers from our modules */
import * as account from 'modules/account/reducer'
import * as auth from 'modules/auth/reducer'
import * as booking from 'modules/booking/reducer'
import * as order from 'modules/order/reducer'
import * as calendar from 'modules/calendar/reducer'
import * as common from 'modules/common/reducer'
import * as dashboard from 'modules/dashboard/reducer'
import * as documents from 'modules/documents/reducer'
import * as funderDashboard from 'modules/funder-dashboard/reducer'
import * as register from 'modules/register/reducer'
import * as setup from 'modules/setup/reducer'
import * as supportWorkers from 'modules/support-workers/reducer'
import * as timesheet from 'modules/timesheet/reducer'
import platform from 'modules/platform'
// import * as another from '../another/reducers'

/**
 * The root store state. Include sub-states for all of the modules / ducks.
 * All of these should be annotated `readonly`, as should everything down
 * the tree of StoreState interfaces, and their contents.
 */
interface MutableStoreState {
	account: account.MutableStoreState
	auth: auth.MutableStoreState
	booking: booking.MutableStoreState
	order: order.MutableStoreState
	calendar: calendar.MutableStoreState
	common: common.MutableStoreState
	dashboard: dashboard.MutableStoreState
	documents: documents.MutableStoreState
	funderDashboard: funderDashboard.MutableStoreState
	ready: boolean
	register: register.MutableStoreState
	setup: setup.MutableStoreState
	supportWorkers: supportWorkers.MutableStoreState
	timesheet: timesheet.MutableStoreState
}

export type StoreState = DeepReadonly<MutableStoreState>

const readyReducer = reducerWithInitialState(false)
	.case(actions.readyAction, (state, payload) => (true))

/**
 * The root reducer, combines reducers for all of the modules / ducks.
 */
export const reducer = platform.customiseRootReducer(combineReducers(platform.customiseReducers({
	account: account.reducer,
	auth: auth.reducer,
	booking: booking.reducer,
	order: order.reducer,
	calendar: calendar.reducer,
	common: common.reducer,
	dashboard: dashboard.reducer,
	documents: documents.reducer,
	funderDashboard: funderDashboard.reducer,
	ready: readyReducer,
	register: register.reducer,
	setup: setup.reducer,
	supportWorkers: supportWorkers.reducer,
	timesheet: timesheet.reducer,
})))
