import Component from '../components/ThisWeek'

import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'
import { calendarView } from 'modules/calendar/functions'
import { LocalDate } from '@js-joda/core'
import * as bookingActions from 'modules/booking/actions'

/* Import module actions */
// import * as actions from '../actions'
import * as ct from 'modules/calendar/types'

/** Interface for properties that are passed to the container/ */
export interface OwnProps {
}

/** Interface for properties that the container passes to the component. */
export interface Props {
	calendar: ct.CalendarView
	view?: ct.WeekView
	loading: boolean
}

export interface Actions {
	onNewBooking: () => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	const now = LocalDate.now()
	const calendar = calendarView(now, state)
	const week = calendar.weeks.filter(w => w.thisWeek)[0]
	const loading = state.dashboard.loadingCalendar
	return {
		calendar: calendar,
		view: week,
		loading,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	onNewBooking: () => {
		dispatch(bookingActions.startBooking())
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
