
export interface FormValue {
	/// personal details
	firstName?: string
	lastName?: string
	knownAs?: string
	nhiNumber?: string
	address?: string
	phoneNumbers?: PhoneNumbers
	email?: string
	/// things you must know
	healthCondition?: string
	emergencyInformation?: string
	/// communication
	firstLanguage?: string
	otherLanguages?: string[]
	interpretingHelp?: boolean
	interpretingHelpLanguage?: string
	communicationAbilities?: string
	communicationInfo?: string
	/// medical
	painAwareness?: string
	allergies?: string
	medicationPrefs?: string
	examinationPrefs?: string
	otherMedicalCareInfo?: string
	/// legal
	legalRepresentative?: boolean
	legalRepresentativeInfo?: LegalFormValue
	futureWishes?: boolean
	futureWishesInfo?: string
	decisionConsent?: boolean
	/// safety 
	safetySupport?: boolean
	physcialSafety?: string
	stressCauses?: string
	stressSigns?: string
	stressRelievers?: string
	/// moving around
	movingAround?: boolean
	movingMethods?: string
	movingInfo?: string
	/// daily activities
	dailyActivities?: boolean
	usingToilet?: ThreeQuestionValue
	usingShower?: ThreeQuestionValue
	personalHygiene?: ThreeQuestionValue
	dressing?: ThreeQuestionValue
	eatingDrinking?: ThreeQuestionValue
	sleeping?: ThreeQuestionValue
	/// contacts
	nextOfKin?: ContactValue
	supportPerson?: ContactValue
	generalPractitioner?: ContactValue
	contactOther?: string
	/// other info 
	likes?: string
	dislikes?: string
	religiousNeeds?: string
	culturalNeeds?: string
	preferencesOther?: string
}

export interface LegalFormValue {
	name?: string
	legalRelationship?: string
	phone?: string
	mobile?: string
	fax?: string
	email?: string
}

export interface ThreeQuestionValue {
	valueOne?: string
	valueTwo?: string
	valueThree?: string
}

export interface ContactValue {
	fullName?: string
	relationshipToMe?: string
	nameOfAgency?: string
	address?: string
	phone?: string
	mobile?: string
	fax?: string
	email?: string
}

export interface PhoneNumbers {
	phone?: string
	mobile?: string
	fax?: string
}

export const DEFAULT_FORM: FormValue = {
	/// personal details
	firstName: '',
	lastName: '',
	knownAs: '',
	nhiNumber: '',
	address: '',
	phoneNumbers: {
		fax: '',
		mobile: '',
		phone: '',
	},
	email: '',
	/// communication
	firstLanguage: '0',
	otherLanguages: ['0'],
	interpretingHelp: false,
	interpretingHelpLanguage: '0',
	/// legal
	legalRepresentative: true,
	futureWishes: true,
	decisionConsent: true,
	/// safety 
	safetySupport: false,
	/// moving around
	movingAround: false,
	/// daily activities
	dailyActivities: false,
}

export const DOCUMENT_TYPE = 'health-passport'
export const DEFAULT_NAME = 'e-Health Passport'
