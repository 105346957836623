import * as React from 'react'

export default class PersonCompletingNotes extends React.Component {
	public render() {
		return (
			<div className="form-item -background -infobox">
				<div className="form-wrapper">
					<div className="content-wrapper">
						<div className="form-input -text">
							<h2>Notes for person completing the passport:</h2>
							<ul>
								<li>Completing this passport is optional. You may decide how much information you want to give under each section and may even choose not to complete some sections of the passport.</li>
								<li>If you are unsure what to write in a particular section, please refer to the Guide to Completing the Health Passport.</li>
							</ul>
						</div>
					</div> 
				</div>
			</div>
		)
	}
}