import { SagaIterator } from 'redux-saga'
import { takeEvery, put, call, select } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { callApiWithActions } from 'modules/api/functions'
import getApi from 'modules/api'
import * as a from './actions'
import * as aa from 'modules/account/actions'
import * as as from 'modules/account/selectors'
import * as authActions from '../auth/actions'
import { readyAction } from 'modules/root/actions'
import { loggedInSelector } from 'modules/auth/selectors'

function* loadSettings(): SagaIterator {
	const loggedIn = (yield select(loggedInSelector)) as ReturnType<typeof loggedInSelector>
	if (!loggedIn) {
		return
	}

	yield call(callApiWithActions, '', a.loadSettingsAsync, () => {
		return getApi().settingsApi.getSettings()
	})
}

/** Goes to dashboard or account of the current user */
function* handleGoHome(): SagaIterator {
	const currentUserClientRef = (yield select(as.currentUserClientRefSelector)) as ReturnType<typeof as.currentClientRefSelector>
	yield put(aa.chooseClient({ clientRef: currentUserClientRef, interactive: true }))
	yield put(push('/'))
}

export default function* (): SagaIterator {
	yield takeEvery([authActions.login.done, readyAction], loadSettings)
	yield takeEvery(a.goHome, handleGoHome)
}
