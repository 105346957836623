import * as React from 'react'
import { Props, Actions, OwnProps } from '../containers/SupportWorkerTile'
import * as f from '../functions'
import moment from 'moment'
import { startDateTime } from 'modules/calendar/functions'
import truncate from 'truncate'
import { avatarPlaceholder } from 'modules/root/theme'

export default class SupportWorkerTile extends React.Component<Props & Actions & OwnProps> {

	public render() {
		const { supportWorker, relationship } = this.props
		if (!supportWorker || !relationship) {
			return null
		}

		const nameOrPronoun = f.givenName(this.props.supportWorker!)
		const prevAppointment = this.props.prevAppointment
		const nextAppointment = this.props.nextAppointment

		return (
			<div className="support-worker">
				<div className="details">
					<header className="header">
						<figure className="avatar-image -large -bordered">
							<div className="image">
								<img src={supportWorker.image || avatarPlaceholder()} alt={supportWorker.givenName} />
							</div>
						</figure>
						<div className="content">
							<h1 className="name">{supportWorker.fullName}</h1>
							{nextAppointment ? (
								<p className="history">You will see {nameOrPronoun} {moment(startDateTime(nextAppointment).toString()).fromNow()}.</p>
							) : prevAppointment && (
								<p className="history">You last saw {nameOrPronoun} {moment(startDateTime(prevAppointment).toString()).fromNow()}.</p>
							)} 
						</div>
						{ this.props.showPreference && relationship.favourite && (
							<p className="like-options -default">
								<span className="like -yes -active">Favourite</span>
							</p>
						)}
					</header>
					{/* <a href="#" className={'favourite' + (faved ? ' -active' : ' -inactive')}>{firstname} is one of your favourites</a> */}
					{supportWorker.bio && (
						<div className="description">
							<p>{truncate(supportWorker.bio, 100)}</p>
						</div>
					)}
					<footer className="footer">
						<p className="profilelink"><a href="#" onClick={this.onViewDetail}>View profile &amp; availability</a></p>
						{ this.props.showPreference && (
							<div className="form-field">
								<label className="label _sr-only">Preference</label>
								<div className="form-input -select">
									<select className="select -small" value={this.props.preferenceLevel || ''} onChange={this.onPreferenceLevelChange}>
										<option value="">No preference</option>
										<option value="1">First</option>
										<option value="2">Second</option>
										<option value="3">Third</option>
										<option value="4">Fourth</option>
										<option value="99">Never (hide)</option>
									</select>
								</div>
							</div>
						)}
						{ !this.props.showPreference && relationship.favourite && (
							<p className="like-options -default">
								<span className="like -yes -active">Favourite</span>
							</p>
						)}
					</footer>
				</div>
			</div>
		)
	}

	private onMakeFavourite = (evt: React.MouseEvent) => {
		evt.preventDefault()
		this.props.onMakeFavourite()
	}

	private onMakeHidden = (evt: React.MouseEvent) => {
		evt.preventDefault()
		this.props.onMakeHidden()
	}

	private onViewDetail = (evt: React.MouseEvent) => {
		evt.preventDefault()
		this.props.onViewDetail()
	}

	private onPreferenceLevelChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
		this.props.onPreferenceLevelChange(evt.target.value ? parseInt(evt.target.value, 10) : undefined)
	}
}
