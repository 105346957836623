import * as React from 'react'
import { Api } from 'typescript-fetch-api'
import { OwnProps, Props, Actions } from '../containers/NewBooking'
import DayRow from './DayRow'
import * as t from '../types'
import * as f from '../functions'
import * as commonFunctions from 'modules/common/functions'
import Datetime from 'react-datetime'
import moment from 'moment'
import { LocalDate } from '@js-joda/core'
import { Link } from 'react-router-dom'
import { Funding } from 'modules/calendar/types'
import * as strings from 'modules/root/strings'

export default class NewBooking extends React.Component<OwnProps & Props & Actions> {

	addRow = () => {
		this.props.onAddBookingLine()
	}

	isSelectableEndDate = (date: moment.Moment): boolean => {
		let startDate = moment(new Date()).subtract(1, 'day')

		if (this.props.settings.startDate) {
			startDate = moment(this.props.settings.startDate!.toString()).subtract(1, 'day')
		}
		return date.isAfter(startDate)
	}

	removeRow = (bookingIndex: number) => {
		const ob = [...this.props.bookings]
		this.props.onRemoveBookingLine(ob[bookingIndex])
	}

	handleStartDate = (date: moment.Moment | string) => {
		if (typeof date === 'string') {
			/* The user entered something that was not parseable as a date */
			return
		}
		const ob = { ...this.props.settings }
		ob.startDate = LocalDate.parse(date.format('YYYY-MM-DD'))
		if (ob.endDate && moment(ob.endDate.toString()).isBefore(date)) {
			ob.endDate = LocalDate.parse(date.add(1, 'day').format('YYYY-MM-DD'))
		}
		this.props.onSettingsChange(ob)
	}

	handleEndDate = (date: moment.Moment | string) => {
		if (typeof date === 'string') {
			/* The user entered something that was not parseable as a date */
			return
		}
		const ob = { ...this.props.settings }
		ob.endDate = LocalDate.parse(date.format('YYYY-MM-DD'))
		this.props.onSettingsChange(ob)
	}

	handleBookingLineChange = (bookingIndex: number, newBookingLine: t.BookingLine) => {
		const ob = this.props.bookings[bookingIndex]
		this.props.onBookingLineChange(ob, newBookingLine)
	}

	handleNotesChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
		const ob = { ...this.props.settings }
		ob.notes = evt.target.value
		this.props.onSettingsChange(ob)
	}

	handleFundingTypeChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const ob = { ...this.props.settings }
		ob.fundingType = Api.NewAppointment.FundingTypeEnum[evt.target.value]
		this.props.onSettingsChange(ob)
	}

	handleChooseSupportWorkerChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const ob = { ...this.props.settings }
		ob.selectSupportWorker = evt.target.value === 'true' ? true : false
		this.props.onSettingsChange(ob)
	}

	handleSetRepeatDate = (addDate: boolean) => {
		const ob = { ...this.props.settings }

		if (addDate === true && !ob.startDate) {
			ob.startDate = LocalDate.now()
		} else if (addDate === false) {
			ob.startDate = undefined
		}
		this.props.onSettingsChange(ob)
	}

	handleCancel = (evt: React.MouseEvent<HTMLInputElement>) => {
		evt.preventDefault()
		this.props.onCancel()
	}

	fundingTypesText = (funding: Funding): string => {
		const jobTypesStrings = funding.jobTypes!.map(jt => `${Math.round(jt.minutes * 10 / 60) / 10} hour${jt.minutes !== 60 ? 's' : ''} of ${jt.jobType.name}`)
		return ' covering ' + commonFunctions.joinLast(jobTypesStrings, ', ', ' and ')
	}

	onContinue = (evt: React.MouseEvent) => {
		evt.preventDefault()

		if (this.props.settings.selectSupportWorker && this.props.findSupportWorkers) {
			this.props.onContinue()
		} else {
			this.props.onContinueToSummary()
		}
	}

	componentDidMount() {
		this.checkFundingType()
	}

	componentDidUpdate() {
		this.checkFundingType()
	}

	public render() {
		const { initialDate, bookings, settings, findSupportWorkers } = this.props

		return (
			<article className="site-body">
	
				<div className="width-limit -site">
					
					<form className="content-layout booking-form" action="#">
						<div className="body">
					
							<div className="form-layout">
								<div className="row">
									<h1 className="headline-text">Booking details</h1>
									{
										this.props.funding && this.props.funding.hasFunding &&
										<>
											<div className="body-text">
												<p>
												You currently have funding{this.fundingTypesText(this.props.funding)} per week. Will this booking be for 
												private (paid) care in addition to those hours?
												</p>
											</div>
											<div className="form-field">
												<div className="form-input -options">
													<ul className="option-inputs -inline">
														<li className="option -radio">
															<label className="label">
																<input 
																	type="radio"
																	value={Api.NewAppointment.FundingTypeEnum.Funded} 
																	onChange={this.handleFundingTypeChange} 
																	name="useFunding" 
																	className="radio" 
																	checked={settings.fundingType === Api.NewAppointment.FundingTypeEnum.Funded ? true : false}
																/>
																<span className="substitute" />
															No, I will use my funded hours
															</label>
														</li>
														<li className="option -radio">
															<label className="label">
																<input 
																	type="radio" 
																	value={Api.NewAppointment.FundingTypeEnum.Private} 
																	onChange={this.handleFundingTypeChange} 
																	name="useFunding" 
																	className="radio"
																	checked={settings.fundingType === Api.NewAppointment.FundingTypeEnum.Private ? true : false}
																/>
																<span className="substitute" />
															Yes, I wish to pay for additional hours
															</label>
														</li>
													</ul>
												</div>
											</div>
										</>
									}
								</div>
							
								<div className="row">
									<h2 className="form-title">Which days and times do you need us to come?</h2>
								</div>

								{
									bookings.map((booking, index) => {
										return (
											<DayRow 
												key={index} 
												index={index} 
												initialDate={initialDate} 
												dayProperties={booking} 
												removeRow={this.removeRow} 
												handleBookingLineChange={this.handleBookingLineChange}
												bookings={bookings}
												handleSetRepeatDate={this.handleSetRepeatDate}
												jobTypes={settings.fundingType === Api.NewAppointment.FundingTypeEnum.Private ? 
													this.props.privateJobTypes : 
													(this.props.funding && this.props.funding.jobTypes ? this.props.funding.jobTypes.map(jt => jt.jobType) : commonFunctions.filterJobTypes(this.props.jobTypes, t.DEFAULT_FUNDED_JOB_TYPES))}
											/>
										)
									})
								}
							
								<div className="row -wide">
									<div className="add-row">
										<div className="link" onClick={this.addRow}><span className="label">Add another booking</span></div>
									</div>
								</div>
								{ 
									f.showStartEndDate(bookings) && 
									<div className="row -split -half">
								
										<div className="form-field">
											<label className="label">Start date</label>
											<div className="form-input -date">
												<Datetime
													className="field"
													dateFormat="ddd D MMM"
													timeFormat={false}
													inputProps={{ readOnly: true, placeholder: 'Select a date…' }}
													onChange={this.handleStartDate}
													isValidDate={f.isSelectableBookingStartDate}
													value={settings.startDate && moment(settings.startDate!.toString(), ['YYYY-MM-DD']).toDate()}
												/>
											</div>
										</div>
					
										<div className="form-field">
											<label className="label">End date <small className="note">(if required)</small></label>
											<div className="form-input -date">
												<Datetime
													className="field"
													dateFormat="ddd D MMM"
													timeFormat={false}
													inputProps={{ readOnly: true, placeholder: 'Select a date…' }}
													onChange={this.handleEndDate}
													isValidDate={this.isSelectableEndDate}
													value={settings.endDate && moment(settings.endDate!.toString(), ['YYYY-MM-DD']).toDate()}
												/>
											</div>
										</div>
					
									</div>
								}

								{
									!!strings.requestBookingNote &&
									<div className='row'>
										<p className='note'>{strings.requestBookingNote}</p>
									</div>
								}

								<div className="row">

									<div className="form-field">
										<label className="label">Additional notes</label>
										<div className="form-input -textarea">
											<textarea
												onChange={this.handleNotesChange}
												value={this.props.settings.notes || ''}
												className="field"
												placeholder="Please provide any additional details about your requirements or special equipment (e.g. Must be able to operate a hoist)" 
											/>
										</div>
									</div>
				
								</div>
								
								{
									findSupportWorkers &&
									<>
										<div className="row">
											<h2 className="form-title">
											Would you like to choose your support worker(s) 
											or let our coordinators find the best match based on your requirements?
											</h2>
										</div>
								
										<div className="row">
											<div className="form-field">
												<div className="form-input -options">
													<ul className="option-inputs">
														<li className="option -radio">
															<label className="label">
																<input 
																	type="radio" 
																	name="supportWorkers" 
																	className="radio" 
																	checked={this.props.settings.selectSupportWorker === true} 
																	value="true"
																	onChange={this.handleChooseSupportWorkerChange}
																/>
																<span className="substitute" />
															I'd like to choose my own support worker(s)
															</label>
														</li>
														<li className="option -radio">
															<label className="label">
																<input 
																	type="radio" 
																	name="supportWorkers" 
																	className="radio" 
																	checked={this.props.settings.selectSupportWorker === false} 
																	value="false" 
																	onChange={this.handleChooseSupportWorkerChange}
																/>
																<span className="substitute" />
															Please find the best match for me
															</label>
														</li>
													</ul>
												</div>
											</div>
										</div>
									</>
								}
							
								<div className="row">
									<div className="button-group">
										<div className="button">
											<input type="submit" className="button-link -text" value="Cancel" onClick={this.handleCancel} />
										</div>
										<div className="button -constrained">
											<a href="#" onClick={this.onContinue} className="button-link -action">Continue</a>
										</div>
									</div>
								</div>
						
							</div>
						</div>
						
						<aside className="sidebar">
							<h1 className="page-title">New booking</h1>
							<nav className="progress-nav">
								<ul className="listitems">
									<li className="item -active"><Link to="/calendar/new" className="link">Booking details</Link></li>
									{
										this.props.settings.selectSupportWorker && findSupportWorkers &&
										<li className="item"><span className="link">Support workers</span></li>
									}
									<li className="item"><span className="link">Summary</span></li>
								</ul>
							</nav>
						</aside>

					</form>
				
				</div>
				
			</article>
		)
	}

	private checkFundingType = () => {
		if (this.props.defaultedFundingType) {
			this.props.onSettingsChange(this.props.settings)
		}
	}
}
