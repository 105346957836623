import * as React from 'react'
import { Props, OwnProps, Actions } from '../containers/Login'
import { ChangeEvent } from 'react'
import { RouteComponentProps } from 'react-router'
import Waiting from 'modules/common/components/Waiting'
import { Link } from 'react-router-dom'
import * as strings from 'modules/root/strings'

interface State {
	username: string
	password: string
	rememberMe: boolean
}

const INITIAL_STATE: State = {
	username: '',
	password: '',
	rememberMe: false,
}

export default class Login extends React.Component<Props & OwnProps & Actions & RouteComponentProps<undefined>, State> {

	public state = INITIAL_STATE

	doLogin = (evt: React.FormEvent) => {
		evt.preventDefault()

		this.props.onLogin(this.state)
	}

	doLogout = () => {
		this.props.onLogout()
	}

	handleUsername = (e: ChangeEvent<HTMLInputElement>) => {
		this.setState({
			username: e.target.value,
		})
	}

	handlePassword = (e: ChangeEvent<HTMLInputElement>) => {
		this.setState({
			password: e.target.value,
		})
	}

	handleRememberMe = (e: ChangeEvent<HTMLInputElement>) => {
		this.setState({
			rememberMe: e.target.checked,
		})
	}

	componentDidUpdate() {
		if (this.props.loggedIn && this.props.loadedAccount && !this.props.loadingAccount) {
			this.props.onLoggedIn()
		}
	}

	public render() {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const registration = this.props.location.state && (this.props.location.state as any).registration
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const fromInvite = this.props.location.state && (this.props.location.state as any).from && (this.props.location.state as any).from.pathname === '/setup/register-new/invites'
		let bannerMessage: string | undefined = undefined
		
		if (fromInvite) {
			bannerMessage = 'Please sign in to view your support network invites'
		} else if (registration === 'completed') {
			bannerMessage = 'Registration complete'
		} else if (registration === 'incomplete') {
			bannerMessage = 'You must be logged in to update your account details'
		}

		if (this.props.loggedIn || this.props.loadingAccount) {
			return (
				<Waiting />
			)
		}

		return (
			<article className="site-body">
				{bannerMessage && (
					<div className={'banner-container ' + (registration === 'completed' || fromInvite ? '-success' : '-failed')}>
						<div className="width-limit -site">
							<div className="banner">
								<div className="body-text">
									<h1>{bannerMessage}</h1>
									{registration === 'completed' && <p>Please sign in below to access your account</p>}
								</div>
							</div>
						</div>
					</div>
				)}
	
				<div className="width-limit -site">
					
					<form className="signin-form" onSubmit={this.doLogin}>
					
						<div className="form-layout">
							<div className="introduction">
								<h1 className="headline-text">Sign in</h1>
							</div>

							{!!strings.signInScreenNote && (
								<div className="messages -info">{strings.signInScreenNote}</div>
							)}

							{/* {(error && error.message) && (
								<div className="messages -error">
									<h2 className="heading">Oops!</h2>
									<p>{error.message}</p>
								</div>
							)} */}
							
							<div className="row">
								<div className="form-field">
									<label className="label">Email</label>
									<div className="form-input">
										<div className="form-input -text">
											<input type="email" className="field" onChange={this.handleUsername} value={this.state.username} autoComplete="username" />
										</div>
									</div>
								</div>
							</div>
							<div className="row -password">
								<div className="form-field">
									<label className="label">Password</label>
									<div className="form-input">
										<div className="form-input -text">
											<input type="password" className="field" onChange={this.handlePassword} autoComplete="current-password" />
										</div>
									</div>
								</div>
							</div>
							
							<div className="row -remember">
								<div className="form-field">
									<div className="form-input -options">
										<ul className="option-inputs">
											<li className="option -radio">
												<label className="label">
													<input type="checkbox" name="remember" className="checkbox" value="true" checked={this.state.rememberMe} onChange={this.handleRememberMe} /><span className="substitute" />
													Remember me
												</label>
											</li>
										</ul>
									</div>
								</div>
							</div>
							
							<div className="row -button">
								<div className={'button-link -nested -action -fullwidth' + (this.props.processing ? ' -processing' : '')}>
									<input type="submit" value="Sign in" />
								</div>
							</div>
							
							<div className="row">
								<p><Link to="/sign-in/forgot-password" className="text">Forgotten your password?</Link></p>
							</div>
						</div>
						
					</form>
				
				</div>
				
			</article>
		)
	}
}
