import * as React from 'react'

interface OwnProps {
	params: string
}

interface State {
	showDebug: boolean
}

const INITIAL_STATE: State = {
	showDebug: false,
}

export default class Footer extends React.Component<OwnProps, State> {

	public state = INITIAL_STATE

	public componentDidMount() {
		const debug = new URLSearchParams(this.props.params).get('debug')
		if (debug === '1') {
			this.setState({ showDebug: true })
		} else {
			this.setState({ showDebug: false })
		}
	}
	
	public render() {
		return (
			<>
				{
					this.state.showDebug &&
					<div className="site-grid width-limit -site">
						<div className="column" />
						<div className="column" />
						<div className="column" />
						<div className="column" />
						<div className="column" />
						<div className="column" />
						<div className="column" />
						<div className="column" />
						<div className="column" />
						<div className="column" />
						<div className="column" />
						<div className="column" />
					</div>
				}
			</>
		)
	}
}
