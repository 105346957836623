import * as React from 'react'

import platform, { Brand } from 'modules/platform'

interface OwnProps {
	message?: string
}

export default class Waiting extends React.Component<OwnProps> {

	public render() {
		const { message } = this.props
		return (
			<article className="site-body">
				<div className="width-limit -site">
					<div className="loading-state">
						<div className="indicator">
							{platform.brand() === Brand.Suemann || platform.brand() === Brand.Hcnz ? (
								<svg xmlns="http://www.w3.org/2000/svg" width="56" height="55" viewBox="0 0 56 55">
									<g fill="none" fillRule="evenodd">
										<path fill="#4ABDB6" d="M53.7021064,20.5604138 L49.7197802,17.791123 L49.7197802,8.43249409 C49.7197802,7.40283395 48.69012,7.32494004 48.69012,7.32494004 L45.7366426,7.32494004 C45.7366426,7.32494004 44.590953,7.30546656 44.590953,8.43249409 C44.590953,8.84062573 44.5941985,9.77859824 44.6071809,10.5672741 C44.6209746,11.3713664 44.6104264,12.0691661 44.6104264,12.4537673 C44.6104264,13.7901347 43.5418194,12.784005 43.5418194,12.784005 L29.3983947,2.00218944 C29.3983947,2.00218944 28.1739998,0.991191388 26.9187719,1.00005798 C25.8071608,1.00741929 24.7742551,2.040325 24.7742551,2.040325 L2.24587549,20.4492527 C-0.421179569,22.7828244 2.02355329,23.0051466 2.02355329,23.0051466 L5.09387162,22.9848618 C5.09387162,22.9848618 5.09143744,45.2097797 5.09143744,49.4428269 C5.09143744,53.0389292 8.14390508,52.9999911 8.14390508,52.9999911 C8.14390508,52.9999911 42.2849686,52.9999911 46.0360473,52.9999911 C49.7222144,52.9999911 49.7222144,49.4752827 49.7222144,49.4752827 L49.7011181,22.9102134 C49.7011181,22.9102134 52.1515307,22.9158932 53.485464,22.9158932 C56.7708019,22.9158932 53.7021064,20.5604138 53.7021064,20.5604138"/>
										<path className="heart" fill="#FFFFFF" d="M42.1364834,29.3900132 C41.4695168,33.2798404 39.2471061,40.3925281 27.9111078,45.9489605 C27.9111078,45.9489605 14.5742097,41.7264614 12.3517991,30.5016242 C10.128577,19.276787 20.999646,19.9964943 22.3538641,20.4987478 C23.7640685,21.0220975 25.466375,21.832681 27.3553023,24.1662528 C27.3553023,24.1662528 31.0228073,19.276787 36.0242455,20.2764256 C41.0248723,21.2768755 42.80345,25.500186 42.1364834,29.3900132"/>
									</g>
								</svg>
							) : (
								<svg xmlns="http://www.w3.org/2000/svg" width="56" height="55" viewBox="0 0 56 55">
									<g fill="none" fillRule="evenodd">
										<path fill="#FFC233" d="M50.9750317,24.4901903 L50.9750317,46.3170141 C50.9750317,50.56021 47.5463747,54 43.316912,54 L12.6844334,54 C8.45497069,54 5.02631373,50.56021 5.02631373,46.3170141 L5.02631373,24.4901903 C3.35263216,24.4885968 1.71704462,23.6486852 0.764578665,22.1197913 C-0.706478388,19.7584575 0.00903802966,16.6478187 2.36272931,15.171985 L25.3370883,0.766386398 C26.9667543,-0.255462133 29.0345911,-0.255462133 30.6642571,0.766386398 L53.6386161,15.171985 C55.9923074,16.6478187 56.7078238,19.7584575 55.2367667,22.1197913 C54.2843008,23.6486852 52.6487132,24.4885968 50.9750317,24.4901903 Z"/>
										<path className="heart" fill="#F03F51" d="M26.4783574,25.528431 C27.8120898,25.4371887 29.2346645,24.2744418 31.5428426,21.8626079 C33.8510206,19.450774 36.7412964,18.9975668 38.8011232,19.7509091 C40.86095,20.5042514 42.3855289,22.145981 42.6848039,25.2113758 C42.9840789,28.2767705 40.140183,35.6892224 34.1434013,40.300993 C28.1466197,44.9127636 25.7922186,43.5050698 24.5242954,42.9783311 C23.2563722,42.4515924 20.3121956,41.4449221 15.3693007,33.3453854 C10.4264058,25.2458486 14.8951613,22.573887 15.8368591,22.1820352 C16.7785569,21.7901833 19.1851353,21.6287302 21.0653971,22.8635854 C22.9456589,24.0984406 25.1446251,25.6196734 26.4783574,25.528431 Z"/>
									</g>
								</svg>
							)}
						</div>
						<h1 className="text">{message || 'One moment…'}</h1>
					</div>
				</div>
			</article>
		)
	}
}
