import * as React from 'react'
import { Api } from 'typescript-fetch-api'
import { Props, Actions } from '../containers/UserPersonalDetails'
import AvatarModal from './AvatarModal'
import { PatchFormState, Input } from 'formalities'
import { Helmet } from 'react-helmet'
import { avatarPlaceholder } from 'modules/root/theme'

interface State {
	editingAvatar: boolean
	user?: PatchFormState<Api.User, Api.UpdateUserDetailsRequest>
}

const INITIAL_STATE: State = {
	editingAvatar: false,
}

export default class UserPersonalDetails extends React.Component<Props & Actions, State> {

	public state = INITIAL_STATE

	onAvatarStart = (evt: React.MouseEvent) => {
		evt.preventDefault()
		this.setState({ editingAvatar: true })
	}

	onAvatarDone = (image?: string) => {
		this.setState({ editingAvatar: false })
		if (image) {
			this.props.onAvatarUpload(image)
		}
	}

	onAvatarCancel = () => {
		this.setState({ editingAvatar: false })
	}

	onSave = (evt: React.FormEvent<HTMLInputElement>) => {
		evt.preventDefault()

		if (this.state.user) {
			this.props.onSave(this.state.user.getValues())
		}
	}

	onNewFormState = (newState: PatchFormState<Api.User, Api.UpdateUserDetailsRequest>) => {
		this.setState({ user: newState })
	}

	componentWillReceiveProps(nextProps: Props) {
		/* Clear our state when we get new props, as it means the client details have changed */
		if (this.props.saving && !nextProps.saving) {
			this.setState({ user: undefined })
		}
	}

	public render() {
		const { user } = this.props
		if (!user) {
			return null
		}

		const userState = this.state.user || new PatchFormState<DeepReadonlyObject<Api.User>, Api.UpdateUserDetailsRequest>(user, {})

		return (
			<div className="body">
				<Helmet>
					<title>Personal details — My Homecare</title>
				</Helmet>
				
				<h1 className="headline-text">Personal details</h1>
				
				<form className="form-layout">
					<div className="row">
						<div className="form-field -avatar">
							<figure className="avatar-image -upload -bordered">
								<div className="image">
									{userState.get('image') ? (
										<img src={userState.get('image')} alt={user.fullName} />
									) : (
										<img src={avatarPlaceholder()} alt={user.fullName} />
									)}
								</div>
							</figure>
							<div className="content">
								<label className="label">Profile image</label>
								<p>Upload an image of yourself. This will not be displayed publicly, but helps our support workers recognise you.</p>
								<a href="#" onClick={this.onAvatarStart} className="button-link -action -xsmall">{userState.get('image') ? 'Replace' : 'Add'}</a>
							</div>
						</div>
					</div>
					<div className="row -split -half">
						
						<div className="form-field">
							<label className="label">Given name</label>
							<div className="form-input -text">
								<Input formState={userState} onNewFormState={this.onNewFormState} type="text" className="field" name="givenName" />
							</div>
						</div>
		
						<div className="form-field">
							<label className="label">Family name</label>
							<div className="form-input -text">
								<Input formState={userState} onNewFormState={this.onNewFormState} type="text" className="field" name="familyName" />
							</div>
						</div>
		
					</div>

					<div className="row">
						<div className="form-field">
							<label className="label">Phone number</label>
							<div className="form-input -text">
								<Input formState={userState} onNewFormState={this.onNewFormState} type="number" className="field" name="phoneHome" />
							</div>
						</div>
					</div>

					<div className="row">
						<div className="form-field">
							<label className="label">Email address {/* <span className="note">(<Link to="/account/email" className="-link">update email address</Link>)</span> */}</label>
							<div className="form-input -text">
								<input type="email" className="field" disabled={true} value={user.email} />
							</div>
						</div>
					</div>

					<div className="row">
						<div className="button-group">
							<div className="button -constrained">
								<div className={'button-link -nested -action' + (this.props.saving ? ' -processing' : '') + (userState.isEmpty() ? ' -disabled' : '')}>
									<input type="submit" value="Save changes" onClick={this.onSave} disabled={userState.isEmpty()} />
								</div>
							</div>
						</div>
					</div>
				</form>

				{this.state.editingAvatar && (
					<AvatarModal onDone={this.onAvatarDone} onClose={this.onAvatarCancel} />
				)}
			</div>
		)
	}
}
