import actionCreatorFactory from 'typescript-fsa'
import { Api } from 'typescript-fetch-api'

const actionCreator = actionCreatorFactory('Dashboard')

export interface AppointmentFeedbackPayload {
	request: Api.PostAppointmentFeedbackRequest
	appointment: string
}

export const addAppointmentFeedback = actionCreator<AppointmentFeedbackPayload>('ADD_APPOINTMENT_FEEDBACK')
export type AddAppointmentFeedbackAction = ReturnType<typeof addAppointmentFeedback>
export const addAppointmentFeedbackAsync = actionCreator.async<AppointmentFeedbackPayload, never, Api.PostAppointmentFeedbackError>('ADD_APPOINTMENT_FEEDBACK')
