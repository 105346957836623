import * as React from 'react'
import { RouteComponentProps, Switch, Route, Redirect } from 'react-router'
import { OwnProps, Props, Actions } from '../containers/AccountScreen'
import Header from 'modules/common/containers/Header'
import Footer from 'modules/common/components/Footer'
import Debug from 'modules/common/components/Debug'
import PersonalDetails from '../containers/PersonalDetails'
import IndividualisedFundingRouteComponent from 'modules/individualised-funding/components/IndividualisedFundingRouteComponent'
import ChangePassword from '../containers/ChangePassword'
import SupportNetwork from '../containers/SupportNetwork'
import MyNavLink from 'modules/routing/components/MyNavLink'
import Resources from 'modules/individualised-funding/components/Resources'
import ClientDoctorDetails from '../containers/ClientDoctorDetails'
import ClientNextOfKinDetails from '../containers/ClientNextOfKinDetails'
import { Link } from 'react-router-dom'  
import { Api } from 'typescript-fetch-api'
import HealthPassportIndex from 'modules/health-passport/containers/HealthPassportIndex'
import AdvanceCarePlanIndex from 'modules/advance-care-plan/containers/AdvanceCarePlanIndex'
import Documents from '../containers/Documents'

export default class AccountScreen extends React.Component<Props & OwnProps & Actions & RouteComponentProps<undefined>> {

	onSignOut = () => {
		this.props.onSignOut()
	}

	public render() {
		const { membership, user, you, client, individualisedFunding, funders, featureAdvanceCarePlan, featureHealthPassport } = this.props

		const canPersonalDetails = user || (membership && (membership.capabilities.manager || membership.capabilities.viewClientDetails || membership.capabilities.manageClientDetails))

		const canViewRosterOnly = membership && membership.capabilities.viewRoster && !membership.capabilities.manageClientDetails && !membership.capabilities.manager && !membership.capabilities.viewClientDetails
		const canViewDocuments = membership && membership.capabilities.manager
		const showSideNav = (you || canPersonalDetails || membership)

		return (

			<div className="site-content account-screen">
				<Header section="account" />
				
				<article className="site-body">
					<div className="width-limit -site">
					
						<div className={'content-layout' + (showSideNav ? '' : ' -singlecol')}>
					
							<Switch>
								<Route 
									path="/account" 
									exact={true} 
									render={props => {
										if (canPersonalDetails) {
											return (
												<Redirect to="/account/details" />
											)
										} else {
											return (
												<Redirect to="/account/support-network" />
											)
										}
									}}
								/>
								<Route path="/account/details" exact={true} component={PersonalDetails} />
								<Route path="/account/individualised-funding/:individualisedFundingId" component={IndividualisedFundingRouteComponent} />
								{this.props.featureDoctorDetails && <Route path="/account/doctor-details" exact={true} component={ClientDoctorDetails} />}
								{this.props.featureNextOfKin && <Route path="/account/next-of-kin-details" exact={true} component={ClientNextOfKinDetails} />}
								<Route path="/account/support-network" component={SupportNetwork} />
								<Route path="/account/change-password" component={ChangePassword} />
								<Route path="/account/resources" component={Resources} />
								{this.props.featureMyDocuments && <Route path="/account/my-documents" exact={true} component={Documents} />}
								{featureHealthPassport && <Route path="/account/my-documents/health-passport" exact={true} component={HealthPassportIndex} />}
								{featureAdvanceCarePlan && <Route path="/account/my-documents/advance-care-plan" exact={true} component={AdvanceCarePlanIndex} />}
							</Switch>

							{showSideNav && (
								<aside className="sidebar">
									<Switch>
										<Route 
											path="/account/individualised-funding/:individualisedFundingId/register*"
											render={(props) => {
												return (
													<p className="back-link"><Link to={`/account/individualised-funding/${props.match.params.individualisedFundingId}`} className="-arrow">Back to account</Link></p>
												)
											}}
										/>
										<Route
											path="/account"
											render={() => {
												return (
													<>
														{you ? (
															<h1 className="page-title">My account</h1>
														) : '' }
														<nav className="section-nav">
															<ul className="listitems">
																{canPersonalDetails && (
																	<MyNavLink to="/account/details" className="item" activeClassName="-active -current" linkClassName="link">My personal details</MyNavLink>
																)}
																{
																	Object.keys(individualisedFunding).map((key) => {
																		const ifo = individualisedFunding[key]
																		return (
																			<React.Fragment key={key}>
																				<MyNavLink 
																					to={'/account/individualised-funding/' + key} 
																					className="item" 
																					activeClassName="-active -current" 
																					linkClassName="link"
																				>
																					{Object.keys(individualisedFunding).length > 1 ? funders[ifo.funderRef].name : 'My funding'}
																				</MyNavLink>
																				<Switch>
																					<Route 
																						path={'/account/individualised-funding/' + key}
																						render={() => {
																							return (
																								<div className="subnav">
																									<MyNavLink to={'/account/individualised-funding/' + key} exact={true} className="item" activeClassName="-active -current" linkClassName="link">Overview</MyNavLink>
																									{ifo.needsLevel !== Api.IndividualisedFunding.NeedsLevelEnum.Level3 &&
																							(
																								<MyNavLink to={'/account/individualised-funding/' + key + '/support-workers'} className="item" activeClassName="-active -current" linkClassName="link">Support workers</MyNavLink>
																							)}
																									{ifo.needsLevel !== Api.IndividualisedFunding.NeedsLevelEnum.Level3 &&
																							(
																								<MyNavLink to={'/account/individualised-funding/' + key + '/payroll-details'} className="item" activeClassName="-active -current" linkClassName="link">Payroll details</MyNavLink>
																							)}
																									<MyNavLink to={'/account/individualised-funding/' + key + '/timesheets-expenses'} className="item" activeClassName="-active -current" linkClassName="link">
																										{ifo.needsLevel === Api.IndividualisedFunding.NeedsLevelEnum.Level1 && 'Expense claims'}
																										{ifo.needsLevel === Api.IndividualisedFunding.NeedsLevelEnum.Level2 && 'Timesheets & expenses'}
																										{ifo.needsLevel === Api.IndividualisedFunding.NeedsLevelEnum.Level3 && 'Expense claims'}
																									</MyNavLink>
																									<MyNavLink to={'/account/individualised-funding/' + key + '/transaction-history'} className="item" activeClassName="-active -current" linkClassName="link">Transaction history</MyNavLink>
																								</div>
																							)
																						}}
																					/>
																				</Switch>
																			</React.Fragment>
																		)
																	})
																}
																{this.props.featureDoctorDetails && client && !canViewRosterOnly && <MyNavLink to="/account/doctor-details" className="item" activeClassName="-active -current" linkClassName="link">My doctor details</MyNavLink>}
																{this.props.featureNextOfKin && client && !canViewRosterOnly && <MyNavLink to="/account/next-of-kin-details" className="item" activeClassName="-active -current" linkClassName="link">My next of kin</MyNavLink>}
																{membership && (
																	<MyNavLink to="/account/support-network" className="item" activeClassName="-active -current" linkClassName="link">My support network</MyNavLink>
																)}
																{client && canViewDocuments && this.props.featureMyDocuments && 
															<>
																<MyNavLink to="/account/my-documents" className="item" activeClassName="-active -current" linkClassName="link">My documents</MyNavLink>
																<Switch>
																	<Route 
																		path={'/account/my-documents/'}
																		render={() => {
																			return (
																				<div className="subnav">
																					{featureHealthPassport && <MyNavLink to={'/account/my-documents/health-passport'} className="item" activeClassName="-active -current" linkClassName="link">e-Health passport</MyNavLink>}
																					{featureAdvanceCarePlan && <MyNavLink to={'/account/my-documents/advance-care-plan'} className="item" activeClassName="-active -current" linkClassName="link">Advance care plan</MyNavLink>}
																				</div>
																			)
																		}}
																	/>
																</Switch>
															</>
																}
																{you && (
																	<MyNavLink to="/account/change-password" className="item" activeClassName="-active -current" linkClassName="link">Change my password</MyNavLink>
																)}
																{/*
														<MyNavLink to="/account/my-services" className="item" activeClassName="-active -current" linkClassName="link">My services</MyNavLink>
														<MyNavLink to="/account/payment" className="item" activeClassName="-active -current" linkClassName="link">Payment method</MyNavLink>
														*/}
																{you && (
																	<li className="item">
																		<a onClick={this.onSignOut} className="link">Sign out</a>
																	</li>
																)}
															</ul>
														</nav>
													</>
												)
											}}
										/>
									</Switch>
								</aside>
							)}
						</div>
					</div>
				</article>

				<Footer />
				<Debug params={this.props.location.search}/>
			</div>
		)
	}
}
