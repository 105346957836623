import { RootStoreState } from 'modules/root'

export const organisationClientAccessSelector = (state: RootStoreState, clientRef: string) => {
	if (state.funderDashboard.funderSearchResultRefs) {
		const index = state.funderDashboard.funderSearchResultRefs.findIndex(x => x.clientRef === clientRef)
		return state.funderDashboard.funderSearchResultRefs[index]
	}

	return undefined
}

export const createClientFromOrganisation = (state: RootStoreState, clientRef: string) => state.funderDashboard.searchResultClients ? state.funderDashboard.searchResultClients.clients[clientRef] : undefined