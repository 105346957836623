import { Api } from 'typescript-fetch-api'
import { RootStoreState } from 'modules/root'

export function pronoun(supportWorker: DeepReadonly<Api.SupportWorker>) {
	switch (supportWorker.genderRef) {
		case 'F': return 'her'
		case 'M': return 'him'
		default: return 'them'
	}
}

export function givenName(supportWorker: DeepReadonly<Api.SupportWorker>) {
	if (supportWorker.givenName) {
		return supportWorker.givenName
	}

	const fullName = supportWorker.fullName
	const i = fullName.indexOf(' ')
	if (i !== -1) {
		return fullName.substring(0, i)
	}
	return pronoun(supportWorker)
}

export function filterBlockedWorkers(state: RootStoreState, clientSupportWorkerRelationshipRefs: DeepReadonlyArray<string>): string[] {
	return clientSupportWorkerRelationshipRefs.filter((ref) => {
		return state.supportWorkers.refs.relationships[ref].blocked === false
	})
}
