import * as React from 'react'
import { OwnProps, Props, Actions } from '../containers/JoinSupportNetwork'
import { avatarPlaceholder } from 'modules/root/theme'

interface State {
	invitationAcceptanceList: string[]
}

const INITIAL_STATE: State = {
	invitationAcceptanceList: [],
}

export default class JoinSupportNetwork extends React.Component<OwnProps & Props & Actions, State> {

	public state = INITIAL_STATE

	declineInvite = (clientRef: string, inviteRef: string) => {
		this.props.declineInvite({ clientRef, inviteRef })
	}

	toggleInvitationAcceptance = (clientRef: string) => {
		const index = this.state.invitationAcceptanceList.indexOf(clientRef)
		if (index > -1) {
			const newArray = [...this.state.invitationAcceptanceList]
			newArray.splice(index, 1)
			this.setState(previousState => ({
				invitationAcceptanceList: newArray,
			}))
		} else {
			this.setState(previousState => ({
				invitationAcceptanceList: [...previousState.invitationAcceptanceList, clientRef],
			}))
		}
	}

	joinSupportNetworks = () => {
		this.state.invitationAcceptanceList.forEach((clientRef) => {
			this.props.acceptSupportNetworkInvite(clientRef)
		})
	}

	public render() {
		const hasInvites = this.props.supportNetworkInvites && this.props.supportNetworkInvites.length > 0 ? true : false

		return (
			<div className="form-layout -supportnetwork">
				<div className="introduction">
					<h1 className="headline-text">Join a support network</h1>
					{hasInvites && (
						<div className="body-text">
							<p>You have been invited to join the support network for someone you know. Please review the invitations below.</p>
						</div>
					)}
				</div>
				{hasInvites ? (
					<>
						<div className="row">
							<div className="network-invitations">
								<table className="table table-default">
									<tbody>
										{
											this.props.supportNetworkInvites!.map((invite, key) => {
												return (
													<tr key={key}>
														<td>
															<div className="person">
																<figure className="avatar-image -large">
																	<div className="image">
																		{invite.client ? (
																			<img src={invite.client.image || avatarPlaceholder()} alt={invite.client.fullName} />
																		) : (
																			<img src={avatarPlaceholder()} alt="" />
																		)}
																	</div>
																</figure>
																{invite.client ? (
																	<div className="name">
																		<strong>{invite.client.fullName}</strong><br/>
																		{
																			invite.inviterIsClient ? (
																				<span className="context">{invite.client.givenName || invite.client.fullName} has invited you to their support network</span>
																			) : (invite.invitedByUserName === '' ? (
																				<span className="context">You have been invited to join {invite.client.givenName || invite.client.fullName}'s support network</span>
																			) : (
																				<span className="context">{invite.invitedByUserName} has invited you to {invite.client.givenName || invite.client.fullName}'s support network</span>
																			))
																		}
																	</div>
																) : (
																	<div className="name">
																		<strong>Loading details…</strong><br/>
																	</div>
																)}
															</div>
														</td>
														<td className="button"><a href="#" onClick={() => this.declineInvite(invite.clientRef, invite.ref)}className="button-link -secondary -micro">Decline</a></td>
														<td>
															<div className="option-inputs">
																<div className="option -checkbox">
																	<label className="label">
																		<input 
																			type="checkbox" 
																			name="supportWorkers" 
																			className="checkbox"
																			checked={this.state.invitationAcceptanceList.indexOf(invite.clientRef) > -1}
																			onChange={() => this.toggleInvitationAcceptance(invite.clientRef)}
																		/>
																		<span className="substitute" />
																	Accept
																	</label>
																</div>
															</div>
														</td>
													</tr>
												)
											})
										}
									</tbody>
								</table>
							</div>
						</div>
						<div className="row">
							<div className="button-group">
								<div className="button -joinbutton">
									<input 
										type="submit" 
										className="button-link -action -small" 
										value="Join" 
										disabled={this.state.invitationAcceptanceList.length === 0} 
										onClick={this.joinSupportNetworks}
									/>
								</div>
							</div>
						</div>
						<div className="introduction">
							<div className="body-text">
								<p>If you would like to join the support network for a loved one and they don't appear above, ask them to send you an invitation.</p>
							</div>
						</div>
					</>
				) : (
					<>
						<div className="introduction">
							<div className="body-text">
								<p>If you would like to join the support network for a loved one, ask them to send you an invitation.</p>
							</div>
						</div>
					</>
				)}
			</div>
		)
	}
}