import * as React from 'react'
import * as t from '../types'
import { OwnProps, Props, Actions } from '../containers/RegisterForCare'

interface State {
	requestingCareFor: t.RequestCareForEnum
}

const INITIAL_STATE: State = {
	requestingCareFor: t.RequestCareForEnum.Self,
}

export default class RegisterForCare extends React.Component<OwnProps & Props & Actions> {

	public state = INITIAL_STATE

	public render() {
		return (
			<div className="form-layout -registerforcare">
				<div className="introduction">
					<h1 className="headline-text">Register for care</h1>
					<div className="body-text">
						<p>I wish to manage care for myself, or on behalf of a loved one who doesn’t currently have a My Homecare account.</p>
					</div>
				</div>
				<div className="row">
					<div className="form-field">
						<div className="form-input -options">
							<ul className="option-inputs">
								<li className="option -radio">
									<label className="label">
										<input 
											type="radio" 
											name="supportWorkers" 
											className="radio"
											value={t.RequestCareForEnum.Self}
											onChange={this.onUpdateRequestingCareFor} 
											checked={this.requestingCareFor() === t.RequestCareForEnum.Self} 
											disabled={this.props.alreadyAClient}
										/>
										<span className="substitute" />
										I'd like to manage care for myself
									</label>
								</li>
								<li className="option -radio">
									<label className="label">
										<input 
											type="radio" 
											name="supportWorkers" 
											className="radio"
											value={t.RequestCareForEnum.Another}
											onChange={this.onUpdateRequestingCareFor} 
											checked={this.requestingCareFor() === t.RequestCareForEnum.Another} 
										/>
										<span className="substitute" />
										I'd like to manage care on behalf of a loved one
									</label>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="button-group -singlebutton">
						<div className="button -constrained">
							<a href="#" onClick={this.onRegisterForCare} className="button-link -action">
								Register for care
							</a>
						</div>
					</div>
				</div>
			</div>
		)
	}

	private onUpdateRequestingCareFor = (evt: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ requestingCareFor: t.RequestCareForEnum[evt.target.value] })
	}

	private requestingCareFor = () => {
		if (this.props.alreadyAClient) {
			return t.RequestCareForEnum.Another
		} else {
			return this.state.requestingCareFor
		}
	}

	private onRegisterForCare = (evt: React.MouseEvent) => {
		evt.preventDefault()
		this.props.onRegisterForCare(this.requestingCareFor())
	}
}