import * as React from 'react'

import coverLogo from 'modules/frontend/img/health-passport/hdc-logo-wordmark.svg'

export default class HealthPassportBackCover extends React.Component {
	public render() {
		return (
			<div className="passport-back-cover">
				<h2 className="carry-message">The Passport stays with me in hospital. <br/>Please ensure I take it with me when I leave.</h2>
				<div className="info-table">
					<p className="intro">To provide feedback on the Passport, please contact:</p>
					<div className="contact">
						<p>Health &amp; Disability Commissioner</p>
						<p>PO Box 1791, Auckland 1140.</p>
						<p>Free Phone: 0800 11 22 33; Fax: 09 373 1061</p>
						<p>Email: <a href="mailto:healthpassport@hdc.org.nz">healthpassport@hdc.org.nz</a></p>
						<p>Website: <a href="www.hdc.org.nz">www.hdc.org.nz</a></p>
					</div>
					<div className="logo-container">
						<img src={coverLogo} />
					</div>
				</div>
			</div>
		)
	}
}