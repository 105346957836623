import { reducerWithInitialState } from 'typescript-fsa-reducers'
import * as calendar from '../calendar/actions'
import * as f from './functions'
import * as t from './types'
import { loggedOut, login } from 'modules/auth/actions'
import produce from 'immer'
import { Api } from 'typescript-fetch-api'

export type StoreState = DeepReadonly<MutableStoreState>

export interface MutableStoreState {
	jobs?: t.RecentDay[]
	loadingCalendar: boolean

	tickets?: Api.Ticket[]
	loadingTickets: boolean
}

const INITIAL_STATE: StoreState = {
	loadingCalendar: false,
	loadingTickets: false,
}

export const reducer = reducerWithInitialState(INITIAL_STATE)

reducer.case(calendar.loadCalendarAsync.started, (state) => produce(state, draft => {
	draft.jobs = undefined
	draft.loadingCalendar = true
}))

reducer.case(calendar.loadCalendarAsync.failed, (state) => produce(state, draft => {
	draft.loadingCalendar = false
}))

reducer.case(calendar.loadCalendarAsync.done, (state, payload) => {
	let recentJobs: t.RecentDay[] = []
	if (payload.result.payload && payload.result.refs) {
		recentJobs = f.recentJobs(payload.result.payload.days, payload.result.refs)
	}
	return { ...state, jobs: recentJobs, loadingCalendar: false }
})

reducer.case(calendar.loadClientTicketsAsync.started, (state) => produce(state, draft => {
	draft.tickets = undefined
	draft.loadingTickets = true
}))

reducer.case(calendar.loadClientTicketsAsync.failed, (state) => produce(state, draft => {
	draft.loadingTickets = false
}))

reducer.case(calendar.loadClientTicketsAsync.done, (state, payload) => {
	const ticketRefs = payload.result.payload.ticketRefs
	const tickets = ticketRefs.map(ref => payload.result.refs.tickets[ref])

	return { ...state, tickets, loadingTickets: false }
})

reducer.cases([login.started, loggedOut], (): StoreState => INITIAL_STATE)
