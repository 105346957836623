import { SagaIterator } from 'redux-saga'
import { takeEvery, call, select } from 'redux-saga/effects'
import * as a from './actions'
import { currentClientRefSelector } from 'modules/account/selectors'
import { callApiWithActions, handleApiFailedAction, handleApiSuccessAction } from 'modules/api/functions'
import api from 'modules/api'

function* addAppointmentFeedback(action: a.AddAppointmentFeedbackAction): SagaIterator {
	const currentClientRef = (yield select(currentClientRefSelector)) as string | undefined
	if (!currentClientRef) {
		return
	}

	yield call(callApiWithActions, action.payload, a.addAppointmentFeedbackAsync, (options: RequestInit) => {
		return api().clientApi.postAppointmentFeedback(currentClientRef, action.payload.appointment, action.payload.request, options)
	})
}

export default function* (): SagaIterator {
	yield takeEvery(a.addAppointmentFeedback, addAppointmentFeedback)
	yield takeEvery([a.addAppointmentFeedbackAsync.done], handleApiSuccessAction.bind(null, 'Thank you, your feedback has been sent.'))
	yield takeEvery([a.addAppointmentFeedbackAsync.failed], handleApiFailedAction.bind(null, 'Oops! We were unable to send your feedback.'))
}
