import * as React from 'react'
import TextBox from './TextBox'
import { forComponentProps, wrapComponent } from 'changeling'
import LegalForm from './LegalForm'
import Dropdown from './Dropdown'
import MultiDropdown from './MultiDropdown'
import Radio from './Radio'
import CheckBox from './CheckBox'
import { HealthPassportFormProps } from './HealthPassportScreen'

import communicationHeader from 'modules/frontend/img/health-passport/4-other-languages.jpg'
import medicalHeader from 'modules/frontend/img/health-passport/8-medical-care.jpg'
import decisionMakingHeader from 'modules/frontend/img/health-passport/14-decision-making.jpg'
import Image1 from 'modules/frontend/img/health-passport/1-health-condition.jpg'
import Image2 from 'modules/frontend/img/health-passport/2-emergency.jpg'
import Image3 from 'modules/frontend/img/health-passport/3-first-language.jpg'
import Image4 from 'modules/frontend/img/health-passport/4-other-languages.jpg'
import Image5 from 'modules/frontend/img/health-passport/5-interpreting-help.jpg'
import Image6 from 'modules/frontend/img/health-passport/6-communication-abilities.jpg'
import Image7 from 'modules/frontend/img/health-passport/7-communication-info.jpg'
import Image9 from 'modules/frontend/img/health-passport/9-pain-awareness.jpg'
import Image10 from 'modules/frontend/img/health-passport/10-allergies.jpg'
import Image11 from 'modules/frontend/img/health-passport/11-medication-prefs.jpg'
import Image12 from 'modules/frontend/img/health-passport/12-examination-prefs.jpg'
import Image13 from 'modules/frontend/img/health-passport/13-other-medical-care-info.jpg'
import Image15 from 'modules/frontend/img/health-passport/15-legal-representative.jpg'
import Image16 from 'modules/frontend/img/health-passport/16-future-wishes-info.jpg'
import Image17 from 'modules/frontend/img/health-passport/17-decision-consent.png'
import Image18 from 'modules/frontend/img/health-passport/18-safety-support.jpg'
import Image19 from 'modules/frontend/img/health-passport/19-physical-safety.jpg'
import Image20 from 'modules/frontend/img/health-passport/20-stress-causes.jpg'
import Image21 from 'modules/frontend/img/health-passport/21-stress-signs.jpg'
import Image22 from 'modules/frontend/img/health-passport/22-stress-relievers.jpg'

type MyComponentProps = HealthPassportFormProps

class HealthPassportSectionA extends React.Component<MyComponentProps> {

	private changeling = forComponentProps(this)

	public render() {
		const { editingMode, value } = this.props

		return (
			<>
				<h2 className="section-subheader"><span className="header-number">1.</span>This is what I want to tell you about myself</h2>
				<TextBox
					subOrder="a)"
					title="My impairment or other health condition/s are"
					subText="(e.g., I have cerebral palsy; I have epilepsy and my seizures vary from mild seizures to strong seizures that may last up to three minutes; I have Alzheimer’s disease, etc):"
					controller={this.changeling}
					imageLocation={Image1}
					prop="healthCondition"
					editingMode={editingMode}
				/>
				<TextBox 
					subOrder="b)"
					title="Things you need to know in an emergency"
					subText="(e.g., Please ensure my next of kin knows about my hospital admission; I have a child to be looked after, etc):"
					controller={this.changeling}
					prop="emergencyInformation"
					imageLocation={Image2}
					editingMode={editingMode}
				/>

				<div className="header-wrapper">
					<h2 className="section-subheader"><span className="header-number">2.</span>My communication</h2>
					<p className="image-contain"><img src={communicationHeader} /></p>
				</div>
				{
					((this.props.editingMode && this.props.languages) || (!this.props.editingMode)) &&
					<>
						<Dropdown 
							subOrder="a)"
							title="My first (or preferred) language is"
							imageLocation={Image3}
							dropdownOptions={this.props.languages ? this.props.languages : undefined}
							controller={this.changeling}
							prop="firstLanguage"
							editingMode={editingMode}
						/>
						<MultiDropdown 
							subOrder="b)"
							title="Languages I can also use are"
							imageLocation={Image4}
							multipleOptionText="Add another option you can use"
							dropdownOptions={this.props.languages ? this.props.languages : undefined}
							controller={this.changeling}
							prop="otherLanguages"
							editingMode={editingMode}
							firstLang={this.props.value.firstLanguage}
						/>
						<Radio 
							subOrder="c)"
							title="Do you need help with interpreting?"
							defaultOption="No, I don't need help interpreting"
							secondaryOption="Yes, I need help interpreting"
							controller={this.changeling}
							prop="interpretingHelp"
							imageLocation={Image5}
							editingMode={editingMode}
						/>
						{
							value.interpretingHelp && 
						<Dropdown 
							title="The language I need help with interpreting is:"
							secondaryQuestion={true}
							dropdownOptions={this.props.languages ? this.props.languages : undefined}
							controller={this.changeling}
							prop="interpretingHelpLanguage"
							editingMode={editingMode}
						/>
						}
					</>
				}

				<TextBox 
					subOrder="d)"
					title="I communicate with people using"
					subText="(e.g., gestures, facial expressions, picture charts, hearing aid, digital diary, electronic communicator, etc):"
					controller={this.changeling}
					imageLocation={Image6}
					prop="communicationAbilities"
					editingMode={editingMode}
				/>
				<TextBox 
					subOrder="e)"
					title="Things you need to know when communicating with me are"
					subText="(e.g., speak slowly, face me, tap my shoulder for attention, turn on my equipment, etc):"
					controller={this.changeling}
					imageLocation={Image7}
					prop="communicationInfo"
					editingMode={editingMode}
				/>

				<div className="header-wrapper">
					<h2 className="section-subheader"><span className="header-number">3.</span>Things to know when providing medical care</h2>
					<p className="image-contain"><img src={medicalHeader} /></p>
				</div>
				<TextBox 
					subOrder="a)"
					title="You would know I am in pain when"
					subText="(e.g., I can tell you, I make a particular sound, I rock my body, etc):"
					controller={this.changeling}
					imageLocation={Image9}
					prop="painAwareness"
					editingMode={editingMode}
				/>
				<TextBox 
					subOrder="b)"
					title="I am allergic to"
					subText="(e.g., certain medicines, perfume, nuts, etc):"
					controller={this.changeling}
					imageLocation={Image10}
					prop="allergies"
					editingMode={editingMode}
				/>
				<TextBox 
					subOrder="c)"
					title="When giving me medication, please"
					subText="(e.g., crush my tablets):"
					controller={this.changeling}
					imageLocation={Image11}
					prop="medicationPrefs"
					editingMode={editingMode}
				/>
				<TextBox 
					subOrder="d)"
					title="When conducting a medical examination, please"
					subText="(e.g., be aware of my catheter bag, lie me on my left side, etc):"
					controller={this.changeling}
					imageLocation={Image12}
					prop="examinationPrefs"
					editingMode={editingMode}
				/>
				<TextBox 
					subOrder="e)"
					title="Other things that you need to know about my medical care"
					subText="(information that you need to know that I have not already told you):"
					controller={this.changeling}
					imageLocation={Image13}
					prop="otherMedicalCareInfo"
					editingMode={editingMode}
				/>

				<div className="header-wrapper">
					<div className="content">
						<h2 className="section-subheader"><span className="header-number">4.</span>Decision-making</h2>
						<h2 className="section-subheader -secondary">I can and would like to make my own decisions, so please ask me first. </h2>
						<p className="sub-text">If, for some reason, I am incompetent or unconscious at the time when the decision needs to be made, the following will apply:</p>
					</div>
					<p className="image-contain"><img src={decisionMakingHeader} /></p>
				</div>

				<Radio 
					subOrder="a)"
					title="Legal representation"
					defaultOption="No, I don't have a legal representative"
					secondaryOption="Yes, I have a legal representative"
					controller={this.changeling}
					prop="legalRepresentative"
					imageLocation={Image15}
					editingMode={editingMode}
				/>
				{
					value.legalRepresentative && 
					<>
						<p className="sub-text">These are my legal representative's details</p>
						<LegalForm 
							controller={this.changeling}
							prop="legalRepresentativeInfo"
							editingMode={editingMode}
						/>
					</>
				}
				<Radio 
					subOrder="b)"
					title="Wishes for care in the future"
					defaultOption="No, I don't have a list of future wishes"
					secondaryOption="Yes, I have a list of future wishes"
					controller={this.changeling}
					prop="futureWishes"
					editingMode={editingMode}
				/>
				{
					value.futureWishes && 
					<TextBox 
						title="Information about my wishes can be found at"
						subText="(e.g., on my medical file, in cupboard at home, in my advance directives held by my GP, I have given verbal directives to my eldest son, etc):"
						controller={this.changeling}
						imageLocation={Image16}
						secondaryQuestion={true}
						prop="futureWishesInfo"
						editingMode={editingMode}
					/>
				}
				{
					!value.legalRepresentative && !value.futureWishes &&
					<CheckBox
						title="I do not have a legal representative or advance directives and trust that any decision concerning my care and welfare will be made by appropriate professional/s in my best interests after taking into account my views if they are known, or consulting people who know me and care about me."
						editingNote="You are seeing this option because you have selected ‘No’ for both sections a) and b) above. Please confirm your consent below, or edit your answer for sections a) or b). "
						valueNote="You are seeing this option because you have selected ‘No’ for both sections a) and b) above."
						question="I agree to decisions being made on my behalf"
						imageLocation={Image17}
						controller={this.changeling}
						prop="decisionConsent"
						editingMode={editingMode}
					/>
				}

				<h2 className="section-subheader"><span className="header-number">5.</span>Safety and comfort</h2>
				<Radio 
					title="Do you require support with your safety?"
					defaultOption="No, I don't need support with my safety"
					secondaryOption="Yes, I may need support in keeping safe"
					imageLocation={Image18}
					controller={this.changeling}
					prop="safetySupport"
					editingMode={editingMode}
				/>
				{
					value.safetySupport &&
					<>
						<TextBox 
							subOrder="a)"
							title="Things important for my physical safety"
							subText="(e.g., raised bed rails, my chest harness, sharp objects removed from room, to be watched as I tend to run away, etc):"
							controller={this.changeling}
							imageLocation={Image19}
							prop="physcialSafety"
							editingMode={editingMode}
						/>
						<TextBox 
							subOrder="b)"
							title="Things that upset me or cause me stress are"
							subText="(e.g., bright lights, loud noise, etc):"
							controller={this.changeling}
							imageLocation={Image20}
							prop="stressCauses"
							editingMode={editingMode}
						/>
						<TextBox 
							subOrder="c)"
							title="You would know that I am anxious or stressed when"
							subText="(e.g., I start rocking my body, I start biting myself, I start banging my hands, etc):"
							controller={this.changeling}
							imageLocation={Image21}
							prop="stressSigns"
							editingMode={editingMode}
						/>
						<TextBox 
							subOrder="d)"
							title="Things you could do to help me settle down are"
							subText="(e.g., play soft music, take me out for a walk, call the crisis team, etc):"
							controller={this.changeling}
							imageLocation={Image22}
							prop="stressRelievers"
							editingMode={editingMode}
						/>
					</>
				}
			</>
		)
	}
}

export default wrapComponent(HealthPassportSectionA)
