import * as React from 'react'
import { RouteComponentProps, Switch, Route } from 'react-router'
import Header from 'modules/common/containers/Header'
import Footer from 'modules/common/components/Footer'
import { Helmet } from 'react-helmet'
import NewOrder from './NewOrder'

const NewOrderScreen = (props: RouteComponentProps) => {
	return (
		<div className="site-content booking-screen">
			<Helmet>
				<title>New Order — My Homecare</title>
			</Helmet>
			<Header section="calendar" />
			<Switch>
				<Route 
					path={`${props.match.url}`}
					render={() => (
						<NewOrder/>
					)}
				/>
			</Switch>
			<Footer />
		</div>
	)
}

export default NewOrderScreen
