import { RootStoreState } from 'modules/root'
import { BookingSupportWorkersPreference } from './types'

export const selectBooking = (state: RootStoreState) => state.booking.booking

export const preferenceLevelByRef = (state: RootStoreState, relationshipRef: string): number | undefined => state.booking.supportWorkerPreference && state.booking.supportWorkerPreference[relationshipRef]

export const preferenceRefs = (state: RootStoreState): BookingSupportWorkersPreference | undefined => state.booking.supportWorkerPreference

export const selectOwnSupportWorkers = (state: RootStoreState): boolean => state.booking.editBookingFormState.editSupportWorkers && state.booking.editBookingFormState.chooseOwnSupportWorker

export const supportWorkerRelationshipsSelector = (state: RootStoreState) => state.calendar.refs.relationships

export const timesheetableSupportWorkerRefsSelector = (state: RootStoreState) => state.calendar.roster.roster && state.calendar.roster.roster.timesheetStaffRefs 
