/**
 * The root saga file that defines the root saga.
 */

import { all } from 'redux-saga/effects'

/* Import module sagas */
import authSaga from 'modules/auth/sagas'
import dashboardSaga from 'modules/dashboard/sagas'
import funderDashboardSaga from 'modules/funder-dashboard/sagas'
import platformSaga from 'modules/platform/sagas'
import calendarSaga from 'modules/calendar/sagas'
import documentsSaga from 'modules/documents/sagas'
import bookingSaga from 'modules/booking/sagas'
import registerSaga from 'modules/register/sagas'
import accountSaga from 'modules/account/sagas'
import commonSaga from 'modules/common/sagas'
import routingSaga from 'modules/routing/sagas'
import setupSaga from 'modules/setup/sagas'
import orderSaga from 'modules/order/sagas'
import supportWorkersSaga from 'modules/support-workers/sagas'
import timesheetSaga from 'modules/timesheet/sagas'

/** The root saga that starts all of the other sagas. */
export default function* rootSaga() {
	yield all([
		authSaga(),
		dashboardSaga(),
		funderDashboardSaga(),
		platformSaga(),
		calendarSaga(),
		documentsSaga(),
		bookingSaga(),
		registerSaga(),
		accountSaga(),
		commonSaga(),
		routingSaga(),
		setupSaga(),
		supportWorkersSaga(),
		timesheetSaga(),
		orderSaga(),
	])
}
