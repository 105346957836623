import { Api } from 'typescript-fetch-api'
import { RootStoreState } from 'modules/root'
import * as t from './types'

export function createClientView(client: Api.Client, membership: Api.SupportNetworkMembership): t.ClientView {
	return {
		name: client.fullName,
		displayName: client.knownAs || client.givenName || client.fullName,
		ref: client.ref,
		image: client.image,
		role: membership.role,
		you: membership.role === Api.SupportNetworkMembership.RoleEnum.Client,
		capabilities: membership.capabilities,
		showTimesheets: client.timesheets,
	}
}

export function createSupportNetworkView(supportNetworkMerberships: DeepReadonlyArray<Api.SupportNetworkMembership>, supportNetworkUserRefs: DeepReadonlyObject<Api.ClientSupportNetworkResponseRefs>, state: RootStoreState): t.SupportNetworkMembership[] {
	const result: t.SupportNetworkMembership[] = []
	supportNetworkMerberships.forEach(membership => {
		const clientImage = supportNetworkUserRefs.users[membership.userRef].image
		const name = supportNetworkUserRefs.users[membership.userRef].fullName
		if (name) {
			result.push({
				name: name,
				userRef: membership.userRef,
				capabilities: membership.capabilities,
				image: clientImage ? clientImage : undefined,
				role: membership.role,
			})
		}
	})
	return result
}