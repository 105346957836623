import * as React from 'react'
import { Props, Actions, OwnProps } from '../containers/SupportWorker'

import * as f from '../functions'
import Moment from 'react-moment'
import { Redirect } from 'react-router'
import moment from 'moment'
import { Api } from 'typescript-fetch-api'
import { startDateTime } from 'modules/calendar/functions'
import SupportWorkerFeedbackModal from './SupportWorkerFeedbackModal'
import { avatarPlaceholder } from 'modules/root/theme'

const DAYS_OF_WEEK = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
const DAYS_OF_WEEK_SHORT = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

interface State {
	showFeedbackModal: boolean
}

const INITIAL_STATE: State = {
	showFeedbackModal: false,
}

export default class SupportWorker extends React.Component<Props & Actions & OwnProps, State> {

	public state = INITIAL_STATE

	toggleFeedbackModal = () => {
		this.setState({ showFeedbackModal: !this.state.showFeedbackModal })
	}

	handleSubmitFeedback = (feedback: string) => {
		if (this.props.supportWorker) {
			this.props.onSendFeedback({
				supportWorkerRef: this.props.supportWorker.ref,
				request: { feedback: feedback },
			})
		}
	}

	public render() {
		const { supportWorker, prevAppointment, nextAppointment } = this.props
		if (!supportWorker) {
			return <Redirect to="/support-workers"/>
		}

		const nameOrPronoun = f.givenName(this.props.supportWorker!)
		const hasHistory = prevAppointment || nextAppointment

		return (
			<div className="width-limit -site">
				<div className="content-layout">
					<div className="body supportworker-bio">
						<h1 className="headline-text">{supportWorker.fullName}</h1>
						{supportWorker.suburb && (
							<p className="suburb">{supportWorker.suburb}</p>
						)}
						{hasHistory && (
							<p className="history">
								{
									prevAppointment && (
										<span className="visits">You last saw {nameOrPronoun} {moment(startDateTime(prevAppointment).toString()).toNow()}.</span>
									)
								}
								<br />
								{
									nextAppointment && (
										<span className="upcoming">Your next appointment with {nameOrPronoun} is {moment(startDateTime(nextAppointment).toString()).fromNow()}.</span>
									)
								}
							</p>
						)}
						
						{supportWorker.bio && (
							<div className="body-text">
								<p>{supportWorker.bio}</p>
							</div>
						)}
						<div className="button-group -singlebutton">
							<div className="button">
								<a href="" onClick={this.props.supportWorker && this.onBookSupportWorker.bind(this, this.props.supportWorker.ref)} className="button-link -action">Book {nameOrPronoun}</a>
							</div>
						</div>
						<div className="details-list">
							{
								this.props.skills && this.props.skills.length > 0 && (
									<div className="detail">
										<h3 className="title">Skills &amp; training</h3>
										<ul className="list">
											{this.props.skills && this.props.skills.map((skill) => (
												<li className="listitem" key={skill.ref}>{skill.name}</li>
											))}
										</ul>
									</div>
								)
							}
							{
								this.props.languages && this.props.languages.length > 0 && (
									<div className="detail">
										<h3 className="title">Languages</h3>
										<ul className="list">
											{this.props.languages && this.props.languages.map((language, index) => (
												<li className="listitem" key={index}>{language.name}</li>
											))}
										</ul>
									</div>
								)
							}
							{
								this.props.supportWorker && this.props.supportWorker.driverLicenses && this.props.supportWorker.driverLicenses.length > 0 && (
									<div className="detail">
										<h3 className="title">Transport / Driver licence</h3>
										<ul className="list">
											{this.props.supportWorker.driverLicenses.map((transport, index) => (
												<li className="listitem" key={index}>{transport}</li>
											))}
										</ul>
									</div>
								)
							}
						</div>
						
						<div className="calendar-view -weekly">
							<h2 className="title">Availability this week</h2>
							<div className="week">
								{this.renderAvailability()}
							</div>
						</div>
						
					</div>
					<aside className="sidebar">
						<figure className="avatar-image -hero -bordered">
							<div className="image">
								<img src={supportWorker.image || avatarPlaceholder()} alt={supportWorker.givenName} />
							</div>
						</figure>
						
						<div className="rating-widget">
							<p className="like-options -large">
								<a href="" onClick={this.onMakeFavourite} className={'like -yes' + (this.props.relationship && this.props.relationship.favourite ? ' -active' : ' -inactive')}>
									<span className="icon" />
									<span className="label">{(this.props.relationship && this.props.relationship.favourite ? 'Saved as favourite' : 'Save as favourite')}</span>
								</a>
								<a href="" onClick={this.onMakeHidden} className={'like -no' + (this.props.relationship && this.props.relationship.blocked ? ' -active' : ' -inactive')}>
									<span className="icon" />
									<span className="label">{(this.props.relationship && this.props.relationship.blocked ? ' Hidden from search' : 'Hide from search')}</span>
								</a>
							</p>
						</div>
						
						{this.props.featureFeedback && (
							<div className="report-links">
								<p onClick={this.toggleFeedbackModal} className="-link">Report or give feedback about {supportWorker.givenName}</p>
							</div>
						)}
					</aside>
				</div>
				{
					this.state.showFeedbackModal && this.props.featureFeedback &&
					<SupportWorkerFeedbackModal onClose={this.toggleFeedbackModal} submitFeedback={this.handleSubmitFeedback}/>
				}
			</div>
		)
	}

	private renderAvailability = () => {

		const workHours = this.props.supportWorker && this.props.supportWorker.workHours

		if (!workHours) {
			return null
		}

		return DAYS_OF_WEEK.map((day, index) => {
			const dayWorkHours = workHours.filter(element => element.dayOfWeek.toString() === day && element.status === Api.SupportWorkerHours.StatusEnum.NotAllocated)

			return (
				<div className="day" key={index}>
					<h3 className="dayname">{DAYS_OF_WEEK_SHORT[index]}</h3>
					<div className="bookings">
						{	
							dayWorkHours.map((element, i) => {
								return (
									<div className="booking -active" key={i}>
										<div className="details">
											<span className="time"><Moment parse="hh:mm" date={element.startTime.toString()} format="h:mma" />—<Moment parse="hh:mm" date={element.endTime.toString()} format="h:mma" /></span>
										</div>
									</div>
								)
							})
						}
						{dayWorkHours.length === 0 && (
							<div className="booking -unavailable">
								<div className="details">
									<span className="time">Unavailable</span>
								</div>
							</div>
						)}
					</div>
				</div>
			)
		})
	}

	private onMakeFavourite = (evt: React.MouseEvent) => {
		evt.preventDefault()
		this.props.onMakeFavourite()
	}

	private onMakeHidden = (evt: React.MouseEvent) => {
		evt.preventDefault()
		this.props.onMakeHidden()
	}

	private onBookSupportWorker = (supportWorkerRef: string, evt: React.MouseEvent) => {
		evt.preventDefault()
		this.props.onBookSupportWorker(supportWorkerRef)
	}
}
