/** Platform specific implementations. */
import { Middleware } from 'redux'
import * as Api from 'typescript-fetch-api'

import { middleware as routerMiddleware, history } from 'modules/routing'
import { Config } from 'modules/auth/types'
import { PlatformSupport, Environment, Brand } from '..'
import { connectRouter } from 'connected-react-router'
import { store } from 'modules/root'

function basePath() {
	if (process.env.REACT_APP_API_BACKEND) {
		/* Edit the API backend in the .env file, and then restart webpack */
		return process.env.REACT_APP_API_BACKEND
	} else {
		return '/api/v0'
	}
}

const platformSupportImplementation: PlatformSupport = {
	/** Customise the Redux middleware for this platform */
	customiseReduxMiddleware: (middlewares: Middleware[]): Middleware[] => {
		middlewares.push(routerMiddleware)
		return middlewares
	},

	customiseRootReducer: (reducer) => {
		return reducer
	},

	customiseReducers: (reducers) => {
		const router = connectRouter(history)

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const hackReducers = reducers as any
		hackReducers.router = router
		return reducers
	},

	alert: (message: string) => {
		alert(message)
	},

	confirm: (message: string, title: string, confirmAction: string): Promise<boolean> => {
		const result = window.confirm(message)
		return Promise.resolve(result)
	},

	createApiConfigurationParams: (): Api.ConfigurationParameters => {
		return {
			basePath: basePath(),
			authorization: (name: string, scopes?: string[]): string | null => {
				const accessToken = store.getState().auth.accessToken
				if (accessToken) {
					return accessToken.access_token
				} else {
					return null
				}
			},
		}
	},

	createAuthConfiguration: (): Config => {
		return {
			tokenEndpoint: `${basePath()}/auth/sign-in`,
			revokeTokenEndpoint: `${basePath()}/auth/sign-out`,
			clientId: 'web_portal',
			clientSecret: '240bebde-6ad9-488b-b86e-4850d23313b0',
		}
	},

	environment: (): Environment => {
		if (window.location.hostname === 'localhost' || window.location.hostname.endsWith('.cactuslab.com')) {
			return Environment.Development
		} else if (window.location.hostname.indexOf('test-') !== -1) {
			return Environment.Test
		} else {
			return Environment.Live
		}
	},

	brand: (): Brand => {
		if (process.env.REACT_APP_BRANDING) {
			const brand = Object.keys(Brand).find(key => Brand[key] === process.env.REACT_APP_BRANDING)
			if (brand) {
				return Brand[brand]
			}
		}

		return Brand.Default
	},

	googleApiKey: (): string => {
		switch (platformSupportImplementation.brand()) {
			case Brand.Default: return 'AIzaSyC7EXdK8jiCluEsycjPrDCwekEiiqcsLW4'
			case Brand.Suemann: return 'AIzaSyC8fHW9WCxL-5tqpv6CyeX7TqhK_YNkHz4'
			case Brand.Hcnz: return '' // TODO HCNZ API KEY
		}
	},

	googlePlacesComponentRestrictions: () => {
		switch (platformSupportImplementation.brand()) {
			case Brand.Default: return { country: 'nz' }
			case Brand.Suemann: return { country: 'au' }
			case Brand.Hcnz: return { country: 'nz' }
		}
	},
}

export default platformSupportImplementation
