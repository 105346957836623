import * as React from 'react'
import { Snapshot, forComponentProps, wrapComponent, Input, Controller } from 'changeling'
import { IndexedActions, IndexedCursor } from 'changeling/dist/changeling-react'
import { MultiTextBoxValue, PreferenceOption } from '../types'

export interface OwnProps extends Snapshot<MultiTextBoxValue | undefined> {
	title?: string
	editingMode?: boolean
}

class MultiTextBox extends React.Component<OwnProps> {
	private changeling = forComponentProps(this)
	
	public render() {
		const { editingMode, value } = this.props
		return (
			<div className={'form-item -multi-textbox -no-gutters' + (editingMode ? '' : ' -valuemode')}>
				<div className="form-wrapper">
					<div className="pref-titles">
						<p className="title">In these circumstances:</p>
						<p className="title">I would/ would not want:</p>
					</div>
					{
						editingMode ? (
							<div className="form-field">
								<Input.Indexed 
									controller={this.changeling} 
									prop="this" 
									renderEach={this.renderChild}
								/>
							</div>) : (
							<div className="value-wrapper form-field">
								{ value && value.map((preference, key) => (
									<div key={key} className="form-input">
										<p className="sub-text field">{preference.circumstance}</p>
										<p className="sub-text field -no-gutter">{preference.preference}</p>
									</div>
								))}
							</div>
						)
					}
				</div>
				{
					editingMode &&
					<Input.Indexed 
						controller={this.changeling}
						prop="this"
						renderAfter={this.renderAfter}
					/>
				}
			</div>
		)
	}

	private renderChild = (controller: Controller<PreferenceOption>, cursor: IndexedCursor, actions: IndexedActions<PreferenceOption>) => {
		return (
			<div key={cursor.index} className="form-input -textarea">
				<Input.TextArea 
					className="field" 
					controller={controller} 
					prop="circumstance" 
				/>
				<Input.TextArea 
					className="field -no-gutter" 
					controller={controller} 
					prop="preference" 
				/>
				{
					!cursor.first &&
					<div onClick={() => actions.onRemove(cursor.index)} className="remove-item"/>
				}
			</div>
		)
	}

	private renderAfter = (actions: IndexedActions<PreferenceOption>) => {
		return (
			<div className="add-item" onClick={() => actions.onPush({})}>
				<span className="border"/>
				<div className="link">
					<span className="label">Add another preference</span>
				</div>
			</div>
		)
	}
}

export default wrapComponent(MultiTextBox)