import * as React from 'react'
import { Api } from 'typescript-fetch-api'
import Moment from 'react-moment'
import * as t from '../types'
import * as features from 'modules/root/features'
import { ChronoUnit, LocalDateTime } from '@js-joda/core'
import * as strings from 'modules/root/strings'

interface OwnProps {
	job: t.JobDetailView
	onCancel: () => void
	onClose: () => void
	onCancelBooking: (request: Api.AppointmentChangeRequest) => void
}
/**
 * Interface for private internal component state.
 */
interface State {
	cancellationNotes: string
	repeating: boolean
}

/**
 * The initial state for our internal component state.
 */
const INITIAL_STATE: State = {
	cancellationNotes: '',
	repeating: false,
}

export default class CancelJobModal extends React.Component<OwnProps, State> {

	public state = INITIAL_STATE

	handleCancellationNotesChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
		this.setState({ cancellationNotes: evt.target.value })
	}

	handleCancelBooking = () => {
		const cancellationRequest: Api.AppointmentChangeRequest = {
			notes: this.state.cancellationNotes,
			effectiveDate: this.props.job.date.toString(),
			repeating: false,
			cancellation: {
				cancel: true,
			},
		}

		if (this.props.job.repeatOption !== Api.Appointment.RepeatOptionEnum.None) {
			cancellationRequest.repeating = this.state.repeating
			cancellationRequest.cancellation!.cancelOnDate = this.props.job.date.toString()
		}

		this.props.onCancelBooking(cancellationRequest)
		this.props.onClose()
	}

	toggleRepeat = () => {
		this.setState({ repeating: !this.state.repeating })
	}

	isJobWithinCancelBookingFeeWindow = (job: t.JobDetailView) => {
		if (job.startTime) {
			const startTime = LocalDateTime.of(job.date, job.startTime)
			const hoursUntilJob = LocalDateTime.now().until(startTime, ChronoUnit.HOURS)
			return hoursUntilJob < 24
		}

		return false
	}

	public render() {
		const { job } = this.props
		const jobRequiresCancelBookingFee = features.cancelBookingFee && this.isJobWithinCancelBookingFeeWindow(job)

		return (
			<div className="form-layout">
				<div className="introduction">
					<h1 className="page-title">Cancel Booking</h1>
					<div className="body-text">
						{
							jobRequiresCancelBookingFee ?
								<p> You are trying to cancel less than 24 hours from your appointment, please contact the office instead. Normally, cancellations less than 24 hours attract a fee.</p> :
								<p>
									To request the cancellation of this booking simply click the <strong>{strings.cancelJobSubmitLabel}</strong> button. 
									You may also add a reason for the cancellation, which may be useful in case we can help with rescheduling or resolving any issues.
								</p>
						}
					</div>
				</div>
				<div className="row">
					<div className="form-field">
						<label className="label">Reason for cancellation</label>
						<div className="form-input -textarea">
							<textarea
								className="field"
								placeholder="Please provide any relevant details about this cancellation"
								value={this.state.cancellationNotes}
								onChange={this.handleCancellationNotesChange}
							/>
						</div>
					</div>
				</div>
				{
					job.repeatOptionDisplayName && features.repeatBooking &&
					(
						<div className="row">
							<div className="body-text">
								<p>
									<strong>
										This booking repeats {job.repeatOptionDisplayName}
										{job.repeatEndDate && (
											<>{' '}until <Moment date={job.repeatEndDate.toString()} format="dddd D MMMM YYYY" /></>
										)}.
									</strong> Do you want to cancel for <Moment date={job.date.toString()} format="dddd D MMMM YYYY" /> only, or also for all upcoming dates?
								</p>
							</div>
							<div className="form-field">
								<div className="form-input -options">
									<ul className="option-inputs -inline">
										<li className="option -radio">
											<label className="label">
												<input type="radio" name="supportWorkers" className="radio" checked={!this.state.repeating} onChange={this.toggleRepeat} /><span className="substitute" />
												For this date only
											</label>
										</li>
										<li className="option -radio">
											<label className="label">
												<input type="radio" name="supportWorkers" className="radio" checked={this.state.repeating} onChange={this.toggleRepeat}/><span className="substitute" />
												For all upcoming dates
											</label>
										</li>
									</ul>
								</div>
							</div>
						</div>
					)
				}
				<div className="row">
					<div className="button-group">
						<div className="button">
							<input type="submit" className="button-link -text" value="Cancel" onClick={this.props.onCancel} />
						</div>
						<div className="button -constrained">
							<div className="button-link -action" onClick={this.handleCancelBooking}>{strings.cancelJobSubmitLabel}</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
