import * as React from 'react'
import { OwnProps, Props, Actions } from '../containers/FunderDashboard'

export default class FunderDashboard extends React.Component<OwnProps & Props & Actions> {

	public render() {
		const { organisations } = this.props

		return (
			<div className="funder-dashboard">
				<div className="funders-container">
					{
						organisations && Object.keys(organisations).map((key) => {
							const organisation = organisations[key]

							return (
								<div className="funder-wrapper" key={key}>
									<div className="image"><img src={organisation.image} /></div>
									<h1 className="name headline-text">{organisation.name}</h1>
									<div className="button-group -centered">
										<div className="button">
											<div onClick={() => this.props.onSearchFunder(organisation.ref)} className="button-link -action -icon"><span className="icon -search-white" />Search clients</div>
										</div>
										{/* <div className="button">
											<div onClick={() => this.props.onViewReports(organisation.ref)} className="button-link -action -icon"><span className="icon -report-white" />View reports</div>
										</div> */}
									</div>
								</div>
							)
						})
					}
				</div>
			</div>
		)
	}
}
