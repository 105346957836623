/**
 * Gets the IndividualisedFunding id from the route parameter and passes the id to the Job component.
 */

import * as React from 'react'
import { RouteComponentProps } from 'react-router'

import IndividualisedFunding from '../containers/IndividualisedFunding'

interface IndividualisedFundingParams {
	individualisedFundingId: string
}

export default class IndividualisedFundingRouteComponent extends React.Component<RouteComponentProps<IndividualisedFundingParams>> {
	public render() {
		const { match } = this.props
		return (
			<IndividualisedFunding individualisedFundingId={match.params.individualisedFundingId} />
		)
	}
}