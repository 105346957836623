/* Import the component from the component path */
import Component from '../components/TimesheetSupportWorkerSelect'
import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'
import * as ba from 'modules/booking/actions'
import * as t from '../types'
import * as s from '../selectors'

/** Interface for properties that are passed to the container/ */
export interface OwnProps {
	updateEditFormState?: () => void
	availableSupportWorkers?: DeepReadonlyArray<string>
}

/** Interface for properties that the container passes to the component. */
export interface Props {
	currentBookedSupportWorkerRef?: string
	currentlySelectedSupportWorkerRef?: string
	availableSupportWorkerViews?: DeepReadonly<t.TimesheetSupportWorkerView>[]
}

/**
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
	onPreferenceLevelChange: (supportWorkerRef: string, timesheetEdit: boolean, level?: number) => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	const currentSupportWorkerOnJob = state.booking.editBooking ? state.booking.editBooking.job.supportWorkerRef : undefined

	return {
		currentBookedSupportWorkerRef: currentSupportWorkerOnJob,
		currentlySelectedSupportWorkerRef: s.currentSupportWorkerUpdateSelector(state) ? s.currentSupportWorkerUpdateSelector(state) : undefined,
		availableSupportWorkerViews: ownProps.availableSupportWorkers ? s.availableSupportWorkerViews(state, ownProps.availableSupportWorkers) : undefined,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: OwnProps): Actions => ({
	onPreferenceLevelChange: (supportWorkerRef, timesheetEdit, level) => {
		dispatch(ba.preferenceLevelChange({
			supportWorkerRef,
			timesheetEdit,
			level,
		}))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
