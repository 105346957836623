import * as React from 'react'
import { Helmet } from 'react-helmet'
import { ComponentProps } from '../containers/Documents'

type MyProps = ComponentProps

export default class Documents extends React.Component<MyProps> {

	public render() {
		return (
			<div className="individualised-funding-screen body body-text">
				<Helmet>
					<title>My Documents — My Homecare</title>
				</Helmet>   
				<h1 className="headline-text">My Documents</h1>
				<div className="form-layout">
					<div className="row">
						
					</div>
				</div>
			</div>
		)
	}
	
}
