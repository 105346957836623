import * as React from 'react'
import { withModal } from 'modules/common/modal-hoc'

interface State {
	feedback: string
}

const INITIAL_STATE: State = {
	feedback: '',
}

interface OwnProps {
	onClose: () => void
	submitFeedback: (feedback: string) => void
}

export default withModal(class SupportWorkerFeedbackModal extends React.Component<OwnProps, State> {

	public state = INITIAL_STATE

	handleFeedback = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
		this.setState({ feedback: evt.target.value })
	}

	onSubmitFeedback = () => {
		this.props.submitFeedback(this.state.feedback)
		this.props.onClose()
	}

	public render() {
		return (
			<div className="content">
				<div className="form-layout">
					<div className="row">
						<h1 className="page-title">Report or add feedback</h1>
					</div>
					<div className="row">
						<div className="form-field">
							<div className="form-input -textarea">
								<textarea
									className="field"
									value={this.state.feedback}
									onChange={this.handleFeedback}
								/>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="button-group">
							<div className="button">
								<div className="button-link -text" onClick={this.props.onClose}>Cancel</div>
							</div>
							<div className="button">
								<div className={'button-link -action' + (this.state.feedback === '' ? ' -disabled' : '')} onClick={this.onSubmitFeedback}>Submit</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
})
