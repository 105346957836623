import Component from '../components/DashboardScreen'
import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'
import { currentClientSelector, clientRefsSelector } from 'modules/account/selectors'

/* Import module actions */
// import * as actions from '../actions'

/** Interface for properties that are passed to the container/ */
export interface OwnProps {

}

/** Interface for properties that the container passes to the component. */
export interface Props {
	hasClients: boolean
	hasCurrentClient: boolean
	hasFunderAccess: boolean
}

export interface Actions {
	// onExample: (value: string) => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	const user = state.account.user

	return {
		hasClients: clientRefsSelector(state).length > 0,
		hasCurrentClient: !!currentClientSelector(state),
		hasFunderAccess: user && user.organisationRefs ? user.organisationRefs.length > 0 : false,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	// onExample: (value) => {
	// 	dispatch(actions.examplePrimitiveAction(value))
	// },
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
