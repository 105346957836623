import Component from '../components/ClientDetails'
import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'
import { RootStoreState } from 'modules/root'
import * as actions from '../actions'
import * as t from '../types'
import { Api } from 'typescript-fetch-api'
import { featuresSelector } from 'modules/common/selectors'

export interface Props {
	clientDetails: DeepReadonly<Api.RegisterClientRequest>
	settings?: DeepReadonly<Api.Settings>
	clientIsSelf: boolean
	createClientRequestProcessing: boolean
	userDetails?: DeepReadonly<Api.User>
	features: DeepReadonly<Api.ClientDetailsFieldsFeatureSet>
}

export interface Actions {
	onUpdateClientDetails: (details: Api.RegisterClientRequest) => void
	onRegisterClient: (details: Api.RegisterClientRequest) => void
}

const mapStateToProps = (state: RootStoreState): Props => {
	return {
		clientDetails: state.setup.clientDetails,
		settings: state.common.settings,
		clientIsSelf: state.setup.requestingCareFor === t.RequestCareForEnum.Self,
		createClientRequestProcessing: state.setup.createClientRequestProcessing,
		userDetails: state.account.user,
		features: featuresSelector(state).clientDetailsFields,
	}
}

const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	onUpdateClientDetails: (details) => {
		dispatch(actions.updateClientDetails(details))
	},
	onRegisterClient: (request) => {
		dispatch(actions.registerNewClient(request))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
