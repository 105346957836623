import * as React from 'react'
import { RouteComponentProps, Switch, Route, Redirect } from 'react-router'

import Header from 'modules/common/containers/Header'
import Footer from 'modules/common/components/Footer'
import StartRegistration from '../containers/StartRegistration'
import SetPassword from '../containers/SetPassword'
import EmailSubmitted from './EmailSubmitted'
import Welcome from '../containers/Welcome'
import ComingSoon from './ComingSoon'

export default class RegisterScreen extends React.Component<RouteComponentProps<undefined>> {

	public render() {
		return (
			<div className="site-content register-screen">
				<Header section="register" />
					
				<Switch>
					<Route 
						path="/register/coming-soon" 
						render={() => (
							<ComingSoon />
						)}
					/>
					<Route 
						path="/register/start-registration" 
						render={() => (
							<StartRegistration {...this.props}/>
						)}
					/>
					<Route path="/register/set-password" component={SetPassword} />
					<Route path="/register/email-submitted" component={EmailSubmitted} />
					<Route 
						path="/register/welcome" 
						render={() => (
							<Welcome params={this.props.location.search} />
						)}
					/>
					<Route 
						render={() => (
							<Redirect to="/register/start-registration" />
						)}
					/>
				</Switch>
				
				<Footer />
			</div>
		)
	}
}
