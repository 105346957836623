import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { Api } from 'typescript-fetch-api'

/* Import our module's actions */
import * as a from './actions'
import * as t from './types'
import * as aa from 'modules/auth/actions'
import * as fda from 'modules/funder-dashboard/actions'

/**
 * Export the StoreState interface for this module. We always name this interface
 * `StoreState` so it is consistent across each of our modules.
 * We export a readonly version of the interface, to try to prevent modifications,
 * and retain a private (not exported) MutableStoreState for ourselves to use very
 * carefully in the reducers, if we need to.
 */
export type StoreState = DeepReadonly<MutableStoreState>

export interface MutableStoreState {
	/** Details about the current user */
	user?: Api.User
	refs?: Api.UserDetailsResponseRefs
	/** Details of the support networks the current user belongs to */
	supportNetworks?: Api.UserSupportNetwork
	/** The ref of the client currently selected by the user  */
	currentClientRef?: string
	/** Details of the clients known to the current user */
	clients: t.StoreClients
	loading: boolean
	saving: boolean
	savingChangePassword: boolean
	currentClientSupportNetwork?: Api.ClientSupportNetwork
	currentClientSupportNetworkUserRefs?: Api.ClientSupportNetworkResponseRefs
	currentUserClientsAsyncVersion?: number
	ready: boolean
	supportNetworkUsers?: t.StoreUsers
}

/**
 * The initial store state for this module.
 */
const INITIAL_STATE: StoreState = {
	clients: {},
	supportNetworkUsers: {},
	loading: false,
	saving: false,
	savingChangePassword: false,
	ready: false,
}

/**
 * Reducer function for this module.
 */
export const reducer = reducerWithInitialState(INITIAL_STATE)

reducer.case(a.currentUserDetailsAsync.done, (state, payload): StoreState => {
	return {
		...state, 
		user: payload.result.payload,
		refs: payload.result.refs,
	}
})

reducer.case(a.currentUserClientsAsync.started, (state, payload): StoreState => {
	return {
		...state,
		currentUserClientsAsyncVersion: payload,
	}
})

reducer.case(a.currentUserClientsAsync.done, (state, payload): StoreState => {
	if (payload.params !== state.currentUserClientsAsyncVersion) {
		console.log('Ignoring racing currentUserClientsAsync')
		return state
	}

	return {
		...state,
		supportNetworks: payload.result.payload,
		clients: {
			...state.clients,
			...payload.result.refs.clients,
		},
		supportNetworkUsers: {
			...payload.result.refs.users,
		},
		currentUserClientsAsyncVersion: undefined,
	}
})

reducer.case(a.currentUserClientsAsync.failed, (state, payload): StoreState => {
	if (payload.params !== state.currentUserClientsAsyncVersion) {
		console.log('Ignoring racing currentUserClientsAsync')
		return state
	}

	return {
		...state,
		currentUserClientsAsyncVersion: undefined,
	}
})

reducer.case(a.clientDetailsAsync.done, (state, payload): StoreState => {
	return {
		...state,
		clients: {
			...state.clients,
			[payload.params]: payload.result,
		},
	}
})

reducer.case(a.chooseClient, (state, { clientRef }): StoreState => ({
	...state, currentClientRef: clientRef, currentClientSupportNetwork: undefined,
}))

reducer.cases([aa.login.started, aa.loggedOut], (state): StoreState => (INITIAL_STATE))

/** We fire a logoutRequest when loading fails. */
reducer.case(aa.logoutRequest, (state) => ({
	...state,
	loading: false,
}))

reducer.case(a.loadingAccount, (state) => ({
	...state, loading: true, ready: false,
}))

reducer.case(a.loadedAccount, (state) => ({
	...state, loading: false, ready: true,
}))

reducer.cases([a.updateClientDetailsAsync.started, a.updateUserDetailsAsync.started], (state) => ({
	...state, saving: true,
}))

reducer.cases([a.updateClientDetailsAsync.failed, a.updateUserDetailsAsync.failed], (state) => ({
	...state, saving: false,
}))

reducer.case(a.updateClientDetailsAsync.done, (state, { result }) => ({
	...state, saving: false, clients: { ...state.clients, [result.payload.ref]: result.payload },
}))

reducer.case(a.updateUserDetailsAsync.done, (state, { result }) => ({
	...state, saving: false, user: result.payload,
}))

reducer.case(a.changePasswordAsync.started, (state) => ({
	...state, savingChangePassword: true,
}))

reducer.cases([a.changePasswordAsync.done, a.changePasswordAsync.failed], (state) => ({
	...state, savingChangePassword: false,
}))

reducer.case(a.loadClientSupportNetworkAsync.started, (state) => ({
	...state, currentClientSupportNetwork: undefined, currentClientSupportNetworkUserRefs: undefined,
}))

reducer.case(a.loadClientSupportNetworkAsync.done, (state, payload) => ({
	...state, currentClientSupportNetwork: payload.result.payload, currentClientSupportNetworkUserRefs: payload.result.refs,
}))

reducer.case(fda.addClientToUserClients, (state, payload) => {
	let currentMemberships = state.supportNetworks ? [...state.supportNetworks.memberships] : undefined
	const currentClients = { ...state.clients }
	
	if (!currentMemberships) {
		currentMemberships = [payload.membership]
	} else if (currentMemberships && (currentMemberships.findIndex(x => x.clientRef === payload.client.ref) < 0)) {
		currentMemberships.push(payload.membership)
	}

	if (!currentClients[payload.client.ref]) {
		currentClients[payload.client.ref] = { ...payload.client }
	}

	return {
		...state,
		supportNetworks: state.supportNetworks ? {
			invitations: [...state.supportNetworks.invitations],
			memberships: currentMemberships,
		} : undefined,
		clients: currentClients,
	}
})