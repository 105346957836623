import * as React from 'react'
import { OwnProps, Props, Actions } from '../containers/BookingSupportWorkers'
import { Link } from 'react-router-dom'
import SupportWorkerSearch, { TileMode } from 'modules/support-workers/containers/SupportWorkerSearch'
import { Helmet } from 'react-helmet'
import { Switch, Route, RouteComponentProps } from 'react-router'
import SupportWorkerModal from './SupportWorkerModal'

export default class BookingSupportWorkers extends React.Component<Props & OwnProps & Actions & RouteComponentProps<undefined>> {

	onContinue = (evt: React.MouseEvent) => {
		evt.preventDefault()

		this.props.onContinue()
	}

	onCancel = (evt: React.MouseEvent<HTMLInputElement>) => {
		evt.preventDefault()
		this.props.onCancel()
	}
	
	public render() {
		const { newBooking } = this.props
		return (
			<article className="site-body">
				<Helmet>
					<title>{newBooking ? 'New' : 'Edit'} Booking: Support Workers — My Homecare</title>
				</Helmet>
				<div className="width-limit -site">
					<div className="content-layout booking-form">
						<div className="body">
							<div className="supportworker-selection">
								<SupportWorkerSearch searchMode={TileMode.Book}/>

								<div className="row">

									<div className="button-group">
										<div className="button">
											<a onClick={this.onBack} className="button-link -text">Back</a>
										</div>
										<div className="button -constrained">
											<a href="#" onClick={this.onContinue} className="button-link -action">Continue</a>
										</div>
									</div>

								</div>
							</div>
						</div>
						
						<aside className="sidebar">
							<h1 className="page-title">{newBooking ? 'New' : 'Edit'} booking</h1>
							<nav className="progress-nav">
								<ul className="listitems">
									<li className="item -active"><Link to="./" className="link">Booking details</Link></li>
									<li className="item -active -current"><span className="link">Support workers</span></li>
									<li className="item"><span className="link">Summary</span></li>
								</ul>
							</nav>
						</aside>

					</div>
				
				</div>

				<Switch>
					<Route 
						path={`${this.props.match.url}/support-workers/worker/:supportWorkerRef`} 
						render={props => (<SupportWorkerModal onClose={this.closeModal} supportWorkerRef={props.match.params.supportWorkerRef} {...props}/>)}
					/>
				</Switch>
				
			</article>
		)
	}

	private closeModal = () => {
		this.props.history.goBack()
	}

	private onBack = () => {
		this.props.history.goBack()
	}
}
