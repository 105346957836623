import { SagaIterator } from 'redux-saga'
import { takeEvery, put, call, select } from 'redux-saga/effects'
import { Api } from 'typescript-fetch-api'
import { login } from 'modules/auth/actions'
import { callApiWithActions, handleApiFailedAction, handleApiSuccessAction } from 'modules/api/functions'
import api from 'modules/api'
import { push } from 'connected-react-router'
import * as a from './actions'
import * as setupActions from '../setup/actions'
import * as aa from 'modules/auth/actions'
import { readyAction } from 'modules/root/actions'
import { loggedInSelector } from 'modules/auth/selectors'
import platform from 'modules/platform'
import * as s from './selectors'
import * as accountSelectors from 'modules/account/selectors'
import { currentUserSelector } from 'modules/support-workers/selectors'

function* loadUserDetails(): SagaIterator {
	const loggedIn = (yield select(loggedInSelector)) as boolean
	if (!loggedIn) {
		return
	}

	yield put(a.loadingAccount())
	
	/* Load current user details */
	const userDetailsSuccess = (yield call(callApiWithActions, '', a.currentUserDetailsAsync, (options: RequestInit) => {
		return api().userApi.getUserDetails('me', options)
	})) as boolean

	const user = (yield select(currentUserSelector)) as ReturnType<typeof currentUserSelector>
	if (!userDetailsSuccess || !user) {
		platform.alert('Failed to load user details.')
		yield put(aa.logoutRequest())
		return
	}

	/* Load user's support networks */
	const supportNetworksSuccess = (yield call(callApiWithActions, 0, a.currentUserClientsAsync, (options: RequestInit) => {
		return api().userApi.getUserSupportNetworkMemberships('me', options)
	})) as boolean

	if (!supportNetworksSuccess) {
		platform.alert('Failed to load your support networks.')
		yield put(aa.logoutRequest())
		return
	}

	if (!user.organisationRefs || !user.organisationRefs.length) {
		/* Choose a client to be */
		const clientRefs = (yield select(s.clientRefsSelector)) as ReturnType<typeof s.clientRefsSelector>
		const currentClientRef = (yield select(s.currentClientRefSelector)) as ReturnType<typeof s.currentClientRefSelector>
		if (currentClientRef === undefined && clientRefs.length > 0) {
			const memberships = (yield select(s.supportNetworkMembershipsSelector)) as ReturnType<typeof s.supportNetworkMembershipsSelector>
			const clientMemberships = memberships.filter(m => m.role === Api.SupportNetworkMembership.RoleEnum.Client)
			if (clientMemberships.length > 0) {
				yield put(a.chooseClient({ clientRef: clientMemberships[0].clientRef, interactive: false }))
			} else {
				yield put(a.chooseClient({ clientRef: clientRefs[0], interactive: false }))
			}
		}
	}

	yield put(a.loadedAccount())
}

function* sendSupportNetworkInvitation(action: a.SendSupportNetworkInvitationAction): SagaIterator {
	const clientRef = (yield select(accountSelectors.currentClientRefSelector))
	yield call(callApiWithActions, action.payload, a.sendSupportNetworkInvitationAsync, (options: RequestInit) => {
		return api().clientApi.postClientSupportNetworkInvitation(clientRef, action.payload, options)
	})
}

function* updateSupportNetworkCapabilities(action: a.UpdateSupportNetworkCapabilitiesAction): SagaIterator {
	const clientRef = (yield select(accountSelectors.currentClientRefSelector))
	yield call(callApiWithActions, action.payload, a.updateSupportNetworkCapabilitiesAsync, (options: RequestInit) => {
		return api().clientApi.patchUserCapabilitiesInClientSupportNetwork(clientRef, action.payload.userRef, action.payload.request, options)
	})
}

/** Show the details for the current user */
function* handleShowMyDetails(): SagaIterator {
	const currentUserClientRef = (yield select(s.currentUserClientRefSelector)) as ReturnType<typeof s.currentClientRefSelector>
	yield put(a.chooseClient({ clientRef: currentUserClientRef, interactive: true }))
	yield put(push('/account/details'))
}

function* handleUpdateClientDetails(action: a.UpdateClientDetailsAction): SagaIterator {
	yield call(callApiWithActions, action.payload, a.updateClientDetailsAsync, (options: RequestInit) => {
		return api().clientApi.patchClientDetails(action.payload.clientRef, action.payload.request, options)
	})
}

function* handleUpdateUserDetails(action: a.UpdateUserDetailsAction): SagaIterator {
	yield call(callApiWithActions, action.payload, a.updateUserDetailsAsync, (options: RequestInit) => {
		return api().userApi.patchUserDetails('me', action.payload, options)
	})
}

function* handleChangePassword(action: a.ChangePasswordAction): SagaIterator {
	yield call(callApiWithActions, action.payload, a.changePasswordAsync, (options: RequestInit) => {
		return api().authApi.postChangePassword(action.payload, options)
	})
}

function* reloadClientSupportNetworks(): SagaIterator {
	/* Check logged in status as we call this on ready now */
	const loggedIn = (yield select(loggedInSelector)) as boolean
	if (!loggedIn) {
		return
	}

	const clientRef = (yield select(accountSelectors.currentClientRefSelector))
	yield call(callApiWithActions, '', a.loadClientSupportNetworkAsync, (options: RequestInit) => {
		return api().clientApi.getClientSupportNetwork(clientRef, options)
	})
}

function* revokeSupportNetworkInvite(action: a.RevokeSupportNetworkInviteAction): SagaIterator {
	yield call(callApiWithActions, action.payload, a.revokeSupportNetworkInviteAsync, (options: RequestInit) => {
		return api().clientApi.deleteClientSupportNetworkInvitation(action.payload.clientRef, action.payload.inviteRef, options)
	})
}

function* declineSupportNetworkInvite(action: a.DeclineSupportNetworkInviteAction): SagaIterator {
	yield call(callApiWithActions, action.payload, a.declineSupportNetworkInviteAsync, (options: RequestInit) => {
		return api().clientApi.deleteClientSupportNetworkInvitation(action.payload.clientRef, action.payload.inviteRef, options)
	})
}

function* removeUserFromSupportNetwork(action: a.RemoveUserFromSupportNetworkAction): SagaIterator {
	const clientRef = (yield select(accountSelectors.currentClientRefSelector))
	yield call(callApiWithActions, action.payload, a.removeUserFromSupportNetworkAsync, (options: RequestInit) => {
		return api().clientApi.deleteUserFromClientSupportNetwork(clientRef, action.payload, options)
	})
}

function* leaveSupportNetwork(action: a.LeaveSupportNetworkAction): SagaIterator {
	const clientRef = (yield select(accountSelectors.currentClientRefSelector))
	const userRef = (yield select(accountSelectors.currentUserRefSelector))
	yield call(callApiWithActions, action, a.leaveSupportNetworkAsync, (options: RequestInit) => {
		return api().clientApi.deleteUserFromClientSupportNetwork(clientRef, userRef, options)
	})
}

function* reloadUserSupportNetworks(): SagaIterator {
	yield call(callApiWithActions, '', a.currentUserClientsAsync, (options: RequestInit) => {
		return api().userApi.getUserSupportNetworkMemberships('me', options)
	})
	yield put(push('/'))
}

function* switchToDefaultClient(): SagaIterator {
	yield call(callApiWithActions, '', a.currentUserClientsAsync, (options: RequestInit) => {
		return api().userApi.getUserSupportNetworkMemberships('me', options)
	})
	/* Choose a client to be */
	const clientRefs = (yield select(s.clientRefsSelector)) as ReturnType<typeof s.clientRefsSelector>
	const currentClientRef = (yield select(s.currentClientRefSelector)) as ReturnType<typeof s.currentClientRefSelector>
	if (currentClientRef === undefined && clientRefs.length > 0) {
		const memberships = (yield select(s.supportNetworkMembershipsSelector)) as ReturnType<typeof s.supportNetworkMembershipsSelector>
		const clientMemberships = memberships.filter(m => m.role === Api.SupportNetworkMembership.RoleEnum.Client)
		if (clientMemberships.length > 0) {
			yield put(a.chooseClient({ clientRef: clientMemberships[0].clientRef, interactive: true }))
		} else {
			yield put(a.chooseClient({ clientRef: clientRefs[0], interactive: true }))
		}
	}
	yield put(a.loadedAccount())
	yield put(push('/'))
}

export default function* (): SagaIterator {
	yield takeEvery(login.done, loadUserDetails)
	yield takeEvery(readyAction, loadUserDetails)
	yield takeEvery(setupActions.registerNewClientAsync.done, loadUserDetails)
	yield takeEvery(a.sendSupportNetworkInvitation, sendSupportNetworkInvitation)
	yield takeEvery(a.sendSupportNetworkInvitationAsync.done, reloadClientSupportNetworks)
	yield takeEvery([a.sendSupportNetworkInvitationAsync.done], handleApiSuccessAction.bind(null, 'Thank you, your support network invitation has been sent.'))
	yield takeEvery([a.sendSupportNetworkInvitationAsync.failed], handleApiFailedAction.bind(null, 'Oops! Your support network invitation was unable to send.'))
	yield takeEvery(a.updateSupportNetworkCapabilities, updateSupportNetworkCapabilities)
	yield takeEvery(a.updateSupportNetworkCapabilitiesAsync.done, reloadClientSupportNetworks)
	yield takeEvery([a.updateSupportNetworkCapabilitiesAsync.done], handleApiSuccessAction.bind(null, 'Thank you, your support network capabilities have been updated.'))
	yield takeEvery([a.updateSupportNetworkCapabilitiesAsync.failed], handleApiFailedAction.bind(null, 'Oops! We were unable to update your support network capabilities.'))
	yield takeEvery(a.showMyDetails, handleShowMyDetails)
	yield takeEvery(a.updateClientDetails, handleUpdateClientDetails)
	yield takeEvery(a.updateUserDetails, handleUpdateUserDetails)
	yield takeEvery([a.updateClientDetailsAsync.done, a.updateUserDetailsAsync.done], handleApiSuccessAction.bind(null, 'Thank you, your details have been saved.'))
	yield takeEvery([a.updateClientDetailsAsync.failed, a.updateUserDetailsAsync.failed], handleApiFailedAction.bind(null, 'Oops! Your details could not be saved.'))
	yield takeEvery([a.changePasswordAsync.done], handleApiSuccessAction.bind(null, 'Thank you, your password has been changed.'))
	yield takeEvery([a.changePasswordAsync.failed], handleApiFailedAction.bind(null, 'Oops! Your password could not be changed.'))
	yield takeEvery(a.changePassword, handleChangePassword)
	yield takeEvery([a.chooseClient, readyAction], reloadClientSupportNetworks)

	yield takeEvery(a.revokeSupportNetworkInvite, revokeSupportNetworkInvite)
	yield takeEvery(a.revokeSupportNetworkInviteAsync.done, reloadClientSupportNetworks)
	yield takeEvery([a.revokeSupportNetworkInviteAsync.done], handleApiSuccessAction.bind(null, 'Thank you, your support network invitation was successfully removed.'))
	yield takeEvery([a.revokeSupportNetworkInviteAsync.failed], handleApiFailedAction.bind(null, 'Oops! We were unable to remove your support network invitation.'))

	yield takeEvery(a.declineSupportNetworkInvite, declineSupportNetworkInvite)
	yield takeEvery(a.declineSupportNetworkInviteAsync.done, reloadUserSupportNetworks)
	yield takeEvery([a.declineSupportNetworkInviteAsync.done], handleApiSuccessAction.bind(null, 'Support network invitation successfully removed.'))
	yield takeEvery([a.declineSupportNetworkInviteAsync.failed], handleApiFailedAction.bind(null, 'Oops! We were unable to decline this support network invitation.'))

	yield takeEvery(a.removeUserFromSupportNetwork, removeUserFromSupportNetwork)
	yield takeEvery(a.removeUserFromSupportNetworkAsync.done, reloadClientSupportNetworks)
	yield takeEvery([a.removeUserFromSupportNetworkAsync.done], handleApiSuccessAction.bind(null, 'Thank you, we have removed this user from your support network.'))
	yield takeEvery([a.removeUserFromSupportNetworkAsync.failed], handleApiFailedAction.bind(null, 'Oops! We were unable to remove this user from your support network.'))
	yield takeEvery(a.leaveSupportNetwork, leaveSupportNetwork)
	yield takeEvery(a.leaveSupportNetworkAsync.done, switchToDefaultClient)
	yield takeEvery([a.leaveSupportNetworkAsync.done], handleApiSuccessAction.bind(null, 'Thank you, you have successfully left this support network.'))
	yield takeEvery([a.leaveSupportNetworkAsync.failed], handleApiFailedAction.bind(null, 'Oops! We were unable to remove you from this support network.'))
}
