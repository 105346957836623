import { Api } from 'typescript-fetch-api'
import * as t from 'modules/calendar/types'
import { LocalDate, LocalTime } from '@js-joda/core'

///////////////////////////////////////////////////////////////////////////////////////////////

export interface Booking {
	settings: DeepReadonlyObject<BookingSettings>
	bookings: BookingLine[]
}

export interface BookingSettings {
	startDate?: LocalDate
	endDate?: LocalDate
	notes?: string
	fundingType?: Api.NewAppointment.FundingTypeEnum
	selectSupportWorker: boolean
}

export interface BookingLine {
	date?: LocalDate // TODO these get changed to strings when the store is persisted
	dayOfWeek?: DayOfWeekEnum
	startTimeOption: Api.NewAppointment.StartTimeOptionEnum
	startTime?: LocalTime
	durationMinutes?: number
	durationOption: Api.NewAppointment.DurationOptionEnum
	repeat: Api.NewAppointment.RepeatOptionEnum
	services: string[]
	fundingRef?: string
}

export const INITIAL_APPOINTMENT: BookingLine = {
	date: LocalDate.now(),
	durationMinutes: 30,
	startTimeOption: Api.NewAppointment.StartTimeOptionEnum.Flexible,
	repeat: Api.NewAppointment.RepeatOptionEnum.None,
	services: [],
	durationOption: Api.NewAppointment.DurationOptionEnum.NotSpecified,
}

export const TIMESHEET_INITIAL_APPOINTMENT: BookingLine = {
	date: LocalDate.now(),
	durationMinutes: 30,
	startTime: LocalTime.parse('00:00'),
	startTimeOption: Api.NewAppointment.StartTimeOptionEnum.Specified,
	repeat: Api.NewAppointment.RepeatOptionEnum.None,
	services: [],
	durationOption: Api.NewAppointment.DurationOptionEnum.Specified,
}

export enum DayOfWeekEnum {
	Monday = 'Monday',
	Tuesday = 'Tuesday',
	Wednesday = 'Wednesday',
	Thursday = 'Thursday',
	Friday = 'Friday',
	Saturday = 'Saturday',
	Sunday = 'Sunday',
}

/** The job types to expose as options to private clients.
 * These ones have also had tidy names applied in the common/reducer.
 */
export const PRIVATE_JOB_TYPES = [
	'HH-L', 'PC-L', 'RES-H', 'RN',
]

export const DEFAULT_FUNDED_JOB_TYPES = [
	'HH-L', 'PC-L',
]

export interface EditBooking {
	calDate: LocalDate
	effectiveDate: LocalDate
	job: t.JobDetailView
	notes?: string
	timesheetRef?: string
}

export interface EditBookingFormState {
	editRepeating: boolean
	editTime: boolean
	editSupportWorkers: boolean
	chooseOwnSupportWorker: boolean
}

export interface BookingSupportWorkersPreference {
	[supportWorkerRef: string]: number
}

export interface BlockSupportWorkerFromBooking {
	ref: string
	currentClientRef: string
	supportWorkerRef: string
}
