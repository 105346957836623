import * as React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'  
import { Api } from 'typescript-fetch-api'

export interface OwnProps {
	individualisedFunding: DeepReadonly<Api.IndividualisedFunding>
}

interface State {
	// myValue: string
}

const INITIAL_STATE: State = {
	// myValue: 'Example',
}

export default class PayrollDetails extends React.Component<OwnProps, State> {
// export default class Overview extends React.Component<OwnProps & Props & Actions, State> {

	public state = INITIAL_STATE

	public render() {
		const { individualisedFunding } = this.props

		return (
			<div className="individualised-funding-screen body body-text">
				<Helmet>
					<title>Payroll details</title>
				</Helmet>
				<h1 className="headline-text">Payroll details</h1>
				<div className="form-layout">
					{/* <div className="row -ruler">
						{individualisedFunding.needsLevel === Api.IndividualisedFunding.NeedsLevelEnum.Level1 && 
							<p className="-lrg">
								<span className="-bold">You are currently managing your own payroll. </span> 
								Submit expense claims each fortnight, then Geneva will forward the funds to your account. You are then responsible for paying your employees.
							</p>
						}
						{individualisedFunding.needsLevel === Api.IndividualisedFunding.NeedsLevelEnum.Level2 && 
							<p className="-lrg">
								<span className="-bold">Geneva is currently paying your Support Workers. </span> 
								When you submit timesheets for your registered Support Workers, Geneva will pay them on your behalf.
							</p>
						}
						{individualisedFunding.needsLevel === Api.IndividualisedFunding.NeedsLevelEnum.Level3 && 
							<p className="-lrg">
								<span className="-bold">Geneva is currently paying their Support Workers on your behalf. </span> 
								When you use your Calendar to add bookings, Geneva will take care of paying the Support Workers for you. You can view updated balance information on the Overview page.
							</p>
						}
						<div className="button-group -singlebutton"> 
							<div className="button">
								{individualisedFunding.needsLevel === Api.IndividualisedFunding.NeedsLevelEnum.Level1 && <Link to={'/account/individualised-funding/' + individualisedFunding.ref + '/timesheets-expenses'} className="button-link -action -small">Go to expense claims</Link>}
								{individualisedFunding.needsLevel === Api.IndividualisedFunding.NeedsLevelEnum.Level2 && <Link to="/account/individualised-funding/timesheets-expenses" className="button-link -action -small">Go to timesheets</Link>}
								{individualisedFunding.needsLevel === Api.IndividualisedFunding.NeedsLevelEnum.Level3 && <Link to="/account/individualised-funding" className="button-link -action -small">View my funding overview</Link>}
							</div>
						</div> 
					</div> */}
					{/* {individualisedFunding.needsLevel === Api.IndividualisedFunding.NeedsLevelEnum.Level1 && 
						<>
						<div className="row -ruler">
							<h2>Managing your own Payroll through Geneva</h2>
							<p>If you prefer, Geneva can pay your employees directly.</p>
							<p><Link to="">Read more about how the payroll process works</Link></p>
							<div className="button-group -singlebutton">
								<div className="button">
									<Link to="/" className="button-link -action -small">I'd like Geneva to pay my employees</Link>
								</div>
							</div> 
						</div>
						<div className="row">
							<h2>Let Geneva take care of it</h2>
							<p>If you prefer, you can also use Support Workers provided by Geneva. In this instance you also won’t need to managing your own payroll.</p>
							<div className="button-group -singlebutton">
								<div className="button">
									<Link to="/calendar/new" className="button-link -action -small">Add a booking</Link>
								</div>
							</div> 
						</div>
						</>
					} */}
					{/* {individualisedFunding.needsLevel === Api.IndividualisedFunding.NeedsLevelEnum.Level2 && 
						<>
						<div className="row -ruler">
							<h2>Managing your own Payroll</h2>
							<p>If you would prefer to pay your Support Workers directly, you can opt for Geneva to forward you the amounts required and then you are responsible for payments to your employees.</p>
							<p><Link to="">Read more about how the payroll process works</Link></p>
							<div className="button-group -singlebutton">
								<div className="button">
									<Link to="/" className="button-link -action -small">I'd like to pay my employees directly</Link>
								</div>
							</div> 
						</div>
						<div className="row">
							<h2>Let Geneva take care of it</h2>
							<p>If you prefer, you can also use Support Workers provided by Geneva. In this instance you also won’t need to managing your own payroll.</p>
							<div className="button-group -singlebutton">
								<div className="button">
									<Link to="/calendar/new" className="button-link -action -small">Add a booking</Link>
								</div>
							</div> 
						</div>
						</>
					} */}
					<div className="row">
						<h2>Managing your own Payroll</h2>
						<p>If you are using your own Support Workers rather than those supplied by Geneva, then you have two payroll options — Geneva can pay your Support Workers on your behalf, or you can opt to manage the payroll process yourself. In both cases you would provide timesheets via My Homecare indicating the time spent by your Support Workers each week, then Geneva would either pay them directly or forward you the money to pay them yourself.</p>
						<p><Link to="">Read more about how the payroll process works</Link></p>
						<div className="button-group -singlebutton">
							<div className="button">
								<Link to={`/account/individualised-funding/${individualisedFunding.ref}/register-as-employer`} className="button-link -action -small">Register as an employer</Link>
							</div>
						</div> 
					</div>
				</div>
			</div>
		)
	}
}
