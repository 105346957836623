import * as React from 'react'
import Moment from 'react-moment'
import { Api } from 'typescript-fetch-api'
import moment from 'moment'
import * as t from 'modules/calendar/types'
import * as bt from 'modules/booking/types'
import { OwnProps, Props, Actions } from '../containers/TimesheetJob'
import { avatarOverlayPlaceholder } from 'modules/root/theme'

export default class TimesheetJob extends React.Component<OwnProps & Props & Actions> {

	classNameForJob = () => {
		if (this.props.view.date) {
			const endDateTime = this.props.view.startTime ? 
				moment(this.props.view.date + ' ' + this.props.view.startTime.toString()) : 
				moment(this.props.view.date.toString(), ['YYYY-MM-DD'])

			if (endDateTime.isBefore(new Date()) && this.props.view.status !== t.JobViewStatus.Pending && this.props.view.status !== t.JobViewStatus.PendingCancellation && this.props.view.status !== t.JobViewStatus.Cancelled) {
				return 'booking -past'
			}

			switch (this.props.view.status) {
				case t.JobViewStatus.Active:
					return 'booking -active'
				case t.JobViewStatus.Pending:
					return 'booking -pending'
				case t.JobViewStatus.NotAllocated:
					return 'booking -active'
				case t.JobViewStatus.Completed:
					return 'booking -completed'
				case t.JobViewStatus.PendingCancellation:
					return 'booking -pending-cancellation'
				case t.JobViewStatus.Cancelled:
					return 'booking -cancelled'
				default:
					return 'booking'
			} 
		} else {
			return 'booking'
		}
	}

	public render() {
		const { view } = this.props
		const jobCancelled = view.status === t.JobViewStatus.Cancelled ? true : false

		return (
			<div onClick={this.handleEditBooking} className={this.classNameForJob()}>
				{view.supportWorker && !jobCancelled && (
					<figure className="avatar-image -small">
						<div className="image">
							<img src={view.supportWorker.image || avatarOverlayPlaceholder()} alt="{view.supportWorker.name}" />
						</div>
					</figure>
				)}
				<div className="details">
					{view.supportWorker ? (
						<strong className="name">{view.supportWorker.name}</strong>
					) : (
						(view.status === t.JobViewStatus.Pending || view.status === t.JobViewStatus.PendingCancellation) && <strong className="name">Pending...</strong>
					)}
					{this.renderTime(view)}
				</div>
			</div>
		)
	}
	
	private handleEditBooking = () => {
		if (this.props.job) {
			const editBooking: bt.EditBooking = {
				effectiveDate: this.props.job.date,
				calDate: this.props.calDate,
				job: this.props.job,
				timesheetRef: this.props.timesheetRef ? this.props.timesheetRef : undefined,
			}
			this.props.onEditBooking(editBooking)
		}
	}

	private renderTime = (view: t.JobView) => (
		<span className="time">
			{view.startTime ? (
				<>
					<Moment parse="hh:mm" date={view.startTime.toString()} format="h:mma" />
					—
					<Moment parse="hh:mm" date={view.startTime.plusMinutes(view.totalMinutes).toString()} format="h:mma" />
				</>
			) : view.startTimeOption === Api.Appointment.StartTimeOptionEnum.Flexible ? (
				<>Flexible</>
			) : view.startTimeOption === Api.Appointment.StartTimeOptionEnum.Morning ? (
				<>Morning</>
			) : view.startTimeOption === Api.Appointment.StartTimeOptionEnum.Afternoon ? (
				<>Afternoon</>
			) : null}
		</span>
	)
}
