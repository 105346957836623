import * as React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { Api } from 'typescript-fetch-api'

export interface OwnProps {
	individualisedFunding: DeepReadonly<Api.IndividualisedFunding>
}  

interface State {
	// myValue: string
}

const INITIAL_STATE: State = {
	// myValue: 'Example',
}

export default class RegisterAsAnEmployer extends React.Component<OwnProps, State> {
// export default class Overview extends React.Component<OwnProps & Props & Actions, State> {

	public state = INITIAL_STATE

	public render() {
		return (
			<div className="individualised-funding-screen body body-text">
				<Helmet>
					<title>Register as an employer</title>
				</Helmet>
				<h1 className="headline-text">Register as an employer</h1>
				<div className="form-layout">
					<div className="row -ruler">
						<p className="-lrg">This page will outline the steps required for you to employ your own Support Workers and then register them in My Homecare.</p>
						<h2>1. Are you registered as an employer?</h2>
						<p>To employ your own Support Workers you must be registered as an employer by completing an IR334 form and submitting it to the IRD. If you have not done this, use the link below.</p>
						<div className="registration-list">
							<ul>
								<li className="icon -external-link"><a target="_blank" rel="noopener noreferrer" href="https://business-survival-guide.ird.govt.nz/pdf/ir334.pdf">IR 334 — Employer registration</a></li>
							</ul>
						</div>
					</div>
					<div className="row -ruler">
						<h2>2. Will you manage your own payroll?</h2>
						<p><span className="-bold">If you would like Geneva to manage your payroll</span> (pay your employees directly, on your behalf) complete the following form to get set up with our payroll system. You will be required to submit timesheets for each employee fortnightly.</p>
						<div className="registration-list">
							<ul>
								<li className="icon -document-download"><a href="https://www.ird.govt.nz/resources/4/4/44c21987-48ad-404f-9e1f-4aa0364f833e/ir920.pdf">IR920 — Linking to our Payroll provider for PAYE</a></li>
							</ul>
						</div>
						<p><span className="-bold">If you would like handle your own payroll</span> (where you pay your employees directly) then indicate this in the online form below. This option will require you submit a claim fortnightly so that Geneva can pay you the lump sum directly, and then it is your responsibility to pay your employees.</p>
						<p>If you’re unsure which option is best for you, <Link to="">you can find out more here</Link>.</p>
					</div>
					<div className="row">
						<h2>3. Submit your documentation</h2>
						<p>Once you have gathered the information above, please complete the form below and attach a copy of each document for our records.</p>
						<div className="button-group -singlebutton">
							<div className="button">
								<Link to="" className="button-link -action -small">Register my employer details and documents</Link>
							</div>
						</div> 
					</div>
				</div>
			</div>
		)
	}
}
