import actionCreatorFactory from 'typescript-fsa'
import { Api } from 'typescript-fetch-api'
import { LocalDate } from '@js-joda/core'
import * as t from './types'

/**
 * The action creator for this module. Note that it contains the module name.
 */
const actionCreator = actionCreatorFactory('Booking')

export const addBookingLine = actionCreator('ADD_BOOKING_LINE')
export const removeBookingLine = actionCreator<DeepReadonly<t.BookingLine>>('REMOVE_BOOKING_LINE')

export const bookSupportWorker = actionCreator<string>('BOOK_SUPPORT_WORKER')
export type BookSupportWorker = ReturnType<typeof bookSupportWorker>

export interface UpdateBookingLinePayload {
	original: DeepReadonly<t.BookingLine>
	updated: DeepReadonly<t.BookingLine>
}

export const updateBookingLine = actionCreator<UpdateBookingLinePayload>('UPDATE_BOOKING_LINE')

export const updateBookingSettings = actionCreator<DeepReadonly<t.BookingSettings>>('UPDATE_BOOKING_SETTINGS')

export const cancelBooking = actionCreator('CANCEL_BOOKING')

export const clearEditBooking = actionCreator('CLEAR_EDIT_BOOKING')

export const startBooking = actionCreator('START_BOOKING')
export const startBookingFromDate = actionCreator<LocalDate>('START_BOOKING_FROM_DATE')

export interface PreferenceLevelChangePayload {
	supportWorkerRef: string
	timesheetEdit?: boolean
	level?: number
}
export const preferenceLevelChange = actionCreator<PreferenceLevelChangePayload>('PREFERENCE_LEVEL_CHANGE')

export const editBooking = actionCreator<t.EditBooking>('EDIT_BOOKING')
export const cancelEditBooking = actionCreator('CANCEL_EDIT_BOOKING')
export const updateEditBookingFormState = actionCreator<t.EditBookingFormState>('UPDATE_EDIT_BOOKING_FORM_STATE')

export const updateEditBookingDetails = actionCreator<t.EditBooking>('UPDATE_EDIT_BOOKING_DETAILS')

export const blockSupportWorkerFromBooking = actionCreator<t.BlockSupportWorkerFromBooking>('BLOCK_SUPPORT_WORKER_FROM_BOOKING')
export type BlockSupportWorkerFromBookingAction = ReturnType<typeof blockSupportWorkerFromBooking>
export const blockingSupportWorkerFromBooking = actionCreator.async<t.BlockSupportWorkerFromBooking, Response, Error>('BLOCK_SUPPORT_WORKER_FROM_BOOKING')

export interface SubmitNewBookingPayload {
	timesheetEdit?: boolean
	timesheetRef?: string
}

export const submitNewBooking = actionCreator<SubmitNewBookingPayload>('SUBMIT_NEW_BOOKING')
export type SubmitNewBookingAction = ReturnType<typeof submitNewBooking>

export const submitNewBookingAsync = actionCreator.async<SubmitNewBookingPayload, never, Api.AddAppointmentsError>('SUBMIT_NEW_BOOKING')
export type SubmitNewBookingAsyncDoneAction = ReturnType<typeof submitNewBookingAsync.done>
export type SubmitNewBookingAsyncFailedAction = ReturnType<typeof submitNewBookingAsync.failed>
