import * as React from 'react'
import { Switch, Route, Redirect } from 'react-router'
import Header from 'modules/common/containers/Header'
import Footer from 'modules/common/components/Footer'
import MyNavLink from 'modules/routing/components/MyNavLink'
import { Helmet } from 'react-helmet'

const AboutSuemann: React.FC = () => {
	return (
		<div className="body">
			<Helmet>
				<title>About us — My Homecare</title>
			</Helmet>
			
			<h1 className="headline-text">About us</h1>
			
			<div className="body-text">
				<p>With more and more home care providers to choose from, it is getting harder to find quality, personalised care for you or your loved ones.<br />myHomecare was founded by three pioneering home care providers, Sue Mann Nursing &amp; Community Care, Enrich Living Services and Bromilow Community Care - we are unifying our collective 75 years of experience to ensure ageing Australians have access to the reliable, quality and personalised care they deserve.</p>
				<p>myHomecare helps you navigate the government complexity when understanding your options, find the best care and manage your care all in one place.</p>
				<p>It is quality home care made easy.</p>
			</div>

		</div>
	)
}

const ContactSuemann: React.FC = () => {
	return (
		<div className="body">
			<Helmet>
				<title>Contact — My Homecare</title>
			</Helmet>
			
			<h1 className="headline-text">Contact information</h1>
			
			<div className="body-text">
				<p>
					myHomecare national helpline<br />
					<a href="tel:1300209020">1300 20 90 20</a><br />
					<a href="mailto:care@myhomecare.com.au">care@myhomecare.com.au</a>
				</p>
				<p>
					Sue Mann Nursing &amp; Community Care<br />
					Hunter, Newcastle &amp; Central Coast<br />
					<a href="tel:1300241300">1300 24 13 00</a><br />
					<a href="mailto:care@suemann.com.au">care@suemann.com.au</a>
				</p>
				<p>
					Enrich Living Services<br />
					WA, VIC, QLD and NSW<br />
					<a href="tel:1300202003">1300 20 20 03</a><br />
					<a href="mailto:care@enrichliving.com.au">care@enrichliving.com.au</a>
				</p>
				<p>
					Bromilow Community Care<br />
					Sunshine Coast QLD<br />
					<a href="tel:0754455676">07 5445 5676</a><br />
					<a href="mailto:care@bromilow.com.au">care@bromilow.com.au</a>
				</p>
			</div>

		</div>
	)
}

const ResourcesSuemann: React.FC = () => {
	return (
		<div className="body">
			<Helmet>
				<title>Resources — My Homecare</title>
			</Helmet>
			
			<h1 className="headline-text">Helpful resources</h1>
			
			<div className="body-text">
				<h2>My Aged Care</h2>
				<a href="http://www.myagedcare.gov.au" target="_blank" rel="noopener noreferrer">http://www.myagedcare.gov.au</a>
				<p>My Aged Care is an Australian Government website and phone line to help you find out what aged care services may be available to help you.</p>

				<h2>Older Persons Advocacy Network (OPAN)</h2>
				<a href="http://www.opan.com.au" target="_blank" rel="noopener noreferrer">http://www.opan.com.au</a>
				<p>OPAN is a free service which offers support you and your family to understand and exercise your aged care rights.</p>

				<h2>Department of Health – ageing and aged care</h2>
				<a href="http://www.agedcare.health.gov.au" target="_blank" rel="noopener noreferrer">http://www.agedcare.health.gov.au</a>
				<p>The Australian Government website that offers further information and fact sheets for any questions you or your family may have regarding home care packages. There is information on consumer directed care, fees and subsidies and the home care package program.</p>

				<h2>Aged Care Quality And Safety Commission</h2>
				<a href="http://www.agedcarequality.gov.au" target="_blank" rel="noopener noreferrer">http://www.agedcarequality.gov.au</a>
				<p>The role of the Commission is to protect and enhance safety, health, well-being and quality of life of people in aged care.</p>

				<h2>Carer Gateway</h2>
				<a href="http://www.carergateway.gov.au" target="_blank" rel="noopener noreferrer">http://www.carergateway.gov.au</a>
				<p>A national online and phone service that provides practical information and resources to support carers. The interactive service finder helps carers connect to local support services.</p>

				<h2>Advance Care Planning Australia</h2>
				<a href="https://www.advancecareplanning.org.au" target="_blank" rel="noopener noreferrer">https://www.advancecareplanning.org.au</a>
				<p>Advance care planning is a routine part of a person's health care. Advance Care Planning Australia is a national program that provides information and resources to individuals, care workers and healthcare professionals.</p>

				<h2>Palliative Care Australia</h2>
				<a href="http://palliativecare.org.au" target="_blank" rel="noopener noreferrer">http://palliativecare.org.au</a>
				<p>Palliative Care Australia represents all those who work towards high quality palliative care for all Australians. Their goal is to improve access to, and promote the need for, palliative care.</p>

				<h2>Dementia Support Australia</h2>
				<a href="https://www.dementia.com.au/services" target="_blank" rel="noopener noreferrer">https://www.dementia.com.au/services</a>
				<p>A national service, supported by funding from the Australian Government. Their role is to improve the quality of life for people living with dementia and their carers.</p>
			</div>

		</div>
	)
}

export default class SuemannHelpScreen extends React.Component {

	public render() {
		return (
			<div className="site-content account-screen">
				<Header section='' />
				
				<article className="site-body">
					<div className="width-limit -site">
						<div className="content-layout">
							{

							}
							<Switch>
								<Route path="/help/about" component={AboutSuemann} />
								<Route path="/help/contact" component={ContactSuemann} />
								<Route path="/help/resources" component={ResourcesSuemann} />
								<Route path="/help">
									<Redirect to="/help/about" />
								</Route>
							</Switch>
							<aside className="sidebar">
								<Switch>
									<Route
										path="/help"
										render={() => {
											return (
												<nav className="section-nav">
													<ul className="listitems">
														<MyNavLink to="/help/about" className="item" activeClassName="-active -current" linkClassName="link">About us</MyNavLink>
														<MyNavLink to="/help/contact" className="item" activeClassName="-active -current" linkClassName="link">Contact</MyNavLink>
														<MyNavLink to="/help/resources" className="item" activeClassName="-active -current" linkClassName="link">Resources</MyNavLink>
													</ul>
												</nav>
											)
										}}
									/>
								</Switch>
							</aside>
						</div>
					</div>
				</article>
				<Footer />
			</div>
		)
	}
}
