import * as React from 'react'
import { OwnProps, Props, Actions } from '../containers/ThisWeek'
import { Link } from 'react-router-dom'
import Moment from 'react-moment'
import Job from 'modules/calendar/components/Job'
import Loading from 'modules/common/components/Loading'

export default class ThisWeek extends React.Component<OwnProps & Props & Actions> {

	hasJobs = (): boolean => {
		let hasJobs = false
		if (this.props.view) {
			this.props.view.days.forEach((day) => {
				if (day.jobs.length > 0) {
					hasJobs = true
				}
			})
		}
		return hasJobs
	}

	public render() {
		const { view, calendar } = this.props

		return (
			<section className="dashboard-section">
				<header className="dashboard-header">
					<h1 className="headline-text">This week</h1>
					<div className="button-group">
						<div className="button">
							<Link to="/calendar" className="button-link -secondary -small">View <span className="_hide-mobile">full </span>calendar</Link>
						</div>
					</div>
				</header>

				{
					this.props.loading ? (
						<Loading/>
					) : (
						<>
							<div className="calendar-view -weekly">
								<div className="week">
									{view && view.days.map((d, i) => (
										<div key={i} className="dayname"><Moment date={d.date.toString()} format="ddd D" /></div>
									))}
								</div>
								<div className="week">
									{view && view.days.map((d, i) => (
										<div className="day" key={i}>
											<div className="bookings">
												{d.jobs.map((j, jobIndex) => (
													<Job view={j} calendar={calendar} key={jobIndex} />
												))}
											</div>
										</div>
									))}
									{ !this.hasJobs() &&
										<div className="message">
											<p>No bookings this week. Would you like to <Link to={'/calendar/new'} onClick={this.onAddNewBookingClick}>add one</Link>?</p>
										</div>
									}
								</div>
							</div>
						</>
					)
				}
				
			</section>
		)
	}

	private onAddNewBookingClick = (evt: React.MouseEvent<HTMLAnchorElement>) => {
		evt.preventDefault()
		this.props.onNewBooking()
	}
}
