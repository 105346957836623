import * as React from 'react'
import { Api } from 'typescript-fetch-api'
import { OwnProps, Props, Actions } from '../containers/BookingSummary'
import * as t from '../types'
import * as f from '../functions'
import * as commonFunctions from 'modules/common/functions'
import Moment from 'react-moment'
import { Link, RouteComponentProps } from 'react-router-dom'
import { repeatOptionDisplayNameJobRequest } from 'modules/calendar/functions'
import { Helmet } from 'react-helmet'
import { avatarPlaceholder } from 'modules/root/theme'
import * as strings from 'modules/root/strings'

export default class BookingSummary extends React.Component<OwnProps & Props & Actions & RouteComponentProps<undefined>> {

	public render() {
		const { bookingSettings, bookings, jobTypes, editBookingFormState, newBooking, bookingToEdit, findSupportWorkers } = this.props

		return (
			<article className="site-body">
				<Helmet>
					<title>{newBooking ? 'New' : 'Edit'} Booking: Summary — My Homecare</title>
				</Helmet>
				<div className="width-limit -site">
					<form className="content-layout booking-form" action="#">
						<div className="body">
							<div className="form-layout">
								<div className="introduction">
									<h1 className="headline-text">Summary</h1>
									<div className="body-text">
										<p>
											Please confirm the details below, then submit your {newBooking ? 'booking request' : 'amendments'}.
										</p>
									</div>
								</div>
								<div className="row">
									<div className="booking-summary">
										{ !(!newBooking && !editBookingFormState.editTime) &&
											<div className="summaryline">
												<h2 className="label">Dates and times</h2>
												<div className="content">
													<ul className="summary-list">
														{
															newBooking && bookings && bookings.map((booking, key) => {
																return (
																	<li className="listitem" key={key}>
																		<p>
																			{booking.date && <><Moment date={booking.date!.toString()} format="dddd D MMMM YYYY" />, </>}
																			{
																				booking.dayOfWeek && booking.repeat !== Api.NewAppointment.RepeatOptionEnum.MondayToFriday && 
																				booking.repeat !== Api.NewAppointment.RepeatOptionEnum.Daily &&
																				<>{t.DayOfWeekEnum[booking.dayOfWeek]}, </>
																			}
																			{f.startText(booking)}
																			{booking.durationMinutes ? (
																				<> for {f.durationText(booking.durationMinutes)}</>
																			) : booking.durationOption === Api.NewAppointment.DurationOptionEnum.NotSpecified ? (
																				<></>
																			) : booking.durationOption === Api.NewAppointment.DurationOptionEnum.Overnight ? (
																				<> overnight</>
																			) : null}
																			{booking.services.length > 0 && jobTypes && <><br/><span>{commonFunctions.getJobTypeName(jobTypes, booking.services[0])}</span></>}
																			{	
																				booking.repeat !== Api.NewAppointment.RepeatOptionEnum.None && 
																					<>
																						<br/><span className="repeat">Repeat {repeatOptionDisplayNameJobRequest(booking.repeat)}</span>
																					</>
																			}
																		</p>
																	</li>
																)
															})
														}
														{
															!newBooking && bookingToEdit && editBookingFormState.editTime &&
															<li className="listitem">
																<p>
																	{bookingToEdit.job.date && <><Moment date={bookingToEdit.job.date.toString()} format="dddd D MMMM YYYY" />, </>}

																	{f.startEditText(bookingToEdit.job)}

																	{bookingToEdit.job.durationMins ? (
																		<> for {f.durationText(bookingToEdit.job.durationMins)}</>
																	) : bookingToEdit.job.durationOption === Api.NewAppointment.DurationOptionEnum.NotSpecified ? (
																		<></>
																	) : bookingToEdit.job.durationOption === Api.NewAppointment.DurationOptionEnum.Overnight ? (
																		<> overnight</>
																	) : null}
																	{
																		bookingToEdit.job.repeatOption !== Api.NewAppointment.RepeatOptionEnum.None && editBookingFormState.editRepeating && ( 
																			<>
																				<br/><span className="repeat">Repeat {repeatOptionDisplayNameJobRequest(bookingToEdit.job.repeatOption)}</span>
																			</>
																		)
																	}
																</p>
															</li>
														}
													</ul>
												</div>
											</div>
										}

										{
											newBooking && bookings && bookingSettings && 
											<>
												{
													f.showStartEndDate(bookings) && 
												<>
													<div className="summaryline">
														<h2 className="label">Start date</h2>
														<div className="content">
															<p><Moment date={bookingSettings.startDate!.toString()} format="dddd D MMMM YYYY" /></p>
														</div>
													</div>
													{
														bookingSettings.endDate &&
														<div className="summaryline">
															<h2 className="label">End date</h2>
															<div className="content">
																<p><Moment date={bookingSettings.endDate!.toString()} format="dddd D MMMM YYYY" /></p>
															</div>
														</div>
													}
												</>
												}
												{
													bookingSettings.notes &&
												<div className="summaryline">
													<h2 className="label">Notes</h2>
													<div className="content">
														<p>{bookingSettings.notes}</p>
													</div>
												</div>
												}
											</>
										}

										{
											!newBooking && bookingToEdit && bookingToEdit.notes && bookingToEdit.notes !== '' &&
												<div className="summaryline">
													<h2 className="label">Notes</h2>
													<div className="content">
														<p>{bookingToEdit.notes}</p>
													</div>
												</div>
										}

										{
											newBooking && bookingSettings && bookingSettings.selectSupportWorker && this.props.selectedSupportWorkers && this.props.selectedSupportWorkers.length > 0 &&
												<div className="summaryline">
													<h2 className="label">Support workers</h2>
													<div className="content">
														<ol className="summary-list -supportworkers -ordered">
															{
																this.props.selectedSupportWorkers.map((sw, index) => {
																	return (
																		<li key={index} className="listitem" data-count={index + 1}>
																			<figure className="avatar-image -medium -bordered">
																				<div className="image">
																					<img src={sw.image ? sw.image : avatarPlaceholder()} alt="Heather" />
																				</div>
																			</figure>
																			<div className="name"><p>{sw.fullName}</p></div>
																		</li>
																	)
																})
															}
														</ol>
													</div>
												</div>
										}
										{
											((!newBooking && editBookingFormState.editSupportWorkers && !this.props.timesheetJobEdit) ||
											(!newBooking && editBookingFormState.editSupportWorkers && this.props.timesheetJobEdit && this.props.selectedSupportWorkers)) && 
												<div className="summaryline">
													<h2 className="label">Support workers</h2>
													<div className="content">
														{
															editBookingFormState.chooseOwnSupportWorker && this.props.selectedSupportWorkers && findSupportWorkers ? 
																<ol className="summary-list -supportworkers -ordered">
																	{this.props.selectedSupportWorkers.map((sw, index) => {
																		return (
																			<li key={index} className="listitem" data-count={index + 1}>
																				<figure className="avatar-image -medium -bordered">
																					<div className="image">
																						<img src={sw.image ? sw.image : avatarPlaceholder()} alt="Heather" />
																					</div>
																				</figure>
																				<div className="name"><p>{sw.fullName}</p></div>
																			</li>
																		)
																	})}
																</ol> : (
																	<ol className="summary-list -supportworkers">
																		<li className="listitem">
																			<p>Please find the best match for me<br/>
																				{
																					bookingToEdit && bookingToEdit.job.repeatOption !== Api.NewAppointment.RepeatOptionEnum.None && editBookingFormState.editRepeating && !editBookingFormState.editTime && ( 
																						<span className="repeat">Repeat {repeatOptionDisplayNameJobRequest(bookingToEdit.job.repeatOption)}</span>
																					)
																				}
																			</p>
																		</li>
																	</ol>
																)
														}
													</div>
												</div>
										}
									</div>
								</div>
							
								<div className="row">
									<div className="body-text">
										{strings.bookingSummary(newBooking)}
									</div>
								</div>
							
								<div className="row -spaced">
									<div className="button-group">
										<div className="button">
											<a onClick={this.onBack} className="button-link -text">Back</a>
										</div>
										<div className="button -constrained">
											<div onClick={newBooking ? this.props.onSubmitNewBooking : this.onSubmitEditBooking} className={'button-link -action' + (this.props.processing ? ' -processing' : '')}>{newBooking ? 'Confirm booking' : 'Confirm amendments'}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						
						<aside className="sidebar">
							<h1 className="page-title">{newBooking ? 'New' : 'Edit'} booking</h1>
							<nav className="progress-nav">
								<ul className="listitems">
									<li className="item -active">
										<Link to="." className="link">Booking details</Link>
									</li>
									{
										this.props.newBooking && bookingSettings && bookingSettings.selectSupportWorker && findSupportWorkers &&
										<li className="item -active"><Link to="support-workers" className="link">Support workers</Link></li>
									}
									{	
										!this.props.newBooking && editBookingFormState.editSupportWorkers && editBookingFormState.chooseOwnSupportWorker &&
										<li className="item -active"><Link to="support-workers" className="link">Support workers</Link></li>
									}
									<li className="item -active -current"><Link to="summary" className="link">Summary</Link></li>
								</ul>
							</nav>
						</aside>

					</form>
				</div>
			</article>
		)
	}

	private onSubmitEditBooking = () => {
		if (this.props.bookingToEdit) {
			const booking = { ...this.props.bookingToEdit, job: { ...this.props.bookingToEdit.job } }

			const request: Api.AppointmentChangeRequest = {
				effectiveDate: booking.effectiveDate.toString(),
				repeating: this.props.editBookingFormState.editRepeating,
				notes: booking.notes,
			}
			
			if (this.props.editBookingFormState.editTime) {
				request.scheduleChange = {
					date: booking.job.date.toString(),
					startTimeOption: booking.job.startTimeOption,
					startTime: booking.job.startTime ? booking.job.startTime.toString() : undefined,
					durationOption: booking.job.durationOption,
					durationMins: booking.job.durationMins,
				}
			}			
			
			if (this.props.editBookingFormState.editSupportWorkers) {
				request.supportWorkerChange = {
					allAppointments: false,
				}
			}
			
			this.props.onSubmitEditBooking(booking.job.ref, request)
		}
	}

	private onBack = () => {
		this.props.history.goBack()
	}
}