/**
 * Store state selectors for the common module.
 */

import { RootStoreState } from '../root/index'
import * as registerTypes from 'modules/register/types'
import { Api } from 'typescript-fetch-api'

/**
 * 
 * @param state Select the current auth access token from the root store state.
 */

/* Returns user reference */
export const userReference = (state: RootStoreState): string => {
	if (state.account.user) {
		return state.account.user.ref
	} else {
		return ''
	}
}
/* Returns user password for initial log in */
export const userPassword = (state: RootStoreState): string | undefined => {
	if (state.register.yourDetails.password) {
		return state.register.yourDetails.password
	} else {
		return undefined
	}
}
/* Returns user email for initial log in */
export const userEmail = (state: RootStoreState): string | undefined => {
	if (state.register.yourDetails.email) {
		return state.register.yourDetails.email
	} else {
		return undefined
	}
}
/* Returns user details for populating new client details */
export const yourDetails = (state: RootStoreState): registerTypes.YourDetails => {
	if (state.register.yourDetails) {
		return state.register.yourDetails
	} else {
		return registerTypes.INITIAL_YOUR_DETAILS
	}
}

export const genders = (state: RootStoreState) => state.common.settings && state.common.settings.genders

export const jobTypesSelector = (state: RootStoreState) => state.common.settings && state.common.settings.jobTypes

export const languages = (state: RootStoreState) => state.common.settings && state.common.settings.languages

export const skills = (state: RootStoreState) => state.common.settings && state.common.settings.skills

export const featuresSelector = (state: RootStoreState): DeepReadonlyObject<Api.FeatureSet> => (state.common.settings && state.common.settings.features) || {
	clientDetailsFields: {
		dateOfBirth: true,
		deliveryAddress: true,
		diagnosis: true,
		doctor: true,
		ethnicity: true,
		fullName: true,
		genderRef: true,
		image: true,
		knownAs: true,
		languages: true,
		location: true,
		nextOfKin: true,
		nhi: true,
		paymentDetails: true,
		phoneHome: true,
		phoneMobile: true,
		phoneWork: true,
		postalAddress: true,
		preferredLanguage: true,
		salutation: true,
		skillsRequired: true,
	},
	documents: {
		advancedCarePlan: false,
		healthPassport: false,
		myDocuments: false,
	},
	findSupportWorkers: false,
	jobFeedback: false,
	supportWorkerFeedback: false,
}
