import { RootStoreState } from 'modules/root'
import * as t from './types'
import { clientSelector } from 'modules/account/selectors'

/** Returns views of the current invitations for the current user.  */
export const currentUserInvitationsViews = (state: RootStoreState) => {
	if (!state.account.supportNetworks) {
		return undefined
	}

	return state.account.supportNetworks.invitations.map(inv => {
		const result: t.InvitationView = {
			clientRef: inv.clientRef,
			client: clientSelector(state, inv.clientRef),
			ref: inv.ref,
			inviterIsClient: inv.inviterIsClient,
			invitedByUserName: state.account.supportNetworkUsers ? state.account.supportNetworkUsers[inv.invitedByUserRef].givenName : '', 
		}
		return result
	})
}
