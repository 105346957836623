import * as React from 'react'
import { Api } from 'typescript-fetch-api'
import { OwnProps, Props, Actions } from '../containers/ClientPersonalDetails'
import AvatarModal from './AvatarModal'
import { PatchFormState, Input, Select } from 'formalities'
import Cleave from 'cleave.js/react'
import moment from 'moment'
import GoogleMapLoader from 'react-google-maps-loader'
import GooglePlacesSuggest from 'react-google-places-suggest'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { avatarPlaceholder } from 'modules/root/theme'
import platform from 'modules/platform'

/**
 * Interface for private internal component state.
 */
interface State {
	editingAvatar: boolean
	client?: PatchFormState<Api.Client, Api.UpdateClientDetailsRequest>
	deliverAddressCompleted?: string
	postalAddressCompleted?: string
}

/**
 * The initial state for our internal component state.
 */
const INITIAL_STATE: State = {
	editingAvatar: false,
}

export default class ClientPersonalDetails extends React.Component<OwnProps & Props & Actions, State> {

	public state = INITIAL_STATE

	onAvatarStart = (evt: React.MouseEvent) => {
		evt.preventDefault()
		this.setState({ editingAvatar: true })
		document.body.className = 'modal-open'
	}

	onAvatarDone = (image?: string) => {
		this.setState({ editingAvatar: false })
		if (image) {
			this.props.onAvatarUpload(this.props.client.ref, image)
		}
	}

	onAvatarCancel = () => {
		this.setState({ editingAvatar: false })
	}

	onRemoveAvatar = () => {
		this.setState({ editingAvatar: false })
		this.props.onAvatarRemove(this.props.client.ref)
	}

	onSave = (evt: React.FormEvent<HTMLDivElement>) => {
		evt.preventDefault()

		if (this.state.client) {
			const request = this.state.client.getValues()
			if (request.dateOfBirth) {
				request.dateOfBirth = moment(request.dateOfBirth, 'DD/MM/YYYY').format('YYYY-MM-DD')
			}
			if (request.deliveryAddress) {
				request.deliveryAddress = request.deliveryAddress.replace(new RegExp(', ', 'g'), '\n')
			}
			if (request.postalAddress) {
				request.postalAddress = request.postalAddress.replace(new RegExp(', ', 'g'), '\n')
			}
			this.props.onSave({
				clientRef: this.props.client.ref,
				request,
			})
		}
	}

	onNewFormState = (newState: PatchFormState<Api.Client, Api.UpdateClientDetailsRequest>) => {
		this.setState({ client: newState })
	}

	formState = () => {
		if (this.state.client) {
			return this.state.client
		}

		const { client } = this.props
		const fixedClient = {
			...client, 
			dateOfBirth: client.dateOfBirth ? moment(client.dateOfBirth).format('DD/MM/YYYY') : undefined,
			// eslint-disable-next-line no-control-regex
			deliveryAddress: client.deliveryAddress ? client.deliveryAddress.replace(new RegExp('\n', 'g'), ', ') : undefined,
			// eslint-disable-next-line no-control-regex
			postalAddress: client.postalAddress ? client.postalAddress.replace(new RegExp('\n', 'g'), ', ') : undefined,
		}
		return new PatchFormState<Api.Client, Api.UpdateClientDetailsRequest>(fixedClient, {})
	}

	componentWillReceiveProps(nextProps: Props) {
		/* Clear our state when we get new props, as it means the client details have changed */
		if (this.props.saving && !nextProps.saving) {
			this.setState({ client: undefined })
		}
	}

	public render() {
		const { client, settings, you, features } = this.props
		if (!settings) {
			return null
		}

		const clientState = this.formState()

		return (
			<div className="body">
				<Helmet>
					<title>Personal details — My Homecare</title>
				</Helmet>
				
				{you ? (
					<h1 className="headline-text">Personal details</h1>
				) : (
					<h1 className="headline-text">Personal details for {clientState.get('givenName') || client.fullName}</h1>
				)}
				
				<form className="form-layout">
					{features.image && 
					<div className="row">
						<div className="form-field -avatar">
							<figure className="avatar-image -upload -bordered">
								<div className="image">
									{clientState.get('image') ? (
										<img src={clientState.get('image')} alt={client.fullName} />
									) : (
										<img src={avatarPlaceholder()} alt={client.fullName} />
									)}
								</div>
							</figure>
							<div className="content">
								<label className="label">Profile image</label>
								<p>Upload an image of yourself. This will not be displayed publicly, but helps our support workers recognise you.</p>
								<div className="button-group -leftaligned">
									<div className="button">
										<a href="#" onClick={this.onAvatarStart} className="button-link -action -xsmall">{clientState.get('image') ? 'Replace' : 'Add'}</a>
									</div>
									{
										clientState.get('image') && (
											<div className="button">
												<a href="#" onClick={this.onRemoveAvatar} className="button-link -action -xsmall">Remove</a>
											</div>
										)
									}
									
								</div>
							</div>
						</div>
					</div>
					}
					<div className="row -split -names">
						
						{settings.salutations && features.salutation && (
							<div className="form-field -salutation">
								<label className="label">Salutation</label>
								<div className="form-input -select">
									<Select formState={clientState} onNewFormState={this.onNewFormState} className="select" name="salutationRef">
										<option value="" disabled={true} />
										{settings.salutations.map((s, index) => (
											<option value={s.ref} key={index}>{s.name}</option>
										))}
									</Select>
								</div>
							</div>
						)}
						
						<div className="form-field -firstname">
							<label className="label">Given name</label>
							<div className="form-input -text">
								<Input formState={clientState} onNewFormState={this.onNewFormState} type="text" className="field" name="givenName" />
							</div>
						</div>
		
						<div className="form-field -lastname">
							<label className="label">Family name</label>
							<div className="form-input -text">
								<Input formState={clientState} onNewFormState={this.onNewFormState} type="text" className="field" name="familyName" />
							</div>
						</div>
		
					</div>

					<div className="row -split -thirds">
						
						{features.knownAs &&
						<div className="form-field -knownas">
							<label className="label">Known as <span className="note">(optional)</span></label>
							<div className="form-input -text">
								<Input formState={clientState} onNewFormState={this.onNewFormState} type="text" className="field" name="knownAs" />
							</div>
						</div>
						}

						{features.dateOfBirth &&
						<div className="form-field -dob">
							<label className="label">Date of birth</label>
							<div className="form-input -text">
								<Cleave 
									className="field"
									placeholder="DD/MM/YYYY"
									options={{ date: true, datePattern: ['d', 'm', 'Y'] }}
									value={clientState.get('dateOfBirth') || ''}
									onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
										this.onNewFormState(this.formState().set('dateOfBirth', evt.target.value))
									}} 
								/>
							</div>
						</div>
						}

						{features.genderRef &&
						<div className="form-field -gender">
							<label className="label">Gender</label>
							<div className="form-input -select">
								<Select formState={clientState} onNewFormState={this.onNewFormState} className="select" name="genderRef">
									<option value="" disabled={true} />
									{settings.genders.map((s, index) => (
										<option value={s.ref} key={index}>{s.name}</option>
									))}
								</Select>
							</div>
						</div>
						}
						
					</div>

					<div className="row">
						<div className="form-field">
							<label className="label">Email address <span className="note">(<Link to="/account/email" className="-link">change or edit</Link>)</span></label>
							<div className="form-input -text">
								<input type="email" className="field" disabled={true} value={client.email} />
							</div>
						</div>
					</div>

					<div className="row -split -half">
						{features.phoneHome && 
						<div className="form-field">
							<label className="label">Phone number</label>
							<div className="form-input -text">
								<Input formState={clientState} onNewFormState={this.onNewFormState} type="text" className="field" name="phoneHome" />
							</div>
						</div>
						}
						{features.phoneMobile &&
						<div className="form-field">
							<label className="label">Mobile</label>
							<div className="form-input -text">
								<Input formState={clientState} onNewFormState={this.onNewFormState} type="text" className="field" name="phoneMobile" />
							</div>
						</div>
						}
					</div>

					<div className={'row ' + (client.nhi ? '-split -half' : '')}>
						{this.props.settings && features.preferredLanguage && (
							<div className="form-field -language">
								<label className="label">Language</label>
								<div className="form-input -select">
									<Select name="preferredLanguage" className="select" formState={clientState} onNewFormState={this.onNewFormState}>
										<option value="">English</option>
										{this.props.settings && this.props.settings.languages.map((lang, index) => (
											<option key={index} value={lang.ref}>{lang.name}</option>
										))}
									</Select>
								</div>
							</div>
						)}
						{
							client.nhi && features.nhi &&
							<div className="form-field">
								<label className="label">NHI</label>
								<div className="form-input -text">
									<input type="text" className="field" value={client.nhi} disabled={true}/>
								</div>
							</div>
						}
					</div>

					<GoogleMapLoader
						params={{
							key: platform.googleApiKey(),
							libraries: 'places,geocode',
						}}
						render={googleMaps => (
							<>
								{features.deliveryAddress && 
								<div className="row">
									<div className="form-field">
										<label className="label">Address</label>
										<div className="form-input -text">
											{googleMaps ? (
												<GooglePlacesSuggest
													googleMaps={googleMaps}
													autocompletionRequest={{
														input: clientState.getValues().deliveryAddress && clientState.getValues().deliveryAddress !== this.state.deliverAddressCompleted ? clientState.getValues().deliveryAddress! : '',
														componentRestrictions: platform.googlePlacesComponentRestrictions(),
													}}
													onSelectSuggest={(geocodedPrediction: google.maps.GeocoderResult) => {
														this.setState({
															client: clientState.merge({
																'deliveryAddress': geocodedPrediction.formatted_address,
																'location': {
																	latitude: geocodedPrediction.geometry.location.lat(),
																	longitude: geocodedPrediction.geometry.location.lng(),
																},
															}), 
															deliverAddressCompleted: geocodedPrediction.formatted_address,
														})
													}}
													textNoResults="No results"
													customRender={prediction => (
														<>
															{prediction
																? prediction.description
																: 'No results'}
														</>
													)}
												>
													<Input formState={clientState} onNewFormState={this.onNewFormState} type="text" className="field address-field" name="deliveryAddress" autoComplete="off" />
												</GooglePlacesSuggest>
											) : (
												<Input formState={clientState} onNewFormState={this.onNewFormState} type="text" className="field address-field" name="deliveryAddress" autoComplete="off" />
											)}
										</div>
									</div>
								</div>
								}

								{features.postalAddress && 
								<div className="row">
									<div className="form-field">
										<label className="label">Postal address <span className="note">(if different from above)</span></label>
										<div className="form-input -text">
											{googleMaps ? (
												<GooglePlacesSuggest
													googleMaps={googleMaps}
													autocompletionRequest={{
														input: clientState.getValues().postalAddress && clientState.getValues().postalAddress !== this.state.postalAddressCompleted ? clientState.getValues().postalAddress! : '',
														componentRestrictions: platform.googlePlacesComponentRestrictions(),
													}}
													onSelectSuggest={(geocodedPrediction: google.maps.GeocoderResult) => {
														this.setState({ client: clientState.set('postalAddress', geocodedPrediction.formatted_address), postalAddressCompleted: geocodedPrediction.formatted_address })
													}}
													textNoResults="No results"
													customRender={prediction => (
														<>
															{prediction
																? prediction.description
																: 'No results'}
														</>
													)}
												>
													<Input formState={clientState} onNewFormState={this.onNewFormState} type="text" className="field address-field" name="postalAddress" />
												</GooglePlacesSuggest>
											) : (
												<Input formState={clientState} onNewFormState={this.onNewFormState} type="text" className="field address-field" name="postalAddress" />
											)}
										</div>
									</div>
								</div>
								}
							</>
						)}
					/>

					<div className="row">
						<div className="button-group">
							<div className="button -constrained">
								<div className={'button-link -action' + (clientState.isEmpty() ? ' -disabled' : '') + (this.props.saving ? ' -processing' : '')} onClick={this.onSave}>
									Save changes
								</div>
							</div>
						</div>
					</div>
				</form>

				{this.state.editingAvatar && (
					<AvatarModal onDone={this.onAvatarDone} onClose={this.onAvatarCancel} />
				)}
			</div>
		)
	}
}
