import actionCreatorFactory, { Action, Failure } from 'typescript-fsa'
import { AccessToken, LoginRequest } from './types'
import { Api } from 'typescript-fetch-api'

const actionCreator = actionCreatorFactory('Auth')

export const login = actionCreator.async<LoginRequest, AccessToken, Error>('LOGIN')
export type LoginFailedAction = Action<Failure<LoginRequest, Error>>

export const refreshedToken = actionCreator<AccessToken>('REFRESHED_TOKEN')

/** Signals that refreshing failed. The payload is the time that it failed. */
export const refreshTokenFailed = actionCreator<number>('REFRESH_TOKEN_FAILED')

/** Action creator for the logout request. */
export const logoutRequest = actionCreator('LOGOUT_REQUEST')

/** The user has interactively logged in using the sign-in page, and their account details are all loaded. */
export const loggedIn = actionCreator('LOGGED_IN')

/** The user has been logged out. */
export const loggedOut = actionCreator('LOGGED_OUT')

/** An error has occurred while the user is logged in, either logging out or refreshing the token. */
export const loggedInError = actionCreator<Error>('LOGGED_IN_ERROR')

export const forgotPassword = actionCreator<Api.ForgottenPasswordRequest>('FORGOTTEN_PASSWORD')
export type ForgotPasswordAction = ReturnType<typeof forgotPassword>
export const forgotPasswordAsync = actionCreator.async<Api.ForgottenPasswordRequest, never, Api.ForgottenPasswordError>('FORGOTTEN_PASSWORD')

export const resetPassword = actionCreator<Api.ResetPasswordRequest>('RESET_PASSWORD')
export type ResetPasswordAction = ReturnType<typeof resetPassword>
export const resetPasswordAsync = actionCreator.async<Api.ResetPasswordRequest, never, Api.ResetPasswordError>('RESET_PASSWORD')