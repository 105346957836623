import Component from '../components/Calendar'
import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'
import { withRouter } from 'react-router'
import { LocalDate } from '@js-joda/core'
import { RootStoreState } from 'modules/root'
import { calendarView } from '../functions'
import * as t from '../types'
import * as s from '../selectors'
import * as bookingActions from 'modules/booking/actions'
import * as orderActions from 'modules/order/actions'
import { Api } from 'typescript-fetch-api'
import { currentClientSelector, supportNetworkMembershipSelector } from 'modules/account/selectors'
import { NoCapabilities } from 'modules/account/types'

export interface OwnProps {
	date: LocalDate
}

/**
 * Interface for properties that the container passes to the component.
 */
export interface Props {
	view: t.CalendarView
	loading: boolean
	funding?: t.Funding
	capabilities: Api.SupportNetworkCapabilities
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
	// onExample: (value: string) => void
	onSelectDay: (date: LocalDate) => void
	onNewBooking: () => void
	onNewOrder: () => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	const currentClient = currentClientSelector(state)
	const membership = currentClient ? supportNetworkMembershipSelector(state, currentClient.ref) : undefined

	return {
		view: calendarView(ownProps.date, state),
		loading: state.calendar.loading,
		funding: s.currentFunding(state),
		capabilities: membership ? membership.capabilities : NoCapabilities,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	onSelectDay: (date) => {
		dispatch(bookingActions.startBookingFromDate(date))
	},
	onNewBooking: () => {
		dispatch(bookingActions.startBooking())
	},
	onNewOrder: () => {
		dispatch(orderActions.newOrder())
	},
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Component))
