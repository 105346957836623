import { Api } from 'typescript-fetch-api'
import * as t from './types'

export function createFunderClientViews(clients: Api.SearchOrganisationClientsResponseRefs, refs: DeepReadonlyArray<Api.ClientOrganisationAccessControl>): t.ClientRearchResultView[] {
	const clientViews: t.ClientRearchResultView[] = []

	refs.forEach((clientRef) => {
		const currentClient = clients.clients[clientRef.clientRef]
		clientViews.push({
			ref: currentClient.ref,
			name: currentClient.fullName,
			nhi: currentClient.nhi,
			dateOfBirth: currentClient.dateOfBirth,
			image: currentClient.image,
			location: currentClient.postalAddress,
		})
	})

	return clientViews
}

export function createSupportNetworkMembership(client: DeepReadonlyObject<Api.ClientOrganisationAccessControl>, userRef: string): Api.SupportNetworkMembership | undefined {
	const membership: Api.SupportNetworkMembership = {
		clientRef: client.clientRef,
		userRef: userRef,
		role: Api.SupportNetworkMembership.RoleEnum.Supporter,
		capabilities: client.capabilities,
	}

	return membership
}