import * as React from 'react'
import { Props, OwnProps, Actions } from '../containers/TimesheetNewJob'
import { Api } from 'typescript-fetch-api'
import { withModal, ModalStyle } from 'modules/common/modal-hoc'
import TimesheetSupportWorkerSelect from '../containers/TimesheetSupportWorkerSelect'
import { LocalDate, LocalTime } from '@js-joda/core'
import moment from 'moment'
import Datetime from 'react-datetime'
import { produce } from 'immer'
import TimeField from 'react-simple-timefield'
import * as cf from 'modules/calendar/functions'

export default withModal(
	class TimesheetNewJob extends React.Component<Props & OwnProps & Actions> {
		StartTimeOptionEnum = Api.NewAppointment.StartTimeOptionEnum
	
		public render() {
			const { availableSupportWorkers, booking } = this.props

			return (
				<div className="timesheet-modal">
					<h1 className="page-title">New timesheet entry</h1>
					<div className="form-layout">
						<TimesheetSupportWorkerSelect availableSupportWorkers={availableSupportWorkers}/>
						<div className="row">
							<div className="form-field">
								<label className="label">Start date</label>
								<div className="form-input -date">
									<Datetime
										className="field"
										dateFormat="ddd D MMM"
										timeFormat={false}
										inputProps={{ readOnly: true, placeholder: 'Select a date…' }}
										onChange={this.handleBookingDate}
										value={booking && booking.date && moment(booking.date!.toString(), ['YYYY-MM-DD']).toDate()}
									/>
								</div>
							</div>
						</div>
						<div className="row -split -half">
							<div className="form-field">
								<label className="label">Start time</label>
								<div className="form-input -text">
									<TimeField
										onChange={this.handleStartTimeTextChange}
										value={booking && booking.startTime ? booking.startTime.toString() : ''}
										input={<input type="text" className="field" />}
									/>
								</div>
							</div>
							<div className="form-field">
								<label className="label">Duration</label>
								<div className="form-input -text">
									<TimeField
										onChange={this.handleDurationTextChange}
										value={booking && booking.durationMinutes ? `${LocalTime.ofSecondOfDay(booking.durationMinutes * 60)}` : ''}
										input={<input type="text" className="field" />}
									/>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="form-field -services">
								<label className="label">Funding</label>
								<div className="form-input -select">
									<select className="select" onChange={this.handleFundingChange} value={booking && booking.fundingRef}>
										{/* NOTE: Always have the blank option so the user has to choose the service, as we rely on the onChange to set it for us */}
										<option value=""/>
										{
											this.props.fundings && this.props.fundings.map((funding, key) => {
												const funder = this.props.funders ? this.props.funders[funding.funderRef] : undefined
												const jobType = this.props.jobTypes ? this.props.jobTypes[funding.jobTypeRef] : undefined
												const fundingInfo = this.props.timesheetHoursSummary && this.props.timesheetHoursSummary.fundings[funding.ref]
												if (fundingInfo) {
													const minsRemaining = fundingInfo.minsFunded - fundingInfo.minsAllocated
													return (
														<option key={key} value={funding.ref}>{funder ? funder.name : ''} — {(jobType && jobType.name) || funding.jobTypeRef} {minsRemaining !== undefined ? `(${cf.formatDuration(minsRemaining)} available)` : null}</option>
													)
												} else {
													return null
												}
											})
										}
									</select>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="button-group">
								<div className="button">
									<div onClick={this.onSubmitNewBooking} className={'button-link -action' + (this.props.processing ? ' -processing' : '')}>Confirm booking</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		}

		private onSubmitNewBooking = (evt: React.MouseEvent) => {
			evt.preventDefault()

			this.props.onSubmitNewBooking()
		}

		private handleDurationTextChange = (evt: React.ChangeEvent, stringValue: string) => {
			if (this.props.booking) {
				const ob = produce(this.props.booking, draft => {
					draft.durationMinutes = LocalTime.parse(stringValue).toSecondOfDay() / 60
				})
				this.props.onBookingLineChange(this.props.booking, ob)
			}
		}

		private handleBookingDate = (date: moment.Moment | string) => {
			if (typeof date === 'string') {
				/* The user entered something that was not parseable as a date */
				return
			}
			if (this.props.booking) {
				const ob = produce(this.props.booking, draft => {
					draft.date = LocalDate.parse(date.format('YYYY-MM-DD'))
				})
				this.props.onBookingLineChange(this.props.booking, ob)
			}
		}

		private handleStartTimeTextChange = (evt: React.ChangeEvent, stringValue: string) => {
			if (this.props.booking) {
				const ob = produce(this.props.booking, draft => {
					draft.startTime = LocalTime.parse(stringValue)
				})
				this.props.onBookingLineChange(this.props.booking, ob)
			}
		}

		private handleFundingChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
			if (this.props.booking) {
				const funding = this.props.fundings ? this.props.fundings.find(f => f.ref === evt.target.value) : undefined
				const ob = produce(this.props.booking, draft => {
					draft.fundingRef = evt.target.value
					draft.services = funding ? [funding.jobTypeRef] : []
				})
				this.props.onBookingLineChange(this.props.booking, ob)
			}
		}
	},
	
	ModalStyle.Medium,
)
