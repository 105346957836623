/**
 * Container template for a component.
 */

/* Import the component from the component path */
import Component from '../components/ClientPersonalDetailsViewOnly'
import { Api } from 'typescript-fetch-api'

import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'
import { currentClientSelector } from '../selectors'

/* Import module actions */
// import * as actions from '../actions'

/** Interface for properties that are passed to the container/ */
export interface OwnProps {

}

/** Interface for properties that the container passes to the component. */
export interface Props {
	client?: DeepReadonly<Api.Client>
	settings?: DeepReadonly<Api.Settings>
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
	// onExample: (value: string) => void
	
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	return {
		client: currentClientSelector(state),
		settings: state.common.settings,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	// onExample: (value) => {
	// 	dispatch(actions.examplePrimitiveAction(value))
	// },
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
