import * as React from 'react'
import { OwnProps, Props, Actions } from '../containers/SetPassword'
import { Link } from 'react-router-dom'
import { showToastFailedMessage } from 'modules/root/functions'

interface State {
	passwordMatch: string
	formError: boolean
}

const INITIAL_STATE: State = {
	passwordMatch: '',
	formError: false,
}

export default class SetPassword extends React.Component<OwnProps & Props & Actions, State> {

	public state = INITIAL_STATE

	handleGivenNameChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const details = { ...this.props.yourDetails }
		details.givenName = evt.target.value
		this.props.onUpdateYourDetailsStore(details)
	}

	handleFamilyNameChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const details = { ...this.props.yourDetails }
		details.familyName = evt.target.value
		this.props.onUpdateYourDetailsStore(details)
	}

	handlePhoneNumberChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const details = { ...this.props.yourDetails }
		details.phone = evt.target.value
		this.props.onUpdateYourDetailsStore(details)
	}

	handlePasswordChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		this.props.onUpdateYourDetailsStore({ ...this.props.yourDetails, password: evt.target.value })

		this.setState({ 
			formError: false,
		})
	}

	handlePasswordMatchChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ 
			formError: false,
			passwordMatch: evt.target.value,
		})
	}

	validatePasswordsMatch = (): boolean => {
		if (this.props.yourDetails.password === this.state.passwordMatch) {
			return true
		}
		return false
	}

	toggleTermsChecked = () => {
		const details = { ...this.props.yourDetails }
		details.acceptedTerms = !details.acceptedTerms
		this.props.onUpdateYourDetailsStore(details)
	}

	componentDidMount() {
		/* Check that we have a valid token */
		if (!this.props.token) {
			this.props.onInvalidToken()
		}
	}

	public render() {
		return (
			<article className="site-body">
				<div className="width-limit -site">
					<form className="form register-form" onSubmit={this.setPassword}>
					
						<div className="form-layout">
							<div className="introduction">
								<h1 className="headline-text">Your details</h1>
								<div className="body-text">
									<p>Enter your details, and set a password. You will use this password whenever you sign in to the My Homecare site.</p>
								</div>
							</div>

							<div className="form-field" hidden={true}>
								<div className="form-input">
									<label className="label" htmlFor="username">Email</label>
									<div className="form-input -text">
										<input type="email" className="field" id="username" name="username" value={this.props.yourDetails.email} autoComplete="username email" readOnly={true} />
									</div>
								</div>
							</div>

							<div className="row -split -thirds">
								<div className="form-field">
									<label className="label">Given name</label>
									<div className="form-input -text">
										<input 
											type="text" 
											className="field" 
											onChange={this.handleGivenNameChange} 
											value={this.props.yourDetails && this.props.yourDetails.givenName} 
										/>
									</div>
								</div>

								<div className="form-field">
									<label className="label">Family name</label>
									<div className="form-input -text">
										<input 
											type="text" 
											className="field" 
											onChange={this.handleFamilyNameChange} 
											value={this.props.yourDetails && this.props.yourDetails.familyName} 
										/>
									</div>
								</div>

								<div className="form-field">
									<label className="label">Phone number</label>
									<div className="form-input -text">
										<input 
											type="text" 
											className="field" 
											onChange={this.handlePhoneNumberChange} 
											value={this.props.yourDetails && this.props.yourDetails.phone} 
										/>
									</div>
								</div>
							</div>

							<div className="row -split -half">
								<div className="form-field">
									<label className="label">Password</label>
									<div className="form-input">
										<div className="form-input -text">
											<input type="password" name="password" id="password" className="field" onChange={this.handlePasswordChange} autoComplete="new-password"/>
										</div>
									</div>
								</div>

								<div className="form-field">
									<label className="label">Re-type your password</label>
									<div className="form-input">
										<div className="form-input -text">
											<input type="password" className="field" onChange={this.handlePasswordMatchChange} autoComplete="new-password" />
										</div>
									</div>
								</div>

							</div>

							<div className="row">
								<div className="form-field">
									<div className="form-input -options">
										<ul className="option-inputs">
											<li className="option -checkbox">
												<label className="label">
													<input type="checkbox" name="" className="checkbox" checked={this.props.yourDetails.acceptedTerms} onChange={this.toggleTermsChecked}/><span className="substitute"/>
													I have read and agree to the <a href="/contact-policies/terms-of-use/">Terms and Conditions</a>
												</label>
											</li>
										</ul>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="button-group">
									<div className="button">
										<Link to="/register/client-details" className="button-link -text">Back</Link>
									</div>
									<div className="button -constrained">
										<div className={'button-link -action -nested' + (this.props.processing ? ' -processing' : '') + (this.props.yourDetails.acceptedTerms ? '' : ' -disabled')}>
											<input type="submit" value="Submit" />
										</div>
									</div>
								</div>
							</div>
						</div>
						
					</form>
				</div>
			</article>
		)
	}

	private setPassword = (evt: React.FormEvent) => {
		evt.preventDefault()
		if (this.validatePasswordsMatch() && this.props.yourDetails.password !== '' && this.props.yourDetails.acceptedTerms) {
			if (this.props.token) {
				this.props.onComplete(this.props.token, this.props.yourDetails)
				this.setState({})
			} else {
				this.props.onInvalidToken()
			}
		} else {
			let errorMessage = 'Oops! Something went wrong. Please try again.'
			
			if (this.props.yourDetails.password === '') {
				errorMessage = 'Password must not be empty.'
			} else if (!this.validatePasswordsMatch()) {
				errorMessage = 'Passwords do not match.'
			} else if (!this.props.yourDetails.acceptedTerms) {
				errorMessage = 'You must agree to the terms and conditions.'
			}

			showToastFailedMessage(errorMessage)

		}
	}
}
