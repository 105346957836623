import api from 'modules/api'
import { currentClientRefSelector } from 'modules/account/selectors'
import { push } from 'connected-react-router'
import { put, takeEvery, call, select } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'
import * as a from 'modules/order/actions'
import { callApiWithActions, handleApiFailedAction } from 'modules/api/functions'
import { Api } from 'typescript-fetch-api'
import * as rf from 'modules/root/functions'

function* handleNewOrder() {
	yield put(push('/calendar/new-order'))
}

function* cancelNewOrder() {
	yield put(push('/calendar'))
}

function* handleSubmitNewOrder(action: a.SubmitNewOrderAction): SagaIterator {
	const currentClientRef = (yield select(currentClientRefSelector)) as string | undefined
	if (!currentClientRef) {
		return
	}

	const ticket: Api.AddTicketRequest = {
		type: Api.AddTicketRequest.TypeEnum.ConsumablesOrder,
		subject: action.payload.subject,
		message: action.payload.notes || '',
	}

	yield call(callApiWithActions, action.payload, a.submitNewOrderAsync, (options: RequestInit) => {
		return api().clientApi.postTicket(currentClientRef, ticket, options)
	})
}

function* handleSubmitNewOrderDone() {
	rf.showToastSuccessMessage('Thank you, your new order has been submitted.')
	yield put(push('/calendar'))
}

export default function* (): SagaIterator {
	yield takeEvery(a.newOrder, handleNewOrder)
	yield takeEvery(a.cancelNewOrder, cancelNewOrder)
	yield takeEvery(a.submitNewOrder, handleSubmitNewOrder)
	yield takeEvery(a.submitNewOrderAsync.done, handleSubmitNewOrderDone)
	yield takeEvery(a.submitNewOrderAsync.failed, handleApiFailedAction.bind(null, 'Oops! Your order could not be submitted.'))
}