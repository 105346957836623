import Component from '../components/TimesheetNewJob'
import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'
import * as _ from 'lodash'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'
import * as s from '../selectors'
import * as bt from 'modules/booking/types'
import * as ba from 'modules/booking/actions'
import * as cs from 'modules/calendar/selectors'
import { Api } from 'typescript-fetch-api'
import { push } from 'connected-react-router'
import { dontScrollToTopState } from 'modules/routing'
import { timesheetableSupportWorkerRefsSelector } from 'modules/booking/selectors'
import { TimesheetHoursSummary } from '../types'

/** Interface for properties that are passed to the container/ */
export interface OwnProps {
	timesheetRef?: string
}

/** Interface for properties that the container passes to the component. */
export interface Props {
	availableSupportWorkers?: DeepReadonlyArray<string>
	processing: boolean
	settings: DeepReadonly<bt.BookingSettings>
	timesheet?: DeepReadonlyObject<Api.Timesheet>
	booking?: DeepReadonlyObject<bt.BookingLine>
	fundings?: DeepReadonlyArray<Api.Funding>
	funders?: DeepReadonly<{ [key: string]: Api.Funder }>
	jobTypes?: DeepReadonly<{ [key: string]: Api.JobType }>
	timesheetHoursSummary?: TimesheetHoursSummary
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
	onClose: () => void
	onSettingsChange: (settings: bt.BookingSettings) => void
	onBookingLineChange: (original: DeepReadonly<bt.BookingLine>, updated: DeepReadonly<bt.BookingLine>) => void
	onSubmitNewBooking: () => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	const settings = { ...state.booking.booking.settings }
	settings.fundingType = Api.NewAppointment.FundingTypeEnum.Funded

	return {
		booking: state.booking.booking.bookings[0] ? state.booking.booking.bookings[0] : undefined,
		availableSupportWorkers: timesheetableSupportWorkerRefsSelector(state),
		processing: state.booking.submitting,
		settings,
		timesheet: ownProps.timesheetRef ? s.timesheetSelector(state, ownProps.timesheetRef) : undefined,
		fundings: _.values(cs.fundingsSelector(state)),
		funders: cs.fundersSelector(state),
		jobTypes: cs.jobTypesSelector(state),
		timesheetHoursSummary: ownProps.timesheetRef ? s.timesheetHoursSummary(state, ownProps.timesheetRef) : undefined,
	}
}

/** Populate the Actions with the callbacks for the component. */
export const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: OwnProps): Actions => ({
	onClose: () => {
		if (ownProps.timesheetRef) {
			dispatch(push(`/timesheet/ref/${ownProps.timesheetRef}`, dontScrollToTopState()))
		} else {
			dispatch(push('/timesheet', dontScrollToTopState()))
		}
	},
	onSettingsChange: (settings) => {
		dispatch(ba.updateBookingSettings(settings))
	},
	onBookingLineChange: (original, updated) => {
		dispatch(ba.updateBookingLine({ original, updated }))
	},
	onSubmitNewBooking: () => {
		dispatch(ba.submitNewBooking({
			timesheetEdit: true,
			timesheetRef: ownProps.timesheetRef ? ownProps.timesheetRef : undefined,
		}))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
