import * as React from 'react'
import * as t from '../types'
import * as cf from 'modules/calendar/functions'
import _ from 'lodash'

interface OwnProps {
	summary: t.TimesheetHoursSummary
}

export default class TotalHoursByType extends React.Component<OwnProps> {

	public render() {
		const { summary } = this.props

		const fundings = _.values(summary.fundings)
		fundings.sort((a, b) => a.jobTypeRef.localeCompare(b.jobTypeRef))

		return (
			<div className="table-wrapper">
				<div className="title">Hours by type</div>
				<table className="table table-default">
					<thead>
						<tr>
							<th scope="col">Funder</th>
							<th scope="col">Type</th>
							<th scope="col">Funded</th>
							<th scope="col">Allocated</th>
						</tr>
					</thead>
					<tbody>
						{fundings.map((jt, index) => (
							<tr key={index}>
								<td>{jt.funder.name}</td>
								<td>{jt.jobTypeName || jt.jobTypeRef}</td>
								<td>{cf.formatDuration(jt.minsFunded, true)}</td>
								<td>{cf.formatDuration(jt.minsAllocated, true)}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		)
	}
}