import * as React from 'react'
import GoogleMapLoader from 'react-google-maps-loader'
import GooglePlacesSuggest from 'react-google-places-suggest'
import { forComponentProps, wrapComponent, Input, Snapshot } from 'changeling'
import { ContactValue } from 'modules/health-passport/types'
import platform from 'modules/platform'

export interface OwnProps extends Snapshot<ContactValue | undefined> {
	subOrder?: string
	title: string
	subText?: string
	secondaryQuestion?: boolean // TRUE if this question has been displayed due to the previous question
	address?: boolean
	relationshipToMe?: boolean
	nameOfAgency?: boolean
	imageLocation?: string
	editingMode?: boolean
}

class Contact extends React.Component<OwnProps> {
	private changeling = forComponentProps(this)

	public render() {
		return (
			<div className={'form-item -contact' + (this.props.secondaryQuestion ? ' -secondary' : '') + (this.props.editingMode ? ' -no-gutters' : ' -valuemode')}>
				<p className="title">
					{ this.props.subOrder && 
						<span className="title-order">{this.props.subOrder}</span>
					}
					{this.props.title}
					{
						this.props.subText && 
						<span className="sub-text"> {this.props.subText}</span>
					}
				</p>

				<div className="input-wrapper">
					<div className="content-wrapper">
						{
							this.props.editingMode ? (
								<div className={'form-layout' + (this.props.imageLocation ? ' -withimage' : '')}>
									<div className="row">
										<div className="form-field">
											<label className="label">Full name</label>
											<div className="form-input -text">
												<Input.String type="text" className="field" controller={this.changeling} prop="fullName" />
											</div>
										</div>
									</div>

									{
										this.props.relationshipToMe &&
										<div className="row">
											<div className="form-field">
												<label className="label">Relationship to me</label>
												<div className="form-input -text">
													<Input.String type="text" className="field" controller={this.changeling} prop="relationshipToMe" />
												</div>
											</div>
										</div>
									}

									{
										this.props.nameOfAgency &&
										<div className="row">
											<div className="form-field">
												<label className="label">Name of agency <span className="note">(if applicable)</span></label>
												<div className="form-input -text">
													<Input.String type="text" className="field" controller={this.changeling} prop="nameOfAgency" />
												</div>
											</div>
										</div>
									}

									{
										this.props.address &&
										<div className="row">
											<div className="form-field">
												<label className="label">Address</label>
												<div className="form-input -text">
													<GoogleMapLoader
														params={{
															key: platform.googleApiKey(),
															libraries: 'places,geocode',
														}}
														render={googleMaps =>
															googleMaps && (
																<GooglePlacesSuggest
																	googleMaps={googleMaps}
																	autocompletionRequest={{
																		input: (this.props.value && this.props.value.address) || '',
																		componentRestrictions: platform.googlePlacesComponentRestrictions(),
																	}}
																	onSelectSuggest={this.handleAddressSelectSuggest}
																	textNoResults="No results"
																	customRender={prediction => (
																		<>
																			{prediction
																				? prediction.description
																				: 'No results'}
																		</>
																	)}
																>
																	<Input.String
																		type="text"
																		controller={this.changeling}
																		className="address-field"
																		prop="address"
																		placeholder="Search a location"
																	/>
																</GooglePlacesSuggest>
															)
														}
													/>
												</div>
											</div>
										</div>
									}
								</div>
							) : (
								<div className="value-wrapper">
									<p className="sub-text"><span className="title">Full name: </span>  {this.props.value && this.props.value.fullName}</p>
									{this.props.relationshipToMe && <p className="sub-text"><span className="title">Relationship to me: </span>  {this.props.value && this.props.value.relationshipToMe}</p>}
									{this.props.nameOfAgency && <p className="sub-text"><span className="title">Name of agency: </span>  {this.props.value && this.props.value.nameOfAgency}</p>}
									{this.props.address && <p className="sub-text"><span className="title">Address: </span>  {this.props.value && this.props.value.address}</p>}
									<p className="sub-text"><span className="title">Phone: </span>  {this.props.value && this.props.value.phone}</p>
									<p className="sub-text"><span className="title">Mobile: </span>  {this.props.value && this.props.value.mobile}</p>
									<p className="sub-text"><span className="title">Fax: </span>  {this.props.value && this.props.value.fax}</p>
									<p className="sub-text"><span className="title">Email: </span>  {this.props.value && this.props.value.email}</p>
								</div>
							)
						}
					</div>
					<div className="image-wrapper">
						{
							this.props.imageLocation &&
								<img src={this.props.imageLocation} />
						}
					</div>
				</div>
				{
					this.props.editingMode &&
					<div className={'form-layout' + (this.props.imageLocation ? ' -withimage' : '')}>
						<div className="row -split -thirds">
							<div className="form-field ">
								<label className="label">Phone</label>
								<div className="form-input -text">
									<Input.String type="text" className="field" controller={this.changeling} prop="phone" />
								</div>
							</div>
							<div className="form-field">
								<label className="label">Mobile phone</label>
								<div className="form-input -text">
									<Input.String type="text" className="field" controller={this.changeling} prop="mobile" />
								</div>
							</div>
							<div className="form-field">
								<label className="label">Fax</label>
								<div className="form-input -text">
									<Input.String type="text" className="field" controller={this.changeling} prop="fax" />
								</div>
							</div>
						</div>

						<div className="row">
							<div className="form-field">
								<label className="label">Email</label>
								<div className="form-input -text">
									<Input.String type="text" className="field" controller={this.changeling} prop="email" />
								</div>
							</div>
						</div>
					</div>
				}
			</div>
		)
	}

	private handleAddressSelectSuggest = (geocodedPrediction: google.maps.GeocoderResult, originalPrediction: google.maps.places.AutocompletePrediction) => {
		this.changeling.snapshot('address').onChange(geocodedPrediction.formatted_address)
	}
}

export default wrapComponent(Contact)