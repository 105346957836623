import * as React from 'react'
import { Helmet } from 'react-helmet'
import IFGraphExample from 'modules/individualised-funding/resources/if-graph-example.png'
export default class IndividualisedFundingFAQs extends React.Component {

	public render() {
		return (
			<div className="body">
				<Helmet>
					<title>FAQs — Individualised Funding</title>
				</Helmet>
				
				<h1 className="headline-text">Individualised funding FAQs</h1>
				
				<div className="body-text">
					<h2>What funding options do I have?</h2>
					<p>
						Geneva, as your Personal Budget Host offers three services.
					</p>
					<ul>
						<li>Setup and coaching, invoicing, monitoring and reporting.</li>	
						<li>The above, along with payment to your chosen Support Worker.</li>	
						<li>The above, along with training and management (payroll/scheduling) of Support Worker.</li>	
					</ul>
					<h2>Who will help me set up and manage my support?</h2>
					<p>
						We, as your Personal Budget Host will help get you set up, we will support you and we may manage your support.
					</p>
					<h2>How do I pay my Support Workers?</h2>
					<p>
						You only pay SW directly if you have chosen this level of service. The other services Geneva will manage the payroll.
					</p>
					<h2>Will Geneva allocate a staff member to assist me?</h2>
					<p>
						If you have chosen this level of service we will recruit, train and allocates a SW to provide services, your coach will give us valuable information that will assist us in finding the right SW for you.
					</p>
					<h2>Can I use my family to provide care?</h2>
					<p>
						Yes you can, with some exceptions. The person providing your care cannot live at the same address as you, and cannot be your parent, child, spouse or partner.
					</p>
					<h2>What are my responsibilities?</h2>
					<p>Maintaining records to detail how support services have been used and complying with the ministry policies.</p>
					<h2>Can I choose how and when the services are provided to me? What are my options?</h2>
					<p>
						Yes – with My Homecare, you are in control of when you SW attends to provide services for you. We will help you manage your funding and ensure the right services are set up to provide you with the right care.
					</p>
					<h2>How do I track my Personal Budget?</h2>
					<p>My Homecare displays your funding in a graph and provides a list of your past transactions.</p>
				</div>
				<figure className="media-asset">
					<div className="media">
						<div className="aspect" style={{ paddingTop: '30%' }}>
							<img src={IFGraphExample} />
						</div>
					</div>
				</figure>
				<div className="body-text">
					<h2>Do I have to keep all of my receipts and invoices?</h2>
					<p>
						Yes you do, all receipts and invoices need to be kept for seven years. If you are submitting your expense claims online, this paperwork will be stored for you digitally.
					</p>
					<h2>How do I submit my expense claims?</h2>
					<p>
						You can submit all of your expense claims through the My Homecare online portal. Alternatively, you may email or post your claims to our office.
					</p>
					<h2>Is there any review?</h2>
					<p>
						Yes, we review all our Personal Budget clients after a year.
					</p>
					<h2>Is NASC still involved? What do they do?</h2>
					<p>
						NASC complete an assessment and make the referral to the chosen Personal Budget Host.  We may determine that you require more services in this case we will contact the NASC and ask them to reassess you.
					</p>
					<h2>What is a Coach?</h2>
					<p>
						A Coach is a member of our specialised team who is assigned to you, they will assist with your initial paperwork and ensure you understand the ministry requirements , they oversee the setup of services and they monitor your case.
					</p>
					<h2>What is a Personal Budget Host?</h2>
					<p>
						It is the agency that administers the funding from the ministry. 
					</p>
				</div>
			</div>
		)
	}
}
