/**
 * Container template for a component.
 */

/* Import the component from the component path */
import Component from '../components/SupportWorkersFavourites'

import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'

/* Import module actions */
import * as a from '../actions'
import { clientSupportWorkerRelationshipRefsToSupportWorkerRefs } from '../selectors'

/**
 * Interface for properties that the container passes to the component.
 */
export interface Props {
	supportWorkerRefs?: DeepReadonlyArray<string>
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
	onLoadFavourites: () => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState): Props => {
	return {
		supportWorkerRefs: clientSupportWorkerRelationshipRefsToSupportWorkerRefs(state, state.supportWorkers.favouriteClientSupportWorkerRelationshipRefs),
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	onLoadFavourites: () => {
		dispatch(a.searchFavouriteWorkers())
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)