import * as React from 'react'
import { Api } from 'typescript-fetch-api'
import { Snapshot, forComponentProps, wrapComponent, Input } from 'changeling'

export interface OwnProps extends Snapshot<string | undefined> {
	subOrder?: string
	secondaryQuestion?: boolean // TRUE if this question has been displayed due to the previous question
	title?: string
	dropdownOptions?: DeepReadonlyArray<Api.Language>
	imageLocation?: string
	editingMode?: boolean
}

class Dropdown extends React.Component<OwnProps> {
	private changeling = forComponentProps(this)
	
	public render() {
		return (
			<div className={'form-item -dropdown' + (this.props.secondaryQuestion ? ' -secondary' : '') + (this.props.imageLocation ? '' : ' -noimage') + (this.props.editingMode ? '' : ' -valuemode')}>
				<div className="form-wrapper">
					<div className="content-wrapper">
						<p className="title">
							{ this.props.subOrder && 
							<span className="title-order">{this.props.subOrder}</span>
							}
							{this.props.title}
						</p>
						{
							this.props.editingMode ? (
								<div className="form-field">
									<div className="form-input -select">
										<Input.Select 
											className="select" 
											controller={this.changeling} 
											prop="this" 
											options={
												this.props.dropdownOptions ? this.props.dropdownOptions.map(option => ({
													value: option.ref, text: option.name,
												})) : []
											}
										/>
									</div>
								</div>
							) : (
								<div className="value-wrapper">
									<p className="sub-text">{this.valueToText()}</p>
								</div>
							)
						}
					</div>
					{
						this.props.imageLocation &&
					<div className="image-wrapper">
						<img src={this.props.imageLocation} />
					</div>
					}
				</div>
			</div>
		)
	}

	private valueToText = () => {
		if (this.props.dropdownOptions) {
			const langIndex = this.props.dropdownOptions.findIndex(x => x.ref === this.props.value)
			if (this.props.dropdownOptions[langIndex]) {
				return this.props.dropdownOptions[langIndex].name
			} else {
				return ''
			}
		} else {
			return '' 
		}
	}
}

export default wrapComponent(Dropdown)