import * as React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'  
import { Api } from 'typescript-fetch-api'

const employmentAgreement = require('../resources/Individual_Employment_agreement.docx')
const supportWorkerAgreement = require('../resources/Support_Worker_Privacy_Declaration_template.docx')

export interface OwnProps {
	individualisedFunding: DeepReadonly<Api.IndividualisedFunding>
}

interface State {
	// myValue: string
}

const INITIAL_STATE: State = {
	// myValue: 'Example',
}

export default class RegisterSupportWorker extends React.Component<OwnProps, State> {
// export default class Overview extends React.Component<OwnProps & Props & Actions, State> {

	public state = INITIAL_STATE

	public render() {
		return (
			<div className="individualised-funding-screen body body-text">
				<Helmet>
					<title>Register a Support Worker</title>
				</Helmet>
				<h1 className="headline-text">Register a Support Worker</h1>
				<div className="form-layout">
					<div className="row -ruler">
						<p className="-lrg">This page will outline the information required for registering one of your employees as a Support Worker.</p>
						<h2>Collect relevant employment forms</h2>
						<p>The following forms must be downloaded and completed by your employee.</p>
						<div className="registration-list">
							<ul>
								<li className="icon -external-link"><a target="_blank" rel="noopener noreferrer" href="https://www.ird.govt.nz/resources/c/c/cccb8332-f8f6-49b8-8ff5-86a55d527519/IR330%2818%29.pdf">IR 330 — Tax code declaration</a></li>
								<li className="icon -document-download"><a href={employmentAgreement}>Support worker agreement form</a></li>
								<li className="icon -document-download"><a href={supportWorkerAgreement}>Support worker privacy declaration</a></li>
							</ul>
							<p>Choose one of the below, if the worker will be opting in or out of Kiwisaver:</p>
							<ul>
								<li className="icon -external-link"><a target="_blank" rel="noopener noreferrer" href="https://www.nzqa.govt.nz/assets/About-us/Working-for-NZQA/ECM/KS2-Deduction-Form.pdf">KS2 — Kiwisaver opt in form</a></li>
								<li className="icon -external-link"><a target="_blank" rel="noopener noreferrer" href="https://www.ird.govt.nz/resources/b/8/b8a84d4e-84f4-4e22-b5f5-b5e15bd10f55/ks10.pdf">KS10 — Kiwisaver opt out form</a></li>
							</ul>
						</div>
					</div>
					<div className="row">
						<h2>Enter registration details</h2>
						<p>Complete the online to register your employee as a Support Worker, and attach a copy of all relevant documents collected from above.</p>
						<div className="button-group -singlebutton">
							<div className="button">
								<Link to="" className="button-link -action -small">Enter Support Worker details</Link>
							</div>
						</div> 
					</div>
				</div>
			</div>
		)
	}
}
