import { RootStoreState } from 'modules/root'
import * as _ from 'lodash'
import { LocalDate } from '@js-joda/core'
import moment from 'moment'
import * as cf from 'modules/calendar/functions'
import * as t from './types'
import * as f from './functions'
import { createSelector } from 'reselect'
import { TimesheetHoursSummary } from './types'
import { supportWorkersSelector, fundersSelector, fundingsSelector, jobTypesSelector } from 'modules/calendar/selectors'
import { Api } from 'typescript-fetch-api'

export const currentWeekTimesheetRefSelector = (state: RootStoreState): string | undefined => {
	const timesheets = state.calendar.refs.timesheets
	const now = moment()

	return _.keys(timesheets).find((ref) => {
		return f.inTimesheetPeriod(now, timesheets[ref])
	})
}

export const timesheetSelector = (state: RootStoreState, timesheetRef: string) => state.calendar.refs.timesheets[timesheetRef]
const appointmentsSelector = (state: RootStoreState) => state.calendar.refs.appointments

/** Returns all of the jobs for the given timesheet ref */
const timesheetAppointmentsSelector = createSelector(
	timesheetSelector,
	appointmentsSelector,
	(timesheet, appointments) => {
		if (!timesheet) {
			return []
		}
		return _.values(appointments).filter(appt => appt.timesheetRef === timesheet.ref)
	},
)

export const weeklyTimesheetAppointmentsSelector = (state: RootStoreState, timesheetRef: string) => {
	const timesheet = timesheetSelector(state, timesheetRef)
	if (!timesheet) {
		return {
			days: [],
			totalMinutes: 0,
			thisWeek: false,
		}
	}

	function filterAppointment(appointment: DeepReadonly<Api.Appointment>) {
		return appointment.timesheetRef === timesheetRef
	}

	const calendarView = cf.calendarView(LocalDate.parse(timesheet.startDate), state, LocalDate.parse(timesheet.startDate), LocalDate.parse(timesheet.endDate).plusDays(1), filterAppointment)
	return calendarView.weeks[0]
}

export const previousTimesheetRefSelector = (state: RootStoreState, timesheetRef: string): string | undefined => {
	const timesheetRefArray = state.calendar.roster.roster ? state.calendar.roster.roster.timesheetRefs : undefined

	if (timesheetRefArray) {
		const index = timesheetRefArray.indexOf(timesheetRef)

		if (index > 0) {
			return timesheetRefArray[index - 1]
		}
	}
	return 
}

export const nextTimesheetRefSelector = (state: RootStoreState, timesheetRef: string): string | undefined => {
	const timesheetRefArray = state.calendar.roster.roster ? state.calendar.roster.roster.timesheetRefs : undefined

	if (timesheetRefArray) {
		const index = timesheetRefArray.indexOf(timesheetRef)

		if (index >= 0 && (index !== timesheetRefArray.length - 1)) {
			return timesheetRefArray[index + 1]
		}
	}
	return 
}

export const timesheetHoursSummary = createSelector(
	timesheetSelector,
	timesheetAppointmentsSelector,
	supportWorkersSelector,
	fundersSelector,
	fundingsSelector,
	jobTypesSelector,
	(timesheet, appointments, supportWorkers, funders, fundings, jobTypes) => {
		const result: TimesheetHoursSummary = {
			supportWorkers: {},
			fundings: {},
		}

		if (timesheet && timesheet.funding) {
			timesheet.funding.forEach(fundingInfo => {
				const funding = fundings[fundingInfo.fundingRef]
				const funder = funders[funding.funderRef]
				result.fundings[fundingInfo.fundingRef] = {
					fundingRef: fundingInfo.fundingRef,
					minsAllocated: fundingInfo.minsAllocated,
					minsFunded: fundingInfo.minsFunded,
					funder,
					jobTypeRef: funding.jobTypeRef,
					jobTypeName: jobTypes[funding.jobTypeRef] ? jobTypes[funding.jobTypeRef].name : undefined,
				}
			})
		}

		appointments.forEach(appt => {
			if (appt.status === Api.Appointment.StatusEnum.Cancelled || appt.status === Api.Appointment.StatusEnum.PendingCancellation) {
				return
			}
			const jobTypeRef = appt.jobTypeRefs.length > 0 ? appt.jobTypeRefs[0] : undefined
			const supportWorkerRef = appt.supportWorkerRefs.length > 0 ? appt.supportWorkerRefs[0] : undefined
			const durationMins = appt.durationMins || 0

			if (supportWorkerRef) {
				const supportWorker = supportWorkers[supportWorkerRef]
				if (supportWorker) {
					if (!result.supportWorkers[supportWorkerRef]) {
						result.supportWorkers[supportWorkerRef] = {
							supportWorker: cf.supportWorkerView(supportWorker),
							totalMinutes: 0,
							jobTypes: {},
						}
					}
					result.supportWorkers[supportWorkerRef].totalMinutes += durationMins
					if (jobTypeRef) {
						if (!result.supportWorkers[supportWorkerRef].jobTypes[jobTypeRef]) {
							result.supportWorkers[supportWorkerRef].jobTypes[jobTypeRef] = {
								totalMinutes: 0,
							}
						}
						result.supportWorkers[supportWorkerRef].jobTypes[jobTypeRef].totalMinutes += durationMins
					}
				}
			}
		})

		return result
	},
)

export const currentSupportWorkerUpdateSelector = (state: RootStoreState) => {
	const preferences = state.booking.supportWorkerPreference
	return Object.keys(preferences).find(key => preferences[key] === 1)
}

export const availableSupportWorkerViews = (state: RootStoreState, supportWorkerRefs: DeepReadonlyArray<string>): DeepReadonly<t.TimesheetSupportWorkerView>[] => {
	const swViews: DeepReadonly<t.TimesheetSupportWorkerView>[] = []

	supportWorkerRefs.forEach((ref) => {
		const supportWorker = state.supportWorkers.refs.supportWorkers[ref]

		if (supportWorker) {
			swViews.push({
				supportWorkerRef: ref,
				supportWorkerName: supportWorker.fullName,
			})
		}
	})

	return swViews
}
