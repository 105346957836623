import { reducerWithInitialState } from 'typescript-fsa-reducers'
import * as a from './actions'
import * as t from './types'
import { login } from 'modules/auth/actions'

export type StoreState = DeepReadonly<MutableStoreState>

export interface MutableStoreState {
	yourDetails: t.YourDetails
	registrationToken?: string
	completeRegistrationProcessing: boolean
	prepareRegistrationProcessing: boolean
}

/**
 * The initial store state for this module.
 */
const INITIAL_STATE: MutableStoreState = {
	yourDetails: t.INITIAL_YOUR_DETAILS,
	completeRegistrationProcessing: false,
	prepareRegistrationProcessing: false,
}

/**
 * Reducer function for this module.
 */
export const reducer = reducerWithInitialState(INITIAL_STATE)

reducer.case(a.updateYourDetailsStore, (state, payload) => {
	return {
		...state,
		yourDetails: payload,
	}
})

reducer.case(a.updateEmailStore, (state, payload) => ({
	...state, yourDetails: {
		...state.yourDetails, email: payload,
	},
}))

reducer.case(a.checkRegistrationProcess, (state, payload) => {
	const result = {
		...state,
		registrationToken: payload.token,
	}
	return result
})

reducer.case(a.completeRegistrationAsync.started, (state, payload) => {
	const result = {
		...state,
		completeRegistrationProcessing: true,
	}
	return result
})

reducer.case(a.completeRegistrationAsync.done, (state, payload) => {
	const result = {
		...state,
		completeRegistrationProcessing: false,
	}
	return result
})

reducer.case(a.completeRegistrationAsync.failed, (state, payload) => {
	const result = {
		...state,
		completeRegistrationProcessing: false,
	}
	return result
})

reducer.case(a.prepareRegistrationAsync.failed, (state, payload) => {
	const result = {
		...state,
		prepareRegistrationProcessing: false,
	}
	return result
})

reducer.case(a.prepareRegistrationAsync.started, (state, payload) => {
	const result = {
		...state,
		prepareRegistrationProcessing: true,
	}
	return result
})

reducer.case(a.prepareRegistrationAsync.done, (state, payload) => {
	const result = {
		...state,
		prepareRegistrationProcessing: false,
	}
	return result
})

reducer.cases([login.started], (): StoreState => INITIAL_STATE)
