import Component from '../components/StartRegistration'

import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'

/* Import module actions */
import * as actions from '../actions'

/** Interface for properties that the container passes to the component. */
export interface Props {
	processing: boolean
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
	onPrepareRegistration: (email: string) => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState): Props => {
	return {
		processing: state.register.prepareRegistrationProcessing,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	onPrepareRegistration: (email) => {
		dispatch(actions.prepareRegistration({ email }))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
