import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { Props } from '../containers/MyCommunity'

import { Helmet } from 'react-helmet'

import imgSrc from 'modules/frontend/img/community/elderly-woman-with-support-worker.jpg'
import imgSrc2x from 'modules/frontend/img/community/elderly-woman-with-support-worker@2x.jpg'

export default class MyCommunity extends React.Component<Props & RouteComponentProps<undefined>> {

	public render() {
		const srcSet = imgSrc2x + ' 1450w, ' + imgSrc + ' 725w'
		
		return (
			<section className="body">
				<Helmet>
					<title>My Community — My Homecare</title>
				</Helmet>
								
				<h1 className="headline-text">My Community</h1>
				
				<figure className="media-asset">
					<div className="media -image">
						<div className="aspect" style={{ paddingTop: '66.6206897%' }}>
							<img src={imgSrc} srcSet={srcSet} alt="" className="lazyload" />
						</div>
					</div>
				</figure>
				
				<div className="body-text">
					<p>We do not live in isolation: all of us are part of a community whether we choose to engage or not. It is in the nature of us to commune with each other, and New Zealanders have a long history of successful public good volunteerism – the bedrock of any community. In general, communities comprise residential, business, educational, spiritual, sporting and social actors and activities. A community is often the main determinant of the quality of its local environment. </p>
					<p>There is clear international evidence that a vibrant, inclusive and engaged community yields better health and environmental outcomes for all the residents, businesses, schools, etc., within it, i.e. they are more sustainable. You and your community can be a positive influence for a better future. </p>
					<p>My Community, as part of the My Homecare website, is designed to evolve into a vibrant forum for our clients to interact with each other, and with other organisations who help promote community involvement for people of all ages and abilities. The information in this section introduces you to ideas on what you can do and what is available to assist you. All of the best environmental and social ideas including change begins in a community.</p>
					<p>In this section you will find a selection of websites that might be of interest to you. Use the menu on the left to browse.</p>
				</div>
				
			</section>
		)
	}
}
