import * as React from 'react'
import { Link } from 'react-router-dom'
import { Props, Actions } from '../containers/StartRegistration'
import { RouteComponentProps } from 'react-router'

/**
 * Interface for private internal component state.
 */
interface State {
	email: string
}

/**
 * The initial state for our internal component state.
 */
const INITIAL_STATE: State = {
	email: '',
}

export default class StartRegistration extends React.Component<Props & Actions & RouteComponentProps<undefined>, State> {
	
	public state = INITIAL_STATE
	
	public render() {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const registrationFailed = this.props.location.state && (this.props.location.state as any).registrationError
		let bannerMessage: string | undefined = undefined
		let bannerDescription: string | undefined = undefined
		if (registrationFailed === 'invalid') {
			bannerMessage = 'Registration token invalid'
			bannerDescription = 'This registration token has expired, already been used, or is invalid. Please re-enter your email below and we’ll send you a new one.'
		} else if (registrationFailed === 'failed') {
			bannerMessage = 'Registration failed'
			bannerDescription = 'Something went wrong. Please try again or contact support.'
		}

		return (
			<article className="site-body">
				{bannerMessage && (
					<div className="banner-container -failed">
						<div className="width-limit -site">
							<div className="banner">
								<div className="body-text">
									<h1>{bannerMessage}</h1>
									<p>{bannerDescription}</p>
								</div>
							</div>
						</div>
					</div>
				)}
				
				<div className="width-limit -site">
					<div className="single-form">
			
						<form className="form" onSubmit={this.prepareRegistration}>
							<div className="form-layout">
								<div className="introduction">
									<h1 className="headline-text">Let’s get started!</h1>
									<div className="body-text">
										<p>Enter your email address below and we‘ll send you a link to begin the registration process.</p>
									</div>
								</div>

								<div className="row">
									<div className="form-field">
										<label className="label">Email address</label>
										<div className="form-input">
											<div className="form-input -text">
												<input type="email" className="field" value={this.state.email} onChange={this.handleEmailChange}/>
											</div>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="button-group">
										<div className="button">
											<Link to="/sign-in" className="button-link -text">Cancel</Link>
										</div>
										<div className="button -constrained">
											<div className={'button-link -action -nested' + (this.props.processing ? ' -processing' : '')}>
												<input type="submit" value="Submit" />
											</div>
										</div>
									</div>
								</div>
							</div>
				
						</form>
						
					</div>
				</div>
			</article>
		)
	}
	
	private handleEmailChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ email: evt.target.value })
	}
	
	private prepareRegistration = (evt: React.FormEvent) => {
		evt.preventDefault()
		this.props.onPrepareRegistration(this.state.email)
	}
}
