/* Import the component from the component path */
import Component from '../components/NewBooking'
import { Api } from 'typescript-fetch-api'
import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'

/* Import module actions */
import * as actions from '../actions'
import * as t from '../types'
import * as calendarTypes from 'modules/calendar/types'
import * as calendarSelectors from 'modules/calendar/selectors'
import { push } from 'connected-react-router'
import { featuresSelector } from 'modules/common/selectors'

/** Interface for properties that are passed to the container/ */
export interface OwnProps {
	initialDate?: string
}

/** Interface for properties that the container passes to the component. */
export interface Props {
	settings: DeepReadonly<t.BookingSettings>
	bookings: DeepReadonly<t.BookingLine[]>
	funding?: calendarTypes.Funding
	jobTypes?: DeepReadonlyArray<Api.JobType>
	defaultedFundingType: boolean
	privateJobTypes?: DeepReadonlyArray<Api.JobType>
	findSupportWorkers: boolean
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
	onAddBookingLine: () => void
	onRemoveBookingLine: (bookingLine: DeepReadonly<t.BookingLine>) => void
	onBookingLineChange: (original: DeepReadonly<t.BookingLine>, updated: DeepReadonly<t.BookingLine>) => void
	onSettingsChange: (settings: DeepReadonly<t.BookingSettings>) => void
	onCancel: () => void
	onContinueToSummary: () => void
	onContinue: () => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	const funding = calendarSelectors.currentFunding(state)
	const settings = { ...state.booking.booking.settings }
	let defaultedFundingType = false

	/** Initialise the funding type for the form based on whether the client has funding or not. */
	if (settings.fundingType === undefined) {
		if (funding && funding.hasFunding) {
			settings.fundingType = Api.NewAppointment.FundingTypeEnum.Funded
		} else {
			settings.fundingType = Api.NewAppointment.FundingTypeEnum.Private
		}
		defaultedFundingType = true
	}

	return {
		settings,
		bookings: state.booking.booking.bookings,
		funding,
		jobTypes: state.common.settings ? state.common.settings.jobTypes : undefined,
		defaultedFundingType,
		privateJobTypes: state.common.settings ? state.common.settings.privateJobTypes : undefined,
		findSupportWorkers: featuresSelector(state).findSupportWorkers,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	onAddBookingLine: () => {
		dispatch(actions.addBookingLine())
	},
	onRemoveBookingLine: (bookingLine) => {
		dispatch(actions.removeBookingLine(bookingLine))
	},
	onBookingLineChange: (original, updated) => {
		dispatch(actions.updateBookingLine({ original, updated }))
	},
	onSettingsChange: (settings) => {
		dispatch(actions.updateBookingSettings(settings))
	},
	onCancel: () => {
		dispatch(actions.cancelBooking(undefined))
	},
	onContinueToSummary: () => {
		dispatch(push('/calendar/new/summary'))
	},
	onContinue: () => {
		dispatch(push('/calendar/new/support-workers'))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)