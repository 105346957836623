import actionCreatorFactory from 'typescript-fsa'
import { Api } from 'typescript-fetch-api'
import { SearchForm } from './components/AllSupportWorkersSearch'

/**
 * The action creator for this module. Note that it contains the module name.
 */
const actionCreator = actionCreatorFactory('SupportWorkers')

export const search = actionCreator<SearchForm>('SEARCH')
export type SearchAction = ReturnType<typeof search>
export const searching = actionCreator.async<Api.PostSupportWorkersSearchRequest, Api.PostSupportWorkersSearchResponse, Error>('SEARCH')

export const searchNextPage = actionCreator('SEARCH_NEXT_PAGE')

export const searchFavouriteWorkers = actionCreator('SEARCH_FAVORITES')
export const searchBlockedWorkers = actionCreator('SEARCH_BLOCKED')

export const searchingFavouriteWorkers = actionCreator.async<string, Api.PostSupportWorkersSearchResponse, Error>('SEARCH_FAVORITES')
export const searchingBlockedWorkers = actionCreator.async<string, Api.PostSupportWorkersSearchResponse, Error>('SEARCH_BLOCKED')

export const searchInitial = actionCreator('SEARCH_INITIAL')

type SupportWorkerRef = string

export const createFavourite = actionCreator<SupportWorkerRef>('CREATE_FAVOURITE')
export const creatingFavourite = actionCreator.async<SupportWorkerRef, Response, Error>('CREATE_FAVOURITE')
export type CreateFavouriteAction = ReturnType<typeof createFavourite>
export type CreatingFavouriteDoneAction = ReturnType<typeof creatingFavourite.done>

export const deleteFavourite = actionCreator<SupportWorkerRef>('DELETE_FAVOURITE')
export const deletingFavourite = actionCreator.async<SupportWorkerRef, Response, Error>('DELETE_FAVOURITE')
export type DeleteFavouriteAction = ReturnType<typeof deleteFavourite>
export type DeletingFavouriteDoneAction = ReturnType<typeof deletingFavourite.done>

export const createBlock = actionCreator<SupportWorkerRef>('CREATE_BLOCK')
export const creatingBlock = actionCreator.async<SupportWorkerRef, Response, Error>('CREATE_BLOCK')
export type CreateBlockAction = ReturnType<typeof createBlock>
export type CreatingBlockDoneAction = ReturnType<typeof creatingBlock.done>

export const deleteBlock = actionCreator<SupportWorkerRef>('DELETE_BLOCK')
export const deletingBlock = actionCreator.async<SupportWorkerRef, Response, Error>('DELETE_BLOCK')
export type DeleteBlockAction = ReturnType<typeof deleteBlock>
export type DeletingBlockDoneAction = ReturnType<typeof deletingBlock.done>

export const viewDetail = actionCreator<SupportWorkerRef>('VIEW_DETAIL')
export type ViewDetailAction = ReturnType<typeof viewDetail>

export interface AddFeedbackPayload {
	supportWorkerRef: string
	request: {
		feedback: string
	}
}

export const addFeedback = actionCreator<AddFeedbackPayload>('ADD_SUPPORT_WORKER_FEEDBACK')
export type AddFeedbackAction = ReturnType<typeof addFeedback>
export const addFeedbackAsync = actionCreator.async<AddFeedbackPayload, never, Api.PostSupportWorkerFeedbackError>('ADD_SUPPORT_WORKER_FEEDBACK')
