import * as React from 'react'
import { OwnProps, Props, Actions } from '../containers/UpdateEmailScreen'
import { Link } from 'react-router-dom'

import Header from 'modules/common/containers/Header'
import Footer from 'modules/common/components/Footer'

interface State {
	newEmail: string
}

const INITIAL_STATE: State = {
	newEmail: '',
}

export default class SetPassword extends React.Component<OwnProps & Props & Actions, State> {

	public state = INITIAL_STATE
	
	handleEmailChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ newEmail: evt.target.value })
	}

	handleUpdateEmail = () => {
		this.props.onSaveEmail(this.props.clientRef, this.state.newEmail)
	}

	public render() {
		return (
			<div className="site-content register-screen">
				<Header section="register" />
				<article className="site-body">
					<div className="width-limit -site">
						<form className="form register-form">
						
							<div className="form-layout">
								<div className="introduction">
									<h1 className="headline-text">Update your email address</h1>
									<div className="body-text">
										<p>
											Please confirm your updated email address below. We will send an email to your new address with a link 
											to complete the verification. Until then, please continue to sign in with your old email address.
										</p>
									</div>
								</div>

								<div className="row">
									<div className="form-field">
										<label className="label">Current email address</label>
										<div className="form-input">
											<div className="form-input -text">
												<input type="text" className="field" value={this.props.email} disabled={true}/>
											</div>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="form-field">
										<label className="label">New email address</label>
										<div className="form-input">
											<div className="form-input -text">
												<input type="text" className="field" onChange={this.handleEmailChange} value={this.state.newEmail}/>
											</div>
										</div>
									</div>
								</div>

								<div className="row">
									<div className="button-group">
										<div className="button">
											<Link to="/account/details" className="button-link -text">Back</Link>
										</div>
										<div className="button -constrained">
											<div className={'button-link -action' + (this.state.newEmail === '' ? ' -disabled' : '')} onClick={this.handleUpdateEmail}>Update</div>
										</div>
									</div>
								</div>
							</div>
							
						</form>
					</div>
				</article>
				<Footer />
			</div>
		)
	}
}
