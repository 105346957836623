import { LocalTime, DayOfWeek } from '@js-joda/core'
import { RootStoreState } from 'modules/root'

import moment from 'moment'
import { Api } from 'typescript-fetch-api'
import * as t from './types'
import * as calTypes from 'modules/calendar/types'
import * as _ from 'lodash'
import * as sws from 'modules/support-workers/selectors'
import platform, { Brand } from 'modules/platform'

const currentBrand = platform.brand()

/**
 * Returns an array of LocalTimes from startTime to endTime inclusive
 * @param startTime 
 * @param endTime 
 * @param step The number of minutes to step each time.
 */
export function times(startTime: LocalTime, endTime: LocalTime, step: number): LocalTime[] {
	const result: LocalTime[] = []
	let it = startTime
	let prev = startTime /* as LocalTime can wrap past 23:59 back to 00:00 */
	while (!it.isAfter(endTime) && !it.isBefore(prev)) {
		prev = it
		result.push(it)
		it = it.plusMinutes(step)
	}
	return result
}

export function bookingTimes(amendBookingCustomTime?: string): LocalTime[] {
	const bookingTimeOptions: LocalTime[] = times(LocalTime.parse('06:00'), LocalTime.parse('20:00'), 30)

	if (amendBookingCustomTime) {
		const customTime = LocalTime.parse(amendBookingCustomTime)
		if (bookingTimeOptions.indexOf(customTime) < 0) {
			bookingTimeOptions.push(customTime)
		}
	}

	return bookingTimeOptions
}

export function bookingTimes24hrs(): LocalTime[] {
	return times(LocalTime.parse('00:00'), LocalTime.parse('23:59'), 30)
}

export interface Duration {
	minutes: number
	toText: string
}

export function durations(startMinutes: number, endMinutes: number, amendBookingCustomDuration?: number): Duration[] {
	let result: Duration[] = []
	let minutes = startMinutes

	while (minutes <= endMinutes) {
		let formattedTime = ''

		if (minutes === 30) {
			formattedTime = '30 minutes'
		} else if (minutes === 60) {
			formattedTime = '1 hour'
		} else {
			formattedTime = minutes / 60 + ' hours'
		}

		const durationItem: Duration = {
			minutes: minutes,
			toText: formattedTime,
		}
		result.push(durationItem)
		minutes += 30
	}

	if (amendBookingCustomDuration) {
		if (result.findIndex(x => x.minutes === amendBookingCustomDuration) === -1) {
			let formattedTime = ''

			if (amendBookingCustomDuration === 30) {
				formattedTime = '30 minutes'
			} else if (amendBookingCustomDuration === 60) {
				formattedTime = '1 hour'
			} else if (amendBookingCustomDuration < 60) {
				formattedTime = amendBookingCustomDuration + ' minutes'
			} else {
				formattedTime = amendBookingCustomDuration / 60 + ' hours'
			}
			
			const durationItem: Duration = {
				minutes: amendBookingCustomDuration,
				toText: formattedTime,
			}
			result.push(durationItem)
		}
	}

	result = _.orderBy(result, ['minutes'], ['asc'])
	
	return result
}

export function durationText(minutes: number): string {
	let formattedTime = ''

	if (minutes === 30) {
		formattedTime = '30 minutes'
	} else if (minutes === 60) {
		formattedTime = '1 hour'
	} else if (minutes < 60) {
		formattedTime = minutes + ' minutes'
	} else {
		formattedTime = minutes / 60 + ' hours'
	}

	return formattedTime
}

export function startText(booking: DeepReadonly<t.BookingLine>): string {
	let formattedStartText = ''

	if (booking.startTimeOption === Api.NewAppointment.StartTimeOptionEnum.Flexible) {
		formattedStartText = 'any time'
	} else if (booking.startTimeOption === Api.NewAppointment.StartTimeOptionEnum.Morning) {
		formattedStartText = 'morning'
	} else if (booking.startTimeOption === Api.NewAppointment.StartTimeOptionEnum.Afternoon) {
		formattedStartText = 'afternoon'
	} else {
		formattedStartText = moment(booking.startTime!.toString(), ['hh:mm']).format('h:mma')
	}

	return formattedStartText
}

export function startEditText(booking: DeepReadonly<calTypes.JobDetailView>): string {
	let formattedStartText = ''

	if (booking.startTimeOption === Api.NewAppointment.StartTimeOptionEnum.Flexible) {
		formattedStartText = 'any time'
	} else if (booking.startTimeOption === Api.NewAppointment.StartTimeOptionEnum.Morning) {
		formattedStartText = 'morning'
	} else if (booking.startTimeOption === Api.NewAppointment.StartTimeOptionEnum.Afternoon) {
		formattedStartText = 'afternoon'
	} else {
		formattedStartText = moment(booking.startTime!.toString(), ['hh:mm']).format('h:mma')
	}

	return formattedStartText
}

export function isSelectableBookingStartDate(date: moment.Moment): boolean {
	if (currentBrand === Brand.Suemann) {
		const now = moment()
		const isBefore4 = now.isBefore(moment({ hour: 16 }))
		const diff = date.diff(now, 'days')

		return isBefore4 ? diff >= 1 : diff >= 2
	}
	
	return isSelectableStartDate(date)
}

export function isSelectableStartDate(date: moment.Moment): boolean {
	const yesterday = moment(new Date()).subtract(1, 'day')
	return date.isAfter(yesterday)
}

export function showStartEndDate(bookings: DeepReadonly<t.BookingLine[]>): boolean {
	let result = false

	bookings.forEach((booking) => {
		if (booking.repeat !== Api.NewAppointment.RepeatOptionEnum.None) {
			result = true
		}
	})

	return result
}

/** Convert our day of week to a joda one. */
function dayOfWeekToJoda(dayOfWeek: t.DayOfWeekEnum): DayOfWeek {
	switch (dayOfWeek) {
		case t.DayOfWeekEnum.Monday: return DayOfWeek.MONDAY
		case t.DayOfWeekEnum.Tuesday: return DayOfWeek.TUESDAY
		case t.DayOfWeekEnum.Wednesday: return DayOfWeek.WEDNESDAY
		case t.DayOfWeekEnum.Thursday: return DayOfWeek.THURSDAY
		case t.DayOfWeekEnum.Friday: return DayOfWeek.FRIDAY
		case t.DayOfWeekEnum.Saturday: return DayOfWeek.SATURDAY
		case t.DayOfWeekEnum.Sunday: return DayOfWeek.SUNDAY
		default: throw new Error('Invalid day of week: ' + dayOfWeek)
	}
}

/** Calculate the start date to send to the API for a given booking line. */
export function startDateForBookingLine(line: DeepReadonly<t.BookingLine>, settings: DeepReadonly<t.BookingSettings>): string | undefined {
	if (line.date) {
		return line.date.toString()
	}

	if (!settings.startDate || !line.dayOfWeek) {
		return undefined
	}

	let result = settings.startDate
	const targetDayOfWeek = dayOfWeekToJoda(line.dayOfWeek)
	while (targetDayOfWeek !== result.dayOfWeek()) {
		result = result.plusDays(1)
	}
	return result.toString()
}

export function selectedSupportWorkers(state: RootStoreState, preference: t.BookingSupportWorkersPreference): DeepReadonlyObject<Api.SupportWorker>[] {
	const allRefs = _.keys(preference)
	const selectedRefs = allRefs.sort((ref1, ref2) => {
		if (preference[ref1] > preference[ref2]) {
			return 1
		} else if (preference[ref1] < preference[ref2]) {
			return -1
		} else {
			return 0
		}
	}).filter((ref) => {
		return preference[ref] < 5
	})
	const selectedWorkers: DeepReadonlyObject<Api.SupportWorker>[] = []
	selectedRefs.forEach((ref) => {
		const sw = sws.supportWorkerForRef(state, ref)
		if (sw) {
			selectedWorkers.push(sw)
		}
	})

	return selectedWorkers
}