import Component from '../components/JoinSupportNetwork'

import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'

/* Import module actions */
import * as actions from '../actions'
import * as accountActions from 'modules/account/actions'
import * as t from '../types'
import * as s from '../selectors'

/** Interface for properties that are passed to the container/ */
export interface OwnProps {
}

/** Interface for properties that the container passes to the component. */
export interface Props {
	supportNetworkInvites?: t.InvitationView[]
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
	declineInvite: (request: accountActions.RevokeSupportNetworkInvitePayload) => void
	acceptSupportNetworkInvite: (request: string) => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	return {
		supportNetworkInvites: s.currentUserInvitationsViews(state),
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	declineInvite: (request) => {
		dispatch(accountActions.declineSupportNetworkInvite(request))
	},
	acceptSupportNetworkInvite: (request) => {
		dispatch(actions.acceptSupportNetworkInvite(request))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
