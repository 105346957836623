import bs from 'binary-search'
import { LocalDate, DayOfWeek, TemporalAdjusters, LocalDateTime, LocalTime } from '@js-joda/core'
import { History } from 'history'
import { Api } from 'typescript-fetch-api'
import * as _ from 'lodash'
import * as t from './types'
import { StoreState } from './reducer'
import { jobTypesSelector } from 'modules/common/selectors'
import { RootStoreState } from 'modules/root'
import { getJobType } from 'modules/common/functions'
import { dontScrollToTopLocation } from 'modules/routing'

/** Return a CalendarView object containing the information required to render the calendar given
 * a date in the month that we want to render the calendar for.
 */
export function calendarView(date: LocalDate, state: RootStoreState, first?: LocalDate, last?: LocalDate, filter?: (appointment: DeepReadonly<Api.Appointment>) => boolean): t.CalendarView {
	const weeks: t.WeekView[] = []

	if (!first) {
		first = firstDateForCalendar(date)
	}
	if (!last) {
		last = lastDateForCalendar(date)
	}

	const today = LocalDate.now()

	let current = first
	let currentWeek: t.WeekView = { days: [], totalMinutes: 0, thisWeek: thisWeek(current, today) }
	while (current.isBefore(last)) {
		if (current.dayOfWeek() === DayOfWeek.MONDAY) {
			currentWeek = { days: [], totalMinutes: 0, thisWeek: thisWeek(current, today) }
			weeks.push(currentWeek)
		}

		const day: t.DayView = {
			date: current,
			otherMonth: current.month() !== date.month(),
			jobs: [],
			totalMinutes: 0,
			today: current.equals(today),
		}

		populateDayView(day, state.calendar, filter)

		currentWeek.totalMinutes += day.totalMinutes
		currentWeek.days.push(day)
		current = current.plusDays(1)
	}

	const previousMonth = firstOfMonth(date).minusMonths(1)
	const nextMonth = firstOfMonth(date).plusMonths(1)
	const range = appointmentDateRange(state)

	let canPreviousMonth = false, canNextMonth = false

	if (range) {
		const [earliestDate, latestDate] = range
		canPreviousMonth = !previousMonth.isBefore(firstOfMonth(earliestDate))
		canNextMonth = !nextMonth.isAfter(firstOfMonth(latestDate))
	}

	return {
		date: firstOfMonth(date),
		originalDate: date,
		weeks,
		previousMonth,
		nextMonth,
		canPreviousMonth,
		canNextMonth,
	}
}

/**
 * Appointment dates between which are all the client's existing appointments 
 */
function appointmentDateRange(state: RootStoreState, filter?: (appointment: DeepReadonly<Api.Appointment>) => boolean): [LocalDate, LocalDate] | undefined {
	let appointments = _.values(state.calendar.refs.appointments).sort((a, b) => LocalDate.parse(a.date).compareTo(LocalDate.parse(b.date)))

	if (filter) {
		appointments = appointments.filter(filter)
	}

	if (appointments.length === 0) {
		return undefined
	}

	const firstAppointment = LocalDate.parse(appointments[0].date)
	const lastAppointment = LocalDate.parse(appointments[appointments.length - 1].date)

	return [firstAppointment, lastAppointment]
}

function thisWeek(startOfWeek: LocalDate, today: LocalDate): boolean {
	return startOfWeek.compareTo(today) <= 0 && startOfWeek.plusDays(7).compareTo(today) > 0
}

export function startDateTime(appointment: DeepReadonly<Api.Appointment | Api.AppointmentSummary>): LocalDateTime {
	const date = LocalDate.parse(appointment.date)
	if (appointment.startTime) {
		return date.atTime(LocalTime.parse(appointment.startTime))
	} else {
		return date.atStartOfDay()
	}
}

/** Return a JobDetailView object containing information required to render the job given. */
export function jobDetailView(jobRef: string, date: LocalDate, state: RootStoreState): t.JobDetailView | undefined {
	const job = state.calendar.refs.appointments[jobRef]
	if (!job) {
		return undefined
	}

	const sw = job.supportWorkerRefs && job.supportWorkerRefs.length > 0 ? state.calendar.refs.supportWorkers[job.supportWorkerRefs[0]] : undefined

	const startTime = job.startTime ? LocalTime.parse(job.startTime) : undefined
	const actualStartTime = job.actualStartTime ? LocalTime.parse(job.actualStartTime) : undefined
	const allJobTypes = jobTypesSelector(state)

	return {
		actualDurationMins: job.actualDurationMins,
		actualStartTime: actualStartTime,
		date: date,
		startTime,
		startTimeOption: job.startTimeOption,
		endTime: startTime && job.durationMins ? startTime.plusMinutes(job.durationMins) : undefined,
		durationMins: job.durationMins,
		durationOption: job.durationOption,
		supportWorkerRef: sw ? sw.ref : undefined,
		repeatStartDate: job.repeatStartDate ? LocalDate.parse(job.repeatStartDate) : undefined,
		repeatEndDate: job.repeatEndDate ? LocalDate.parse(job.repeatEndDate) : undefined,
		repeatOption: job.repeatOption,
		repeatOptionDisplayName: repeatOptionDisplayNameJob(job.repeatOption, job.repeatFrequencyInWeeks),
		repeatFrequencyInWeeks: job.repeatFrequencyInWeeks,
		supportWorker: sw ? supportWorkerDetailView(sw) : undefined,
		status: jobViewStatus(job),
		ref: job.ref,
		services: job.jobTypeRefs && allJobTypes && job.jobTypeRefs.map(jobTypeRef => getJobType(allJobTypes, jobTypeRef)).filter(r => r !== undefined) as Api.JobType[],
		timesheetRef: job.timesheetRef,
	}
}

export function repeatOptionDisplayNameJobRequest(repeatOption: Api.Appointment.RepeatOptionEnum): string {
	switch (repeatOption) {
		case Api.Appointment.RepeatOptionEnum.Daily: return 'daily'
		case Api.Appointment.RepeatOptionEnum.Fortnightly: return 'fortnightly'
		case Api.Appointment.RepeatOptionEnum.MondayToFriday: return 'Monday to Friday'
		case Api.Appointment.RepeatOptionEnum.Weekly: return 'weekly'
		case Api.Appointment.RepeatOptionEnum.None: return 'none'
		default: throw new Error(`Unsupported repeat option: ${repeatOption}`)
	}
}

function repeatOptionDisplayNameJob(repeatOption: Api.Appointment.RepeatOptionEnum, repeatFrequencyInWeeks: number | undefined): string | undefined {
	switch (repeatOption) {
		case Api.Appointment.RepeatOptionEnum.None: return undefined
		case Api.Appointment.RepeatOptionEnum.Daily: return 'daily'
		case Api.Appointment.RepeatOptionEnum.Fortnightly: return 'fortnightly'
		case Api.Appointment.RepeatOptionEnum.MondayToFriday: return 'Monday to Friday'
		case Api.Appointment.RepeatOptionEnum.Weekly: return 'weekly'
		case Api.Appointment.RepeatOptionEnum.Other:
			if (repeatFrequencyInWeeks) {
				return `every ${repeatFrequencyInWeeks} weeks`
			} else {
				throw new Error('Repeat option is "Other", but no repeatFrequencyInWeeks was provided')
			}
		default:
			throw new Error(`Unsupported repeat option: ${repeatOption}`)
	}
}

/** Returns a LocationDescriptor for a Link tag to link to a given CalendarView */
export function calendarLocation(view: t.CalendarView): History.LocationDescriptor {
	return `/calendar/date/${view.originalDate}`
}

/** Returns a LocationDescriptor for a Link tag to link to a given JobView */
export function jobLocation(view: t.JobView, calendar: t.CalendarView): History.LocationDescriptor {
	return dontScrollToTopLocation(`/calendar/date/${calendar.originalDate}/appt/${view.date}/${view.ref}`)
}

/** Return a string representing the given duration in minutes */
export function formatDuration(minutes: number, compact = false, leftPadMinutes = false): string {
	const hoursPart = Math.floor(minutes / 60)
	const minutesPart = minutes % 60
	const hourLabel = compact ? 'h' : ' hour'
	const hoursLabel = compact ? 'h' : ' hours'
	const minuteLabel = compact ? 'm' : ' minute'
	const minutesLabel = compact ? 'm' : ' minutes'
	const parts: string[] = []
	if (hoursPart === 1) {
		parts.push(`1${hourLabel}`)
	} else if (hoursPart > 1) {
		parts.push(`${hoursPart}${hoursLabel}`)
	}

	if (minutesPart > 0 || hoursPart === 0) {
		let paddedMinutes = minutesPart.toString()

		if (leftPadMinutes) {
			if (hoursPart === 0 && minutesPart === 0) {
				parts.push('—')
			} else {
				while (paddedMinutes.length < 2) {
					paddedMinutes = '0' + paddedMinutes
				}
				parts.push('   ' + paddedMinutes + 'm')
			}
		} else {
			if (minutesPart === 1) {
				parts.push(`1${minuteLabel}`)
			} else if (minutesPart > 1 || hoursPart === 0) {
				parts.push(`${minutesPart}${minutesLabel}`)
			}
		}
	}

	return parts.join(' ')
}

// function repeatOptionToFrequencyInWeeks(option: Api.RequestedJobDetails.RepeatOptionEnum): number | undefined {
// 	switch (option) {
// 		case Api.RequestedJobDetails.RepeatOptionEnum.None:
// 		case Api.RequestedJobDetails.RepeatOptionEnum.Daily:
// 		case Api.RequestedJobDetails.RepeatOptionEnum.MondayToFriday:
// 			return undefined
// 		case Api.RequestedJobDetails.RepeatOptionEnum.Weekly:
// 			return 1
// 		case Api.RequestedJobDetails.RepeatOptionEnum.Fortnightly:
// 			return 2
// 		default:
// 			return undefined
// 	}
// }

export function jobViewStatus(appt: DeepReadonly<Api.Appointment>): t.JobViewStatus {
	if (appt.changesPending) {
		if (appt.status === Api.Appointment.StatusEnum.PendingCancellation) {
			return t.JobViewStatus.PendingCancellation
		}
		return t.JobViewStatus.Pending
	}

	switch (appt.status) {
		case Api.Appointment.StatusEnum.Allocated: return t.JobViewStatus.Active
		case Api.Appointment.StatusEnum.NotAllocated: return t.JobViewStatus.NotAllocated
		case Api.Appointment.StatusEnum.Completed: return t.JobViewStatus.Completed
		case Api.Appointment.StatusEnum.Cancelled: return t.JobViewStatus.Cancelled
		case Api.Appointment.StatusEnum.PendingCancellation: return t.JobViewStatus.PendingCancellation
		default: return t.JobViewStatus.Unknown
	}
}

export function supportWorkerView(sw: DeepReadonly<Api.SupportWorker>): t.SupportWorkerView {
	return {
		name: sw.knownAs || sw.givenName || sw.fullName,
		image: undefined,
		ref: sw.ref,
	}
}

function supportWorkerDetailView(sw: DeepReadonly<Api.SupportWorker>): t.SupportWorkerDetailView {
	return {
		name: sw.knownAs || sw.givenName || sw.fullName,
		fullName: sw.fullName,
		image: undefined,
		ref: sw.ref,
	}
}

/** Given a DayView, find the jobs that should be shown on that day and populate the DayView with them. */
function populateDayView(day: t.DayView, state: StoreState, filter: ((appointment: DeepReadonly<Api.Appointment>) => boolean) | undefined): void {
	const roster = state.roster.roster
	if (!roster) {
		return
	}

	const rosterDay = clientRosterDayForDate(day.date, roster)
	if (!rosterDay) {
		return
	}

	rosterDay.appointmentRefs.forEach(ref => {
		const job = state.refs.appointments[ref]
		if (!job) {
			return
		}
		if (filter && !filter(job)) {
			return
		}

		day.jobs.push(jobView(job, state.refs.supportWorkers))
		if (job.status !== Api.Appointment.StatusEnum.Cancelled && job.status !== Api.Appointment.StatusEnum.PendingCancellation) {
			day.totalMinutes += job.durationMins || 0
		}
	})
}

export function jobView(job: DeepReadonly<Api.Appointment>, supportWorkers: DeepReadonly<{ [ref: string]: Api.SupportWorker }>, date?: LocalDate): t.JobView {
	const sw = job.supportWorkerRefs && job.supportWorkerRefs.length > 0 ? supportWorkers[job.supportWorkerRefs[0]] : undefined

	const result: t.JobView = {
		date: date ? date : LocalDate.parse(job.date),
		startTime: job.startTime ? LocalTime.parse(job.startTime) : undefined,
		startTimeOption: job.startTimeOption,
		totalMinutes: job.durationMins ? job.durationMins : 0,
		supportWorker: sw ? supportWorkerView(sw) : undefined,
		status: jobViewStatus(job),
		ref: job.ref,
	}
	return result
}

function clientRosterDayForDate(date: LocalDate, roster: DeepReadonly<Api.ClientCalendar>): DeepReadonly<Api.ClientCalendarDay> | undefined {
	/* Comparator for finding jobs on a given day defined by the two dates */
	const comparator = (day: DeepReadonly<Api.ClientCalendarDay>, targetDate: LocalDate) => {
		return LocalDate.parse(day.date).compareTo(targetDate)
	}

	// /* Find a job in the given day */
	const found = bs(roster.days, date, comparator)
	if (found < 0) {
		return undefined
	}

	return roster.days[found]
}

function firstOfMonth(date: LocalDate): LocalDate {
	return date.withDayOfMonth(1)
}

function lastOfMonth(date: LocalDate): LocalDate {
	return firstOfMonth(date).plusMonths(1).minusDays(1)
}

/** The first date to include in the calendar layout for the given month,
 * identified by a date in the month.
 */
function firstDateForCalendar(date: LocalDate): LocalDate {
	return firstOfMonth(date).with(TemporalAdjusters.previousOrSame(DayOfWeek.MONDAY))
}

/** The date one-past the last day to include in the calendar layout for the given month,
 * identified by a date in the month.
 */
function lastDateForCalendar(date: LocalDate): LocalDate {
	const last = lastOfMonth(date)
	let monday = last.with(TemporalAdjusters.nextOrSame(DayOfWeek.MONDAY))
	if (!monday.isAfter(last)) {
		monday = monday.plusWeeks(1)
	}
	return monday
}
