import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { Props } from '../containers/DisabilityLinks'

import { Helmet } from 'react-helmet'

export default class DisabilityLinks extends React.Component<Props & RouteComponentProps<undefined>> {

	public render() {
		return (
			<section className="body">
				<Helmet>
					<title>Disability Links — My Homecare</title>
				</Helmet>
								
				<h1 className="headline-text">Disability Links</h1>
				
				<div className="body-text">
					<h2><a href="https://www.health.govt.nz/your-health/services-and-support/disability-services/more-information-disability-support/disability-organisations-and-websites" target="_blank" rel="noopener noreferrer">Disability Support</a></h2>
					<p>The following is a link to many disability-related organisations and service providers and some of the resources they produce, provided by the Ministry of Health</p>

					<h2><a href="http://disabilityconnect.org.nz/" target="_blank" rel="noopener noreferrer">Disability Connect</a></h2>
					<p>Disability Connect is a Disability Information Advisory Service. They are based in Penrose and operate throughout Auckland – north to Warkworth and south to Pukekohe.</p>
					<p>There are lots of ways they can help individuals with a disability, or family members of a disabled person, to navigate the New Zealand disability sector. </p>
				</div>
				
			</section>
		)
	}
}
