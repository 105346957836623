import * as React from 'react'
import { Props, Actions, OwnProps } from '../containers/JobDetail'
import { Api } from 'typescript-fetch-api'
import JobDetailModal from './JobDetailModal'
import CancelJobModal from './CancelJobModal'
import ChangeSupportWorkerModal from './ChangeSupportWorkerModal'
import AppointmentFeedbackModal from 'modules/dashboard/components/AppointmentFeedbackModal'
import { Switch, Route, RouteComponentProps } from 'react-router'
import { withModal } from 'modules/common/modal-hoc'

export default withModal(class JobDetailModalWrapper extends React.Component<Props & OwnProps & Actions & RouteComponentProps<{ date: string } & { jobRef: string }>> {
	
	onClose = () => {
		this.props.onClose()
	}

	onCancelChild = () => {
		this.props.history.push(this.props.match.url)
	}

	onAddFeedbackModalOpen = () => {
		this.props.history.push(`${this.props.match.url}/feedback`)
	}

	handleSubmitFeedback = (feedback: string) => {
		if (this.props.job) {
			this.props.addAppointmentFeedback(this.props.job.ref, feedback)
		}
	}

	onRosterChange = (request: Api.AppointmentChangeRequest) => {
		this.props.onRosterChange(request)
	}

	onChangeSupportWorker = () => {
		this.props.history.push(`${this.props.match.url}/support-worker`)
	}

	onCancelBooking = () => {
		this.props.history.push(`${this.props.match.url}/cancel`)
	}

	handleViewDetail = (supportWorkerRef: string) => {
		this.props.onViewDetail(supportWorkerRef)
	}

	handleEditBooking = () => {
		if (this.props.job) {
			this.props.onEditBooking({
				calDate: this.props.calDate,
				effectiveDate: this.props.job.date,
				job: this.props.job,
			})
		}
	}
		
	public render() {
		const { job, findSupportWorkers } = this.props
		if (!job) {
			return null
		}

		return (
			<div className="bookinginfo">
				<Switch>
					<Route
						path={`${this.props.match.url}`} 
						exact={true} 
						render={props => (
							<JobDetailModal 
								job={job} 
								capabilities={this.props.capabilities}
								calDate={this.props.calDate}
								onEditBooking={this.handleEditBooking}
								onChangeSupportWorker={this.onChangeSupportWorker}
								onCancelBooking={this.onCancelBooking}
								onViewDetail={this.handleViewDetail}
								onAddFeedback={this.onAddFeedbackModalOpen}
								onClose={this.onClose}
								findSupportWorkers={findSupportWorkers}
							/>
						)}
					/>
					<Route
						path={`${this.props.match.url}/cancel`} 
						exact={true} 
						render={props => (
							<CancelJobModal job={job} onCancel={this.onCancelChild} onClose={this.onClose} onCancelBooking={this.onRosterChange}/>
						)}
					/>
					<Route
						path={`${this.props.match.url}/support-worker`} 
						exact={true} 
						render={props => (
							<ChangeSupportWorkerModal job={job} onCancel={this.onCancelChild} />
						)}
					/>
					<Route
						path={`${this.props.match.url}/feedback`} 
						exact={true} 
						render={props => (
							<AppointmentFeedbackModal supportWorkerName={job.supportWorker && job.supportWorker.name} submitFeedback={this.handleSubmitFeedback} onClose={this.onClose}/>
						)}
					/>
				</Switch>
			</div>
		)
	}
})
