import * as React from 'react'
import { RouteComponentProps, Switch, Route, Redirect } from 'react-router'
import { Props } from '../containers/CommunityScreen'

import Header from 'modules/common/containers/Header'
import Footer from 'modules/common/components/Footer'
import Debug from 'modules/common/components/Debug'
import MyCommunity from 'modules/community/components/MyCommunity'
import GeneralLinks from 'modules/community/components/GeneralLinks'
import RegionalLinks from 'modules/community/components/RegionalLinks'
import OlderPersonsLinks from 'modules/community/components/OlderPersonsLinks'
import DisabilityLinks from 'modules/community/components/DisabilityLinks'
import MyNavLink from 'modules/routing/components/MyNavLink'
import { Helmet } from 'react-helmet'

export default class CommunityScreen extends React.Component<Props & RouteComponentProps<undefined>> {

	public render() {
		return (
			<div className="site-content dashboard-screen">
				<Header section="dashboard" />
				<Helmet>
					<title>My Community — My Homecare</title>
				</Helmet>
				<article className="site-body">
	
					<div className="width-limit -site">
						<div className="content-layout">
							<Switch>
								<Route 
									path="/my-community" 
									exact={true} 
									render={props => {
										return (
											<Redirect to="/my-community/about" />
										)
									}}
								/>
								<Route path="/my-community/about" exact={true} component={MyCommunity} />
								<Route path="/my-community/general-links" exact={true} component={GeneralLinks} />
								<Route path="/my-community/regional-links" exact={true} component={RegionalLinks} />
								<Route path="/my-community/older-persons" exact={true} component={OlderPersonsLinks} />
								<Route path="/my-community/disability" exact={true} component={DisabilityLinks} />
							</Switch>
							
							<aside className="sidebar">
								<nav className="section-nav">
									<ul className="listitems">
										<MyNavLink to="/my-community/about" className="item" activeClassName="-active" linkClassName="link">About</MyNavLink>
										<MyNavLink to="/my-community/general-links" className="item" activeClassName="-active" linkClassName="link">General Community and Informational Links</MyNavLink>
										<MyNavLink to="/my-community/regional-links" className="item" activeClassName="-active" linkClassName="link">Regional Links</MyNavLink>
										<MyNavLink to="/my-community/older-persons" className="item" activeClassName="-active" linkClassName="link">Older Persons Links</MyNavLink>
										<MyNavLink to="/my-community/disability" className="item" activeClassName="-active" linkClassName="link">Disability Links</MyNavLink>
									</ul>
								</nav>
								{/*
								<figure className="image">
									<img data-src={communityImage2x} data-srcset={communityImage2x + ' 560w, ' + communityImage + ' 280w'} width="560" height="567" alt="" className="lazyload" />
								</figure>
								*/}
							</aside>

						</div>
					
					</div>
					
				</article>
				<Footer />
				<Debug params={this.props.location.search}/>
			</div>
		)
	}
}
