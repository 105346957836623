import * as React from 'react'
import { Api } from 'typescript-fetch-api'
import { Snapshot, forComponentProps, wrapComponent, Input, Controller } from 'changeling'
import { IndexedActions, IndexedCursor } from 'changeling/dist/changeling-react'

export interface OwnProps extends Snapshot<string[] | undefined> {
	subOrder?: string
	secondaryQuestion?: boolean // TRUE if this question has been displayed due to the previous question
	title?: string
	dropdownOptions?: DeepReadonlyArray<Api.Language>
	imageLocation?: string
	multipleOptionText?: string
	editingMode?: boolean
	firstLang?: string
}

class Dropdown extends React.Component<OwnProps> {
	private changeling = forComponentProps(this)
	
	public render() {
		return (
			<> 
				{
					(this.props.editingMode || (!this.props.editingMode && this.showOnPrint())) &&
					<div className={'form-item -multi-dropdown' + (this.props.secondaryQuestion ? ' -secondary' : '') + (this.props.imageLocation ? '' : ' -noimage') + (this.props.editingMode ? '' : ' -valuemode')}>
						<div className="form-wrapper">
							<div className="content-wrapper">
								<p className="title">
									{ this.props.subOrder && 
									<span className="title-order">{this.props.subOrder}</span>
									}
									{this.props.title}
								</p>
								{
									this.props.editingMode ? (
										<div className="form-field">
											<Input.Indexed 
												controller={this.changeling} 
												prop="this" 
												renderEach={this.renderChild}
											/>
										</div>) : (
										<div className="value-wrapper">
											<p className="sub-text">
												{this.multiOptionToText()}
											</p>
										</div>
									)
								}
							</div>
							{
								this.props.imageLocation &&
								<div className="image-wrapper">
									<img src={this.props.imageLocation} />
								</div>
							}
						</div>
						{
							this.props.editingMode && this.props.multipleOptionText &&
						<Input.Indexed 
							controller={this.changeling}
							prop="this"
							renderAfter={this.renderAfter}
						/>
						}
					</div>
				}
			</>
		)
	}

	private multiOptionToText = () => {
		let formattedLanguages = ''
		
		this.props.value!.forEach((language, index) => {
			if (this.props.dropdownOptions) {
				const langIndex = this.props.dropdownOptions.findIndex(x => x.ref === language)
				if (index === 0) {
					if (language === '') {
						formattedLanguages = 'English'
					} else {
						formattedLanguages += this.props.dropdownOptions[langIndex].name
					}
				} else if (index + 1 === this.props.value!.length) {
					formattedLanguages += ' and ' + this.props.dropdownOptions[langIndex].name
				} else {
					formattedLanguages += ', ' + this.props.dropdownOptions[langIndex].name
				}
			}
		})

		return formattedLanguages
	}

	private showOnPrint = () => {
		if (this.props.firstLang === '' || '0') {
			if ((this.props.value!.length === 1) && (this.props.value![0] === '' || '0')) {
				return false 
			} else {
				return true
			}
		} else {
			return true
		}
	}

	private renderChild = (controller: Controller<string>, cursor: IndexedCursor, actions: IndexedActions<string>) => {
		return (
			<div key={cursor.index} className="form-input -select">
				<Input.Select 
					className="select" 
					controller={controller} 
					prop="this" 
					options={
						this.props.dropdownOptions!.map(option => ({
							value: option.ref, text: option.name,
						}))
					}
				/>
				{
					!cursor.first &&
					<div onClick={() => actions.onRemove(cursor.index)} className="remove-item"/>
				}
			</div>
		)
	}

	private renderAfter = (actions: IndexedActions<string>) => {
		return (
			<div className="add-item -three-quarters" onClick={() => actions.onPush('0')}>
				<span className="border"/>
				<div className="link">
					<span className="label">
						{this.props.multipleOptionText}
					</span>
				</div>
			</div>
		)
	}
}

export default wrapComponent(Dropdown)