import * as React from 'react'
import { RouteComponentProps, Switch, Route } from 'react-router'
import SupportWorkersHeader from './SupportWorkersHeader'
import AllSupportWorkers from '../containers/AllSupportWorkers'
import SupportWorkersFavourites from '../containers/SupportWorkersFavourites'
import SupportWorkersHidden from '../containers/SupportWorkersHidden'
import SupportWorker from '../containers/SupportWorker'
import Header from 'modules/common/containers/Header'
import Footer from 'modules/common/components/Footer'
import Debug from 'modules/common/components/Debug'

export default class SupportWorkersScreen extends React.Component<RouteComponentProps<undefined>> {
	public render() {
		return (
			<div className="site-content supportworkers-screen">
				<Header section="support-workers" />
				<article className="site-body">

					<Switch>
						<Route
							path={`${this.props.match.url}/worker/:supportWorkerRef`}
							exact={true}
							render={props => (<SupportWorker supportWorkerRef={props.match.params.supportWorkerRef} {...props}/>)}
						/>
						<Route 
							path={`${this.props.match.url}`}
							render={() => (<SupportWorkersHeader {...this.props} />)}
						/>
					</Switch>

					<Switch>
						<Route 
							path={`${this.props.match.url}`}
							exact={true}
							render={props => (
								<AllSupportWorkers {...props}/>
							)}
						/>
						<Route 
							path={`${this.props.match.url}/favourites`}
							exact={true}
							render={props => (
								<SupportWorkersFavourites {...props}/>
							)}
						/>
						<Route 
							path={`${this.props.match.url}/hidden`}
							exact={true}
							render={props => (
								<SupportWorkersHidden {...props}/>
							)}
						/>
					</Switch>

				</article>
				<Footer />
				<Debug params={this.props.location.search}/>
			</div>
		)
	}
}
