import Component from '../components/AccountScreen'
import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'
import { Api } from 'typescript-fetch-api'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'
import { currentUserSelector, currentClientRefSelector, supportNetworkMembershipSelector, clientSelector } from '../selectors'

/* Import module actions */
// import * as actions from '../actions'
import * as authActions from 'modules/auth/actions'
import { featuresSelector } from 'modules/common/selectors'
/** Interface for properties that are passed to the container/ */
export interface OwnProps {
	
}

/** Interface for properties that the container passes to the component. */
export interface Props {
	/** If the account screen is showing a client membership, then this is set */
	membership?: DeepReadonly<Api.SupportNetworkMembership>
	client?: DeepReadonly<Api.Client>
	doctorDetails?: DeepReadonly<Api.Doctor>
	NextOfKinDetails?: DeepReadonly<Api.NextOfKin>
	/** Else if the account screen is showing for a user who isn't a client, then this is set */
	user?: DeepReadonly<Api.User>
	/** Whether we're showing the account screen for you */
	you: boolean
	individualisedFunding: DeepReadonlyObject<{ [key: string]: Api.IndividualisedFunding }>
	funders: DeepReadonlyObject<{ [key: string]: Api.Funder }>
	featureMyDocuments: boolean
	featureHealthPassport: boolean
	featureAdvanceCarePlan: boolean
	featureDoctorDetails: boolean
	featureNextOfKin: boolean
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
	onSignOut: () => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	const clientRef = currentClientRefSelector(state)
	const user = currentUserSelector(state)
	const membership = clientRef ? supportNetworkMembershipSelector(state, clientRef) : undefined
	const features = featuresSelector(state)

	return {
		membership,
		client: clientRef ? clientSelector(state, clientRef) : undefined,
		user: !clientRef ? user : undefined,
		you: !clientRef || !!(membership && membership.role === Api.SupportNetworkMembership.RoleEnum.Client),
		individualisedFunding: state.calendar.refs.individualisedFunding,
		funders: state.calendar.refs.funders,
		featureMyDocuments: features.documents.myDocuments,
		featureAdvanceCarePlan: features.documents.advancedCarePlan,
		featureHealthPassport: features.documents.healthPassport,
		featureDoctorDetails: features.clientDetailsFields.doctor,
		featureNextOfKin: features.clientDetailsFields.nextOfKin,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	onSignOut: () => {
		dispatch(authActions.logoutRequest())
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
