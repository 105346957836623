import * as React from 'react'
import { LocalDate } from '@js-joda/core'

import * as t from '../types'
import Day from './Day'
import { formatDuration } from '../functions'

interface OwnProps {
	view: t.WeekView
	calendar: t.CalendarView
}

interface Actions {
	onSelectDay: (date: LocalDate) => void
}

export default class Week extends React.Component<OwnProps & Actions> {

	public render() {
		const { view, calendar } = this.props

		return (
			<div className="week">
				{
					view.days.map((day, index) => (
						<Day view={day} calendar={calendar} key={index} onSelectDay={this.props.onSelectDay} />
					))
				}
				<div className="totals"><div className="total">{formatDuration(view.totalMinutes, true)}</div></div>
			</div>
		)
	}
}
