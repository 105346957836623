import * as React from 'react'
import GoogleMapLoader from 'react-google-maps-loader'
import GooglePlacesSuggest from 'react-google-places-suggest'
import { forComponentProps, wrapComponent, Input, Snapshot } from 'changeling'
import platform from 'modules/platform'

export interface OwnProps extends Snapshot<string | undefined> {
	subOrder?: string
	address?: boolean
	imageLocation?: string
	editingMode?: boolean
}

interface State {
	focused: boolean
}

const INITIAL_STATE: State = {
	focused: false,
}


class Address extends React.Component<OwnProps> {
	public state = INITIAL_STATE
	private changeling = forComponentProps(this)


	public render() {
		return (
			<div className={'form-item -inline-textbox' + (this.props.editingMode ? '' : ' -valuemode')}>
				<div className="form-wrapper">
					<div className="content-wrapper">
						<p className="title">
							{this.props.editingMode && 'Address: '}
						</p>
						{
							this.props.editingMode ? (
								<div className="form-input -text">
									<GoogleMapLoader
										params={{
											key: platform.googleApiKey(),
											libraries: 'places,geocode',
										}}
										render={googleMaps =>
											googleMaps && (
												<GooglePlacesSuggest
													googleMaps={googleMaps}
													autocompletionRequest={{
														input: this.state.focused && this.props.value ? this.props.value : '',
														componentRestrictions: platform.googlePlacesComponentRestrictions(),
													}}
													onSelectSuggest={this.handleAddressSelectSuggest}
													textNoResults="No results"
													customRender={prediction => (
														<>
															{prediction
																? prediction.description
																: 'No results'}
														</>
													)}
												>
													<Input.String
														type="text"
														controller={this.changeling}
														className="address-field"
														prop="this"
														placeholder="Search a location"
														onFocus={this.handleOnFocus}
														onBlur={this.handleOnBlur} 
													/>
												</GooglePlacesSuggest>
											)
										}
									/>
								</div>
							) : (
								<div className="value-wrapper">
									<p className="sub-text"><span className="title">Address: </span>{this.props.value}</p>
								</div>
							)
						}
					</div>
					<div className="image-wrapper">
						{
							this.props.editingMode && this.props.imageLocation &&
								<img src={this.props.imageLocation} />
						}
					</div>
				</div>
			</div>
		)
	}

	private handleOnFocus = () => {
		this.setState({
			focused: true,
		})
	}

	private handleOnBlur = () => {
		this.setState({
			focused: false,
		})
	}

	private handleAddressSelectSuggest = (geocodedPrediction: google.maps.GeocoderResult, originalPrediction: google.maps.places.AutocompletePrediction) => {
		this.changeling.snapshot().onChange(geocodedPrediction.formatted_address)
	}
}

export default wrapComponent(Address)