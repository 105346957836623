import Component from '../components/TimesheetJob'
import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'
import { LocalDate } from '@js-joda/core'
import * as a from '../actions'
import * as bookingTypes from 'modules/booking/types'
import * as t from 'modules/calendar/types'
import { jobDetailView } from 'modules/calendar/functions'

/** Interface for properties that are passed to the container/ */
export interface OwnProps {
	view: t.JobView
	calendar: t.CalendarView
	date: LocalDate
	timesheetRef?: string
}

/** Interface for properties that the container passes to the component. */
export interface Props {
	job?: t.JobDetailView
	calDate: LocalDate
}

export interface Actions {
	onEditBooking: (editBooking: bookingTypes.EditBooking) => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	return {
		job: jobDetailView(ownProps.view.ref, ownProps.date, state),
		calDate: ownProps.calendar.originalDate,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	onEditBooking: (editBooking: bookingTypes.EditBooking) => {
		dispatch(a.editBooking(editBooking))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
