import * as React from 'react'
import { OwnProps, Props, Actions } from '../containers/Dashboard'
import { Link } from 'react-router-dom'
import ThisWeek from '../containers/ThisWeek'

import FundingDetails from '../containers/FundingDetails'
import IFFundingSummary from '../containers/IFFundingSummary'
import RecentVisits from '../containers/RecentVisits'
import FunderDashboard from 'modules/funder-dashboard/containers/FunderDashboard'
import { avatarPlaceholder } from 'modules/root/theme'
import * as features from 'modules/root/features'
import Tickets from './Tickets'

export default class Dashboard extends React.Component<OwnProps & Props & Actions> {

	onInvitations = () => {
		this.props.onInvitations()
	}

	public render() {
		const { pendingInvitations, incompleteDetails, individualisedFunding, capabilities } = this.props

		const invitationsCallout = pendingInvitations && pendingInvitations.length > 0
		const doctorNextOfKinCallout = (incompleteDetails.doctor || incompleteDetails.nextOfKin) && this.props.features.clientDetailsFields.doctor && this.props.features.clientDetailsFields.nextOfKin
		const callouts = invitationsCallout || doctorNextOfKinCallout

		return (
			<article className="site-body">
				<section className={'dashboard-intro' + (callouts ? ' -hascallouts' : '')}>
					<div className="width-limit -site">
						<div className="content">
							{this.props.you ? (
								<h1 className="page-title">Welcome{this.props.displayName ? ' ' + this.props.displayName : ''}!</h1>
							) : (
								<h1 className="page-title">{this.props.displayName || ''}’s Dashboard</h1>
							)}
							
							<p>What would you like to do?</p>
							{this.props.isClient && 
							<div className="button-group -centered">
								<div className="button">
									<Link to="/calendar" className="button-link -action -reversed -icon"><span className="icon -calendar" />View my calendar</Link>
								</div>
								{
									(capabilities.manager || capabilities.manageRoster) &&
									<>
										<div className="button">
											<a href="#" onClick={this.onNewBooking} className="button-link -action -reversed -icon"><span className="icon -book" />Book a new visit</a>
										</div>
										{this.props.features.findSupportWorkers &&
									<div className="button">
										<Link to="/support-workers" className="button-link -action -reversed -icon"><span className="icon -find" />Find support workers</Link>
									</div>
										}
									</>
								}
								{features.community &&
								<div className="button">
									<Link to="/my-community/" className="button-link -action -reversed -icon"><span className="icon -community" />My community</Link>
								</div>
								}
								<div className="button">
									<Link to="/help/" className="button-link -action -reversed -icon"><span className="icon -help" />Help</Link>
								</div>
							</div>
							}
							{!this.props.isClient && features.registerForCare &&
							<div className="button-group -centered">
								<div className="button">
									<Link to="/setup/" className="button-link -action -reversed -icon"><span className="icon -calendar" />Register for care</Link>
								</div>
							</div>
							}
						</div>
						{callouts &&
							<div className="callouts">
								{invitationsCallout &&
									<p className="callout">You have <span className="count">{pendingInvitations!.length}</span> pending invitation to a Support Network. <a onClick={this.onInvitations} className="button-link -action -micro">View</a></p>
								}
								{doctorNextOfKinCallout &&
									<p className="callout">Complete your profile by adding {
										incompleteDetails.doctor && incompleteDetails.nextOfKin ? (<><Link to="account/next-of-kin-details">next of kin</Link> and <Link to="account/doctor-details">doctor </Link></>) : (
											incompleteDetails.doctor ? (<Link to="account/doctor-details">doctor </Link>) : (<Link to="account/next-of-kin-details">next of kin </Link>)
										)}
									details.</p>
								}
							</div>
						}
					</div>
				</section>
				
				<div className="width-limit -site">
					{capabilities.viewRoster && this.renderCalendar()}
					{features.pendingRequests && capabilities.manageRoster && <Tickets/>}
					{
						individualisedFunding && capabilities.viewClientDetails && Object.keys(individualisedFunding).map((key) => {
							const ifo = individualisedFunding[key]
							return (
								<section key={key} className="dashboard-section">
									<IFFundingSummary individualisedFunding={ifo} you={this.props.you} displayName={this.props.displayName}/>
								</section>
							)
						})
					}

					<div className={'split-content' + ((capabilities.viewClientDetails && this.props.funding && this.props.funding.hasFunding && this.props.features.findSupportWorkers) ? '' : ' -single')} >
						{ capabilities.viewClientDetails && this.props.funding && this.props.funding.hasFunding &&
							<FundingDetails />
						}
						{this.props.isClient && this.props.features.findSupportWorkers && 
							<div className="content">
								{this.props.you ?
									<h2 className="title">My favourites</h2> :
									<h2 className="title">{this.props.displayName || ''}’s favourites</h2>
								}
								<p>Support Workers that {this.displayWhosFavourites()} added to {this.props.you ? 'your' : 'their'} favourites list, or {this.props.you ? 'have' : 'has'} had visits with before. You can also <Link to="/support-workers">browse to find more support workers</Link> in your area.</p>
								<div className="favourites-list -list">
									{
										this.props.favouriteSupportWorkers && this.props.favouriteSupportWorkers.map((supportWorker, index) => (
											<div className="person" key={index}>
												<figure className="avatar-image -medium">
													<div className="image">
														<img src={supportWorker.supportWorker.image || avatarPlaceholder()} alt={supportWorker.supportWorker.givenName} />
													</div>
												</figure>
												<p className="details">
													<a href="#" onClick={this.onViewDetail.bind(this, supportWorker.supportWorker.ref)} className="name">{supportWorker && supportWorker.supportWorker.fullName}</a>
													{/* <span className="history">You‘ve seen Heather 3 times</span> */}
												</p>
											</div>
										))
									}
								</div>
							</div>
						}
					</div>
				
					{this.props.isFunder && <FunderDashboard />}
				</div>
			</article>
		)
	}

	private displayWhosFavourites = (): string => {
		return this.props.you ? 'you have' : this.props.displayName ? `${this.props.displayName} has` : 'have been'
	}
	
	private onViewDetail = (cswrRef: string, evt: React.MouseEvent) => {
		evt.preventDefault()
		this.props.onViewDetail(cswrRef)
	}

	private onNewBooking = (evt: React.MouseEvent) => {
		evt.preventDefault()
		this.props.onNewBooking()
	}

	private renderCalendar = () => {
		const { recentJobs } = this.props

		return (
			<>
				<ThisWeek/>
				{ recentJobs && recentJobs.length > 0 && 
					<RecentVisits recentJobs={recentJobs} />
				}
			</>
		)
	}
}
