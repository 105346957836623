import { Api } from 'typescript-fetch-api'

export enum RequestCareForEnum {
	Undefined,
	Self = 'Self',
	Another = 'Another',
}

export const INITIAL_CLIENT_DETAILS: Api.RegisterClientRequest = {
	givenName: '',
	familyName: '',
	email: '',
	capabilities: {
		manager: true,
		viewRoster: true,
		manageRoster: true,
		viewClientDetails: true,
		manageClientDetails: true,
		viewTimesheets: true,
		manageTimesheets: true,
	},
	role: Api.RegisterClientRequest.RoleEnum.Client,
	existingCareCallClient: Api.RegisterClientRequest.ExistingCareCallClientEnum.No,
}

export interface InvitationView {
	clientRef: string
	client?: Api.Client
	ref: string
	inviterIsClient: boolean
	invitedByUserName: string
}
