import Component from '../components/SetPassword'

import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'

/* Import module actions */
import * as actions from '../actions'
import * as t from '../types'

/** Interface for properties that are passed to the container/ */
export interface OwnProps {
}

/** Interface for properties that the container passes to the component. */
export interface Props {
	yourDetails: t.YourDetails
	token?: string
	processing: boolean
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
	onUpdateYourDetailsStore: (details: t.YourDetails) => void
	onComplete: (token: string, details: t.YourDetails) => void
	onInvalidToken: () => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	return {
		token: state.register.registrationToken,
		yourDetails: state.register.yourDetails,
		processing: state.register.completeRegistrationProcessing,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	onUpdateYourDetailsStore: (details) => {
		dispatch(actions.updateYourDetailsStore(details))
	},
	onComplete: (token, details) => {
		dispatch(actions.completeRegistration({
			token,
			password: details.password,
			givenName: details.givenName,
			familyName: details.familyName,
			phoneHome: details.phone,
			acceptedTerms: details.acceptedTerms,
			marketingOptIn: true,
		}))
	},
	onInvalidToken: () => {
		dispatch(actions.invalidToken())
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
