/**
 * Component template.
 * 
 * Note that this file has a `.tsx` suffix, as it contains React elements.
 */

import * as React from 'react'
import { Route } from 'react-router'
import { Link } from 'react-router-dom'

interface OwnProps {
	to: string
	exact?: boolean
	className?: string
	inactiveClassName?: string
	activeClassName?: string
	linkClassName?: string
}

export default class MyNavLink extends React.Component<OwnProps> {

	public render() {
		return (
			<Route 
				path={this.props.to}
				exact={this.props.exact} 
				children={({ match }) => (
					<li className={(this.props.className || '') + (match ? (this.props.activeClassName ? ' ' + this.props.activeClassName : '') : (this.props.inactiveClassName ? ' ' + this.props.inactiveClassName : ''))}>
						<Link className={this.props.linkClassName} to={this.props.to}>
							{this.props.children}
						</Link>
					</li>
				)}
			/>
		)
	}
}
