import { reducerWithInitialState } from 'typescript-fsa-reducers'

/* Import our module's actions */
import * as a from './actions'

export type StoreState = DeepReadonly<MutableStoreState>

export interface MutableStoreState {
	submitting: boolean
	recalling: boolean
}

/**
 * The initial store state for this module.
 */
const INITIAL_STATE: StoreState = {
	submitting: false,
	recalling: false,
}

/**
 * Reducer function for this module.
 */
export const reducer = reducerWithInitialState(INITIAL_STATE)

reducer.case(a.completingTimesheetAction.started, (state): StoreState => ({
	...state,
	submitting: true,
}))

reducer.cases([a.completingTimesheetAction.done, a.completingTimesheetAction.failed], (state): StoreState => ({
	...state,
	submitting: false,
}))

reducer.case(a.recallingTimesheetAction.started, (state): StoreState => ({
	...state,
	recalling: true,
}))

reducer.cases([a.recallingTimesheetAction.done, a.recallingTimesheetAction.failed], (state): StoreState => ({
	...state,
	recalling: false,
}))
