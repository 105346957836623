import Component from '../components/SetupScreen'

import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'

/* Import module actions */
import * as s from '../selectors'

/** Interface for properties that the container passes to the component. */
export interface Props {
	hasInvites: boolean
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState): Props => {
	const supportNetworkInvites = s.currentUserInvitationsViews(state)
	return {
		hasInvites: (supportNetworkInvites && supportNetworkInvites.length > 0) ? true : false,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
