import * as React from 'react'
import TextBox from './TextBox'
import { forComponentProps, wrapComponent } from 'changeling'
import { HealthPassportFormProps } from './HealthPassportScreen'
import Acknowledgements from './Acknowledgements'

import Image39 from 'modules/frontend/img/health-passport/39-likes.jpg'
import Image40 from 'modules/frontend/img/health-passport/40-dislikes.jpg'
import Image41 from 'modules/frontend/img/health-passport/41-religious-needs.jpg'
import Image42 from 'modules/frontend/img/health-passport/42-cultural-needs.jpg'
import Image43 from 'modules/frontend/img/health-passport/43-preferences-other.jpg'

type MyComponentProps = HealthPassportFormProps

class HealthPassportSectionC extends React.Component<MyComponentProps> {

	private changeling = forComponentProps(this)

	public render() {
		const { editingMode } = this.props

		return (
			<>
				<TextBox 
					subOrder="a)"
					title="Things I like"
					subText="(e.g., music, routines, etc):"
					controller={this.changeling}
					imageLocation={Image39}
					prop="likes"
					editingMode={editingMode}
				/>
				<TextBox 
					subOrder="b)"
					title="Things I don't like"
					subText="(e.g., certain food, dark rooms, etc):"
					controller={this.changeling}
					imageLocation={Image40}
					prop="dislikes"
					editingMode={editingMode}
				/>
				<TextBox 
					subOrder="c)"
					title="My religious needs"
					subText="(e.g., karakia/prayers, Halal food, etc):"
					controller={this.changeling}
					imageLocation={Image41}
					prop="religiousNeeds"
					editingMode={editingMode}
				/>
				<TextBox 
					subOrder="d)"
					title="My cultural needs"
					subText="(e.g., I prefer a woman doctor, etc):"
					controller={this.changeling}
					imageLocation={Image42}
					prop="culturalNeeds"
					editingMode={editingMode}
				/>
				<TextBox 
					subOrder="e)"
					title="Other information"
					subText="(e.g., tell me when you bring food and what’s in it, etc):"
					controller={this.changeling}
					imageLocation={Image43}
					prop="preferencesOther"
					editingMode={editingMode}
				/>
				<Acknowledgements editMode={editingMode} />
			</>
		)
	}
}

export default wrapComponent(HealthPassportSectionC)
