import { Api } from 'typescript-fetch-api'

export interface StoreClients {
	[clientRef: string]: Api.Client
}

export interface StoreUsers {
	[userRef: string]: Api.User
}

export interface ClientView {
	name: string
	displayName: string
	ref: string
	image?: string
	role: Api.SupportNetworkMembership.RoleEnum
	you: boolean
	capabilities: Api.SupportNetworkCapabilities
	showTimesheets: boolean
}

export interface ClientsView {
	you?: ClientView
	manageable: ClientView[]
	viewable: ClientView[]
}

export interface SupportNetworkMembership {
	name: string
	userRef: string
	capabilities: Api.SupportNetworkCapabilities
	image?: string
	role: Api.SupportNetworkMembership.RoleEnum
}

export const NoCapabilities: Api.SupportNetworkCapabilities = {
	manageClientDetails: false,
	manageRoster: false,
	manageTimesheets: false,
	manager: false,
	viewClientDetails: false,
	viewRoster: false,
	viewTimesheets: false,
}
