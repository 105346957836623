import * as React from 'react'
import { OwnProps, Props, Actions } from '../containers/ChangePassword'

import { Api } from 'typescript-fetch-api'
import { SimpleFormState, Password, FormState } from 'formalities'
import { Helmet } from 'react-helmet'

interface State {
	form: SimpleFormState<Api.ChangePasswordRequest>
}

const INITIAL_STATE: State = {
	form: new SimpleFormState<Api.ChangePasswordRequest>({ oldPassword: '', newPassword: '' }),
}

export default class ChangePasswordScreen extends React.Component<OwnProps & Props & Actions> {

	public state = INITIAL_STATE

	public componentWillReceiveProps(nextProps: Props) {
		/* Reset form state when we finish saving */
		if (this.props.processing && !nextProps.processing) {
			this.setState(INITIAL_STATE)
		}
	}

	public render() {
		const { form } = this.state

		return (
			<div className="body">
				<Helmet>
					<title>Change password — My Homecare</title>
				</Helmet>
				
				<h1 className="headline-text">Change password</h1>

				<form className="form-layout" onSubmit={this.onSubmit}>
					<div className="row -split -half">
						
						<div className="form-field">
							<label className="label">Current password</label>
							<div className="form-input -text">
								<Password formState={this.state.form} onNewFormState={this.onNewFormState} className="field" name="oldPassword" autoComplete="current-password" />
							</div>
						</div>

						<div className="form-field">
							<label className="label">New password</label>
							<div className="form-input -text">
								<Password formState={this.state.form} onNewFormState={this.onNewFormState} className="field" name="newPassword" autoComplete="new-password" />
							</div>
						</div>
		
					</div>

					<div className="row">
						<div className="button-group">
							<div className="button -constrained">
								<div className={'button-link -nested -action' + (this.props.processing ? ' -processing' : '')}>
									<input type="submit" value="Change" />
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		)
	}

	private onSubmit = (evt: React.FormEvent) => {
		evt.preventDefault()
		this.props.onChangePassword(this.state.form.getValues())
	}

	private onNewFormState = (newState: FormState<Api.ChangePasswordRequest>) => {
		this.setState({ form: newState })
	}
}
