import { RootStoreState } from 'modules/root'
import { createSelector } from 'reselect'
import { Api } from 'typescript-fetch-api'
import * as t from './types'
import { createClientView } from './functions'

/** Select whether the current user's account details have been fully loaded. */
export const loaded = (state: RootStoreState) => state.account.user !== undefined && state.account.supportNetworks !== undefined

/** Select the current user's ref if we've loaded the account details, or undefined */
export const currentUserRefSelector = (state: RootStoreState) => state.account.user ? state.account.user.ref : undefined

/** Select the current user or undefined. */
export const currentUserSelector = (state: RootStoreState) => state.account.user

/** Select the ref of the currently selected client, or undefined if there isn't one */
export const currentClientRefSelector = (state: RootStoreState) => state.account.currentClientRef

/** Select the clientRefs of the clients that the current user is networked with. */
export const clientRefsSelector = (state: RootStoreState): string[] => state.account.supportNetworks && state.account.supportNetworks.memberships ? state.account.supportNetworks.memberships.map(n => n.clientRef).filter(cr => cr !== undefined) as string[] : []

/** Select the given client */
export const clientSelector = (state: RootStoreState, clientRef: string): DeepReadonly<Api.Client> | undefined => state.account.clients[clientRef]

/** Select the current Client object, if there is one. */
export const currentClientSelector = (state: RootStoreState) => state.account.currentClientRef ? clientSelector(state, state.account.currentClientRef) : undefined

/** Select the membership for the current user with the given client. */
export const supportNetworkMembershipSelector = (state: RootStoreState, clientRef: string): Api.SupportNetworkMembership | undefined => state.account.supportNetworks && state.account.supportNetworks.memberships ? state.account.supportNetworks.memberships.find(n => n.clientRef === clientRef) : undefined

/** Returns whether the given clientRef is the client that is the current user */
export const clientIsYou = createSelector(supportNetworkMembershipSelector, (membership) => membership ? membership.role === Api.SupportNetworkMembership.RoleEnum.Client : false)

/** Select the network memberships for the current user */
export const supportNetworkMembershipsSelector = (state: RootStoreState) => state.account.supportNetworks && state.account.supportNetworks.memberships ? state.account.supportNetworks.memberships : []

/** Return the clientRef for the Client that the current user has the Client role for, or undefined */
export const currentUserClientRefSelector = createSelector(
	supportNetworkMembershipsSelector,
	(memberships) => {
		let result: string | undefined = undefined
		memberships.forEach(m => {
			if (m.role === Api.SupportNetworkMembership.RoleEnum.Client) {
				result = m.clientRef
			}
		})
		return result as string | undefined
	})

/** Returns true if the current user is the Client in a Support Network. */
export const currentUserIsAClient = createSelector(currentUserClientRefSelector, (clientRef) => {
	return clientRef !== undefined
})

/** Select the clients for the current user */
const clientsSelector = (state: RootStoreState) => state.account.clients

export const clientsViewSelector = createSelector(
	supportNetworkMembershipsSelector, 
	clientsSelector,
	(memberships, clients) => {
		const result: t.ClientsView = {
			manageable: [],
			viewable: [],
		}

		memberships.forEach(m => {
			const client = clients[m.clientRef!]
			if (!client) {
				return
			}

			const clientView = createClientView(client, m)

			switch (m.role) {
				case Api.SupportNetworkMembership.RoleEnum.Client:
					result.you = clientView
					break
				case Api.SupportNetworkMembership.RoleEnum.Supporter:
					result.viewable.push(clientView)
					break
				default:
					throw new Error(`Unsupported membership role: ${m.role}`)
			}

		})

		return result
	})

export const currentClientViewSelector = createSelector(
	currentClientRefSelector,
	supportNetworkMembershipsSelector, 
	clientsSelector,
	(clientRef, memberships, clients) => {
		if (!clientRef) {
			return undefined
		}

		const client = clients[clientRef]
		const membership = memberships.find(m => m.clientRef === clientRef)
		if (!client || !membership) {
			return undefined
		}
		
		return createClientView(client, membership)
	},
)