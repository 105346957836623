import * as React from 'react'
import { Props, Actions, OwnProps } from '../containers/ClientNextOfKinDetails'
import { Api } from 'typescript-fetch-api'
import { Input, SimpleFormState, FormState } from 'formalities'

/**
 * Interface for private internal component state.
 */
interface State {
	nextOfKinState?: FormState<Partial<Api.NextOfKin>>
}

/**
 * The initial state for our internal component state.
 */
const INITIAL_STATE: State = {
}

export default class ClientNextOfKinDetails extends React.Component<OwnProps & Props & Actions, State> {

	public state = INITIAL_STATE

	public render() {
		const nextOfKinState = this.formState()

		return (
			<div className="body">
				<form className="form-layout" action="#">
					<div className="introduction">
						<h1 className="headline-text">Next of kin details</h1>
						<div className="body-text">
							<p>Please enter your next of kin details.</p>
						</div>
					</div>
				
					<div className="row -split -half">
						
						<div className="form-field">
							<label className="label">Name</label>
							<div className="form-input -text">
								<Input formState={nextOfKinState} onNewFormState={this.onNewFormState} type="text" className="field" name="name" />
							</div>
						</div>
						<div className="form-field">
							<label className="label">Relationship</label>
							<div className="form-input -text">
								<Input formState={nextOfKinState} onNewFormState={this.onNewFormState} type="text" className="field" name="relationship" />
							</div>
						</div>
		
					</div>

					<div className="row">
						<div className="form-field">
							<label className="label">Email</label>
							<div className="form-input -text">
								<Input formState={nextOfKinState} onNewFormState={this.onNewFormState} type="text" className="field" name="email" />
							</div>
						</div>
					</div>

					<div className="row -split -half">
						<div className="form-field">
							<label className="label">Home phone</label>
							<div className="form-input -text">
								<Input formState={nextOfKinState} onNewFormState={this.onNewFormState} type="text" className="field" name="phone" />
							</div>
						</div>
						<div className="form-field">
							<label className="label">Alternative phone</label>
							<div className="form-input -text">
								<Input formState={nextOfKinState} onNewFormState={this.onNewFormState} type="text" className="field" name="alternatePhone" />
							</div>
						</div>
					</div>

					<div className="row">
						<div className="button-group">
							<div className="button -constrained">
								<div className={'button-link -action' + (nextOfKinState.isEmpty() ? ' -disabled' : '') + (this.props.saving ? ' -processing' : '')} onClick={this.onSave}>Save changes</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		)
	}

	private onSave = (evt: React.FormEvent<HTMLDivElement>) => {
		evt.preventDefault()

		if (!this.props.client) {
			return
		}

		if (this.state.nextOfKinState) {
			const request = this.state.nextOfKinState.getValues()
			const nextOfKin: Partial<Api.NextOfKin> = request
			const clientRef = this.props.client.ref
			this.props.onSave(nextOfKin as Api.NextOfKin, clientRef)
		}
	}

	private onNewFormState = (newState: FormState<Partial<Api.NextOfKin>>) => {
		this.setState({ nextOfKinState: newState })
	}

	private formState = () => {
		if (this.state.nextOfKinState) {
			return this.state.nextOfKinState
		}

		const { nextOfKin } = this.props
		const fixedNextOfKin = {
			...nextOfKin, 
		}
		return new SimpleFormState<Partial<Api.NextOfKin>>(fixedNextOfKin)
	}
}