import * as React from 'react'
import { withModal } from 'modules/common/modal-hoc'

interface OwnProps {
	leaveNetwork: () => void
	onClose: () => void
	supportNetworkName: string
}

export default withModal(class LeaveSupportNetworkModal extends React.Component<OwnProps> {

	public render() {
		return (
			<div className="invitation">
				<div className="content">
					
					<div className="form-layout">
						<div className="row">
							<h1 className="page-title">Leave support network?</h1>
							<div className="body-text">
								<p>
									Are you sure you'd like to leave {this.props.supportNetworkName}'s support network?<br/>
									In order to rejoin, {this.props.supportNetworkName} will need to send you a new invitiation.
								</p>
							</div>
						</div>
						<div className="row">
							<div className="button-group">
								<div className="button">
									<div className="button-link -text" onClick={this.props.onClose}>Cancel</div>
								</div>
								<div className="button">
									<div className="button-link -action" onClick={this.props.leaveNetwork}>Leave network</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
})
