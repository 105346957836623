import * as React from 'react'
import { Snapshot, forComponentProps, wrapComponent, Input } from 'changeling'

export interface OwnProps extends Snapshot<string | undefined> {
	subOrder?: string
	title: string
	subText?: string
	imageLocation?: string
	secondaryQuestion?: boolean
	editingMode?: boolean
	disabled?: boolean
}

class InlineTextBox extends React.Component<OwnProps> {
	private changeling = forComponentProps(this)

	public render() {
		const { editingMode, value, secondaryQuestion, imageLocation } = this.props
		return (
			<div className={'form-item -inline-textbox' + (secondaryQuestion ? ' -secondary' : '') + (editingMode ? '' : ' -valuemode -no-gutters') + (imageLocation ? ' -has-image' : '')}>
				<div className="form-wrapper">
					<div className={'content-wrapper' + (imageLocation ? '' : ' -no-image')}>
						<p className="title">
							{ this.props.subOrder && 
								<span className="title-order">{this.props.subOrder}</span>
							}
							{editingMode && this.props.title}
						</p>
						<div className="form-input -text">
							{
								editingMode ? ( 
									<>
										<Input.String className="field" controller={this.changeling} prop="this" disabled={this.props.disabled ? true : false}/> 
										{ this.props.subText &&
											<p className="sub-text">{this.props.subText}</p>
										}
									</>
								) : (
									<p className="sub-text"><span className="title">{this.props.title} </span>{value}</p>
								)
							}
						</div>
					</div> 
					<div className="image-wrapper">
						{
							this.props.imageLocation &&
								<img src={this.props.imageLocation} />
						}
					</div>
				</div>
			</div>
		)
	}
}

export default wrapComponent(InlineTextBox)