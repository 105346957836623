import Component from '../components/Dashboard'
import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'
import { Api } from 'typescript-fetch-api'
import * as t from '../types'
import * as sws from 'modules/support-workers/selectors'
import * as swa from 'modules/support-workers/actions'
import { push } from 'connected-react-router'
import { Funding } from 'modules/calendar/types'
import * as calendarSelectors from 'modules/calendar/selectors'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'
import { currentClientSelector, clientIsYou, supportNetworkMembershipSelector, currentUserSelector } from 'modules/account/selectors'

/* Import module actions */
// import * as actions from '../actions'
import * as bookingActions from 'modules/booking/actions'
import { NoCapabilities } from 'modules/account/types'
import { featuresSelector } from 'modules/common/selectors'

/** Interface for properties that are passed to the container/ */
export interface OwnProps {

}

/** Interface for properties that the container passes to the component. */
export interface Props {
	isClient: boolean
	isFunder: boolean
	displayName?: string
	you: boolean
	recentJobs?: DeepReadonly<t.RecentDay[]>
	favouriteSupportWorkers?: DeepReadonlyArray<t.SupportWorkerRelationshipView>
	pendingInvitations?: DeepReadonlyArray<Api.ClientSupportNetworkInvitation>
	incompleteDetails: t.IncompleteProfile
	individualisedFunding?: DeepReadonlyObject<{ [key: string]: Api.IndividualisedFunding }>
	funding?: Funding
	capabilities: Api.SupportNetworkCapabilities
	features: DeepReadonly<Api.FeatureSet>
}

export interface Actions {
	onViewDetail: (cswrRef: string) => void
	onNewBooking: () => void
	onInvitations: () => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	const currentUser = currentUserSelector(state)
	const currentClient = currentClientSelector(state)
	const you = currentClient ? clientIsYou(state, currentClient.ref) : false
	const incompleteProfile: t.IncompleteProfile = {
		doctor: false,
		nextOfKin: false,
	}

	if (currentClient && !currentClient.doctor) {
		incompleteProfile.doctor = true
	} 
	if (currentClient && !currentClient.nextOfKin) {
		incompleteProfile.nextOfKin = true
	}

	const membership = currentClient ? supportNetworkMembershipSelector(state, currentClient.ref) : undefined

	const favouriteSupportWorkers = state.supportWorkers.favouriteClientSupportWorkerRelationshipRefs && state.supportWorkers.favouriteClientSupportWorkerRelationshipRefs.length > 0 ? state.supportWorkers.favouriteClientSupportWorkerRelationshipRefs.map((ref) => {
		const relationship = sws.clientSupportWorkerRelationship(state, ref)
		const supportWorker = sws.supportWorkerForClientSupportWorkerRelationshipRef(state, ref)
		if (supportWorker && relationship) {
			return {
				supportWorker,
				relationship,
			}
		}
		return undefined
	}).filter((sw) => { 
		return sw !== undefined 
	}) : undefined
	
	return {
		isClient: !!currentClient,
		isFunder: !currentClient && currentUser && currentUser.organisationRefs ? currentUser.organisationRefs.length > 0 : false,
		displayName: currentClient ? (currentClient.knownAs ? currentClient.knownAs : currentClient.givenName) : currentUser ? (currentUser.knownAs ? currentUser.knownAs : currentUser.givenName) : undefined,
		you,
		recentJobs: currentClient && state.dashboard.jobs ? state.dashboard.jobs : undefined,
		favouriteSupportWorkers: currentClient && favouriteSupportWorkers as DeepReadonlyArray<t.SupportWorkerRelationshipView>,
		pendingInvitations: state.account.supportNetworks && state.account.supportNetworks.invitations,
		incompleteDetails: incompleteProfile,
		individualisedFunding: currentClient && state.calendar.refs.individualisedFunding,
		funding: currentClient && calendarSelectors.currentFunding(state),
		capabilities: membership ? membership.capabilities : NoCapabilities,
		features: featuresSelector(state),
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	onViewDetail: (cswrRef: string) => {
		dispatch(swa.viewDetail(cswrRef))
	},
	onNewBooking: () => {
		dispatch(bookingActions.startBooking())
	},
	onInvitations: () => {
		dispatch(push('/setup/register-new/invites'))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
