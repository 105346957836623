import * as React from 'react'
import SupportWorker from 'modules/support-workers/containers/SupportWorker'
import { withModal } from 'modules/common/modal-hoc'

interface OwnProps {
	supportWorkerRef: string
	onClose: () => void
}

export default withModal(class SupportWorkerModal extends React.Component<OwnProps> {

	public render() {
		return (
			<SupportWorker supportWorkerRef={this.props.supportWorkerRef} />
		)
	}
})
