import * as React from 'react'

interface State {
	bodyIsOverflowing: boolean
	scrollbarWidth: number
}

const INITIAL_STATE: State = {
	bodyIsOverflowing: false,
	scrollbarWidth: 0,
}

interface Props {
	onClose: () => void
}

export enum ModalStyle {
	Normal = '',
	Narrow = '-narrow',
	Medium = '-medium',
}

/** Adds modal capabilities to the given component. */
export function withModal<P extends Props>(WrappedComponent: React.ComponentClass<P>, style: ModalStyle = ModalStyle.Normal) {
	return class WithModal extends React.Component<P, State> {

		public state = INITIAL_STATE

		componentDidMount() {
			setTimeout(this.handleOpenModal, 10)
		}
	
		componentWillUnmount() {
			this.handleCloseModal()
		}

		public render() {
			return (
				<div className="modal-window">
					<div className="overlay"/>
					<div className="container">
						<div className="width-limit -site">
							<div className={'contents' + (style !== ModalStyle.Normal ? ` ${style}` : '')}>
								<div className="modal-contents">
									<WrappedComponent {...this.props} />
								</div>
								<a className="close" onClick={this.props.onClose}>Close</a>
							</div>
						</div>
					</div>
				</div>
			)
		}

		private keyListener = (e: KeyboardEvent) => {
			if (e.keyCode === 27) {
				this.props.onClose()
			}
		}

		private handleOpenModal = () => {
			this.checkScrollbar()
			this.setScrollbar()
			document.body.className = 'modal-open'
	
			document.addEventListener('keyup', this.keyListener)
		}
	
		private handleCloseModal = () => {
			document.body.className = ''
			this.resetScrollbar()
			document.removeEventListener('keyup', this.keyListener)
		}

		private checkScrollbar() {
			let fullWindowWidth = window.innerWidth
			
			if (!fullWindowWidth) { // workaround for missing window.innerWidth in IE8
				const documentElementRect = document.documentElement.getBoundingClientRect()
				fullWindowWidth = documentElementRect.right - Math.abs(documentElementRect.left)
			}
			
			this.setState({
				bodyIsOverflowing: document.body.clientWidth < fullWindowWidth,
				scrollbarWidth: this.measureScrollbar(),
			})
		}
	
		private setScrollbar() {
			if (this.state.bodyIsOverflowing) {
				document.body.style.paddingRight = this.state.scrollbarWidth + 'px'
			}
		}
	
		private resetScrollbar() {
			document.body.style.paddingRight = ''
		}
	
		private measureScrollbar() {
			const scrollDiv = document.createElement('div')
			scrollDiv.className = 'overflow-scrollbar-measure'
			document.body.appendChild(scrollDiv)
	
			const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth
			document.body.removeChild(scrollDiv)
			
			return scrollbarWidth
		}

	}
}
