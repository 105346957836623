import * as React from 'react'
import { Api } from 'typescript-fetch-api'
import moment from 'moment'
import Datetime from 'react-datetime'
import { LocalTime, LocalDate } from '@js-joda/core'
import * as t from '../types'
import * as f from '../functions'
import * as features from 'modules/root/features'

interface OwnProps {
	bookings: DeepReadonlyArray<t.BookingLine>
	dayProperties: DeepReadonly<t.BookingLine>
	initialDate?: string
	index: number
	handleBookingLineChange: (index: number, newBookingLine: DeepReadonly<t.BookingLine>) => void
	removeRow: (index: number) => void
	handleSetRepeatDate: (addDate: boolean) => void
	jobTypes?: DeepReadonlyArray<Api.JobType>
}

export default class DayRow extends React.Component<OwnProps> {
	StartTimeOptionEnum = Api.Appointment.StartTimeOptionEnum
	DurationOptionEnum = Api.Appointment.DurationOptionEnum

	handleStartTimeChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
		const ob = { ...this.props.bookings[this.props.index] }
		if (this.StartTimeOptionEnum[evt.target.value] === this.StartTimeOptionEnum.Flexible) {
			ob.startTime = undefined
			ob.startTimeOption = this.StartTimeOptionEnum.Flexible
		} else if (this.StartTimeOptionEnum[evt.target.value] === this.StartTimeOptionEnum.Morning) {
			ob.startTime = undefined
			ob.startTimeOption = this.StartTimeOptionEnum.Morning
		} else if (this.StartTimeOptionEnum[evt.target.value] === this.StartTimeOptionEnum.Afternoon) {
			ob.startTime = undefined
			ob.startTimeOption = this.StartTimeOptionEnum.Afternoon
		} else {
			ob.startTimeOption = this.StartTimeOptionEnum.Specified
			ob.startTime = LocalTime.parse(evt.target.value)
		}

		this.props.handleBookingLineChange(this.props.index, ob)
	}

	handleDayOfWeekChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
		const ob = { ...this.props.bookings[this.props.index] }
		ob.dayOfWeek = t.DayOfWeekEnum[evt.target.value]
		this.props.handleBookingLineChange(this.props.index, ob)
	}

	handleDurationChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
		const ob = { ...this.props.bookings[this.props.index] }

		/* If the duration was set to overnight, undo the start time */
		if (ob.durationOption === Api.NewAppointment.DurationOptionEnum.Overnight) {
			ob.startTimeOption = Api.NewAppointment.StartTimeOptionEnum.Flexible
			ob.startTime = undefined
		}

		if (this.DurationOptionEnum[evt.target.value] === this.DurationOptionEnum.NotSpecified) {
			ob.durationMinutes = undefined
			ob.durationOption = Api.NewAppointment.DurationOptionEnum.NotSpecified
		} else if (this.DurationOptionEnum[evt.target.value] === this.DurationOptionEnum.Overnight) {
			ob.durationMinutes = undefined
			ob.durationOption = Api.NewAppointment.DurationOptionEnum.Overnight
			ob.startTimeOption = this.StartTimeOptionEnum.Specified
			ob.startTime = LocalTime.parse('23:00')
		} else {
			ob.durationMinutes = Number(evt.target.value)
			ob.durationOption = Api.NewAppointment.DurationOptionEnum.Specified
		}
		this.props.handleBookingLineChange(this.props.index, ob)
	}

	handleRepeatChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
		const ob = { ...this.props.bookings[this.props.index] }
		ob.repeat = Api.NewAppointment.RepeatOptionEnum[evt.target.value]

		if (ob.repeat === Api.NewAppointment.RepeatOptionEnum.None) {
			ob.dayOfWeek = undefined
			this.props.handleSetRepeatDate(false)
		}	else if (ob.date) {
			ob.dayOfWeek = t.DayOfWeekEnum[moment(ob.date.toString(), ['YYYY-MM-DD']).format('dddd')]
		}
		
		if (ob.repeat === Api.NewAppointment.RepeatOptionEnum.Daily) {
			ob.date = undefined
			this.props.handleSetRepeatDate(true)
		} else if (ob.repeat === Api.NewAppointment.RepeatOptionEnum.MondayToFriday) {
			ob.date = undefined
			this.props.handleSetRepeatDate(true)
		} else {
			ob.date = undefined
			this.props.handleSetRepeatDate(true)
		}
		
		this.props.handleBookingLineChange(this.props.index, ob)
	}

	handleBookingDate = (date: moment.Moment | string) => {
		if (typeof date === 'string') {
			/* The user entered something that was not parseable as a date */
			return
		}
		const ob = { ...this.props.bookings[this.props.index] }
		ob.date = LocalDate.parse(date.format('YYYY-MM-DD'))
		this.props.handleBookingLineChange(this.props.index, ob)
	}

	handleServiceChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
		const ob = { ...this.props.bookings[this.props.index] }
		ob.services = [evt.target.value]
		this.props.handleBookingLineChange(this.props.index, ob)
	}

	public render() {
		const { dayProperties, index } = this.props

		return (
			<div className="row -split -wide -bookingtimes">
							
				<div className="form-field">
					<label className="label">Day</label>
					{
						dayProperties.repeat === Api.NewAppointment.RepeatOptionEnum.None ? (
							<div className="form-input -date">
								<Datetime
									className="field"
									dateFormat="D MMM YYYY"
									timeFormat={false}
									inputProps={{ readOnly: true, placeholder: 'Select a date…' }}
									onChange={this.handleBookingDate}
									initialValue={moment()}
									isValidDate={f.isSelectableBookingStartDate}
									value={dayProperties.date && moment(dayProperties.date!.toString(), ['YYYY-MM-DD']).toDate()}
								/>
							</div>
						) : (
							<div className="form-input -select">
								<select 
									className="select" 
									onChange={this.handleDayOfWeekChange} 
									value={dayProperties.dayOfWeek} 
									disabled={((dayProperties.repeat === Api.NewAppointment.RepeatOptionEnum.MondayToFriday) || (dayProperties.repeat === Api.NewAppointment.RepeatOptionEnum.Daily)) ? true : false}
								>
									{
										Object.keys(t.DayOfWeekEnum).map(key => {
											const value = t.DayOfWeekEnum[key]
											return (
												<option key={key} value={value}>{value}</option>
											)
										})
									}
								</select>
							</div>
						)
					}
				</div>

				<div className="form-field">
					<label className="label">Start time</label>
					<div className="form-input -select">
						{dayProperties.durationOption && dayProperties.durationOption === this.DurationOptionEnum.Overnight ? (
							<select 
								className="select" 
								value={
									dayProperties.startTimeOption === this.StartTimeOptionEnum.Specified ? 
										(dayProperties.startTime!.toString()) : 
										(this.StartTimeOptionEnum[dayProperties.startTimeOption])}
								disabled={true}
							>
								<option value={this.StartTimeOptionEnum.Flexible}>Flexible</option>
								<option value={this.StartTimeOptionEnum.Morning}>Morning</option>
								<option value={this.StartTimeOptionEnum.Afternoon}>Afternoon</option>
								{
									dayProperties.startTime && 
									<option value={dayProperties.startTime.toString()}>{moment(dayProperties.startTime.toString(), ['hh:mm']).format('h:mma')}</option>
								}
							</select>
						) : (
							<select 
								className="select" 
								onChange={this.handleStartTimeChange} 
								value={
									dayProperties.startTimeOption === this.StartTimeOptionEnum.Specified ? 
										(dayProperties.startTime!.toString()) : 
										(this.StartTimeOptionEnum[dayProperties.startTimeOption])}
							>
								<option value={this.StartTimeOptionEnum.Flexible}>Flexible</option>
								<option value={this.StartTimeOptionEnum.Morning}>Morning</option>
								<option value={this.StartTimeOptionEnum.Afternoon}>Afternoon</option>
								{
									f.bookingTimes().map((bookingTime, key) => {
										return (
											<option key={key} value={bookingTime.toString()}>{moment(bookingTime.toString(), ['hh:mm']).format('h:mma')}</option>
										)
									})
								}
							</select>
						)}
					</div>
				</div>

				<div className="form-field">
					<label className="label">Duration</label>
					<div className="form-input -select">
						<select className="select" onChange={this.handleDurationChange} value={dayProperties.durationOption === this.DurationOptionEnum.Specified ? dayProperties.durationMinutes : this.DurationOptionEnum[dayProperties.durationOption]}>
							<option value={this.DurationOptionEnum.NotSpecified}>Unsure</option>
							{
								f.durations(30, 480).map((duration, key) => {
									return (
										<option key={key} value={duration.minutes}>{duration.toText}</option>
									)
								})
							}
							<option value={this.DurationOptionEnum.Overnight}>Overnight</option>
						</select>
					</div>
				</div>

				{
					features.repeatBooking &&
					<div className="form-field">
						<label className="label">Repeat</label>
						<div className="form-input -select">
							<select className="select" onChange={this.handleRepeatChange} value={dayProperties.repeat}>
								{
									Object.keys(Api.NewAppointment.RepeatOptionEnum).map(key => {
										const value = Api.NewAppointment.RepeatOptionEnum[key]
										return (
											<option key={key} value={value}>{value === Api.NewAppointment.RepeatOptionEnum.MondayToFriday ? 'Monday to Friday' : value}</option>
										)
									})
								}
							</select>
						</div>
					</div>
				}
				
				<div className="form-field -services">
					<label className="label">Services required</label>
					<div className="form-input -select">
						<select className="select" onChange={this.handleServiceChange} value={dayProperties.services[0]}>
							{/* NOTE: Always have the blank option so the user has to choose the service, as we rely on the onChange to set it for us */}
							<option value=""/>
							{
								this.props.jobTypes && this.props.jobTypes.map((service, key) => {
									return (
										<option key={key} value={service.ref}>{service.name}</option>
									)
								})
							}
						</select>
					</div>
				</div>
				
				{index !== 0 && <div onClick={() => this.props.removeRow(this.props.index)} className="remove-row">Remove this day</div>}
			</div>
		)
	}
}
