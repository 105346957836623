/**
 * Container template for a component.
 */

/* Import the component from the component path */
import Component, { SearchForm } from '../components/AllSupportWorkersSearch'

import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'
import { Api } from 'typescript-fetch-api'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'

/* Import module actions */
import * as a from '../actions'
import * as cs from 'modules/common/selectors'
import * as as from 'modules/account/selectors'
import * as s from 'modules/support-workers/selectors'

/**
 * Interface for properties that the container passes to the component.
 */
export interface Props {
	currentClient?: DeepReadonly<Api.Client>
	genders?: DeepReadonly<Api.Gender[]>
	languages?: DeepReadonly<Api.Language[]>
	jobTypes?: DeepReadonly<Api.JobType[]>
	skills?: DeepReadonly<Api.Skill[]>
	searchForm?: SearchForm
	searching: boolean
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
	onSearch: (request: SearchForm) => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState): Props => {
	return {
		currentClient: as.currentClientSelector(state),
		genders: cs.genders(state),
		languages: cs.languages(state),
		jobTypes: cs.jobTypesSelector(state),
		skills: cs.skills(state),
		searchForm: s.currentSearchFormState(state),
		searching: state.supportWorkers.loadingInitial === false && state.supportWorkers.searching,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	onSearch: (request) => {
		dispatch(a.search(request))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
