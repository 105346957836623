import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { Api } from 'typescript-fetch-api'
import * as a from './actions'
import * as aa from 'modules/account/actions'

export type StoreState = DeepReadonly<MutableStoreState>

export interface MutableStoreState {
	funderSearchResultRefs?: Api.ClientOrganisationAccessControl[]
	searchResultClients?: Api.SearchOrganisationClientsResponseRefs
	searching: boolean
	numberOfResults?: number
	currentFunderRef?: string
}

/**
 * The initial store state for this module.
 */
const INITIAL_STATE: StoreState = {
	searching: false,
}

/**
 * Reducer function for this module.
 */
export const reducer = reducerWithInitialState(INITIAL_STATE)

reducer.case(a.search, (state, payload) => ({
	...state, searchForm: payload,
}))

reducer.case(a.searching.started, (state) => ({
	...state, searching: true,
}))

reducer.case(a.searching.done, (state, { params, result }): StoreState => {
	return {
		...state,
		searching: false,
		funderSearchResultRefs: result.payload.clients,
		searchResultClients: result.refs,
		numberOfResults: result.payload.clients.length,
	}
})

reducer.case(a.searching.failed, (state): StoreState => ({
	...state,
	funderSearchResultRefs: undefined,
	searchResultClients: undefined,
	numberOfResults: undefined,
	searching: false,
}))

reducer.case(a.updateCurrentFunderRef, (state, payload): StoreState => {
	return {
		...state,
		currentFunderRef: payload,
	}
})

reducer.case(aa.currentUserDetailsAsync.done, (state, payload): StoreState => {
	const userOrgs = payload.result.refs && payload.result.refs.organisations

	if (userOrgs && userOrgs[Object.keys(userOrgs)[0]]) {
		return {
			...state, 
			currentFunderRef: userOrgs[Object.keys(userOrgs)[0]].ref,
		}
	} else {
		return {
			...state, 
		}
	}
})