import * as React from 'react'
import { Props, OwnProps, Actions } from '../containers/SupportNetworkInvite'
import { Api } from 'typescript-fetch-api'
import { withModal } from 'modules/common/modal-hoc'

interface State {
	email: string
	role?: Api.ClientSupportNetworkInvitationRequest.RoleEnum
	managerCapability: boolean
	viewClientInfoCapability: boolean
	viewRosterCapability: boolean	
	manageRosterCapability: boolean
	manageClientInfoCapability: boolean
	viewTimesheetsCapability: boolean
	manageTimesheetsCapability: boolean
}

const INITIAL_STATE: State = {
	email: '',
	managerCapability: false,
	viewClientInfoCapability: false,
	viewRosterCapability: false,
	manageRosterCapability: false,
	manageClientInfoCapability: false,
	viewTimesheetsCapability: false,
	manageTimesheetsCapability: false,
}

export default withModal(class SupportNetworkInviteModal extends React.Component<Props & OwnProps & Actions, State> {

	public state = INITIAL_STATE

	onSendInvitation = () => {
		if ((this.state.managerCapability || this.state.viewClientInfoCapability || 
				this.state.viewRosterCapability || this.state.viewTimesheetsCapability || 
				this.state.manageRosterCapability || this.state.manageClientInfoCapability || this.state.manageTimesheetsCapability) && 
				(this.state.email !== '' || this.state.manageRosterCapability || this.state.manageClientInfoCapability)) {
			this.props.onSendInvitation({
				email: this.state.email,
				role: Api.ClientSupportNetworkInvitationRequest.RoleEnum.Supporter,
				capabilities: {
					manager: this.state.managerCapability,
					manageRoster: this.state.manageRosterCapability,
					viewRoster: this.state.viewRosterCapability,
					manageClientDetails: this.state.manageClientInfoCapability,
					viewClientDetails: this.state.viewClientInfoCapability,
					viewTimesheets: this.state.viewTimesheetsCapability,
					manageTimesheets: this.state.manageTimesheetsCapability,
				},
			})
			this.props.onClose()
		}
	}

	handleEmailChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ email: evt.target.value })
	}

	toggleManagerCapability = () => {
		if (this.state.managerCapability === true) {
			this.setState({ managerCapability: false })
		} else {
			this.setState({
				managerCapability: true,
				manageRosterCapability: false,
				viewRosterCapability: false,
				manageClientInfoCapability: false,
				viewClientInfoCapability: false,
				manageTimesheetsCapability: false,
				viewTimesheetsCapability: false,
			})
		}
	}

	toggleClientInfoCapability = () => {
		if (this.state.viewClientInfoCapability === true || this.state.manageClientInfoCapability === true) {
			this.setState({
				viewClientInfoCapability: false,
				manageClientInfoCapability: false,
			})
		} else {
			this.setState({ manageClientInfoCapability: !this.state.manageClientInfoCapability })
		}
	}
	
	toggleManageViewClientInfoCapability = (evt: React.ChangeEvent<HTMLSelectElement>) => {
		if (evt.target.value === 'manage') {
			this.setState({
				manageClientInfoCapability: true,
				viewClientInfoCapability: false,
			})
		} else {
			this.setState({
				manageClientInfoCapability: false,
				viewClientInfoCapability: true,
			})
		}
	}

	toggleTimesheetsCapability = () => {
		if (this.state.viewTimesheetsCapability === true || this.state.manageTimesheetsCapability === true) {
			this.setState({
				viewTimesheetsCapability: false,
				manageTimesheetsCapability: false,
			})
		} else {
			this.setState({ manageTimesheetsCapability: !this.state.manageTimesheetsCapability })
		}
	}
	
	toggleManageTimesheetsCapability = (evt: React.ChangeEvent<HTMLSelectElement>) => {
		if (evt.target.value === 'manage') {
			this.setState({
				manageTimesheetsCapability: true,
				viewTimesheetsCapability: false,
			})
		} else {
			this.setState({
				manageTimesheetsCapability: false,
				viewTimesheetsCapability: true,
			})
		}
	}

	toggleRosterCapability = () => {
		if (this.state.viewRosterCapability === true || this.state.manageRosterCapability === true) {
			this.setState({
				viewRosterCapability: false,
				manageRosterCapability: false,
			})
		} else {
			this.setState({ manageRosterCapability: !this.state.manageRosterCapability })
		}
	}

	toggleManageViewRosterCapability = (evt: React.ChangeEvent<HTMLSelectElement>) => {
		if (evt.target.value === 'manage') {
			this.setState({
				manageRosterCapability: true,
				viewRosterCapability: false,
			})
		} else {
			this.setState({
				manageRosterCapability: false,
				viewRosterCapability: true,
			})
		}
	}

	public render() {
		return (
			<div className="invitation">
				<div className="content">
					
					<div className="form-layout">
						<div className="row">
							<h1 className="page-title">Send an invitation</h1>
							<div className="body-text">
								<p>
									Enter the email address for the person you‘d like to invite.
								</p>
							</div>
						</div>
						<div className="row">
							<div className="form-field">
								<label className="label">Email address</label>
								<div className="form-input -text">
									<input type="email" className="field" value={this.state.email} onChange={this.handleEmailChange}/>
								</div>
							</div>
						</div>
						<div className="row">
							<h2 className="form-title">What level of access would you like them to have?</h2>
							<p className="note">(Note: you can edit this later)</p>
							<div className="form-field">
								<div className="form-input -options">
									<div className="option-inputs -stacked">
										<div className="option -checkbox">
											<label className="label">
												<input 
													type="checkbox" 
													name="manager" 
													className="checkbox"
													checked={this.state.managerCapability} 
													onChange={this.toggleManagerCapability}
												/>
												<span className="substitute" />
												Full Access
											</label>
										</div>
										<div className="option -checkbox">
											<label className="label">
												<input 
													type="checkbox" 
													name="roster" 
													className="checkbox"
													checked={this.state.viewRosterCapability || this.state.manageRosterCapability} 
													onChange={this.toggleRosterCapability}
													disabled={this.state.managerCapability}
												/>
												<span className="substitute" />
												Calendar
											</label>
											{
												(this.state.viewRosterCapability || this.state.manageRosterCapability) &&
												<div className="form-input -select">
													<select 
														className="select -micro" 
														onChange={this.toggleManageViewRosterCapability}
														value={this.state.manageRosterCapability ? 'manage' : this.state.viewRosterCapability ? 'view' : undefined}
													>
														<option value="manage">Manage</option>
														<option value="view">View</option>
													</select>
												</div>
											}
										</div>
										<div className="option -checkbox">
											<label className="label">
												<input 
													type="checkbox" 
													name="clientDetails" 
													className="checkbox"
													checked={this.state.viewClientInfoCapability || this.state.manageClientInfoCapability} 
													onChange={this.toggleClientInfoCapability}
													disabled={this.state.managerCapability}
												/>
												<span className="substitute" />
												Client information
											</label>
											{
												(this.state.viewClientInfoCapability || this.state.manageClientInfoCapability) &&
												<div className="form-input -select">
													<select 
														className="select -micro" 
														onChange={this.toggleManageViewClientInfoCapability}
														value={this.state.manageClientInfoCapability ? 'manage' : this.state.viewClientInfoCapability ? 'view' : undefined}
													>
														<option value="manage">Manage</option>
														<option value="view">View</option>
													</select>
												</div>
											}
										</div>
										{this.props.supportsTimesheets && (
											<div className="option -checkbox">
												<label className="label">
													<input 
														type="checkbox" 
														name="timesheets" 
														className="checkbox"
														checked={this.state.viewTimesheetsCapability || this.state.manageTimesheetsCapability} 
														onChange={this.toggleTimesheetsCapability}
														disabled={this.state.managerCapability}
													/>
													<span className="substitute" />
													Timesheets
												</label>
												{
													(this.state.viewTimesheetsCapability || this.state.manageTimesheetsCapability) &&
													<div className="form-input -select">
														<select 
															className="select -micro" 
															onChange={this.toggleManageTimesheetsCapability}
															value={this.state.manageTimesheetsCapability ? 'manage' : this.state.viewTimesheetsCapability ? 'view' : undefined}
														>
															<option value="manage">Manage</option>
															<option value="view">View</option>
														</select>
													</div>
												}
											</div>
										)}
									</div>
									{/*
									<ul className="option-inputs">
										<li className="option -checkbox">
											<label className="label">
												<input type="checkbox" className="checkbox" checked={this.state.managerCapability} onChange={this.toggleManagerCapability}/><span className="substitute" />
												Full access<br />
												<span className="note">
													This grants full access to your account. This is ideal for when you need someone 
													else to manage your care for you, and supercedes the View Info and Calendar options below.
												</span>
											</label>
										</li>
										<li className="option -radio">
											<label className="label">
												<input 
													type="checkbox" 
													className="checkbox" 
													checked={this.state.viewClientInfoCapability} 
													onChange={this.toggleViewClientInfoCapability}
													disabled={this.state.managerCapability}
												/><span className="substitute" />
												View info<br />
												<span className="note">
													This grants access to view your personal details, but not your calendar.
												</span>
											</label>
										</li>
										<li className="option -radio">
											<label className="label">
												<input 
													type="checkbox" 
													className="checkbox" 
													checked={this.state.viewRosterCapability} 
													onChange={this.toggleViewRosterCapability}
													disabled={this.state.managerCapability}
												/><span className="substitute" />
												View calendar<br />
												<span className="note">
													This grants access to view your calendar and the details of each booking, but not to edit.
												</span>
											</label>
										</li>
									</ul>
									*/}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="button-group">
								<div className="button">
									<div className="button-link -text" onClick={this.props.onClose}>Cancel</div>
								</div>
								<div className="button">
									<div className="button-link -action" onClick={this.onSendInvitation}>Send invitation</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		)
	}
})
