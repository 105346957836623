import * as React from 'react'
import { Props, Actions } from '../containers/Timesheet'
import TimesheetEditJob from '../containers/TimesheetEditJob'
import TimesheetNewJob from '../containers/TimesheetNewJob'
import { Route, RouteComponentProps, Switch } from 'react-router-dom'
import Week from '../containers/Week'
import TotalHoursPerPerson from './TotalHoursPerPerson'
import TotalHoursByType from './TotalHoursByType'
import * as cf from 'modules/calendar/functions'
import Waiting from 'modules/common/components/Waiting'
import { Api } from 'typescript-fetch-api'

export default class Timesheet extends React.Component<Props & Actions & RouteComponentProps<{ [x: string]: string | undefined }>> {

	public render() {
		const { timesheet, timesheetAppointments, prevTimesheetRef, nextTimesheetRef } = this.props

		if (!timesheet) {
			return <Waiting />
		}

		const reasonWhyCannotComplete = timesheet.reasonWhyCannotComplete

		return (
			<article className="site-body">
				<div className="width-limit -site">
					<div className="body">
						{timesheetAppointments && 
							<Week 
								appointments={timesheetAppointments} 
								timesheet={timesheet}
								prevTimesheetRef={prevTimesheetRef}
								nextTimesheetRef={nextTimesheetRef}
							/>
						}
						<div className="timesheet-header totals-row">
							<div className="right-group">
								<div className="hours">
									<p className="-bold">Total hours</p>
									<p>{timesheetAppointments && cf.formatDuration(timesheetAppointments.totalMinutes, true, true)}</p>
								</div>
								<div className="button-group">
									<div className="button">
										{timesheet.canComplete &&
										<a 
											onClick={timesheet.canComplete ? this.onSubmitTimesheet : undefined}
											className={'button-link -action' + (timesheet.canComplete ? '' : ' -disabled')
										+ (this.props.submitting ? ' -processing' : '')}
										>
										Submit timesheet
										</a>
										}
										{timesheet.canRecall && timesheet.canComplete && ' '}
										{timesheet.canRecall && (
											<a 
												onClick={this.onRecallTimesheet}
												className={'button-link -action' + (timesheet.canRecall ? '' : ' -disabled')
											+ (this.props.recalling ? ' -processing' : '')}
											>
											Recall timesheet
											</a>
										)}
										{timesheet.canRecall && !timesheet.canComplete && ' '}
										{!timesheet.canComplete && this.renderCannotCompleteReason(reasonWhyCannotComplete)}
									</div>
								</div>
							</div>
						</div>
						{this.props.hoursSummary && (
							<>
								<div className="timesheet-header">
									<h1 className="headline-text">Totals for this week</h1>
								</div>
								<div className="weekly-totals">
									<TotalHoursPerPerson summary={this.props.hoursSummary} />
									<TotalHoursByType summary={this.props.hoursSummary} />
								</div>
							</>
						)}
					</div>
				</div>
				{
					this.props.bookingToEdit &&
					<Switch>
						<Route 
							path={`${this.props.match.url}/:date/:jobRef`}
							render={props => (
								<TimesheetEditJob timesheetRef={timesheet ? timesheet.ref : undefined} bookingToEdit={this.props.bookingToEdit!}/>
							)}
						/>
					</Switch>
				}
				<Switch>
					<Route 
						path={`${this.props.match.url}/new`}
						render={props => {
							return (
								<TimesheetNewJob timesheetRef={timesheet ? timesheet.ref : undefined} />
							)
						}}
					/>
				</Switch>
			</article>
		)
	}

	private renderCannotCompleteReason = (reason: Api.Timesheet.ReasonWhyCannotCompleteEnum | undefined) => {
		switch (reason) {
			case Api.Timesheet.ReasonWhyCannotCompleteEnum.NoIncompleteJobs:
				return (
					<a href="#" className="button-link -completed" onClick={this.doNothing}>Timesheet submitted</a>
				)
			case Api.Timesheet.ReasonWhyCannotCompleteEnum.ChangesArePending:
				return (
					<p className="unassigned">There are pending changes in your timesheet that must be approved.</p>
				)
			case Api.Timesheet.ReasonWhyCannotCompleteEnum.OverBudget:
				return (
					<p className="unassigned">Your timesheet is over budget.</p>
				)
			case Api.Timesheet.ReasonWhyCannotCompleteEnum.NoJobs:
				return (
					<p className="unassigned">There are no jobs in your timesheet.</p>
				)
			default:
				return null
		}
		
	}

	private onSubmitTimesheet = (evt: React.MouseEvent) => {
		evt.preventDefault()
		this.props.onSubmitTimesheet(this.props.timesheet!.ref)
	}

	private onRecallTimesheet = (evt: React.MouseEvent) => {
		evt.preventDefault()
		this.props.onRecallTimesheet(this.props.timesheet!.ref)
	}

	private doNothing = (evt: React.MouseEvent) => {
		evt.preventDefault()
	}
}
