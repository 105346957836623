import * as React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import LineChart from './LineChart'
import { Api } from 'typescript-fetch-api'
import Moment from 'react-moment'
import numeral from 'numeral'
import * as f from '../functions'

export interface OwnProps {
	individualisedFunding: DeepReadonly<Api.IndividualisedFunding>
	funder?: DeepReadonly<Api.Funder>
	transactions: DeepReadonlyObject<{ [key: string]: Api.IndividualisedFundingTransaction }>
}

interface State {
	// myValue: string
}

/**
 * The initial state for our internal component state.
 */
const INITIAL_STATE: State = {
	// myValue: 'Example',
}

export default class Overview extends React.Component<OwnProps, State> {
	public state = INITIAL_STATE

	public render() {
		const { individualisedFunding } = this.props

		return (
			<div className="individualised-funding-screen body body-text">
				<Helmet>
					<title>My funding</title>
				</Helmet>
				<h1 className="headline-text">{this.props.funder && this.props.funder.name ? this.props.funder.name : 'My funding'}</h1>
				<div className="form-layout">
					<div className="row -split -half">
						<div className="form-field">
							<label className="label">Funding allocation</label>
							<div className="form-input">
								<p>{numeral(individualisedFunding.openingBalanceNZD).format('$0,0.00')}</p>
							</div>
						</div>
						<div className="form-field">
							<label className="label">Funding period</label>
							<div className="form-input">
								<p>
									<Moment date={individualisedFunding.startDate} format="D MMM YYYY" />
									{individualisedFunding.endDate && (
										<>— <Moment date={individualisedFunding.endDate} format="D MMM YYYY" /></>
									)}
								</p>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="funding-remaining">
							<h2>{numeral(individualisedFunding.closingBalanceNZD).format('$0,0.00')} remaining</h2>
							<p>for {f.fundingRemainingText(individualisedFunding)}</p>
						</div>
						<LineChart data={f.prepareDataForGraph(this.props.individualisedFunding, this.props.transactions)} className="individualised-funding-chart" />
						{individualisedFunding.needsLevel !== Api.IndividualisedFunding.NeedsLevelEnum.Level3 && (
							<p>You are currently managing your own Support Workers. If you would like Geneva to do this for you, change your <Link to={'/account/individualised-funding/' + individualisedFunding.ref + '/support-workers'}>Support Worker settings</Link>.</p>
						)}
						{individualisedFunding.needsLevel === Api.IndividualisedFunding.NeedsLevelEnum.Level1 && 
							<>
								<h2>Expense claims</h2>
								<p>If you haven’t already, please go to the Expense Claims section to submit any expense claims you have for the period: <br/>
									<span className="-bold">{f.calculateCurrentTimesheetPeriod()}</span>
								</p>
								<div className="button-group -singlebutton">
									<div className="button">
										<Link to={'/account/individualised-funding/' + individualisedFunding.ref + '/timesheets-expenses'} className="button-link -action -small">Go to expense claims</Link>
									</div>
								</div>
							</>
						}
						{individualisedFunding.needsLevel === Api.IndividualisedFunding.NeedsLevelEnum.Level2 && 
							<>
								<h2>Timesheets &amp; Expense claims</h2>
								<p>If you haven’t already, please submit any timesheets or expense claims you have for the period: <br/>
									<span className="-bold">{f.calculateCurrentTimesheetPeriod()}</span>
								</p>
								<div className="button-group -singlebutton">
									<div className="button">
										<Link to={'/account/individualised-funding/' + individualisedFunding.ref + '/timesheets-expenses'} className="button-link -action -small">Go to timesheets</Link>
									</div>
								</div>
							</>
						}
						{individualisedFunding.needsLevel === Api.IndividualisedFunding.NeedsLevelEnum.Level3 && 
							<>
								<h2>Expense claims</h2>
								<p>If you require reimbursement for any extra expenses, please submit a claim form.</p>
								<div className="button-group -singlebutton">
									<div className="button">
										<Link to={'/account/individualised-funding/' + individualisedFunding.ref + '/timesheets-expenses'} className="button-link -action -small">Go to expense claims</Link>
									</div>
								</div>
							</>
						}
					</div>
				</div>
			</div>
		)
	}
}
