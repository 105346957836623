import actionCreatorFactory from 'typescript-fsa'
import { Api } from 'typescript-fetch-api'

/**
 * The action creator for this module. Note that it contains the module name.
 */
const actionCreator = actionCreatorFactory('Account')

export const currentUserDetailsAsync = actionCreator.async<never, Api.UserDetailsResponse, Error>('CURRENT_USER_DETAILS')
export const currentUserClientsAsync = actionCreator.async<number, Api.UserSupportNetworkMembershipsResponse, Error>('CURRENT_USER_CLIENTS')
export const clientDetailsAsync = actionCreator.async<string, Api.Client, Error>('CLIENT_DETAILS')

/** Action fired when the app has started loading account details. */
export const loadingAccount = actionCreator('LOADING_ACCOUNT')
/** Action fired when all of the account loading is complete, and the app is now ready to use. */
export const loadedAccount = actionCreator('LOADED_ACCOUNT')

interface ChooseClientPayload {
	clientRef?: string
	interactive: boolean
}
export const chooseClient = actionCreator<ChooseClientPayload>('CHOOSE_CLIENT')
export type ChooseClientAction = ReturnType<typeof chooseClient>

export const sendSupportNetworkInvitation = actionCreator<DeepReadonly<Api.ClientSupportNetworkInvitationRequest>>('SUPPORT_NETWORK_INVITE')
export type SendSupportNetworkInvitationAction = ReturnType<typeof sendSupportNetworkInvitation>
export const sendSupportNetworkInvitationAsync = actionCreator.async<Api.ClientSupportNetworkInvitationRequest, never, Error>('SUPPORT_NETWORK_INVITE')
export type SendSupportNetworkInvitationDoneAction = ReturnType<typeof sendSupportNetworkInvitationAsync.done>

export interface UpdateUserCapabilitiesPayload {
	request: Api.UpdateUserCapabilitiesInClientSupportNetworkRequest
	userRef: string
}
export const updateSupportNetworkCapabilities = actionCreator<UpdateUserCapabilitiesPayload>('UPDATE_SUPPORT_NETWORK_CAPABILITIES')
export type UpdateSupportNetworkCapabilitiesAction = ReturnType<typeof updateSupportNetworkCapabilities>
export const updateSupportNetworkCapabilitiesAsync = actionCreator.async<UpdateUserCapabilitiesPayload, never, Api.UpdateUserCapabilitiesInClientSupportNetworkError>('UPDATE_SUPPORT_NETWORK_CAPABILITIES')

export const showMyDetails = actionCreator('SHOW_MY_DETAILS')

export interface UpdateClientDetailsActionPayload {
	clientRef: string
	request: Api.UpdateClientDetailsRequest
}

export const updateClientDetails = actionCreator<UpdateClientDetailsActionPayload>('UPDATE_CLIENT_DETAILS')
export type UpdateClientDetailsAction = ReturnType<typeof updateClientDetails>
export const updateClientDetailsAsync = actionCreator.async<Api.UpdateClientDetailsRequest, Api.UpdateClientDetailsResponse, Api.UpdateClientDetailsError>('UPDATE_CLIENT_DETAILS')
export type UpdateClientDetailsFailedAction = ReturnType<typeof updateClientDetailsAsync.failed>

export const updateUserDetails = actionCreator<Api.UpdateUserDetailsRequest>('UPDATE_USER_DETAILS')
export type UpdateUserDetailsAction = ReturnType<typeof updateUserDetails>
export const updateUserDetailsAsync = actionCreator.async<Api.UpdateUserDetailsRequest, Api.UpdateUserDetailsResponse, Api.UpdateUserDetailsError>('UPDATE_USER_DETAILS')

export const changePassword = actionCreator<Api.ChangePasswordRequest>('CHANGE_PASSWORD')
export type ChangePasswordAction = ReturnType<typeof changePassword>
export const changePasswordAsync = actionCreator.async<Api.ChangePasswordRequest, never, Api.ChangePasswordError>('CHANGE_PASSWORD')

export const loadClientSupportNetworkAsync = actionCreator.async<string, Api.ClientSupportNetworkResponse, Api.ClientSupportNetworkError>('LOAD_CLIENT_SUPPORT_NETWORK')

export interface RevokeSupportNetworkInvitePayload {
	clientRef: string
	inviteRef: string
}
export const revokeSupportNetworkInvite = actionCreator<RevokeSupportNetworkInvitePayload>('REVOKE_INVITE')
export type RevokeSupportNetworkInviteAction = ReturnType<typeof revokeSupportNetworkInvite>
export const revokeSupportNetworkInviteAsync = actionCreator.async<RevokeSupportNetworkInvitePayload, never, Api.ChangePasswordError>('REVOKE_INVITE')

export const declineSupportNetworkInvite = actionCreator<RevokeSupportNetworkInvitePayload>('DECLINE_INVITE')
export type DeclineSupportNetworkInviteAction = ReturnType<typeof declineSupportNetworkInvite>
export const declineSupportNetworkInviteAsync = actionCreator.async<RevokeSupportNetworkInvitePayload, never, Api.ChangePasswordError>('DECLINE_INVITE')

export const removeUserFromSupportNetwork = actionCreator<string>('REMOVE_SUPPORT_NETWORK_USER')
export type RemoveUserFromSupportNetworkAction = ReturnType<typeof removeUserFromSupportNetwork>
export const removeUserFromSupportNetworkAsync = actionCreator.async<string, never, Api.RemoveUserFromClientSupportNetworkError>('REMOVE_SUPPORT_NETWORK_USER')

export const leaveSupportNetwork = actionCreator('LEAVE_SUPPORT_NETWORK')
export type LeaveSupportNetworkAction = ReturnType<typeof leaveSupportNetwork>
export const leaveSupportNetworkAsync = actionCreator.async<never, never, Api.RemoveUserFromClientSupportNetworkError>('LEAVE_SUPPORT_NETWORK')