import Component from '../components/FundingDetails'
import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'
import { RootStoreState } from 'modules/root'
import * as calendarSelectors from 'modules/calendar/selectors'
import { Funding } from 'modules/calendar/types'
import { currentClientSelector, clientIsYou } from 'modules/account/selectors'

/** Interface for properties that are passed to the container/ */
export interface OwnProps {}

/** Interface for properties that the container passes to the component. */
export interface Props {
	displayName?: string
	you: boolean
	funding?: Funding
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
	// onExample: (value: string) => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	const currentClient = currentClientSelector(state)
	const you = currentClient ? clientIsYou(state, currentClient.ref) : false
	return {
		displayName: currentClient ? (currentClient.knownAs ? currentClient.knownAs : currentClient.givenName) : undefined,
		you,
		funding: calendarSelectors.currentFunding(state),
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	// onExample: (value) => {
	// 	dispatch(actions.examplePrimitiveAction(value))
	// },
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
