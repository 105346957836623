import * as React from 'react'
import { Props, Actions, OwnProps } from '../containers/Header'
import { Link } from 'react-router-dom'
import { Switch, Route } from 'react-router'
import AccountMenu from 'modules/account/containers/AccountMenu'
import HeaderNav from '../containers/HeaderNav'
import { createAccount, registerForCare } from 'modules/root/features'
import platform, { Brand } from 'modules/platform'

interface State {
	menuOpen: boolean
}

const INITIAL_STATE: State = {
	menuOpen: false,
}

export default class Header extends React.Component<Props & Actions & OwnProps, State> {

	public state = INITIAL_STATE

	toggleMenu = () => {
		this.setState({ menuOpen: !this.state.menuOpen })
	}

	closeMenu = () => {
		this.setState({ menuOpen: false })
	}

	onRegisterForCare = () => {
		this.props.onRegisterForCare()
	}

	onSignOut = () => {
		this.props.onSignOut()
	}

	public render() {
		const { section, currentClientView, hasFunderAccess, loggedIn } = this.props

		return (
			<header className="site-header -signedin js-site-header">
				{loggedIn && <HeaderNav/>}
				<input type="checkbox" id="main-nav-menustate" className="main-nav-menustate js-main-nav-menustate" hidden={true} checked={this.state.menuOpen} readOnly={true} />
				<div className="width-limit -site nav-container js-nav-container">
					<div className="site-logos">
						<figure className="site-logo -geneva">
							<Link to="/" className="link">My Homecare</Link>
						</figure>
						{platform.brand() === Brand.Hcnz && !loggedIn && 
							<>
								<div className="provider-logo-wrapper">
									<div className="provider-separator"></div>
									<figure className="provider-logo">
										<Link to="/" className="link"></Link>
									</figure>
								</div>
							</>
						}
					</div>
					<nav className="site-nav js-main-nav">
						<ul className="navitems ui-text">
							{loggedIn ? (
								<>
									<AccountMenu section={section} closeMobileMenu={this.closeMenu} />
									{ currentClientView ? (
										<>
											<li className={'navitem' + (section === 'dashboard' ? ' -selected' : '')}><Link to="/"><span className="label">Dashboard</span></Link></li>
											{currentClientView.capabilities.viewRoster && (
												<li className={'navitem' + (section === 'calendar' ? ' -selected' : '')}><Link to="/calendar"><span className="label">Calendar</span></Link></li>
											)}
											{currentClientView.showTimesheets && (
												<li className={'navitem' + (section === 'timesheet' ? ' -selected' : '')}><Link to="/timesheet"><span className="label">Timesheet</span></Link></li>
											)}
											{currentClientView.capabilities.manageRoster && this.props.findSupportWorkers && (
												<li className={'navitem' + (section === 'support-workers' ? ' -selected' : '')}><Link to="/support-workers"><span className="label">Support workers</span></Link></li>
											)}
										</>
									) : (hasFunderAccess &&
									<>
										<li className={'navitem' + (section === 'dashboard' ? ' -selected' : '')}><Link to="/"><span className="label">Dashboard</span></Link></li>
										<li className={'navitem' + (section === 'client-search' ? ' -selected' : '')}><Link to="/client-search"><span className="label">Search</span></Link></li>
										{/* <li className={'navitem' + (section === 'reports' ? ' -selected' : '')}><Link to=""><span className="label">Reports</span></Link></li>  */}
									</>
									)}
									<li className={'navitem' + (section === 'account' ? ' -selected' : '')}><Link to="/account"><span className="label">Account</span></Link></li>
									{
										registerForCare &&
									<li className="navitem -secondary -mobileonly"><a onClick={this.onRegisterForCare} className="link"><span className="label">Register for care</span></a></li> 
									}
									<li className="navitem -secondary -mobileonly"><Link to="/help/faq"><span className="label">Help</span></Link></li> 
									<li className="navitem -secondary -mobileonly"><a onClick={this.onSignOut} className="link"><span className="label">Sign out</span></a></li> 
								</>
							) : (
								<Switch>
									<Route
										path={'/sign-in'}
										render={() => createAccount && (
											<li className="navitem -register">
												<Link to="/register" className="button-link -secondary">Create account</Link>
											</li>
										)}
									/>
									<Route 
										path={'/'}
										render={() => (
											<li className="navitem -signin">
												<Link to="/sign-in" className="button-link -action">Sign in</Link>
											</li>
										)}
									/>
								</Switch>
							)}
						</ul>
					</nav>
					<label className="menuburger js-menuburger _no-print" htmlFor="main-nav-menustate" aria-hidden="true" onClick={this.toggleMenu}>
						<span className="ingredient -bun -top">
							<span className="bar" />
						</span>

						<span className="ingredient -patty">
							<span className="bar" />
						</span>

						<span className="ingredient -bun -bottom">
							<span className="bar" />
						</span>
					</label>
				</div>

				<a className="burger js-menu-toggle _no-print"><span className="ingredients" /></a>
			</header>
		)
	}
}
