import Component from '../components/SupportNetwork'
import { Api } from 'typescript-fetch-api'
import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'

import * as t from '../types'
import * as f from '../functions'
import { currentUserSelector, supportNetworkMembershipSelector, clientSelector, clientIsYou, currentClientSelector } from '../selectors'

/* Import module actions */
import * as actions from '../actions'

/** Interface for properties that are passed to the container/ */
export interface OwnProps {
}

/** Interface for properties that the container passes to the component. */
export interface Props {
	supportNetworks?: DeepReadonlyArray<t.SupportNetworkMembership>
	pendingInvitations?: DeepReadonlyArray<Api.ClientSupportNetworkInvitation>
	/** If the account screen is showing a client membership, then this is set */
	capabilitiesForCurrentUser?: DeepReadonly<Api.SupportNetworkCapabilities>
	client?: DeepReadonly<Api.Client>
	/** Else if the account screen is showing for a user who isn't a client, then this is set */
	user?: string
	/** Whether we're showing the account screen for you */
	clientIsYou: boolean
	supportsTimesheets: boolean
}

export interface Actions {
	handleUpdateSupportNetworkCapabilities: (request: actions.UpdateUserCapabilitiesPayload) => void
	revokeInvite: (request: actions.RevokeSupportNetworkInvitePayload) => void
	onResendInvitation: (request: Api.ClientSupportNetworkInvitationRequest) => void
	removeUserFromSupportNetwork: (request: string) => void
	handleLeaveSupportNetwork: () => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	const client = currentClientSelector(state)
	const user = currentUserSelector(state)
	const membership = client ? supportNetworkMembershipSelector(state, client.ref) : undefined
	
	return {
		supportNetworks: state.account.currentClientSupportNetwork && state.account.currentClientSupportNetworkUserRefs ? f.createSupportNetworkView(state.account.currentClientSupportNetwork.memberships, state.account.currentClientSupportNetworkUserRefs, state) : undefined,
		pendingInvitations: state.account.currentClientSupportNetwork ? state.account.currentClientSupportNetwork.invitations : undefined,
		capabilitiesForCurrentUser: membership ? membership.capabilities : undefined,
		client: client ? clientSelector(state, client.ref) : undefined,
		user: user ? user.ref : undefined,
		clientIsYou: client ? clientIsYou(state, client.ref) : false,
		supportsTimesheets: client ? client.timesheets : false,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	handleUpdateSupportNetworkCapabilities: (request) => {
		dispatch(actions.updateSupportNetworkCapabilities(request))
	},
	revokeInvite: (request) => {
		dispatch(actions.revokeSupportNetworkInvite(request))
	},
	onResendInvitation: (request) => {
		dispatch(actions.sendSupportNetworkInvitation(request))
	},
	removeUserFromSupportNetwork: (request) => {
		dispatch(actions.removeUserFromSupportNetwork(request))
	},
	handleLeaveSupportNetwork: () => {
		dispatch(actions.leaveSupportNetwork())
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
