import Component from '../components/CommunityScreen'

import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'
import { currentClientSelector, clientRefsSelector } from 'modules/account/selectors'

/* Import module actions */
// import * as a from '../actions'

/** Interface for properties that are passed to the container/ */
export interface OwnProps {

}

/** Interface for properties that the container passes to the component. */
export interface Props {
	hasClients: boolean
	currentClient: boolean
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
	// onExample: (value: string) => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	return {
		hasClients: clientRefsSelector(state).length > 0,
		currentClient: currentClientSelector(state) ? true : false,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	// onExample: (value) => {
	// 	dispatch(actions.examplePrimitiveAction(value))
	// },
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
