import Component from '../components/Documents'
import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'

/* Import module actions */
// import * as actions from '../actions'
import { featuresSelector } from 'modules/common/selectors'

/** Interface for properties that are passed to the container/ */
interface OwnProps {
	
}

/** Interface for properties that the container passes to the component. */
interface Props {
	featureHealthPassport: boolean
	featureAdvanceCarePlan: boolean
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
interface Actions {
	
}

export type ComponentProps = Props & Actions

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	const features = featuresSelector(state)

	return {
		featureAdvanceCarePlan: features.documents.advancedCarePlan,
		featureHealthPassport: features.documents.healthPassport,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
