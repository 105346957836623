import * as React from 'react'
import Moment from 'react-moment'
import moment from 'moment'
import { formatDuration } from '../functions'
import { Api } from 'typescript-fetch-api'
import * as t from '../types'
import * as commonFunctions from 'modules/common/functions'
import { LocalDate, LocalTime } from '@js-joda/core'
import { avatarPlaceholder } from 'modules/root/theme'

interface OwnProps {
	job: t.JobDetailView
	capabilities: Api.SupportNetworkCapabilities
	calDate: LocalDate
	onEditBooking: () => void
	onCancelBooking: () => void
	onAddFeedback: () => void
	onChangeSupportWorker: () => void
	onViewDetail: (supportWorkerRef: string) => void
	onClose: () => void
	findSupportWorkers: boolean
}

export default class JobDetailModal extends React.Component<OwnProps> {
	isJobCompleted = (): boolean => {
		const endDateTime = this.props.job.startTime ? 
			moment(this.props.job.date + ' ' + this.props.job.startTime.toString()) : 
			moment(this.props.job.date.toString(), ['YYYY-MM-DD'])

		if (endDateTime.isBefore(new Date()) && this.props.job.status !== t.JobViewStatus.Pending && this.props.job.status !== t.JobViewStatus.PendingCancellation) {
			return true
		} else {
			return false
		}
	}

	jobStatusIndicator = () => {
		switch (this.props.job.status) {
			case t.JobViewStatus.Active:
				return null
			case t.JobViewStatus.Pending:
				return (
					<div className="status -pending">Pending</div>
				)
			case t.JobViewStatus.PendingCancellation:
				return (
					<div className="status -pending">Pending cancellation</div>
				)
			case t.JobViewStatus.NotAllocated:
				return (
					// <div className="status -active">Not allocated</div>
					null
				)
			case t.JobViewStatus.Completed:
				return (
					<div className="status -completed">Completed</div>
				)
			case t.JobViewStatus.Cancelled:
				return (
					<div className="status -cancelled">Cancelled</div>
				)
			default:
				return null
		}
	}

	renderStartTime = (startTime?: LocalTime, endTime?: LocalTime, startTimeOption?: Api.Appointment.StartTimeOptionEnum, durationMins?: number) => (
		<>
			{startTime ? (
				<>
					<Moment date={startTime.toString()} parse="hh:mm" format="h:mma" />
					{endTime ? (
						<> — <Moment date={endTime.toString()} parse="hh:mm" format="h:mma" /></>
					) : (
						durationMins &&
						<> — {moment(startTime.toString(), ['hh:mm']).add(durationMins, 'minutes').format('h:mma')}</>
					)}
				</>
			) : startTimeOption === Api.Appointment.StartTimeOptionEnum.Flexible ? (
				<>Flexible start time</>
			) : startTimeOption === Api.Appointment.StartTimeOptionEnum.Morning ? (
				<>Morning</>
			) : startTimeOption === Api.Appointment.StartTimeOptionEnum.Afternoon ? (
				<>Afternoon</>
			) : null}
		</>
	)

	renderDuration = (durationMins?: number, durationOption?: Api.Appointment.DurationOptionEnum) => (
		<>
			{durationMins ? (
				<>{' '}({formatDuration(durationMins)})</>
			) : durationOption === Api.Appointment.DurationOptionEnum.Overnight ? (
				<>{' '}(Overnight)</>
			) : durationOption === Api.Appointment.DurationOptionEnum.NotSpecified ? (
				<>{' '}(duration not yet specified)</>
			) : null}
		</>
	)

	renderRepeat = (job: t.JobDetailView) => (
		<>
			{job.repeatOptionDisplayName &&
				<p className="item">
					<span className="icon -repeat" />
					<span className="details">
						Repeats {job.repeatOptionDisplayName}
						{job.repeatEndDate && (
							<>{' '}until <Moment date={job.repeatEndDate.toString()} format="dddd D MMMM YYYY" /></>
						)}
					</span>
				</p>
			}
		</>
	)

	renderServices = (job: t.JobDetailView) => (
		<>
			{
				job.services && job.services.length > 0 &&
				<p className="item">
					<span className="icon -service" />
					<span className="details">{commonFunctions.joinLast(job.services.map(s => s.name), ', ', ' and ')}</span>
				</p>
			}
		</>
	)

	isJobUpdateable = (): boolean => {
		if (this.props.job.status === t.JobViewStatus.Cancelled || this.props.job.status === t.JobViewStatus.PendingCancellation) {
			return false
		} else if (this.props.job.startTime) {
			const endDateTime = moment(this.props.job.date + ' ' + this.props.job.startTime.toString())
			if (endDateTime.isBefore(new Date())) {
				return false
			} else {
				return true
			}
		} else if (this.props.job.date) {
			const endDate = moment(this.props.job.date.toString(), ['YYYY-MM-DD'])
			if (endDate.isBefore(new Date())) {
				return false
			} else {
				return true
			}
		} else {
			return false
		}
	}

	isJobFeedbackable = (): boolean => {
		if (this.props.job.startTime) {
			const endDateTime = moment(this.props.job.date + ' ' + this.props.job.startTime.toString())
			if (this.props.job.status === t.JobViewStatus.Cancelled) {
				return false
			} else if (endDateTime.isAfter(new Date())) {
				return false
			} else {
				return true
			}
		} else {
			return false
		}
	}

	public render() {
		const { job, capabilities, findSupportWorkers } = this.props
		const supportWorker = job.supportWorker
		const jobUpdateable = this.isJobUpdateable()
		const jobFeedbackable = this.isJobFeedbackable()

		return (
			<div className="jobdetails-modal">
				<h1 className="page-title"><Moment date={job.date.toString()} format="dddd D MMMM YYYY" /></h1>
				{this.jobStatusIndicator()}
				<section className="job-details">
					<p className={'item' + (job.status === t.JobViewStatus.Completed && job.actualStartTime ? ' -time' : '')}>
						<span className="icon -time" />
						<span className="details">
							<strong>
								{this.renderStartTime(job.startTime, job.endTime, job.startTimeOption)}
								{this.renderDuration(job.durationMins, job.durationOption)}
							</strong>
						</span>
					</p>
					{
						job.status === t.JobViewStatus.Completed && job.actualStartTime &&
						<p className="item -actual-time">
							<span className="icon" />
							<span className="details">
								<strong>Actual time:&nbsp;</strong>
								{this.renderStartTime(job.actualStartTime, undefined, undefined, job.actualDurationMins)}
								{this.renderDuration(job.actualDurationMins)}
							</span>
						</p>
					}
					{supportWorker && (
						<div className="item">
							<figure className="avatar-image -large -bordered">
								<div className="image">
									<img src={(job.supportWorker && job.supportWorker.image) || avatarPlaceholder()} alt={job.supportWorker && job.supportWorker.name} />
								</div>
							</figure>
							{
								findSupportWorkers ? (
									<span className="name">with <a onClick={this.onViewDetail}>{supportWorker.fullName}</a></span>
								) : (
									<span className="name">with {supportWorker.fullName}</span>
								)
							}
						</div>
					)}
					{this.renderServices(job)}
					{this.renderRepeat(job)}
				</section>
				{jobUpdateable && (capabilities.manageRoster || capabilities.manager) && (
					<div className="button-group -split">
						<div className="button">
							<div className="button-link -text -destructive" onClick={this.props.onCancelBooking}>Cancel booking</div>
						</div>
						<div className="button -constrained">
							<div onClick={this.props.onEditBooking} className="button-link -action">Edit booking</div>
						</div>
					</div>
				)}
				{jobFeedbackable && (
					<div className="button-group">
						<div className="button -constrained">
							<div className="button-link -action" onClick={this.props.onAddFeedback}>Add feedback</div>
						</div>
					</div>
				)}
			</div>
		)
	}

	private onViewDetail = (evt: React.MouseEvent) => {
		evt.preventDefault()
		if (this.props.job.supportWorkerRef) {
			this.props.onViewDetail(this.props.job.supportWorkerRef)
		}
	}
}
