import * as React from 'react'
import { Snapshot, forComponentProps, wrapComponent, Input, Controller } from 'changeling'
import { IndexedActions, IndexedCursor } from 'changeling/dist/changeling-react'
import { ImportantPerson } from '../types'

export interface OwnProps extends Snapshot<ImportantPerson[] | undefined> {
	editingMode?: boolean
}

class ImportantPeople extends React.Component<OwnProps> {
	private changeling = forComponentProps(this)
	
	public render() {
		const { editingMode, value } = this.props

		return (
			<>
				{ (editingMode || (!editingMode && value)) &&
				<div className={'form-item -important-people' + (editingMode ? ' -no-gutters' : ' -valuemode')}>
					<div className="form-wrapper">
						{
							editingMode ? (
								<Input.Indexed 
									controller={this.changeling} 
									prop="this" 
									renderEach={this.renderChild}
								/>) : value && (
								<div className="value-wrapper">
									{
										value.map((person, key) => {
											return (
												((person.givenName && person.givenName !== '') || 
												(person.familyName && person.familyName !== '') || 
												(person.homePhone && person.homePhone !== '') || 
												(person.relationship && person.relationship !== '')) &&
													<div className="person-wrapper" key={key}>
														<h3 className="subsection-header">Person {key + 1}</h3>
														<p className="sub-text"><span className="title">Given name: </span>{person.givenName}</p>
														<p className="sub-text"><span className="title">Family name: </span>{person.familyName}</p>
														<p className="sub-text"><span className="title">Home phone: </span>{person.homePhone}</p>
														<p className="sub-text"><span className="title">Relationship: </span>{person.relationship}</p>
													</div>
											)
										})
									}
								</div>
							)
						}
					</div>
					{
						editingMode &&
						<Input.Indexed 
							controller={this.changeling}
							prop="this"
							renderAfter={this.renderAfter}
						/>
					}
				</div>
				}
			</>
		)
	}

	private renderChild = (controller: Controller<ImportantPerson>, cursor: IndexedCursor, actions: IndexedActions<ImportantPerson>) => {
		return (
			<div key={cursor.index} className="person-wrapper">
				<div className="form-layout" key={cursor.index}>
					<h3 className="subsection-header">Person {cursor.index + 1}</h3>
					<div className="row -split -half">
						<div className="form-field ">
							<label className="label">Given name(s)</label>
							<div className="form-input -text">
								<Input.String type="text" className="field" controller={controller} prop="givenName" />
							</div>
						</div>
						<div className="form-field">
							<label className="label">Family name</label>
							<div className="form-input -text">
								<Input.String type="text" className="field" controller={controller} prop="familyName" />
							</div>
						</div>
					</div>
					<div className="row -split -half">
						<div className="form-field ">
							<label className="label">Home phone</label>
							<div className="form-input -text">
								<Input.String type="text" className="field" controller={controller} prop="homePhone" />
							</div>
						</div>
						<div className="form-field">
							<label className="label">Daytime phone</label>
							<div className="form-input -text">
								<Input.String type="text" className="field" controller={controller} prop="relationship" />
							</div>
						</div>
					</div>
					{
						!cursor.first &&
						<div onClick={() => actions.onRemove(cursor.index)} className="remove-item -topaligned"/>
					}
				</div>
			</div>
		)
	}

	private renderAfter = (actions: IndexedActions<ImportantPerson>) => {
		return (
			<div className="add-item" onClick={() => actions.onPush({})}>
				<span className="border"/>
				<div className="link">
					<span className="label">Add another person</span>
				</div>
			</div>
		)
	}
}

export default wrapComponent(ImportantPeople)