import * as React from 'react'
import { Link } from 'react-router-dom'
import { Props, OwnProps, Actions } from '../containers/ForgotPassword'
import { RouteComponentProps } from 'react-router'
import { SimpleFormState, Password } from 'formalities'

interface ResetPasswordForm {
	newPassword: string
	confirmNewPassword: string
}

interface State {
	email: string
	form: SimpleFormState<ResetPasswordForm>
}

const INITIAL_STATE: State = {
	email: '',
	form: new SimpleFormState<ResetPasswordForm>({ newPassword: '', confirmNewPassword: '' }),
}

export default class ForgotPassword extends React.Component<Props & OwnProps & Actions & RouteComponentProps<undefined>, State> {

	public state = INITIAL_STATE

	handleEmailNotesChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ email: evt.target.value })
	}

	onNewFormState = (newState: SimpleFormState<ResetPasswordForm>) => {
		this.setState({ form: newState })
	}

	onSubmit = (evt: React.MouseEvent) => {
		evt.preventDefault()

		if (this.state.email !== '') {
			this.props.onForgotPassword(this.state.email)
		}
	}

	onResetPassword = (evt: React.MouseEvent) => {
		evt.preventDefault()
		const result = this.state.form.getValues()

		if (result.newPassword !== '' && result.newPassword === result.confirmNewPassword) {
			this.props.onResetPassword({
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				token: (this.props.location.state as any).resetPasswordToken,
				newPassword: result.newPassword })
		}
	}

	public render() {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const resetPasswordToken = this.props.location.state && (this.props.location.state as any).resetPasswordToken

		return (
			<article className="site-body">
				<div className="width-limit -site">
					<div className="single-form">
						<form className="form">
						
							<div className="form-layout">
								{ resetPasswordToken ? (
									<>
										<div className="introduction">
											<h1 className="headline-text">Reset password</h1>
											<div className="body-text">
												<p>Enter your new password below.</p>
											</div>
										</div>
									
										<div className="row -split -half">
						
											<div className="form-field">
												<label className="label">New password</label>
												<div className="form-input -text">
													<Password formState={this.state.form} onNewFormState={this.onNewFormState} className="field" name="newPassword" autoComplete="new-password" />
												</div>
											</div>
						
											<div className="form-field">
												<label className="label">Confirm new password</label>
												<div className="form-input -text">
													<Password formState={this.state.form} onNewFormState={this.onNewFormState} className="field" name="confirmNewPassword" autoComplete="repeat-new-password" />
												</div>
											</div>
						
										</div>
									
										<div className="row">
											<div className="button-group">
												<div className="button">
													<Link to="/sign-in" className="button-link -text">Sign in</Link>
												</div>
												<div className="button -constrained">
													<div className="button-link -action -nested">
														<input type="submit" value="Reset" onClick={this.onResetPassword}/>
													</div>
												</div>
											</div>
										</div>
									</>
								) : (
									<>
										<div className="introduction">
											<h1 className="headline-text">Forgotten password</h1>
											<div className="body-text">
												<p>Enter your email address below and we‘ll email you a link to reset your password.</p>
											</div>
										</div>
									
										<div className="row">	
											<div className="form-field">
												<label className="label">Email</label>
												<div className="form-input">
													<div className="form-input -text">
														<input type="email" className="field" value={this.state.email} onChange={this.handleEmailNotesChange}/>
													</div>
												</div>
											</div>
										</div>
									
										<div className="row">
											<div className="button-group">
												<div className="button">
													<Link to="/sign-in" className="button-link -text">Sign in</Link>
												</div>
												<div className="button -constrained">
													<div className="button-link -action -nested">
														<input type="submit" value="Send link" onClick={this.onSubmit}/>
													</div>
												</div>
											</div>
										</div>
									</>
								)}
							</div>
							
						</form>

					</div>				
				</div>
				
			</article>
		)
	}
}
