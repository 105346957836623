import actionCreatorFactory from 'typescript-fsa'
import { Api } from 'typescript-fetch-api'

const actionCreator = actionCreatorFactory('Order')
export const newOrder = actionCreator('NEW_ORDER')
export const cancelNewOrder = actionCreator('CANCEL_NEW_ORDER')

export interface SubmitNewOrderPayload {
	notes?: string
	subject: string
}

export const submitNewOrder = actionCreator<SubmitNewOrderPayload>('SUBMIT_NEW_ORDER')
export type SubmitNewOrderAction = ReturnType<typeof submitNewOrder>

export const submitNewOrderAsync = actionCreator.async<SubmitNewOrderPayload, never, Api.AddTicketErrorMessage>('SUBMIT_NEW_ORDER')
export type SubmitNewOrderAsyncDoneAction = ReturnType<typeof submitNewOrderAsync.done>
export type SubmitNewOrderAsyncFailedAction = ReturnType<typeof submitNewOrderAsync.failed>