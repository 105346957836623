import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { Api } from 'typescript-fetch-api'
import { produce, Draft } from 'immer'
import * as _ from 'lodash'

/* Import our module's actions */
import * as a from './actions'
import * as calActions from 'modules/calendar/actions'
import { chooseClient } from 'modules/account/actions'
import { loggedOut } from 'modules/auth/actions'
import { SearchForm } from './components/AllSupportWorkersSearch'

/**
 * Export the StoreState interface for this module. We always name this interface
 * `StoreState` so it is consistent across each of our modules.
 * We export a readonly version of the interface, to try to prevent modifications,
 * and retain a private (not exported) MutableStoreState for ourselves to use very
 * carefully in the reducers, if we need to.
 */
export type StoreState = DeepReadonly<MutableStoreState>

export const HOW_MANY = 21

export interface MutableStoreState {
	refs: Api.PostSupportWorkersSearchResponseRefs
	searchResults?: string[]
	favouriteClientSupportWorkerRelationshipRefs?: string[]
	blockedClientSupportWorkerRelationshipRefs?: string[]
	searchRequest?: Api.PostSupportWorkersSearchRequest
	searchForm?: SearchForm
	showMoreButton: boolean
	searching: boolean
	loadingInitial: boolean
}

/**
 * The initial store state for this module.
 */
const INITIAL_STATE: StoreState = {
	refs: {
		relationships: {},
		supportWorkers: {},
		appointments: {},
	},
	showMoreButton: false,
	searching: false,
	loadingInitial: true,
}

/**
 * Reducer function for this module.
 */
export const reducer = reducerWithInitialState(INITIAL_STATE)

const mergeRefs = (existingRefs: DeepReadonly<Api.PostSupportWorkersSearchResponseRefs>, newRefs: Api.PostSupportWorkersSearchResponseRefs) => ({
	...existingRefs,
	relationships: {
		...existingRefs.relationships,
		...newRefs.relationships,
	},
	supportWorkers: {
		...existingRefs.supportWorkers,
		...newRefs.supportWorkers,
	},
	appointments: {
		...existingRefs.appointments,
		...newRefs.appointments,
	},
})

reducer.case(a.searchInitial, (state) => ({
	...state, loadingInitial: true,
}))

reducer.case(a.search, (state, payload) => ({
	...state, searchForm: payload,
}))

reducer.case(a.searching.started, (state) => ({
	...state, searching: true,
}))

reducer.case(a.searching.done, (state, { params, result }): StoreState => ({
	...state, refs: mergeRefs(state.refs, result.refs), 
	searchResults: params.page ? [...(state.searchResults ? state.searchResults : []), ...result.payload.clientSupportWorkerRelationshipRefs] : result.payload.clientSupportWorkerRelationshipRefs, 
	searchRequest: params,
	showMoreButton: result.payload.clientSupportWorkerRelationshipRefs && result.payload.clientSupportWorkerRelationshipRefs.length === HOW_MANY ? true : false,
	searching: false,
	loadingInitial: false,
}))

reducer.case(a.searching.failed, (state): StoreState => ({
	...state,
	searching: false,
}))

reducer.cases([chooseClient, loggedOut], (state) => INITIAL_STATE)

reducer.case(a.searchingFavouriteWorkers.done, (state, { result }) => ({
	...state, refs: mergeRefs(state.refs, result.refs), favouriteClientSupportWorkerRelationshipRefs: result.payload.clientSupportWorkerRelationshipRefs,
}))

reducer.case(a.searchingBlockedWorkers.done, (state, { result }) => ({
	...state, refs: mergeRefs(state.refs, result.refs), blockedClientSupportWorkerRelationshipRefs: result.payload.clientSupportWorkerRelationshipRefs,
}))

function updateClientSupportWorkerRelationship(draft: Draft<DeepReadonly<MutableStoreState>>, supportWorkerRef: string, handle: (api: Api.ClientSupportWorkerRelationship) => void) {
	const refs = _.keys(draft.refs.relationships)
	for (const ref of refs) {
		const relationship = draft.refs.relationships[ref]
		if (relationship.supportWorkerRef === supportWorkerRef) {
			handle(relationship)
		}
	}
}

reducer.case(a.creatingBlock.done, (state, { params: supportWorkerRef }): StoreState => produce(state, draft => {
	updateClientSupportWorkerRelationship(draft, supportWorkerRef, (relationship) => {
		relationship.favourite = false
		relationship.blocked = true
	})
}))

reducer.case(a.deletingBlock.done, (state, { params: supportWorkerRef }): StoreState => produce(state, draft => {
	updateClientSupportWorkerRelationship(draft, supportWorkerRef, (relationship) => {
		relationship.blocked = false
	})
}))

reducer.case(a.creatingFavourite.done, (state, { params: supportWorkerRef }): StoreState => produce(state, draft => {
	updateClientSupportWorkerRelationship(draft, supportWorkerRef, (relationship) => {
		relationship.favourite = true
		relationship.blocked = false
	})
}))

reducer.case(a.deletingFavourite.done, (state, { params: supportWorkerRef }): StoreState => produce(state, draft => {
	updateClientSupportWorkerRelationship(draft, supportWorkerRef, (relationship) => {
		relationship.favourite = false
	})
}))

reducer.case(calActions.loadCalendarAsync.done, (state, payload): StoreState => ({
	...state, refs: mergeRefs(state.refs, payload.result.refs), 
}))