import Component from '../components/UpdateEmailScreen'

import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'
import { currentClientRefSelector, currentUserSelector, currentClientSelector } from '../selectors'

/* Import module actions */
import * as a from '../actions'

/** Interface for properties that are passed to the container/ */
export interface OwnProps {
}

/** Interface for properties that the container passes to the component. */
export interface Props {
	clientRef: string
	email: string
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
	onSaveEmail: (clientRef: string, email: string) => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	const clientRef = currentClientRefSelector(state)
	let email = ''
	if (clientRef) {
		const client = currentClientSelector(state)
		if (client && client.email) {
			email = client.email
		}
	} else {
		const user = currentUserSelector(state)
		if (user) {
			email = user.email
		}
	}

	return {
		clientRef: clientRef ? clientRef : '',
		email: email,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: OwnProps): Actions => ({
	onSaveEmail: (clientRef: string, email: string) => {
		dispatch(a.updateClientDetails({
			clientRef: clientRef,
			request: {
				email: email,
			},
		}))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
