import * as React from 'react'
import Moment from 'react-moment'
import { Link, Route, RouteComponentProps, Switch } from 'react-router-dom'
import { Actions, OwnProps, Props } from '../containers/Calendar'
import JobDetail from '../containers/JobDetail'
import Week from './Week'
import Loading from 'modules/common/components/Loading'
import FundingDetails from 'modules/dashboard/containers/FundingDetails'
import * as features from 'modules/root/features'

export default class Calendar extends React.Component<Props & OwnProps & Actions & RouteComponentProps<undefined>> {

	onNewBooking = (evt: React.MouseEvent<HTMLAnchorElement>) => {
		evt.preventDefault()

		this.props.onNewBooking()
	}
	
	onNewOrder = (evt: React.MouseEvent<HTMLAnchorElement>) => {
		evt.preventDefault()

		this.props.onNewOrder()
	}

	onPrint = (evt: React.MouseEvent<HTMLAnchorElement>) => {
		evt.preventDefault()

		window.print()
	}

	public render() {
		const { view, capabilities } = this.props

		return (
			<article className="site-body">
				<div className="width-limit -site">
					<header className="calendar-header">
						<h1 className="headline-text"><Moment date={view.date.toString()} format="MMMM YYYY" /></h1>
						<div className="button-group _no-print">
							<div className="button -print">
								<a href="#" onClick={this.onPrint} className="button-link -text -small -icon"><span className="icon -print" />Print</a>
							</div>
							{
								(capabilities.manageRoster || capabilities.manager) && 
								<div className="button">
									<a href="#" onClick={this.onNewBooking} className="button-link -action -small">New booking</a>
								</div>
							}
							{
								capabilities.manageRoster && features.orders &&
								<div className="button">
									<a href="#" onClick={this.onNewOrder} className="button-link -action -small">New order</a>
								</div>
							}
							<div className="date-navigator">
								<div className="navigation">
									<Link to={`/calendar/date/${view.canPreviousMonth ? view.previousMonth : view.date}`} className={`direction -prev ${view.canPreviousMonth ? '' : '-disabled'}`}>Previous day</Link>
									<Link to="/calendar" className="day">Today</Link>
									<Link to={`/calendar/date/${view.canNextMonth ? view.nextMonth : view.date}`} className={`direction -next ${view.canNextMonth ? '' : '-disabled'}`}>Next day</Link>
								</div>
							</div>
						</div>
					</header>
					
					<section className="calendar-view">
						{this.props.loading && (
							<Loading/>
						)}
						<div className="week">
							<div className="dayname">Mon</div>
							<div className="dayname">Tue</div>
							<div className="dayname">Wed</div>
							<div className="dayname">Thu</div>
							<div className="dayname">Fri</div>
							<div className="dayname">Sat</div>
							<div className="dayname">Sun</div>
							<div className="dayname -thisweek">This week</div>
						</div>

						{
							view.weeks.map((week, index) => (
								<Week view={week} calendar={view} key={index} onSelectDay={this.props.onSelectDay} />
							))
						}
					</section>
					{ this.props.funding && this.props.funding.hasFunding &&
						<div className="split-content -single _no-print">
							<FundingDetails/>
						</div>
					}
				</div>

				{/* Job detail */}
				<Switch>
					<Route 
						path={`${this.props.match.url}/appt/:date/:jobRef`}
						render={props => (
							<JobDetail jobRef={props.match.params.jobRef} date={props.match.params.date} calendar={view} capabilities={capabilities} {...props} />
						)}
					/>
				</Switch>
				
			</article>
		)
	}
}
