import * as React from 'react'
import { Props, Actions } from '../containers/AllSupportWorkers'

import { Helmet } from 'react-helmet'
import SupportWorkerSearch, { TileMode } from '../containers/SupportWorkerSearch'

export default class AllSupportWorkers extends React.Component<Props & Actions> {

	public render() {
		return (
			<>
				<Helmet>
					<title>Find my support worker — My Homecare</title>
				</Helmet>
				<div className="width-limit -site">	
					<SupportWorkerSearch searchMode={TileMode.Find}/>
				</div>
			</>
		)
	}
}
