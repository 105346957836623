import * as React from 'react'

interface OwnProps {
	name: string
	irdNumber: string
	supportNetworkId: string
}

interface State {

}

const INITIAL_STATE: State = {
}

export default class SupportNetworkMembership extends React.Component<OwnProps, State> {
	
	public state = INITIAL_STATE

	removeSupportWorker = () => {
		//
	}

	public render() {

		return (
			<tr>
				<td>
					<div className="person">
						<span className="name">{this.props.name}</span>
					</div>
				</td>
				<td>
					<p>{this.props.irdNumber}</p>
				</td>
				<td colSpan={2}>
					<div className="button-link -action -micro" onClick={this.removeSupportWorker}>Remove</div>
				</td>
			</tr>
		)
	}
}