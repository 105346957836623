import { Value } from './types'
import moment from 'moment'
import { Api } from 'typescript-fetch-api'
import * as _ from 'lodash'

export function prepareDataForGraph(individualisedFunding: DeepReadonly<Api.IndividualisedFunding>, transactions: { [key: string]: Api.IndividualisedFundingTransaction }): Value[][] {
	const dataPaths: Value[][] = []
	const projectedBalance = projectedEndValue(individualisedFunding)
	const projectedNegativeBalanceData: Value[] = []

	// Projected negative balance data
	if (projectedBalance < 0) {
		projectedNegativeBalanceData.push({
			time: projectedPositiveFundingDateEnd(individualisedFunding),
			value: '0',
		})
		if (individualisedFunding.endDate) {
			projectedNegativeBalanceData.push({
				time: individualisedFunding.endDate.toString(),
				value: projectedEndValue(individualisedFunding).toString(),
			})
		}
	} else {
		projectedNegativeBalanceData.push({
			time: moment().toString(),
			value: '0',
		})
	}

	// Projected positive balance data
	const projectedBalanceData: Value[] = []
	projectedBalanceData.push({
		time: new Date().toString(),
		value: individualisedFunding.closingBalanceNZD.toString(),
	})

	if (individualisedFunding.endDate) {
		projectedBalanceData.push({
			time: projectedEndValue(individualisedFunding) > 0 ? individualisedFunding.endDate.toString() : projectedPositiveFundingDateEnd(individualisedFunding),
			value: projectedEndValue(individualisedFunding) > 0 ? projectedEndValue(individualisedFunding).toString() : '0',
		})
	}

	// Actual transaction balance data
	let transactionBalanceData: Value[] = []
	transactionBalanceData.push({
		time: moment(individualisedFunding.startDate).format('YYYY-MM-DD'),
		value: individualisedFunding.openingBalanceNZD.toString(),
	})
	
	let balance = individualisedFunding.openingBalanceNZD 
	
	if (Object.keys(individualisedFunding.transactionRefs).length > 0) {
		individualisedFunding.transactionRefs.forEach((ref) => {
			const tx = transactions[ref]
			balance -= tx.amountNZD
			transactionBalanceData.push({
				time: moment(tx.transactionDate).format('YYYY-MM-DD'),
				value: balance.toString(),
			})
		})
	}
	
	transactionBalanceData.push({
		time: new Date().toString(),
		value: individualisedFunding.closingBalanceNZD.toString(),
	})
	
	dataPaths.push(projectedNegativeBalanceData)
	dataPaths.push(projectedBalanceData)
	
	transactionBalanceData = _.sortBy(transactionBalanceData, function(value: Value) {
		return new Date(value.time)
	})
	
	dataPaths.push(transactionBalanceData)

	return dataPaths
}

export function projectedEndValue(individualisedFunding: DeepReadonly<Api.IndividualisedFunding>): number {
	const averageDailySpend = (individualisedFunding.openingBalanceNZD - individualisedFunding.closingBalanceNZD) / (moment().diff(moment(individualisedFunding.startDate), 'days'))
	const projectedSpendAmount = (moment(individualisedFunding.endDate).diff(moment(), 'days')) * averageDailySpend
	const projectedClosingBalance = individualisedFunding.closingBalanceNZD - projectedSpendAmount
	return projectedClosingBalance
}

export function projectedPositiveFundingDateEnd(individualisedFunding: DeepReadonly<Api.IndividualisedFunding>): string {
	const averageDailySpend = (individualisedFunding.openingBalanceNZD - individualisedFunding.closingBalanceNZD) / (moment().diff(moment(individualisedFunding.startDate), 'days'))
	const daysUntilEndOfFunding = moment(individualisedFunding.endDate).diff(moment(), 'days')

	let currentBalance = individualisedFunding.closingBalanceNZD
	const zeroBalanceDate = moment()

	for (let i = 1; i <= daysUntilEndOfFunding; i++) {
		currentBalance -= averageDailySpend

		if (currentBalance < 0) {
			zeroBalanceDate.add(i, 'd')
			break
		}
	}

	return zeroBalanceDate.toString()
}

export function fundingRemainingText(individualisedFunding: DeepReadonly<Api.IndividualisedFunding>): string {
	let timeRemaining = ''
	const monthsUntilEndOfFunding = moment(individualisedFunding.endDate).diff(moment(), 'months')
	const nowPlusMonths = moment().add(monthsUntilEndOfFunding, 'months')
	const daysUntilEndOfFunding = moment(individualisedFunding.endDate).diff(nowPlusMonths, 'days')

	if (monthsUntilEndOfFunding > 0) {
		timeRemaining = monthsUntilEndOfFunding + (monthsUntilEndOfFunding === 1 ? ' month ' : ' months ')
		if (daysUntilEndOfFunding > 0) {
			timeRemaining += 'and '
		}
	}
	if (daysUntilEndOfFunding > 0) {
		timeRemaining += daysUntilEndOfFunding + (daysUntilEndOfFunding === 1 ? ' day ' : ' days ')
	}

	return timeRemaining
}

export function calculateCurrentTimesheetPeriod(): string {
	const fortnightStartingDate = moment('2018-10-01', ['YYYY-MM-DD'])
	const weeksBetweenNowAndFortnightStartingDate = moment().diff(fortnightStartingDate, 'weeks')
	const fortnightsBeen = Math.floor(weeksBetweenNowAndFortnightStartingDate / 2)
	const endOfFortnightPeriod = fortnightStartingDate.clone().add(fortnightsBeen * 2, 'weeks').subtract(1, 'days')
	const startOfFortnightPeriod = fortnightStartingDate.clone().add((fortnightsBeen - 1) * 2, 'weeks')

	return startOfFortnightPeriod.format('D MMM') + ' – ' + endOfFortnightPeriod.format('D MMM YYYY')
}

export function orderTransactions(individualisedFunding: DeepReadonly<Api.IndividualisedFunding>, transactions: DeepReadonlyObject<{ [key: string]: Api.IndividualisedFundingTransaction}>): Api.IndividualisedFundingTransaction[] {
	let orderedTransactions: Api.IndividualisedFundingTransaction[] = []

	individualisedFunding.transactionRefs.forEach(ref => {
		orderedTransactions.push(transactions[ref])
	})

	orderedTransactions = _.sortBy(orderedTransactions, function(transaction: Api.IndividualisedFundingTransaction) {
		return new Date(transaction.transactionDate)
	}).reverse()

	return orderedTransactions
}