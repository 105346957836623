import Component from '../components/EditBookingScreen'
import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'
import { withRouter } from 'react-router'
import { RootStoreState } from 'modules/root'
import * as t from '../types'

/** Interface for properties that are passed to the container/ */
export interface OwnProps {
	timesheetEdit?: boolean
}

/** Interface for properties that the container passes to the component. */
export interface Props {
	bookingToEdit?: DeepReadonly<t.EditBooking>
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState): Props => {
	return {
		bookingToEdit: state.booking.editBooking,
	}	
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Component))
