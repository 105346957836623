import * as React from 'react'
import { Link } from 'react-router-dom'  
import { Helmet } from 'react-helmet'
import { Api } from 'typescript-fetch-api'
import * as f from '../functions'

const employeeTimesheet = require('../resources/5._IF_Employee_Timesheet_Template_for_People_using_Geneva_Payroll.docx')
const employeeExpenseClaim = require('../resources/6.__IF_Claim_form_for_Support_Related_Expenses_for_People_Using_GH_Payroll.docx')
const levelOneExpenseClaim = require('../resources/14.IF_Claim_form_for_Support_Delivered_and_Expenses.docx')

export interface OwnProps {
	individualisedFunding: DeepReadonly<Api.IndividualisedFunding>
}

/**
 * Interface for private internal component state.
 */
interface State {
	// myValue: string
}

/**
 * The initial state for our internal component state.
 */
const INITIAL_STATE: State = {
	// myValue: 'Example',
}

export default class TimesheetsExpenses extends React.Component<OwnProps, State> {
// export default class Overview extends React.Component<OwnProps & Props & Actions, State> {

	public state = INITIAL_STATE

	public render() {
		const { individualisedFunding } = this.props

		// const { exampleProperty } = this.props

		return (
			<div className="individualised-funding-screen body body-text">
				<Helmet>
					{individualisedFunding.needsLevel === Api.IndividualisedFunding.NeedsLevelEnum.Level2 ? <title>Expense claims</title> : <title>Timesheets &amp; expenses</title>}
				</Helmet>
				<h1 className="headline-text">
					{individualisedFunding.needsLevel === Api.IndividualisedFunding.NeedsLevelEnum.Level2 ? 'Timesheets & expenses' : 'Expense claims'}
				</h1>
				<div className="form-layout">
					{ individualisedFunding.needsLevel === Api.IndividualisedFunding.NeedsLevelEnum.Level1 && 
					<div className="row">
						<p className="-lrg">Please download and fill in a claim form for expenses and support delivered for the period below. Once completed, please email the form to ifaccounts@genevahealth.com no later than the Monday midday after the below pay period.</p>
						<h2>Period: {f.calculateCurrentTimesheetPeriod()}</h2>
						<div className="button-group -singlebutton">
							<div className="button">
								<a href={levelOneExpenseClaim} className="button-link -action -small">Download an expense claim for payroll</a>
							</div>
						</div>
						<p>You can view a list of payments made on the <Link to={`/account/individualised-funding/${individualisedFunding.ref}/transaction-history`}>Transaction history</Link> page.</p>
					</div>
					}
					{ individualisedFunding.needsLevel === Api.IndividualisedFunding.NeedsLevelEnum.Level2 && 
					<div className="row">
						<p className="-lrg">Please download a timesheet for each employee who has worked for you in the two-week time period below. The timesheet form will also allow you to add claims for their expenses. Once completed, please submit your timesheet to myhomecare@genevahealth.com.</p>
						<h2>Period: {f.calculateCurrentTimesheetPeriod()}</h2>
						<div className="button-group -singlebutton">
							<div className="button">
								<a href={employeeTimesheet} className="button-link -action -small">Download a timesheet</a>
							</div>
						</div>
						<p className="-lrg">For any additional personal expenses (not employee expenses) please complete an expense claim.</p>
						<p>Please download and complete an expenses claim form here. Once completed, please submit your timesheet to myhomecare@genevahealth.com.</p>
						<div className="button-group -singlebutton">
							<div className="button">
								<a href={employeeExpenseClaim} className="button-link -action -small">Download a general expenses claim</a>
							</div>
						</div>
						<p>You can view a list of payments made on the <Link to={`/account/individualised-funding/${individualisedFunding.ref}/transaction-history`}>Transaction history</Link> page.</p>
					</div>
					}
					{ individualisedFunding.needsLevel === Api.IndividualisedFunding.NeedsLevelEnum.Level3 && 
					<div className="row">
						<p className="-lrg">If you have personal expenses you would like to be reimbursed for, please complete an expense claim.</p>
						<p>Please download and complete an expenses claim form here. Once completed, please submit your timesheet to myhomecare@genevahealth.com.</p>
						<div className="button-group -singlebutton">
							<div className="button">
								<a href={employeeExpenseClaim} className="button-link -action -small">Download a general expenses claim</a>
							</div>
						</div>
						<p>You can view a list of payments made on the <Link to={`/account/individualised-funding/${individualisedFunding.ref}/transaction-history`}>Transaction history</Link> page.</p>
					</div>
					}
				</div>
			</div>
		)
	}
}
