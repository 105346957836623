import * as React from 'react'
import { Snapshot, forComponentProps, wrapComponent, Input } from 'changeling'

export interface OwnProps extends Snapshot<boolean | undefined> {
	subOrder?: string
	secondaryQuestion?: boolean // TRUE if this question has been displayed due to the previous question
	title?: string
	imageLocation?: string
	defaultOption: string
	secondaryOption: string
	inlineOptions?: boolean
	editingMode?: boolean
}

class Radio extends React.Component<OwnProps> {
	private changeling = forComponentProps(this)

	public render() {
		const { editingMode, value, imageLocation, secondaryQuestion, inlineOptions } = this.props
		return (
			<div className={'form-item -radio' + (secondaryQuestion ? ' -secondary' : '') + (editingMode ? '' : ' -valuemode') + (inlineOptions ? ' -inline' : '')}>
				<div className="form-wrapper">
					<div className={'content-wrapper' + (imageLocation ? '' : ' -no-image')}>
						{
							this.props.title &&
							<p className="title">
								{ this.props.subOrder && 
									<span className="title-order">{this.props.subOrder}</span>
								}
								{this.props.title}
							</p>
						}
						{
							editingMode ? (
								<div className="form-field">
									<div className="form-input -options">
										<ul className={'option-inputs' + (this.props.inlineOptions ? ' -inline' : '')}>
											<li className="option -radio">
												<label className="label">
													<Input.Checkable 
														type="radio"
														className="radio" 
														controller={this.changeling} 
														prop="this"
														checkedValue={false}
													/>
													<span className="substitute" />
													{this.props.defaultOption}
												</label>
											</li>
											<li className="option -radio">
												<label className="label">
													<Input.Checkable  
														type="radio" 
														className="radio"
														controller={this.changeling} 
														prop="this"
														checkedValue={true}
													/>
													<span className="substitute" />
													{this.props.secondaryOption}
												</label>
											</li>
										</ul>
									</div>
								</div>
							) : (
								<div className="value-wrapper">
									<p className="sub-text">
										{value ? this.props.secondaryOption : this.props.defaultOption}
									</p>
								</div>
							)
						}

					</div>
					{
						this.props.imageLocation &&
							<div className="image-wrapper">
								<img src={this.props.imageLocation} />
							</div>
					}
				</div>
			</div>
		)
	}
}

export default wrapComponent(Radio)