import * as React from 'react'
import { Link } from 'react-router-dom'
import { Props, Actions } from '../containers/ClientDetails'
import * as f from '../../common/functions'
import * as t from '../types'
import Cleave from 'cleave.js/react'
import GoogleMapLoader from 'react-google-maps-loader'
import GooglePlacesSuggest from 'react-google-places-suggest'
import moment from 'moment'
import { Api } from 'typescript-fetch-api'
import platform from 'modules/platform'

interface State {
	address: string
	physicalSearch: string
	postalSearch: string
}

const INITIAL_STATE: State = {
	address: '',
	physicalSearch: '',
	postalSearch: '',
}

export default class ClientDetails extends React.Component<Props & Actions, State> {
	
	public state = INITIAL_STATE
	
	componentDidMount() {
		this.initialiseForm()
	}

	handlePhysicalAddressSelectSuggest = (geocodedPrediction: google.maps.GeocoderResult, originalPrediction: google.maps.places.AutocompletePrediction) => {
		const details = { ...(this.props.clientDetails as Api.RegisterClientRequest) }
		details.deliveryAddress = geocodedPrediction.formatted_address
		details.location = {
			latitude: geocodedPrediction.geometry.location.lat(),
			longitude: geocodedPrediction.geometry.location.lng(),
		}
		this.setState({ physicalSearch: '' })
		this.props.onUpdateClientDetails(details)
	}

	handlePostalAddressSelectSuggest = (geocodedPrediction: google.maps.GeocoderResult, originalPrediction: google.maps.places.AutocompletePrediction) => {
		const details = { ...(this.props.clientDetails as Api.RegisterClientRequest) }
		details.postalAddress = geocodedPrediction.formatted_address
		this.setState({ postalSearch: '' })
		this.props.onUpdateClientDetails(details)
	}

	handleFormTextChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const details = { ...(this.props.clientDetails as Api.RegisterClientRequest) }
		const elementToUpdate = evt.target.name

		if (elementToUpdate === 'givenName') {
			details.givenName = evt.target.value
		} else if (elementToUpdate === 'familyName') {
			details.familyName = evt.target.value
		} else if (elementToUpdate === 'email') {
			details.email = evt.target.value
		} else if (elementToUpdate === 'phoneHome') {
			details.phoneHome = evt.target.value
		} else if (elementToUpdate === 'phoneMobile') {
			details.phoneMobile = evt.target.value
		} else if (elementToUpdate === 'deliveryAddress') {
			this.setState({ physicalSearch: evt.target.value })
			details.deliveryAddress = evt.target.value
		} else if (elementToUpdate === 'postalAddress') {
			this.setState({ postalSearch: evt.target.value })
			details.postalAddress = evt.target.value
		} 

		this.props.onUpdateClientDetails(details)
	}

	handleFormSelectChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
		const details = { ...(this.props.clientDetails as Api.RegisterClientRequest) }
		const elementToUpdate = evt.target.name

		if (elementToUpdate === 'salutation') {
			details.salutationRef = evt.target.value !== '' ? evt.target.value : undefined
		} else if (elementToUpdate === 'ethnicity') {
			details.ethnicityRef = evt.target.value !== '' ? evt.target.value : undefined
		} else if (elementToUpdate === 'gender') {
			details.genderRef = evt.target.value !== '' ? evt.target.value : undefined
		} else if (elementToUpdate === 'languages') {
			details.languages = []
			details.languages.push(evt.target.value)
		} else if (elementToUpdate === 'existingCareCallClient') {
			details.existingCareCallClient = Api.RegisterClientRequest.ExistingCareCallClientEnum[evt.target.value]
		}

		this.props.onUpdateClientDetails(details)
	}

	handleFormDateChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const details = { ...(this.props.clientDetails as Api.RegisterClientRequest) }
		details.dateOfBirth = moment(evt.target.value, ['DD/MM/YYYY']).format('YYYY-MM-DD')
		this.props.onUpdateClientDetails(details)
	}
	
	handleNotesChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
		const clientDetails = { ...(this.props.clientDetails as Api.RegisterClientRequest) }
		clientDetails.notes = evt.target.value
		this.props.onUpdateClientDetails(clientDetails)
	}

	handleClientDetailsSubmit = () => {
		const details = { ...(this.props.clientDetails as Api.RegisterClientRequest) }
		if (details.deliveryAddress) {
			details.deliveryAddress = details.deliveryAddress.replace(new RegExp(', ', 'g'), '\n')
		}
		if (details.postalAddress) {
			details.postalAddress = details.postalAddress.replace(new RegExp(', ', 'g'), '\n')
		}
		this.props.onRegisterClient(details)
	}

	public render() {
		const { clientDetails, settings, features } = this.props

		if (!settings) {
			return null
		}

		return (
			<div className="single-form">
				
				<form className="register-form form-layout" action="#">
					{this.props.clientIsSelf ? (
						<div className="introduction">
							<h1 className="headline-text">Your details</h1>
							<div className="body-text">
								<p>Please enter your details as the person who will be receiving care.</p>
							</div>
						</div>
					) : (
						<div className="introduction">
							<h1 className="headline-text">Client details</h1>
							<div className="body-text">
								<p>Please enter the details for the person who will be receiving care.</p>
							</div>
						</div>
					)}
					{/*
					<div className="row">
						<div className="form-field -avatar">
							<figure className="avatar-image -upload">
								<div className="image">
									<img src={dummyAvatar} alt="Person name" />
								</div>
							</figure>
							<div className="content">
								<label className="label">Profile image</label>
								<p>Upload an image of yourself. This will not be displayed publicly, but helps our support workers recognise you.</p>
								<a href="#" className="button-link -action -xsmall">Upload</a>
							</div>
						</div>
					</div>
					*/}
					
					<div className="row -split -names">
						
						{features.salutation &&
						<div className="form-field -salutation">
							<label className="label">Salutation</label>
							<div className="form-input -select">
								<select 
									className="select" 
									onChange={this.handleFormSelectChange} 
									value={clientDetails.salutationRef ? clientDetails.salutationRef : ''}
									name="salutation"
								>
									<option value=""/>
									{
										settings.salutations.map((salutation, key) => {
											return <option key={key} value={salutation.ref}>{salutation.name}</option>
										})
									}
								</select>
							</div>
						</div>
						}
						
						<div className="form-field -firstname">
							<label className="label">Given name</label>
							<div className="form-input -text">
								<input 
									type="text" 
									className="field" 
									name="givenName"
									value={clientDetails.givenName}
									onChange={this.handleFormTextChange}
								/>
							</div>
						</div>

						<div className="form-field -lastname">
							<label className="label">Family name</label>
							<div className="form-input -text">
								<input 
									type="text" 
									className="field" 
									name="familyName"
									value={clientDetails.familyName}
									onChange={this.handleFormTextChange}
								/>
							</div>
						</div>

					</div>

					<div className="row -split -thirds">
						{features.dateOfBirth &&
						<div className="form-field -dob">
							<label className="label">Date of birth</label>
							<div className="form-input -text -relative">
								<Cleave 
									className="field"
									placeholder="DD/MM/YYYY"
									options={{ date: true, datePattern: ['d', 'm', 'Y'] }}
									onChange={this.handleFormDateChange} 
								/>
							</div>
						</div>
						}

						{features.ethnicity && 
						<div className="form-field -ethnicity">
							<label className="label">Ethnicity</label>
							<div className="form-input -select">
								<select 
									className="select" 
									onChange={this.handleFormSelectChange} 
									name="ethnicity" 
									value={clientDetails.ethnicityRef ? clientDetails.ethnicityRef : ''}
								>
									<option value=""/>
									{
										f.ethnicityGroups(settings.ethnicities).map((ethnicityGroup, key) => {
											return (ethnicityGroup.groups ? (
												<optgroup key={key} label={ethnicityGroup.name}>
													{
														ethnicityGroup.groups.map((ethnicity, id) => {
															return <option key={id} value={ethnicity.ref}>{ethnicity.name}</option>
														})
													}
												</optgroup>
											) : (
												<option key={key} value={ethnicityGroup.ref}>{ethnicityGroup.name}</option>
											))
										})
									}
								</select>
							</div>
						</div>
						}
						
						{features.genderRef &&
						<div className="form-field -gender">
							<label className="label">Gender</label>
							<div className="form-input -select">
								<select 
									className="select" 
									onChange={this.handleFormSelectChange} 
									name="gender" 
									value={clientDetails.genderRef ? clientDetails.genderRef : ''}
								>
									<option value=""/>
									{
										settings.genders.map((gender, key) => {
											return <option key={key} value={gender.ref}>{gender.name}</option>
										})
									}
								</select>
							</div>
						</div>
						}
						
					</div>

					<div className="row">
						<div className="form-field">
							<label className="label">Email address</label>
							<div className="form-input -text">
								<input type="email" className="field" name="email" value={clientDetails.email} onChange={this.handleFormTextChange}/>
							</div>
						</div>
					</div>

					<div className="row -split -half">
						{features.phoneHome &&
						<div className="form-field">
							<label className="label">Phone number</label>
							<div className="form-input -text">
								<input type="text" className="field" name="phoneHome" value={clientDetails.phoneHome || ''} onChange={this.handleFormTextChange}/>
							</div>
						</div>
						}
						{features.phoneMobile &&
						<div className="form-field">
							<label className="label">Mobile</label>
							<div className="form-input -text">
								<input type="text" className="field" name="phoneMobile" value={clientDetails.phoneMobile || ''} onChange={this.handleFormTextChange}/>
							</div>
						</div>
						}
					</div>

					{features.deliveryAddress &&
					<div className="row">
						<div className="form-field">
							<label className="label">Physical address for care</label>
							<div className="form-input -text">
								<GoogleMapLoader
									params={{
										key: platform.googleApiKey(),
										libraries: 'places,geocode',
									}}
									render={googleMaps =>
										googleMaps && (
											<GooglePlacesSuggest
												googleMaps={googleMaps}
												autocompletionRequest={{
													input: this.state.physicalSearch,
													componentRestrictions: platform.googlePlacesComponentRestrictions(),
												}}
												onSelectSuggest={this.handlePhysicalAddressSelectSuggest}
												textNoResults="No results"
												customRender={prediction => (
													<>
														{prediction
															? prediction.description
															: 'No results'}
													</>
												)}
											>
												<input
													type="text"
													className="address-field"
													name="deliveryAddress"
													value={clientDetails.deliveryAddress || ''}
													placeholder="Search a location"
													onChange={this.handleFormTextChange}
												/>
											</GooglePlacesSuggest>
										)
									}
								/>
							</div>
						</div>
					</div>
					}
					{features.postalAddress &&
					<div className="row">
						<div className="form-field">
							<label className="label">Postal address <span className="note">(if different from above)</span></label>
							<div className="form-input -text">
								<GoogleMapLoader
									params={{
										key: platform.googleApiKey(),
										libraries: 'places,geocode',
									}}
									render={googleMaps =>
										googleMaps && (
											<GooglePlacesSuggest
												googleMaps={googleMaps}
												autocompletionRequest={{
													input: this.state.postalSearch,
													componentRestrictions: platform.googlePlacesComponentRestrictions(),
												}}
												onSelectSuggest={this.handlePostalAddressSelectSuggest}
												textNoResults="No results"
												customRender={prediction => (
													<>
														{prediction
															? prediction.description
															: 'No results'}
													</>
												)}
											>
												<input
													type="text"
													className="address-field"
													name="postalAddress"
													value={clientDetails.postalAddress || ''}
													placeholder="Search a location"
													onChange={this.handleFormTextChange}
												/>
											</GooglePlacesSuggest>
										)
									}
								/>
							</div>
						</div>
					</div>
					}
					{
						this.props.settings && features.preferredLanguage && (
							<div className="row">
								<div className="form-field -language">
									<label className="label">Language</label>
									<div className="form-input -select">
										<select name="languages" className="select -small" onChange={this.handleFormSelectChange} value={clientDetails.languages ? clientDetails.languages[0] : ''}>
											<option value="">English</option>
											{this.props.settings && this.props.settings.languages.map((lang, index) => (
												<option key={index} value={lang.ref}>{lang.name}</option>
											))}
										</select>
									</div>
								</div>
							</div>
						)
					}

					<div className="row">
						<div className="form-field">
							<label className="label">Additional notes</label>
							<div className="form-input -textarea">
								<textarea
									onChange={this.handleNotesChange}
									value={clientDetails.notes || ''}
									className="field"
									placeholder="Please provide any additional details." 
								/>
							</div>
						</div>
					</div>
					
					<div className="row">
						<div className="form-field">
							<label className="label">Are {this.props.clientIsSelf ? 'you' : 'they'} an existing or past client?</label>
							<div className="form-input -select">
								<select 
									className="select -auto-select" 
									onChange={this.handleFormSelectChange} 
									value={clientDetails.existingCareCallClient}
									name="existingCareCallClient"
								>
									<option value={Api.RegisterClientRequest.ExistingCareCallClientEnum.No}>No</option>
									<option value={Api.RegisterClientRequest.ExistingCareCallClientEnum.Yes}>Yes</option>
									<option value={Api.RegisterClientRequest.ExistingCareCallClientEnum.NotSure}>Not sure</option>
								</select>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="button-group">
							<div className="button">
								<Link to="/setup/register-new" className="button-link -text">Back</Link>
							</div>
							<div className="button -constrained">
								<div className={'button-link -action' + (this.props.createClientRequestProcessing ? ' -processing' : '')} onClick={this.handleClientDetailsSubmit}>Continue</div>
							</div>
						</div>
					</div>
					
				</form>
			</div>
		)
	}

	private initialiseForm = () => {
		if (this.props.clientIsSelf && this.props.userDetails) {
			const initialDetails = {
				...t.INITIAL_CLIENT_DETAILS,
				givenName: this.props.userDetails.givenName,
				familyName: this.props.userDetails.familyName,
				email: this.props.userDetails.email,
				phoneHome: this.props.userDetails.phoneHome,
				image: this.props.userDetails.image,
			}
			this.props.onUpdateClientDetails(initialDetails)
		}
	}
}
