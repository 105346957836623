import * as React from 'react'
import { Props, Actions } from '../containers/AllSupportWorkersSearch'
import { SimpleFormState, Input, Select } from 'formalities'
import { Api } from 'typescript-fetch-api'

import GoogleMapLoader from 'react-google-maps-loader'
import GooglePlacesSuggest from 'react-google-places-suggest'
import platform from 'modules/platform'

export interface SearchForm {
	currentClient?: DeepReadonly<Api.Client>
	text?: string
	genders?: string
	languages?: string
	skills?: string
	location?: DeepReadonly<Api.GPSLocation>
	address?: string
	addressCompleted?: string
}

interface State {
	formState: SimpleFormState<SearchForm>
}

export default class AllSupportWorkersSearch extends React.Component<Props & Actions, State> {

	public state = {
		formState: new SimpleFormState(this.props.searchForm ? this.props.searchForm : {}),
	}

	componentDidUpdate() {
		this.updateLocation()
	}

	componentDidMount() {
		this.updateLocation()
	}

	public render() {
		// const { exampleProperty } = this.props
		const { formState } = this.state

		if (!this.props.currentClient) {
			return null
		}

		return (
			<div className="row -split supportworker-filters">
						
				{/* <div className="form-field -keyword">
					<label className="label">Name/keyword</label>
					<div className="form-input -text">
						<Input formState={formState} onNewFormState={this.onNewFormState} type="text" name="text" className="field -small"/>
					</div>
				</div> */}

				<GoogleMapLoader
					params={{
						key: platform.googleApiKey(),
						libraries: 'places,geocode',
					}}
					render={googleMaps => (
						<div className="form-field -location">
							<label className="label">Location</label>
							<div className="form-input -text">
								{googleMaps ? (
									<GooglePlacesSuggest
										googleMaps={googleMaps}
										autocompletionRequest={{
											input: formState.get('address') && formState.get('address') !== formState.get('addressCompleted') ? formState.get('address')! : '',
											componentRestrictions: platform.googlePlacesComponentRestrictions(),
										}}
										onSelectSuggest={(geocodedPrediction: google.maps.GeocoderResult) => {
											this.onNewFormState(
												formState.merge({
													'location': {
														latitude: geocodedPrediction.geometry.location.lat(),
														longitude: geocodedPrediction.geometry.location.lng(),
													},
													addressCompleted: geocodedPrediction.formatted_address,
													address: geocodedPrediction.formatted_address,
												}),
											)
										}}
										textNoResults="No results"
										customRender={prediction => (
											<>
												{prediction
													? prediction.description
													: 'No results'}
											</>
										)}
									>
										<div className="form-input -text">
											<Input formState={formState} onNewFormState={this.onNewFormState} name="address" type="text" className="field -small" disabled={true}/>
										</div>
									</GooglePlacesSuggest>
								) : (
									<div className="form-input -text">
										<Input formState={formState} onNewFormState={this.onNewFormState} name="address" type="text" className="field -small" disabled={true}/>
									</div>
								)}
							</div>
						</div>
					)}
				/>

				{/* <div className="form-field -services">
					<label className="label">Services</label>
					<div className="form-input -select">
						<Select formState={formState} onNewFormState={this.onNewFormState} name="" className="select -small" defaultValue={'default'}>
							<option value="default" disabled={true} />
							{this.props.jobTypes && this.props.jobTypes.map((jobType, index) => (
								<option key={index} value={jobType.ref}>{jobType.name}</option>
							))}
						</Select>
					</div>
				</div> */}

				<div className="form-field -skills">
					<label className="label">Skills</label>
					<div className="form-input -select">
						<Select formState={formState} onNewFormState={this.onNewFormState} name="skills" className="select -small" >
							<option value="" />
							{this.props.skills && this.props.skills.map((skill, index) => (
								<option key={index} value={skill.ref}>{skill.name}</option>
							))}
						</Select>
					</div>
				</div>

				<div className="form-field -gender">
					<label className="label">Gender</label>
					<div className="form-input -select">
						<Select formState={formState} onNewFormState={this.onNewFormState} name="genders" className="select -small" >
							<option value="">Any</option>
							{this.props.genders && this.props.genders.filter(function(gender: Api.Gender) {
								return gender.ref === 'M' || gender.ref === 'F'
							}).map((gender, index) => (
								<option key={index} value={gender.ref}>{gender.name}</option>
							))}
						</Select>
					</div>
				</div>

				<div className="form-field -language">
					<label className="label">Language</label>
					<div className="form-input -select">
						<Select formState={formState} onNewFormState={this.onNewFormState} name="languages" className="select -small" >
							<option value="">English</option>
							{this.props.languages && this.props.languages.map((lang, index) => (
								<option key={index} value={lang.ref}>{lang.name}</option>
							))}
						</Select>
					</div>
				</div>
				
				<label className="toggle" htmlFor="supportworker-filter-menustate" aria-hidden="true"><span className="label">More</span></label>
				
				<div className="button-group">
					<div className="button">
						<div className={'button-link -nested -action' + (this.props.searching ? ' -processing' : '')}>
							<input type="submit" value="Search" onClick={this.onSearch} />
						</div>
						
					</div>
				</div>
			</div>
		)
	}

	private onNewFormState = (formState: SimpleFormState<SearchForm>) => {
		this.setState({
			formState,
		})
	}

	private onSearch = (evt: React.FormEvent) => {
		evt.preventDefault()

		const request = this.state.formState.getValues()
		this.props.onSearch(request)
	}

	private updateLocation = () => {
		const { formState } = this.state
		if (this.props.currentClient && this.props.currentClient.deliveryAddress) {
			// eslint-disable-next-line no-control-regex
			const clientAddress = this.props.currentClient.deliveryAddress.replace(new RegExp('\n', 'g'), ', ')
			if (formState.get('address') !== clientAddress && formState.get('address') === undefined) {
				this.onNewFormState(formState.merge({
					location: this.props.currentClient.location,
					addressCompleted: clientAddress,
					address: clientAddress,
				}))
			}
		}
	}

}
