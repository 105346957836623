import * as React from 'react'
import Address from './Address'
import PhoneNumbers from './PhoneNumbers'
import { forComponentProps, wrapComponent } from 'changeling'
import { HealthPassportFormProps } from './HealthPassportScreen'
import MedicalStaffNotes from './MedicalStaffNotes'
import PersonCompletingNotes from './PersonCompletingNotes'
import InlineTextBox from './InlineTextBox'
import moment from 'moment'

import NHIImage from 'modules/frontend/img/health-passport/nhi-number.jpg'
import AddressImage from 'modules/frontend/img/health-passport/address.jpg'
import PhoneImage from 'modules/frontend/img/health-passport/phone.jpg'
import PersonalDetailsImage from 'modules/frontend/img/health-passport/personal-details.jpg'
import EmailImage from 'modules/frontend/img/health-passport/email.jpg'
import DateCompletionImage from 'modules/frontend/img/health-passport/date-completion.jpg'

type MyComponentProps = HealthPassportFormProps

class HealthPassportPersonalDetails extends React.Component<MyComponentProps> {

	private changeling = forComponentProps(this)

	public render() {
		const { editingMode, docExtended } = this.props

		return (
			<div className="hp-personal-details">
				<InlineTextBox
					title="First name:"
					controller={this.changeling}
					imageLocation={PersonalDetailsImage}
					prop="firstName"
					editingMode={editingMode}
				/>
				<InlineTextBox 
					title="Last name:"
					controller={this.changeling}
					prop="lastName"
					editingMode={editingMode}
				/>
				<InlineTextBox 
					title="I like to be known as:"
					controller={this.changeling}
					prop="knownAs"
					editingMode={editingMode}
				/>
				<InlineTextBox 
					title="NHI number:"
					controller={this.changeling}
					imageLocation={NHIImage}
					prop="nhiNumber"
					editingMode={editingMode}
				/>
				<Address 
					controller={this.changeling}
					imageLocation={AddressImage}
					prop="address"
					editingMode={editingMode}
				/>
				<PhoneNumbers 
					controller={this.changeling}
					imageLocation={PhoneImage}
					prop="phoneNumbers"
					editingMode={editingMode}
				/>
				<InlineTextBox 
					title="Email:"
					controller={this.changeling}
					imageLocation={EmailImage}
					prop="email"
					editingMode={editingMode}
				/>
				<div className={'form-item -inline-textbox -completion' + (editingMode ? '' : ' -valuemode -no-gutters')}>
					<div className="form-wrapper">
						<div className="image-wrapper">
							<img src={DateCompletionImage} />
						</div>
						<div className="content-wrapper">
							{editingMode && <p className="title">Date of completion:</p>}
							<div className="form-input -text">
								{
									editingMode ? ( 
										<input type="text" className="field" disabled value={docExtended ? moment(docExtended.whenUpdated).format('D MMM YYYY') : ''}/>
									) : (
										<p className="sub-text"><span className="title">Date of completion: </span>{docExtended ? moment(docExtended.whenUpdated).format('D MMM YYYY') : ''}</p>
									)
								}
							</div>
						</div> 
					</div>
				</div>
				<PersonCompletingNotes />
				{
					!editingMode &&
					<MedicalStaffNotes />
				}
			</div>
		)
	}
}

export default wrapComponent(HealthPassportPersonalDetails)
