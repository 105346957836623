import * as React from 'react'
import { Link } from 'react-router-dom'  
import { Helmet } from 'react-helmet'
import SupportWorker from './SupportWorker'
import { Api } from 'typescript-fetch-api'

const employmentAgreement = require('../resources/Individual_Employment_agreement.docx')
const supportWorkerAgreement = require('../resources/Support_Worker_Privacy_Declaration_template.docx')

export interface OwnProps {
	individualisedFunding: DeepReadonly<Api.IndividualisedFunding>
}
/**
 * Interface for private internal component state.
 */
interface State {

}

/**
 * The initial state for our internal component state.
 */
const INITIAL_STATE: State = {
	// myValue: 'Example',
}

export default class SupportWorkers extends React.Component<OwnProps, State> {
// export default class Overview extends React.Component<OwnProps & Props & Actions, State> {

	public state = INITIAL_STATE

	public render() {
		const { individualisedFunding } = this.props
		const clientsRegistered = true

		return (
			<div className="individualised-funding-screen body body-text">
				<Helmet>
					<title>My support workers</title>
				</Helmet>   
				<h1 className="headline-text">My support workers</h1>
				<div className="form-layout">
					{
						individualisedFunding.needsLevel === Api.IndividualisedFunding.NeedsLevelEnum.Level3 ? (
							<>
								<div className="row -ruler">
									<p className="-lrg"><span className="-bold">Geneva is currently providing you with Support Workers.</span> You can manage your bookings with them via your Calendar.</p>
									<div className="button-group -singlebutton">
										<div className="button">
											<Link to="/calendar" className="button-link -action -small">View my calendar</Link>
										</div>
									</div>
								</div>
								<div className="row">
									<h2>Using your own Support Workers</h2>
									<p>If you prefer, you can register your own Support Workers rather than use those which Geneva provides. These workers need to be employed and paid by you, and you must meet the <a target="_blank" rel="noopener noreferrer" href="https://www.ird.govt.nz/payroll-employers/">responsibilities of an employer</a>. Geneva can pay these workers directly on your behalf, or you can mange the payroll yourself.</p>
									<p><Link to="/">Read more about how the payroll process works</Link></p>
									<p>Note that Individualised Funding cannot be used to fund Support Workers or any employees who are:</p>
									<ul>
										<li>A spouse / partner or parents of the person with disabilities</li>
										<li>Any family members who live in the same house as the person with disabilities</li>
									</ul>
									<div className="button-group -singlebutton">
										<div className="button">
											<Link to={'/account/individualised-funding/' + individualisedFunding.ref + '/register-as-employer'} className="button-link -action -small">Register as an employer</Link>
										</div>
									</div>
								</div>
							</>
						) : (
							<>
								<div className="row -ruler">
									{
										clientsRegistered ? (
											<>
												<p className="-lrg">You have registered the following Support Workers:</p>

												<div className="registered-support-workers">
													<table className="table table-default">
														<thead>
															<tr>
																<th scope="col">Name</th>
																<th scope="col">IRD #</th>
																<th scope="col" colSpan={2} />
															</tr>
														</thead>
														<tbody>
															<SupportWorker 
																name={'Test Name'}
																irdNumber={'1231-23-123'}
																supportNetworkId={''}
															/>
															<SupportWorker 
																name={'Bob McGee'}
																irdNumber={'7631-223-123'}
																supportNetworkId={''}
															/>
															<SupportWorker 
																name={'Kathy Bates'}
																irdNumber={'666-213-123'}
																supportNetworkId={''}
															/>
														</tbody>
													</table>
												</div>
												<div className="button-group -singlebutton">
													<div className="button"> 
														<Link to={'/account/individualised-funding/' + individualisedFunding.ref + '/register-support-worker'} className="button-link -action -small">Register a support worker</Link>
													</div>
												</div>
											</>
										) : (
											<>
												<p className="-lrg">You have not registered any Support Workers yet.</p>
												<div className="button-group -singlebutton">
													<div className="button"> 
														<Link to={'/account/individualised-funding/' + individualisedFunding.ref + '/register-support-worker'} className="button-link -action -small">Register a support worker</Link>
													</div>
												</div>
											</>
										)
									}
								</div>
								<div className="row -ruler">
									<h2>Let Geneva take care of it</h2>
									<p>If you prefer, you can also use Support Workers provided by Geneva. In this instance you also won’t need to manage your own payroll.</p>
									<div className="button-group -singlebutton">
										<div className="button">
											<Link to="/calendar/new" className="button-link -action -small">Add a booking</Link>
										</div>
									</div>
								</div>
								<div className="row">
									<h2>Registering a support worker</h2>
									<p>To register a Support Worker, you will need to collect the following information from them. Once you have the information, use the button at the top of this page to begin the process.</p>
									<div className="registration-list">
										<ul>
											<li className="icon -external-link"><a target="_blank" rel="noopener noreferrer" href="https://www.ird.govt.nz/resources/c/c/cccb8332-f8f6-49b8-8ff5-86a55d527519/IR330%2818%29.pdf">IR 330 — Tax code declaration</a></li>
											<li className="icon -document-download"><a href={employmentAgreement}>Support worker agreement form</a></li>
											<li className="icon -document-download"><a href={supportWorkerAgreement}>Support worker privacy declaration</a></li>
										</ul>
										<p>Choose one of the below, if the worker will be opting in or out of Kiwisaver:</p>
										<ul>
											<li className="icon -external-link"><a target="_blank" rel="noopener noreferrer" href="https://www.nzqa.govt.nz/assets/About-us/Working-for-NZQA/ECM/KS2-Deduction-Form.pdf">KS2 — Kiwisaver opt in form</a></li>
											<li className="icon -external-link"><a target="_blank" rel="noopener noreferrer" href="https://www.ird.govt.nz/resources/b/8/b8a84d4e-84f4-4e22-b5f5-b5e15bd10f55/ks10.pdf">KS10 — Kiwisaver opt out form</a></li>
										</ul>
									</div>
								</div>
							</>
						)
					}
				</div>
			</div>
		)
	}
}
