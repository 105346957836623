import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { Api } from 'typescript-fetch-api'
import { LocalDateTime } from '@js-joda/core'
import * as _ from 'lodash'

/* Import our module's actions */
import * as a from './actions'
import * as t from './types'
import { chooseClient } from 'modules/account/actions'
import { loggedOut, login } from 'modules/auth/actions'
import { completingTimesheetAction, recallingTimesheetAction } from 'modules/timesheet/actions'
import { tweakJobType } from 'modules/common/functions'

/**
 * Export the StoreState interface for this module. We always name this interface
 * `StoreState` so it is consistent across each of our modules.
 * We export a readonly version of the interface, to try to prevent modifications,
 * and retain a private (not exported) MutableStoreState for ourselves to use very
 * carefully in the reducers, if we need to.
 */
export type StoreState = DeepReadonly<MutableStoreState>

export interface MutableStoreState {
	roster: t.StoreRoster
	refs: Api.ClientAppointmentsResponseRefs
	loading: boolean
}

/**
 * The initial store state for this module.
 */
const INITIAL_STATE: MutableStoreState = {
	roster: {
		
	},
	refs: {
		appointments: {},
		clients: {},
		funders: {},
		funding: {},
		jobTypes: {},
		supportWorkers: {},
		relationships: {},
		clientParking: {},
		individualisedFunding: {},
		transactions: {},
		timesheets: {},
	},
	loading: false,
}

/**
 * Reducer function for this module.
 */
export const reducer = reducerWithInitialState<StoreState>(INITIAL_STATE)

reducer.case(a.loadCalendarAsync.started, (state) => ({
	...state, loading: true,
}))

reducer.case(a.loadCalendarAsync.failed, (state) => ({
	...state, loading: false,
}))

function tweakJobTypes(jobTypes: { [key: string]: Api.JobType }): { [key: string]: Api.JobType } {
	const result: { [key: string]: Api.JobType } = {}
	_.values(jobTypes).forEach(jobType => {
		result[jobType.ref] = tweakJobType(jobType)
	})
	return result
}

reducer.case(a.loadCalendarAsync.done, (state, payload): MutableStoreState => {
	return { ...state, 
		roster: {
			...state.roster,
			roster: payload.result.payload,
			startDate: payload.params.start.toString(),
			endDate: payload.params.end.toString(),
			lastUpdated: LocalDateTime.now().toString(),
		},
		refs: {
			...payload.result.refs,
			jobTypes: tweakJobTypes(payload.result.refs.jobTypes),
		},
		loading: false,
	}
})

reducer.case(chooseClient, (): StoreState => INITIAL_STATE)

reducer.cases([login.started, loggedOut], (): StoreState => INITIAL_STATE)

reducer.cases([completingTimesheetAction.done, recallingTimesheetAction.done], (state, payload): StoreState => {
	return {
		...state,
		refs: {
			...state.refs,
			appointments: {
				...state.refs.appointments,
				...payload.result.refs.appointments,
			},
			timesheets: {
				...state.refs.timesheets,
				...payload.result.refs.timesheets,
			},
		},
	}
})
