import { SagaIterator } from 'redux-saga'
import { takeEvery, debounce, put, call, select } from 'redux-saga/effects'
import { getDocuments, getDocumentsAsync, loadDocument, LoadDocumentAction, loadDocumentAsync, saveDocument, SaveDocumentAction, saveDocumentAsync, downloadDocument, DownloadDocumentAction, downloadDocumentAsync, DownloadDocumentDoneAction } from './actions'
import { readyAction } from 'modules/root/actions'
import { login } from 'modules/auth/actions'
import { onlineAction } from 'modules/api/actions'
import { chooseClient } from 'modules/account/actions'
import { callApiWithActions, handleApiFailedAction } from 'modules/api/functions'
import api, { getConfiguration } from 'modules/api'
import { currentClientRefSelector } from 'modules/account/selectors'
import { Api } from 'typescript-fetch-api'

function* startLoading(): SagaIterator {
	yield put(getDocuments())
}

function* startGetDocuments(): SagaIterator {
	const currentClientRef = (yield select(currentClientRefSelector)) as string | undefined
	if (!currentClientRef) {
		return
	}
	
	yield call(callApiWithActions, {}, getDocumentsAsync, (options: RequestInit) => {
		return api().clientApi.getClientDocuments(currentClientRef, options)
	})
}

function* startLoadDocument(action: LoadDocumentAction): SagaIterator {
	let clientRef = action.payload.clientRef
	if (!clientRef) {
		clientRef = (yield select(currentClientRefSelector)) as string | undefined
	}
	if (!clientRef) {
		return
	}

	yield call(callApiWithActions, action.payload, loadDocumentAsync, (options: RequestInit) => {
		return api().clientApi.getClientDocument(clientRef!, action.payload.documentRef, options)
	})
}

function* startSaveDocument(action: SaveDocumentAction): SagaIterator {
	const currentClientRef = (yield select(currentClientRefSelector)) as string | undefined
	if (!currentClientRef) {
		return
	}

	const request: Api.ClientDocumentUpsertRequest = {
		name: action.payload.request.name,
		documentType: action.payload.request.documentType,
		unique: action.payload.request.unique,
		data: JSON.stringify(action.payload.request.data),
	}
	
	yield call(callApiWithActions, request, saveDocumentAsync, (options: RequestInit) => {
		return api().clientApi.upsertClientDocument(currentClientRef, action.payload.documentRef, request, options)
	})
}

function* startDownloadDocument(action: DownloadDocumentAction): SagaIterator {
	let clientRef = action.payload.clientRef
	if (!clientRef) {
		clientRef = (yield select(currentClientRefSelector)) as string | undefined
	}
	if (!clientRef) {
		return
	}

	yield call(callApiWithActions, action.payload, downloadDocumentAsync, (options: RequestInit) => {
		return api().clientApi.requestDownloadClientDocument(clientRef!, action.payload.documentRef, options)
	})
}

function doDownloadDocument(action: DownloadDocumentDoneAction) {
	const token = action.payload.result.payload.token

	const frontend = `${window.location.protocol}//${window.location.host}`
	const url = `${getConfiguration().basePath}/client/download-document?token=${encodeURIComponent(token)}&frontend=${encodeURIComponent(frontend)}`
	window.location.assign(url)
}

export default function* (): SagaIterator {
	yield takeEvery(readyAction, startLoading)
	yield takeEvery(login.done, startLoading)
	yield takeEvery(onlineAction, startLoading)
	yield takeEvery(chooseClient, startLoading)

	yield takeEvery(getDocuments, startGetDocuments)
	yield takeEvery(loadDocument, startLoadDocument)
	yield debounce(5000, saveDocument, startSaveDocument)
	yield takeEvery(downloadDocument, startDownloadDocument)
	yield takeEvery(downloadDocumentAsync.done, doDownloadDocument)
	yield takeEvery(downloadDocumentAsync.failed, handleApiFailedAction.bind(null, 'Oops! We were unable to load your document.'))
}
