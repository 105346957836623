/**
 * Component template.
 * 
 * Note that this file has a `.tsx` suffix, as it contains React elements.
 */

import * as React from 'react'
import { Link } from 'react-router-dom'

interface OwnProps {
	section: string
}

export default class AdvanceCarePlanStepWrapper extends React.Component<OwnProps> {

	public render() {
		const { children, section } = this.props

		return (
			<article className="site-body">
				<div className="width-limit -site">
					<form className="content-layout" action="#">
						<div className="body">
							{children}

							<div className="row progress-buttons">
								<div className="button-group">
									<div className="button">
										{section === '1' && <Link to="/account/my-documents/advance-care-plan" className="button-link -text">Cancel</Link>}
										{section === '2' && <Link to="step-one" className="button-link -text">Back</Link>}
										{section === '3' && <Link to="step-two" className="button-link -text">Back</Link>}
									</div>
									<div className="button -constrained">
										{section === '1' && <Link to="step-two" className="button-link -action">Continue</Link>}
										{section === '2' && <Link to="step-three" className="button-link -action">Continue</Link>}
										{section === '3' && <Link to="/account/my-documents/advance-care-plan" className="button-link -action">Complete plan</Link>}
									</div>
								</div>
							</div>
						</div>

						<aside className="sidebar">
							<Link to="/account/my-documents/advance-care-plan" className="back-nav">My Documents</Link>
							<h1 className="page-title">Advance Care Plan</h1>
							<nav className="progress-nav">
								<ul className="listitems">
									<li className={`item${section === '1' ? ' -active' : ''}`}><Link to="step-one" className="link">Step 1</Link></li>
									<li className={`item${section === '2' ? ' -active' : ''}`}><Link to="step-two" className="link">Step 2</Link></li>
									<li className={`item${section === '3' ? ' -active' : ''}`}><Link to="step-three" className="link">Step 3</Link></li>
								</ul>
							</nav>
						</aside>
					</form>
				</div>
			</article>
		)
	}
}
