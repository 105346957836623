import * as React from 'react'
import { AdvanceCarePlanFormProps } from './AdvanceCarePlanScreen'
import { forComponentProps, wrapComponent } from 'changeling'
import MultiTextBox from './MultiTextBox'
import SignatureForm from './SignatureForm'

type MyComponentProps = AdvanceCarePlanFormProps

class AdvanceCarePlanStepThree extends React.Component<MyComponentProps> {
	private changeling = forComponentProps(this)

	public render() {
		const { editingMode } = this.props

		return (
			<>
				<h2 className="section-header">Specific Treatment and Care Preferences</h2>
				<p className="sub-text">Please fill out with the help of your Doctor or Nurse. <br/><br/> These expressed preferences should be used to guide clinical decisions in the circumstances I have set out below:</p>
				<MultiTextBox
					editingMode={editingMode}
					prop="carePreferences"
					controller={this.changeling}
				/>
				<SignatureForm editingMode={editingMode} />
			</>
		)
	}
}

export default wrapComponent(AdvanceCarePlanStepThree)
