import { SagaIterator } from 'redux-saga'
import { takeEvery, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'

import * as auth from 'modules/auth/actions'
import * as account from 'modules/account/actions'
import { history } from '.'

/** User has logged in and account details are loaded.
 * NOTE: This is only for when the user actually goes through the login process interactively, not for just
 * refreshing the page when already logged in.
 */
function* handleInteractiveLogin(): SagaIterator {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	if (history.location.state && (history.location.state as any).from) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		yield put(push((history.location.state as any).from.pathname))
	} else {
		yield put(push('/'))
	}
}

function* handleChooseClient(action: account.ChooseClientAction): SagaIterator {
	/* Go to dashboard on interactively switching the client */
	if (action.payload.clientRef && action.payload.interactive) {
		yield put(push('/'))
	}
}

function* navigate(path: string): SagaIterator {
	yield put(push(path))
}

export default function* (): SagaIterator {
	yield takeEvery(auth.loggedIn, handleInteractiveLogin)
	yield takeEvery(account.chooseClient, handleChooseClient)
	yield takeEvery(auth.loggedOut, navigate.bind(null, '/'))
}
