/**
 * Container template for a component.
 */

/* Import the component from the component path */
import Component from '../components/ChangePassword'

import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'
import { Api } from 'typescript-fetch-api'

/* Import RootStoreState */
import { RootStoreState } from 'modules/root'

/* Import module actions */
import * as a from '../actions'

/** Interface for properties that are passed to the container/ */
export interface OwnProps {

}

/** Interface for properties that the container passes to the component. */
export interface Props {
	processing: boolean
}

/**
 * Interface for action callbacks that the container exposes to the component.
 * The component's `this.props` is typed `Props & Actions`.
 */
export interface Actions {
	onChangePassword: (req: Api.ChangePasswordRequest) => void
}

/** Populate the Props from the store state. */
const mapStateToProps = (state: RootStoreState, ownProps: OwnProps): Props => {
	return {
		processing: state.account.savingChangePassword,
	}
}

/** Populate the Actions with the callbacks for the component. */
const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	onChangePassword: (req) => {
		dispatch(a.changePassword(req))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
