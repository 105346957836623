import * as React from 'react'
import { OwnProps, Props, Actions } from '../containers/FundingDetails'
import { Funding } from 'modules/calendar/types'
import { joinLast } from 'modules/common/functions'
import { Api } from 'typescript-fetch-api'

interface FundingByFunder {
	[funderRef: string]: {
		funderRef: string
		funderName?: string
		minutes: number
	}
}

interface FundingByJobType {
	[jobTypeRef: string]: {
		jobType: Api.JobType
		minutes: number
		minutesAllocated: number
	}
}

function fundingByFunder(funding: Funding): FundingByFunder {
	const initial: FundingByFunder = {}
	return funding.jobTypes!.reduce(
		(progress, jobType) => {
			if (!progress[jobType.funderRef]) {
				progress[jobType.funderRef] = { funderRef: jobType.funderRef, funderName: jobType.funderName, minutes: 0 }
			}
			progress[jobType.funderRef].minutes += jobType.minutes
			return progress
		}, 
		initial,
	)
}

function fundingByJobType(funding: Funding): FundingByJobType {
	const initial: FundingByJobType = {}
	return funding.jobTypes!.reduce(
		(progress, jobType) => {
			if (!progress[jobType.jobType.ref]) {
				progress[jobType.jobType.ref] = { jobType: jobType.jobType, minutes: 0, minutesAllocated: 0 }
			}
			progress[jobType.jobType.ref].minutes += jobType.minutes
			if (jobType.fundedTimeAllocatedThisWeekMins) {
				progress[jobType.jobType.ref].minutesAllocated += jobType.fundedTimeAllocatedThisWeekMins
			}
			
			return progress
		}, 
		initial,
	)
}

export default class FundingDetails extends React.Component<OwnProps & Props & Actions> {

	fundingTypesText = (funding: Funding): string => {
		const byFunder = fundingByFunder(funding)

		const strings = Object.getOwnPropertyNames(byFunder).sort().map(funderRef => {
			const funderInfo = byFunder[funderRef]
			return `from ${funderInfo.funderName || funderInfo.funderRef} for ${Math.round(funderInfo.minutes * 10 / 60) / 10} hour${funderInfo.minutes !== 60 ? 's' : ''}`
		})

		return joinLast(strings, ', ', ' and ')
	}

	renderFundingJobTypes = (funding: Funding) => {
		const byJobType = fundingByJobType(funding)

		return Object.getOwnPropertyNames(byJobType).sort().map(jobTypeRef => {
			const detail = byJobType[jobTypeRef]
			const percentage = detail.minutes !== 0 ? detail.minutesAllocated / detail.minutes * 100 : 0
			
			return (
				<React.Fragment key={jobTypeRef}>
					<h3 className="subtitle">{detail.jobType.name}</h3>
					<div className="funding-graph">
						<div className="total"><div className="booked" style={{ width: `${percentage}%` }} /></div>
						<p className="notes">{Math.round(detail.minutes * 10 / 60) / 10} hour{detail.minutes !== 60 ? 's' : ''} per week funded <small>({Math.round(detail.minutesAllocated * 10 / 60) / 10} hour{detail.minutesAllocated !== 60 ? 's' : ''} allocated this week)</small></p>
					</div>
				</React.Fragment>
			)
		})
	}

	public render() {
		return (
			<div className="content">
				{this.props.you ? (
					<h2 className="title">My funding</h2>
				) : (
					<h2 className="title">{this.props.displayName || ''}’s funding</h2>
				)}
				{!this.props.funding ? (
					<p>We are loading your funding information.</p>
				) : !this.props.funding.hasFunding ? (
					this.props.you ? (
						<p>You do not currently have any funding.</p>
					) : (
						<p>{this.props.displayName} does not currently have any funding.</p>
					)
				) : (
					<>
						{this.props.you ? (
							<p>You have qualified for funding {this.fundingTypesText(this.props.funding)} per week.</p>
						) : (
							<p>{this.props.displayName} has qualified for funding {this.fundingTypesText(this.props.funding)} per week.</p>
						)}
						{this.renderFundingJobTypes(this.props.funding)}
						<p>If you need further care but have used all the funded hours, you can pay for additional private care.</p>
					</>
				)}
			</div>
		)
	}
}
