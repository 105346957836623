import * as React from 'react'
import { RouteComponentProps, Switch, Route } from 'react-router'
import { Props, OwnProps } from '../containers/IndividualisedFunding'
import Overview from './Overview'
import SupportWorkers from './SupportWorkers'
import PayrollDetails from './PayrollDetails'
import TimesheetsExpenses from './TimesheetsExpenses'
import TransactionHistory from '../containers/TransactionHistory'
import RegisterAsAnEmployer from './RegisterAsAnEmployer'
import RegisterSupportWorker from './RegisterSupportWorker'

export default class IndividualisedFundingScreen extends React.Component<Props & OwnProps & RouteComponentProps<undefined>> {

	public render() {
		const { individualisedFunding, funder, transactions } = this.props
		if (!individualisedFunding) {
			return null
		}

		return (
			<Switch>
				<Route 
					path="/account/individualised-funding/:individualisedFundingId" 
					exact={true}
					render={() => (
						<Overview individualisedFunding={individualisedFunding} funder={funder} transactions={transactions} />
					)}
				/>
				<Route 
					path="/account/individualised-funding/:individualisedFundingId/support-workers" 
					exact={true}
					render={() => (
						<SupportWorkers individualisedFunding={individualisedFunding} />
					)}
				/>
				<Route 
					path="/account/individualised-funding/:individualisedFundingId/payroll-details" 
					exact={true}
					render={() => (
						<PayrollDetails individualisedFunding={individualisedFunding} />
					)}
				/>
				<Route 
					path="/account/individualised-funding/:individualisedFundingId/timesheets-expenses" 
					exact={true}
					render={() => (
						<TimesheetsExpenses individualisedFunding={individualisedFunding} />
					)}
				/>
				<Route 
					path="/account/individualised-funding/:individualisedFundingId/transaction-history" 
					exact={true}
					render={() => (
						<TransactionHistory individualisedFunding={individualisedFunding} />
					)}
				/>
				<Route 
					path="/account/individualised-funding/:individualisedFundingId/register-as-employer" 
					exact={true}
					render={() => (
						<RegisterAsAnEmployer individualisedFunding={individualisedFunding} />
					)}
				/>
				<Route 
					path="/account/individualised-funding/:individualisedFundingId/register-support-worker" 
					exact={true}
					render={() => (
						<RegisterSupportWorker individualisedFunding={individualisedFunding} />
					)}
				/>
			</Switch>
		)
	}
}
