import * as d3 from 'd3'
import { Value } from '../types'
import AbstractChart from './AbstractChart'
import moment from 'moment'
import numeral from 'numeral'

export default class LineChart extends AbstractChart<Value[][]> {

	draw = () => {
		const projectedNegativeBalanceData = this.props.data[0].filter((value) => {
			return !isNaN(parseFloat(value.value))
		})
		const projectedBalanceData = this.props.data[1].filter((value) => {
			return !isNaN(parseFloat(value.value))
		})
		const transactionBalanceData = this.props.data[2].filter((value) => {
			return !isNaN(parseFloat(value.value))
		})
		if (projectedNegativeBalanceData.length === 0 || projectedBalanceData.length === 0 || transactionBalanceData.length === 0) {
			return
		}
		
		const yMinExtent = d3.min(this.props.data, d => d3.min(d.map(arr => Number(arr.value)).concat([0])))
		const yMaxExtent = d3.max(this.props.data, d => d3.max(d.map(arr => Number(arr.value))))
		const xMinExtent = d3.min(this.props.data, d => d3.min(d.map(arr => new Date(arr.time))))
		const xMaxExtent = d3.max(this.props.data, d => d3.max(d.map(arr => new Date(arr.time))))

		const width = this.rootNode.offsetWidth
		const height = this.rootNode.offsetHeight

		const margin = this.margin
		const actualWidth = width - (margin.left + margin.right)
	
		const xScale = d3.scaleTime()
			.range([0, actualWidth])
			.domain([xMinExtent === undefined ? 0 : xMinExtent, xMaxExtent === undefined ? 0 : xMaxExtent])
			.unknown(0)
			
		const yScale = d3.scaleLinear()
			.range([height - (margin.top + margin.bottom), 0])
			.domain([yMinExtent === undefined ? 0 : yMinExtent, yMaxExtent === undefined ? 0 : yMaxExtent])
			.unknown(0)

		const svg = d3.select(this.rootNode)
			.append('svg')
			.attr('class', 'line-chart')
			.attr('style', 'display: block;')
			.attr('width', width)
			.attr('height', height)
		
		// Vertical grid lines
		function makeXGridlines() {
			return d3.axisBottom(xScale)
				.ticks(d3.timeMonth)
		}

		svg.append('g')			
			.attr('class', 'grid')
			.attr('transform', 'translate(' + margin.left + ',' + (height - margin.bottom) + ')')
			.call(makeXGridlines()
				.tickSize(-(height - margin.bottom - margin.top))
				.tickFormat(d => ''),
			)
			
		const plot = svg.append('g').attr('transform', `translate(${margin.left},${margin.top})`)

		const xAxis = d3.axisBottom(xScale)
			.ticks(d3.timeMonth)
			.tickFormat((d: Date) => moment(d.toISOString()).format('MMM \'YY'))
			.tickSize(0)
			.tickPadding(12)

		const yAxis = d3.axisLeft(yScale)
			.tickFormat(d => numeral(d).format('$0.[00]a'))
			.tickSize(0)
			.tickPadding(12)

		// Axis lines
			
		plot.append('g')
			.attr('class', 'axis-line')
			.call(yAxis)
			.style('font-size', '8px')
	
		plot.append('g')
			.attr('class', 'axis-line')
			.attr('transform', `translate(0, ${height - margin.bottom - margin.top})`)
			.call(xAxis)
			.selectAll('.tick text') // select all the x tick texts
			.call((t) => {                
				t.each(function() { // for each one
					const self = d3.select(this)
					const s = self.text().split(' ') // get the text and split it
					self.text('') // clear it out
					self.append('tspan') // insert two tspans
						.attr('x', 0)
						.attr('dy', '.8em')
						.text(s[0])
						.style('text-transform', 'uppercase')
						.style('font-size', '8px')
					self.append('tspan')
						.attr('x', 0)
						.attr('dy', '1.2em')
						.text(s[1])
				})
			})

		/* Actual lines */

		// Line 1 
		const line1 = d3.line<typeof projectedNegativeBalanceData[0]>()
			.x(d => xScale(new Date(d.time))!)
			.y(d => yScale(parseFloat(d.value))!)
		plot.append('path')
			.datum(projectedNegativeBalanceData)
			.classed('line', true)
			.attr('d', line1)
			.style('stroke', 'red')
			.style('stroke-dasharray', ('3, 3'))
			.style('fill', 'none')

		// Line 2
		const line2 = d3.line<typeof projectedBalanceData[1]>()
			.x(d => xScale(new Date(d.time))!)
			.y(d => yScale(parseFloat(d.value))!)
		plot.append('path')
			.datum(projectedBalanceData)
			.classed('line', true)
			.attr('d', line2)
			.style('stroke', 'green')
			.style('stroke-dasharray', ('3, 3'))
			.style('fill', 'none')
		
		// Line 3
		const line3 = d3.line<typeof transactionBalanceData[2]>()
			.x(d => xScale(new Date(d.time))!)
			.y(d => yScale(parseFloat(d.value))!)
		plot.append('path')
			.datum(transactionBalanceData)
			.classed('line', true)
			.attr('d', line3)
			.style('stroke', 'black')
			.style('fill', 'none')
			
		// Zero axis 
		if ((yMaxExtent && yMinExtent) !== undefined && yMinExtent! < 0) {
			plot.append('rect')
				.attr('class', 'shaded-area')
				.attr('x', 1)
				.attr('y', yScale(0)!)
				.attr('width', width - (margin.left + margin.right))
				.attr('height', yScale(yMinExtent!)! - yScale(0)!)
			plot.append('line')
				.attr('class', 'zero-line')
				.attr('x1', 0)
				.attr('y1', yScale(0)!)
				.attr('x2', width - (margin.left + margin.right))
				.attr('y2', yScale(0)!)
				.style('stroke-width', 1)
		}

		// Current time circle and marker
		const now = new Date()
		plot.append('circle')
			.attr('cx', xScale(now)!)
			.attr('cy', yScale(Number(transactionBalanceData[transactionBalanceData.length - 1].value))!)
			.attr('fill', 'black')
			.attr('stroke', 'black')
			.attr('r', 3)

		plot.append('line')
			.attr('x1', xScale(now)!)
			.attr('y1', 0)
			.attr('x2', xScale(now)!)
			.attr('y2', height - (margin.top + margin.bottom))
			.style('stroke-width', 1)
			.style('stroke', 'black')
	}
}
