import Component from '../components/UserPersonalDetails'
import { Api } from 'typescript-fetch-api'
import { connect } from 'react-redux'
import { Dispatch, Action } from 'redux'
import { RootStoreState } from 'modules/root'
import { currentUserSelector } from '../selectors'
import * as a from '../actions'

export interface Props {
	user?: DeepReadonly<Api.User>
	saving: boolean
}

export interface Actions {
	onAvatarUpload: (image: string) => void
	onSave: (req: Api.UpdateUserDetailsRequest) => void
}

const mapStateToProps = (state: RootStoreState): Props => {
	return {
		user: currentUserSelector(state),
		saving: state.account.saving,
	}
}

const mapDispatchToProps = (dispatch: Dispatch<Action>): Actions => ({
	onAvatarUpload: (image: string) => {
		dispatch(a.updateUserDetails({
			image,
		}))
	},
	onSave: (req: Api.UpdateUserDetailsRequest) => {
		dispatch(a.updateUserDetails(req))
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
